import React, { Suspense, lazy } from 'react';
import Loading from 'components/Loading/Loading';

const AdminPeek = lazy(() =>
  import(/* webpackChunkName: "AdminPeek" */ './AdminPeek')
);

const LoadableAdminPeek: React.FC = props => (
  <Suspense fallback={<Loading />}>
    <AdminPeek {...props} />
  </Suspense>
);

export default LoadableAdminPeek;
