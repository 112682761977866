import * as React from 'react';
import moment from 'moment';
import styled from 'styled-components';

import { DesktopOnly, IsMobileConsumer } from 'providers/Responsive';

import EnhancedTableHead from 'components/EnhancedTableHead';

import ArrowIcon from '@material-ui/icons/KeyboardArrowRight';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';

import SearchBar from 'components/SearchBar';
import { FontClasses } from 'services/theme';
import Button from '@material-ui/core/Button';

const paddings = {
  paddingLeft: 8,
  paddingRight: 8,
  paddingTop: 12,
  paddingBottom: 12,
};

const contains = (str: string | null, search: string) => {
  if (str === null) return false;
  return str.toLowerCase().includes(search.toLowerCase());
};

function capitalizeFirstLetter(str: string) {
  if (str.length === 0) return str;
  const [first, ...rest] = str.split('');
  const capitalized = first.toUpperCase();
  return [capitalized, ...rest].join('');
}

type OrderDirection = 'desc' | 'asc';

type Name = {
  first: string | null;
  last: string | null;
} | null;

interface OrderListProps {
  orders: {
    id: number;
    status: string;
    placedTimestamp: number;
    details: {
      rep: { name: Name } | null;
      customer: { name: Name } | null;
      hardware: { quantity: number }[];
    };
  }[];
  onRowClick: (_ev: React.MouseEvent<HTMLElement>, id: number) => void;
  onIdClick: (id: number) => void;
}

interface OrderListState {
  orderDir: OrderDirection;
  orderBy: string;
  search: string;
}

class OrderList extends React.Component<OrderListProps, OrderListState> {
  handleRequestSort: (
    _event: React.MouseEvent<HTMLElement>,
    property: string
  ) => void;
  constructor(props: OrderListProps) {
    super(props);
    this.state = {
      orderDir: 'asc',
      orderBy: 'id',
      search: '',
    };

    this.handleRequestSort = (_event, property) => {
      const orderBy = property;
      let orderDir: OrderDirection = 'desc';

      if (this.state.orderBy === property && this.state.orderDir === 'desc') {
        orderDir = 'asc';
      }

      this.setState({ orderDir, orderBy });
    };
  }

  render() {
    return (
      <IsMobileConsumer>
        {({ isMobile }) => {
          const { orders, onRowClick, onIdClick } = this.props;
          const { orderDir, orderBy, search } = this.state;

          const getName = (o: { name: Name } | null) => {
            if (!o) return '';
            const name = o.name;
            if (!name) return '';
            if (!name.first && !name.last) return '';
            if (!name.first) return name.last;
            if (!name.last) return name.first;
            return `${name.first} ${name.last}`;
          };

          const sortedOrders = [...orders].sort((aObj, bObj) => {
            let a;
            let b;
            if (orderBy === 'rep') {
              a = getName(aObj.details.rep);
              b = getName(bObj.details.rep);
            } else if (orderBy === 'customer') {
              a = getName(aObj.details.customer);
              b = getName(bObj.details.customer);
            } else if (orderBy === 'id') {
              a = aObj.id;
              b = bObj.id;
            } else if (orderBy === 'status') {
              a = aObj.status;
              b = bObj.status;
            } else {
              a = aObj.details.hardware.reduce((s, h) => s + h.quantity, 0);
              b = bObj.details.hardware.reduce((s, h) => s + h.quantity, 0);
            }
            const res = (b || '') < (a || '') ? -1 : 1;
            return orderDir === 'desc' ? res * -1 : res;
          });
          const headers = [
            { id: 'id', label: 'Order', centered: false },
            { id: 'rep', label: 'Rep', centered: false },
            { id: 'customer', label: 'Customer', centered: false },
            { id: 'boundIds', label: 'Bound IDs', centered: false },
            { id: 'quantity', label: 'Units', centered: true },
            { id: 'status', label: 'Status', centered: false },
            { id: 'button', label: '', centered: false },
          ];
          if (isMobile) {
            headers.splice(2, 2);
            headers.splice(3, 1);
          }
          return (
            <React.Fragment>
              <div style={{ margin: 10 }}>
                <SearchBar
                  content={this.state.search}
                  onChange={(e) => this.setState({ search: e.target.value })}
                  onClear={() => this.setState({ search: '' })}
                />
              </div>
              <Table>
                <EnhancedTableHead
                  columnData={headers}
                  order={orderDir}
                  orderBy={orderBy}
                  onRequestSort={this.handleRequestSort}
                />
                <TableBody>
                  {!!orders &&
                    sortedOrders
                      .filter((order) => {
                        if (search === '') return true;
                        if (contains(order.id.toString(), search)) return true;
                        if (contains(order.status, search)) return true;
                        if (order.details) {
                          if (order.details.rep && order.details.rep.name) {
                            if (
                              order.details.rep.name.first &&
                              contains(
                                `${order.details.rep.name.first} ${order.details.rep.name.last}`,
                                search
                              )
                            )
                              return true;
                          }
                          if (
                            order.details.customer &&
                            order.details.customer.name
                          ) {
                            if (
                              order.details.customer.name.first &&
                              contains(
                                `${order.details.customer.name.first} ${order.details.customer.name.last}`,
                                search
                              )
                            )
                              return true;
                          }
                        }
                        return false;
                      })
                      .map((order) => {
                        return (
                          <TableRow
                            key={order.id}
                            onClick={(event) => onRowClick(event, order.id)}
                            data-test="orderTableRow"
                            hover
                            style={{ cursor: 'pointer' }}
                          >
                            <TableCell
                              className={FontClasses.body}
                              style={paddings}
                            >
                              <span className={FontClasses.body}>
                                {order.id}
                              </span>
                              <br />
                              <div
                                className={FontClasses.caption}
                                style={{ width: 72 }}
                              >
                                <i>
                                  {moment(order.placedTimestamp).format(
                                    'MM-DD-YYYY'
                                  )}
                                </i>
                              </div>
                            </TableCell>
                            <TableCell
                              className={FontClasses.body}
                              style={paddings}
                            >
                              {getName(order.details.rep)}
                            </TableCell>
                            <DesktopOnly>
                              <TableCell
                                className={FontClasses.body}
                                style={paddings}
                              >
                                {getName(order.details.customer)}
                              </TableCell>
                            </DesktopOnly>
                            <TableCell
                              className={FontClasses.body}
                              style={paddings}
                            >
                              <Button
                                onClick={() => onIdClick(order.id)}
                                variant="outlined"
                              >
                                View
                              </Button>
                            </TableCell>
                            <DesktopOnly>
                              <TableCell
                                style={{
                                  ...paddings,
                                  textAlign: 'center',
                                }}
                                className={FontClasses.title}
                              >
                                {order.details.hardware.reduce(
                                  (s, h) => s + h.quantity,
                                  0
                                )}
                              </TableCell>
                            </DesktopOnly>
                            <TableCell
                              className={FontClasses.body}
                              style={paddings}
                            >
                              <Status>
                                {capitalizeFirstLetter(order.status)}
                              </Status>
                            </TableCell>
                            <DesktopOnly>
                              <TableCell align="right">
                                <ArrowIcon />
                              </TableCell>
                            </DesktopOnly>
                          </TableRow>
                        );
                      })}
                </TableBody>
              </Table>
            </React.Fragment>
          );
        }}
      </IsMobileConsumer>
    );
  }
}

const Status = styled.div``;

export default OrderList;
