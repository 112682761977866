import * as React from 'react';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components';

import { theme, colors, FontClasses } from 'services/theme';
import 'react-dates/initialize';

import './theme.css';
import { useIsMobile } from 'providers/Responsive';

/*
Primary Colors:
- Trellis Green: #226f54
- Light Green: #87c38f
- White: #ffffff

- Normal Text: #222222
- Mild Text: #555555
- Subtle Text: #999999
- Link color: #226f54
*/

// Use http://mcg.mbitson.com to generate palette
const normalText = colors.text;
const mildText = colors.textLight;

const primary = {
  light: colors.primaryLight,
  main: colors.primary,
  dark: colors.primaryDark,
  contrastDefaultColor: '#000',
};

const secondary = {
  light: colors.primaryExtraLight,
  main: colors.primaryLight,
  dark: '#74B57C',
  contrastText: '#000',
};

const textColors = {
  primary: normalText,
  secondary: mildText,
  disabled: 'rgba(0, 0, 0, 0.38)',
  hint: 'rgba(0, 0, 0, 0.38)',
  icon: 'rgba(0, 0, 0, 0.38)',
  divider: 'rgba(0, 0, 0, 0.12)',
  lightDivider: 'rgba(0, 0, 0, 0.075)',
};

const muiTheme = createMuiTheme({
  palette: {
    primary,
    secondary,
    text: textColors,
    error: {
      main: theme.colors.error,
    },
  },
  typography: {
    fontFamily: 'Lato',
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: '1rem !important',
      },
    },
  },
});
const withTheme = (WrappedComponent: React.ComponentType) => (props: {}) => {
  const isMobile = useIsMobile();
  const dynamicTheme = { ...theme };
  dynamicTheme.isMobile = isMobile;

  return (
    <div style={{ height: '100%' }}>
      {/* Background */}
      <div
        className={FontClasses.body}
        style={{
          background: '#e6e6e6',
          position: 'fixed',
          height: '100vh',
          width: '100vw',
          zIndex: -10000,
        }}
      />
      <MuiThemeProvider theme={muiTheme}>
        <StyledComponentsThemeProvider theme={theme}>
          <WrappedComponent {...props} />
        </StyledComponentsThemeProvider>
      </MuiThemeProvider>
    </div>
  );
};

export default withTheme;
