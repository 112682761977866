import React, { Suspense, lazy } from 'react';
import Loading from 'components/Loading/Loading';

const AdminPrepay = lazy(() =>
  import(/* webpackChunkName: "AdminPrepay" */ './AdminPrepay')
);

const LoadableAdminPrepay: React.FC = props => (
  <Suspense fallback={<Loading />}>
    <AdminPrepay {...props} />
  </Suspense>
);

export default LoadableAdminPrepay;
