import * as React from 'react';
import gql from 'graphql-tag';
import { AllSensorsComponent, PermissionLevel } from 'types/graphql';

import Loading from 'components/Loading/Loading';
import GenericError from 'components/Errors/GenericError';
import Paper from '@material-ui/core/Paper';

import SearchBar from 'components/SearchBar';

const arrowIcon = require('./assets/arrow.png');

gql`
  fragment sensorInfo on Sensor {
    id
    name
    moistureMethod
    owner {
      id
      name {
        first
        last
      }
    }
    users {
      id
      name {
        first
        last
      }
      permissionLevel
    }
    order {
      id
      details {
        rep {
          id
          name {
            first
            last
          }
        }
      }
    }
  }

  fragment gatewayInfo on Gateway {
    id
    owner {
      id
      name {
        first
        last
      }
    }
    users {
      id
      name {
        first
        last
      }
      permissionLevel
    }
    order {
      id
      details {
        rep {
          id
          name {
            first
            last
          }
        }
      }
    }
  }

  query AllSensors {
    allUsers {
      id
      sensors(onlyOwned: true) {
        id
        ...sensorInfo
      }
      gateways(onlyOwned: true) {
        id
        ...gatewayInfo
      }
    }
    allDistributors {
      id
      name {
        first
        last
      }
      unassigned {
        sensors {
          id
        }
        gateways {
          id
        }
      }
    }
  }
`;

const contains = (str: string | null, search: string) => {
  if (str === null) return false;
  return str.toLowerCase().includes(search.toLowerCase());
};

interface AdminAllSensorsProps {}

interface AdminAllSensorsState {
  search: string;
  orderBy: string;
  orderDir: 'asc' | 'desc';
}

class AdminAllSensors extends React.Component<
  AdminAllSensorsProps,
  AdminAllSensorsState
> {
  handleRequestSort: (
    event: React.MouseEvent<HTMLElement>,
    property: string
  ) => void;
  constructor(props: AdminAllSensorsProps) {
    super(props);
    this.state = {
      search: '',
      orderBy: 'id',
      orderDir: 'desc',
    };
    this.handleRequestSort = (_event, property) => {
      const orderBy = property;
      let orderDir: 'asc' | 'desc' = 'desc';

      if (this.state.orderBy === property && this.state.orderDir === 'desc') {
        orderDir = 'asc';
      }

      this.setState({ orderDir, orderBy });
    };
  }

  render() {
    return (
      <AllSensorsComponent>
        {({ error, loading, data }) => {
          if (loading) return <Loading />;
          if (error || !data) return <GenericError />;
          const { allUsers, allDistributors } = data;
          const { search } = this.state;

          const hardware: {
            id: string;
            name: string | null;
            type: 'Sensor' | 'Gateway';
            owner: string | null;
            distributor: string | null;
            sharedUsers: string[];
          }[] = [];

          allDistributors.forEach(distributor => {
            if (distributor && distributor.unassigned) {
              if (distributor.unassigned.sensors) {
                distributor.unassigned.sensors.forEach(sensor => {
                  hardware.push({
                    id: sensor.id,
                    name: `Sensor ${sensor.id}`,
                    type: 'Sensor',
                    owner: null,
                    distributor: distributor.name
                      ? `${distributor.name.first} ${distributor.name.last}`
                      : null,
                    sharedUsers: [],
                  });
                });
              }
              if (distributor.unassigned.gateways) {
                distributor.unassigned.gateways.forEach(gateway => {
                  hardware.push({
                    id: gateway.id,
                    name: null,
                    type: 'Gateway',
                    owner: null,
                    distributor: distributor.name
                      ? `${distributor.name.first} ${distributor.name.last}`
                      : null,
                    sharedUsers: [],
                  });
                });
              }
            }
          });

          allUsers.forEach(user => {
            if (user) {
              if (user.sensors) {
                user.sensors.forEach(sensor => {
                  const repName =
                    sensor.order &&
                    sensor.order.details.rep &&
                    sensor.order.details.rep.name
                      ? sensor.order.details.rep.name
                      : null;
                  hardware.push({
                    id: sensor.id,
                    name: sensor.name,
                    type: 'Sensor',
                    owner:
                      sensor.owner && sensor.owner.name
                        ? `${sensor.owner.name.first} ${sensor.owner.name.last}`
                        : null,
                    distributor: repName
                      ? `${repName.first} ${repName.last}`
                      : null,
                    sharedUsers: sensor.users
                      .filter(u => {
                        return u.permissionLevel !== PermissionLevel.owner;
                      })
                      .map(u => {
                        return u.name ? `${u.name.first} ${u.name.last}` : u.id;
                      }),
                  });
                });
              }
              if (user.gateways) {
                user.gateways.forEach(gateway => {
                  const repName =
                    gateway.order &&
                    gateway.order.details.rep &&
                    gateway.order.details.rep.name
                      ? gateway.order.details.rep.name
                      : null;
                  hardware.push({
                    id: gateway.id,
                    name: `Gateway ${gateway.id}`,
                    type: 'Gateway',
                    owner:
                      gateway.owner && gateway.owner.name
                        ? `${gateway.owner.name.first} ${
                            gateway.owner.name.last
                          }`
                        : null,
                    distributor: repName
                      ? `${repName.first} ${repName.last}`
                      : null,
                    sharedUsers: gateway.users
                      .filter(u => {
                        return u.permissionLevel !== PermissionLevel.owner;
                      })
                      .map(u => {
                        return u.name ? `${u.name.first} ${u.name.last}` : u.id;
                      }),
                  });
                });
              }
            }
          });

          const searchResults = hardware.filter(e => {
            if (contains(e.id, search)) return true;
            if (contains(e.name, search)) return true;
            if (contains(e.owner, search)) return true;
            if (e.sharedUsers.some(name => contains(name, search))) return true;
            if (contains(e.distributor, search)) return true;
            return false;
          });

          return (
            <Paper
              style={{
                marginTop: 16,
                marginLeft: 'auto',
                marginRight: 'auto',
                maxWidth: 1200,
                padding: 8,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <SearchBar
                  maxWidth={600}
                  content={search}
                  onChange={e => this.setState({ search: e.target.value })}
                  onClear={() => this.setState({ search: '' })}
                />
              </div>
              <div>
                {searchResults.map(hw => {
                  const hardwareURL =
                    'https://distributor.mytrellis.com/admin/status/' +
                    (hw.type === 'Sensor'
                      ? `sensor/${hw.id}`
                      : `gateway/${hw.id}`);

                  const usersDiv = hw.sharedUsers ? (
                    <div>
                      {hw.sharedUsers.map(name => {
                        return <div key={name}>{name}</div>;
                      })}
                    </div>
                  ) : (
                    ''
                  );

                  return (
                    <div key={`${hw.id}${hw.type}${hw.distributor}`}>
                      <div
                        style={{
                          alignContent: 'center',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          margin: '0 auto',
                          padding: 8,
                        }}
                      >
                        <div style={{ flex: '0 0 200px' }}>
                          <b>ID: {hw.id}</b>
                          <br />
                          Name: {hw.name}
                          <br />
                          Hardware Type: {hw.type}
                          <br />
                        </div>
                        <div style={{ flex: '0 0 150px' }}>
                          <div>Users: </div>
                          <div>
                            <b>{hw.owner}</b>
                            {usersDiv}
                          </div>
                        </div>
                        <div style={{ flex: '0 0 150px' }}>
                          <div>Distributor:</div>
                          {hw.distributor}
                        </div>
                        <div
                          style={{
                            alignSelf: 'center',
                          }}
                        >
                          <a href={hardwareURL}>
                            <img src={arrowIcon} />
                          </a>
                        </div>
                      </div>
                      <hr />
                    </div>
                  );
                })}
              </div>
            </Paper>
          );
        }}
      </AllSensorsComponent>
    );
  }
}

export default AdminAllSensors;
