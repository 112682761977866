import * as React from 'react';

import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';

import { AddCustomerComponent } from 'types/graphql';
import GraphQLError from 'components/Errors/GraphQLError';
import { FontClasses } from 'services/theme';

interface PopupCustomerProps {
  open: boolean;
  close: () => void;
  refresh: () => void;
  repId: string;
  selectCustomer: (id: string) => void;
}
interface PopupCustomerState {
  fname: string;
  lname: string;
  email: string;
  phone: string;
  street: string;
  city: string;
  state: string;
  zip: string;
}

class PopupCustomer extends React.Component<
  PopupCustomerProps,
  PopupCustomerState
> {
  initialState = () => ({
    fname: '',
    lname: '',
    email: '',
    phone: '',
    street: '',
    city: '',
    state: '',
    zip: '',
  });

  state = this.initialState();

  render() {
    return (
      <AddCustomerComponent>
        {(addCustomer, { error, loading }) => {
          const submit = () => {
            addCustomer({
              variables: {
                input: {
                  email: this.state.email,
                  name: {
                    first: this.state.fname,
                    last: this.state.lname,
                  },
                  phone: this.state.phone,
                  address: {
                    street: this.state.street,
                    city: this.state.city,
                    state: this.state.state,
                    zip: this.state.zip,
                    name: {
                      first: this.state.fname,
                      last: this.state.lname,
                    },
                  },
                  repId: this.props.repId,
                },
              },
            }).then(res => {
              // Reset the state, tell parent to refresh, and close
              this.setState(this.initialState);
              this.props.refresh();
              if (
                res &&
                res.data &&
                res.data.addCustomer &&
                res.data.addCustomer.id
              )
                this.props.selectCustomer(res.data.addCustomer.id);
              this.props.close();
            });
          };

          const canSubmit = Object.values(this.state).every(e => e !== '');
          return (
            <Popover
              style={{ margin: 0 }}
              open={this.props.open}
              anchorPosition={{ top: 200, left: 30 }}
              anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
              transformOrigin={{ vertical: 'center', horizontal: 'center' }}
              onClose={this.props.close}
            >
              <Card>
                <div
                  className={FontClasses.title}
                  style={{ textAlign: 'center', paddingTop: 10 }}
                >
                  Add a Customer
                </div>
                <CardContent>
                  <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    <TextField
                      label="First Name"
                      value={this.state.fname}
                      onChange={e => this.setState({ fname: e.target.value })}
                      data-test="firstName"
                    />
                    <TextField
                      label="Last Name"
                      value={this.state.lname}
                      onChange={e => this.setState({ lname: e.target.value })}
                      data-test="lastName"
                    />
                  </div>
                  <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    <TextField
                      label="Email Address"
                      value={this.state.email}
                      onChange={e => this.setState({ email: e.target.value })}
                      data-test="email"
                    />
                    <TextField
                      label="Phone Number"
                      value={this.state.phone}
                      onChange={e => this.setState({ phone: e.target.value })}
                      data-test="phone"
                    />
                  </div>
                  <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    <TextField
                      label="Address"
                      value={this.state.street}
                      onChange={e => this.setState({ street: e.target.value })}
                      fullWidth
                      data-test="addressStreet"
                    />
                  </div>
                  <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    <TextField
                      label="City"
                      value={this.state.city}
                      onChange={e => this.setState({ city: e.target.value })}
                      data-test="addressCity"
                    />
                    <TextField
                      label="State"
                      value={this.state.state}
                      onChange={e => this.setState({ state: e.target.value })}
                      data-test="addressState"
                    />
                    <TextField
                      label="Zip Code"
                      value={this.state.zip}
                      onChange={e => this.setState({ zip: e.target.value })}
                      data-test="addressZip"
                    />
                  </div>
                  <GraphQLError error={error} />
                  <div
                    style={{
                      marginTop: 10,
                      alignItems: 'center',
                      width: '100%',
                      display: 'flex',
                    }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={submit}
                      disabled={loading || !canSubmit}
                      data-test="addCustomerSave"
                    >
                      Save
                    </Button>
                    <div style={{ width: 24 }} />
                    <Button
                      variant="contained"
                      onClick={this.props.close}
                      disabled={loading}
                    >
                      Cancel
                    </Button>
                  </div>
                </CardContent>
              </Card>
            </Popover>
          );
        }}
      </AddCustomerComponent>
    );
  }
}

export default PopupCustomer;
