import * as React from 'react';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import { colors, FontClasses } from 'services/theme';

interface SelectRepProps {
  selectedId: string;
  reps: {
    id: string;
    name: { first: string | null; last: string | null } | null;
  }[];
  callback: (val: string) => void;
  button: (types: string) => void;
}

class SelectRep extends React.Component<SelectRepProps> {
  render() {
    const { reps, selectedId } = this.props;
    return (
      <div>
        <div className={FontClasses.subtitle}>
          Sales Representative Contact Information
        </div>
        <div style={{ color: colors.textLight }}>
          Select or add a sales rep who can help setup the customer{"'"}s
          equipment on the distributor portal. If you will be doing this, add or
          select yourself as the rep.
        </div>
        <Select
          style={{ width: 200, marginRight: 20, marginTop: 0 }}
          value={selectedId}
          onChange={(e) => {
            const val = e.target.value as string;
            if (val === 'addNewRep') {
              this.props.button('rep');
              this.props.callback('');
            } else {
              this.props.callback(val);
            }
          }}
          data-test="selectRep"
        >
          {reps.map(({ id, name }) => {
            return (
              <MenuItem value={id} key={id}>
                {name && name.first} {name && name.last}
              </MenuItem>
            );
          })}
          <MenuItem
            value="addNewRep"
            style={{ background: colors.primary, color: 'white' }}
            data-test="addNewRep"
          >
            Add New Rep
          </MenuItem>
        </Select>
      </div>
    );
  }
}

export default SelectRep;
