import * as React from 'react';
import moment from 'moment';
import styled from 'styled-components';

import { useIsMobile } from 'providers/Responsive';

import { FontClasses, colors } from 'services/theme';
import { Model, computeUniqueModelId, getModelTitles } from 'services/model';
import { HardwareTypeEnum, ZohoInvoiceStatus } from 'types/graphql';
import { formatCurrency } from 'services/currency';

const planToPrice = (plan: string | null) => {
  switch (plan) {
    case 'basic':
      return 10;
    case 'extended':
      return 15;
    case 'ultimate':
      return 25;
    default:
      return 0;
  }
};

const okColor = 'hsla(100, 100%, 65%, 0.25)';
const warningColor = 'hsla(60, 90%, 63%, 0.25)';
const errorColor = 'hsla(10, 100%, 65%, 0.25)';

type Name = {
  first: string | null;
  last: string | null;
} | null;

interface OrderSummaryProps {
  rep?: { name: Name; email: string | null; phone: string | null } | null;
  address?: {
    name: Name;
    street: string | null;
    city: string | null;
    state: string | null;
    zip: string | null;
  } | null;
  hardware: {
    quantity: number;
    pricePerUnit: number;
    model: Model;
  }[];
  shipping?: {
    amount: number;
    carrier: string;
    trackingNumber: string | null;
  } | null;
  prepaid: {
    months: number;
    plan: string | null;
    start: number | null;
  } | null;
  customer: {
    email: string | null;
    name: Name;
  } | null;
  notes?: string | null;
  sharingEmails: string[];
  invoice?: {
    status: ZohoInvoiceStatus;
    due: number;
    paymentUrl: string;
  } | null;
  managedServiceOptions: {
    months: number;
    prepaid: boolean;
    price: number;
  } | null;
}

const OrderSummary: React.FC<OrderSummaryProps> = ({
  rep,
  address,
  hardware,
  shipping,
  prepaid,
  customer,
  notes,
  sharingEmails,
  invoice,
  managedServiceOptions,
}) => {
  const isMobile = useIsMobile();
  let itemSubtotal = 0;
  let prepaidItemsCount = 0;
  hardware.forEach(
    ({
      pricePerUnit,
      quantity,
      model: { type, hasRainGauge, hasWeatherStation, hasPressureSensor },
    }) => {
      itemSubtotal += quantity * pricePerUnit;
      if (type === HardwareTypeEnum.sensor) prepaidItemsCount += quantity;
      if (type === HardwareTypeEnum.gateway) {
        if (hasRainGauge || hasWeatherStation || hasPressureSensor)
          prepaidItemsCount += quantity;
      }
    }
  );

  let prepaidTotal = 0;
  if (prepaid) {
    prepaidTotal =
      prepaidItemsCount * planToPrice(prepaid.plan) * prepaid.months;
  }

  const haveShippingInfo =
    !!shipping &&
    !!shipping.amount &&
    !!shipping.carrier &&
    !!shipping.trackingNumber;

  let total = itemSubtotal;
  if (haveShippingInfo && shipping) total += shipping.amount;
  if (prepaid) total += prepaidTotal;

  const headerStyle = {
    padding: 16,
    paddingBottom: 0,
  };
  const bodyStyle = { padding: 16 };
  const maxCardWidth = isMobile ? undefined : 300;

  let invoiceText = '';
  let invoiceLinkText = '';
  let invoiceColor = '';
  if (invoice) {
    switch (invoice.status) {
      case ZohoInvoiceStatus.draft:
        break;
      case ZohoInvoiceStatus.sent:
        invoiceText = `Invoice due on ${moment(invoice.due).format('MMM Do')}.`;
        invoiceLinkText = 'Click here to pay.';
        invoiceColor = warningColor;
        break;
      case ZohoInvoiceStatus.paid:
        invoiceText = 'Invoice paid.';
        invoiceLinkText = 'Click here to view.';
        invoiceColor = okColor;
        break;
      case ZohoInvoiceStatus.overdue:
        invoiceText = 'Invoice overdue!';
        invoiceLinkText = 'Click here to pay.';
        invoiceColor = errorColor;
        break;
    }
  }

  return (
    <div>
      {invoice && invoiceText && (
        <InvoiceBanner color={invoiceColor}>
          {invoiceText}
          {'  '}
          <a
            href={invoice.paymentUrl}
            rel="noopener noreferrer"
            target="_blank"
          >
            {invoiceLinkText}
          </a>
        </InvoiceBanner>
      )}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        {rep && (
          <div style={{ maxWidth: maxCardWidth, flex: 1 }}>
            <div className={FontClasses.subtitle} style={headerStyle}>
              Sales Representative
            </div>
            <div style={bodyStyle}>
              <div>
                {rep.name && rep.name.first} {rep.name && rep.name.last}
              </div>
              <LightCaption>{rep.email}</LightCaption>
              <LightCaption>{rep.phone}</LightCaption>
            </div>
          </div>
        )}
        {customer && (
          <div style={{ maxWidth: maxCardWidth, flex: 1 }}>
            <div className={FontClasses.subtitle} style={headerStyle}>
              Customer
            </div>
            <div style={bodyStyle}>
              <div>
                {customer.name && customer.name.first}{' '}
                {customer.name && customer.name.last}
              </div>
              <LightCaption>{customer.email}</LightCaption>
              {prepaid && prepaid.start && (
                <LightCaption>
                  Prepaid subscription starts on{' '}
                  {moment(prepaid.start).format('MMMM Do')}
                </LightCaption>
              )}
              {sharingEmails.length > 0 && (
                <LightCaption style={{ marginTop: 8 }}>
                  Will be shared with:
                </LightCaption>
              )}
              {sharingEmails.map((shareEmail, i) => (
                <LightCaption key={i}>{shareEmail}</LightCaption>
              ))}
            </div>
          </div>
        )}
        {address && (
          <div style={{ maxWidth: maxCardWidth, flex: 1 }}>
            <div className={FontClasses.subtitle} style={headerStyle}>
              Shipping Information
            </div>
            <div style={bodyStyle}>
              <div>
                {address.name && address.name.first}{' '}
                {address.name && address.name.last}
              </div>
              <div>{address.street}</div>
              <div>
                {address.city}, {address.state} {address.zip}
              </div>
              <div style={{ height: 8 }} />
              {haveShippingInfo && shipping ? (
                <div>
                  <LightCaption>Cost: ${shipping.amount}</LightCaption>
                  <LightCaption>
                    {shipping.carrier} Tracking #: {shipping.trackingNumber}
                  </LightCaption>
                </div>
              ) : (
                <>
                  {!managedServiceOptions && (
                    <div
                      className={FontClasses.caption}
                      style={{ color: colors.textLight }}
                    >
                      * Shipping prices will be added at the time of shipment
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        )}
      </div>
      <div>
        <div style={bodyStyle}>
          <hr />
          {!managedServiceOptions && (
            <div className={FontClasses.subtitle}>Hardware</div>
          )}
          {hardware.map(({ model, pricePerUnit, quantity }) => {
            const modelId = computeUniqueModelId(model);
            const { title, subtitle } = getModelTitles(model);

            const amountNum = quantity || 0;

            const totalPrice = pricePerUnit * amountNum;
            const totalPriceStr = formatCurrency(totalPrice);
            const pricePerUnitStr = formatCurrency(pricePerUnit);
            return (
              <Row key={modelId}>
                <RowTitle>
                  {title}
                  <LightCaption>{subtitle}</LightCaption>
                </RowTitle>
                <RowRight>
                  {managedServiceOptions ? (
                    <div>x{amountNum}</div>
                  ) : (
                    <>
                      <div>{totalPriceStr}</div>
                      <LightCaption>
                        {amountNum} x {pricePerUnitStr}
                      </LightCaption>
                    </>
                  )}
                </RowRight>
              </Row>
            );
          })}
          {managedServiceOptions && (
            <Row>
              <RowTitle>
                Managed Service
                <LightCaption>
                  {managedServiceOptions.months} months
                </LightCaption>
              </RowTitle>
              <RowRight>
                {managedServiceOptions.prepaid ? (
                  <div>
                    ${managedServiceOptions.price}x
                    {managedServiceOptions.months}
                  </div>
                ) : (
                  <div> ${managedServiceOptions.price}x1</div>
                )}
              </RowRight>
            </Row>
          )}
          <hr />
          <div
            style={{
              width: '100%',
              marginRight: 20,
              textAlign: 'right',
            }}
          >
            {managedServiceOptions ? (
              <>
                <div className={FontClasses.subtitle} style={{ marginTop: 8 }}>
                  {managedServiceOptions.prepaid ? (
                    <Total>
                      Total:{' '}
                      {formatCurrency(
                        managedServiceOptions.price *
                          managedServiceOptions.months
                      )}
                    </Total>
                  ) : (
                    <Total>
                      Monthly Total:{' '}
                      {formatCurrency(managedServiceOptions.price)}
                    </Total>
                  )}
                </div>
              </>
            ) : (
              <>
                <div>Item Subtotal: ${itemSubtotal}</div>
                <div>
                  Shipping:{' '}
                  {haveShippingInfo && shipping
                    ? `$${shipping.amount}`
                    : '*TBD'}
                </div>
                {prepaid && (
                  <div>
                    Pre-paid Software Subscription ({prepaidItemsCount} units x{' '}
                    {prepaid.months} months): ${prepaidTotal}
                  </div>
                )}
                <div className={FontClasses.subtitle} style={{ marginTop: 8 }}>
                  <Total>Total: ${total}</Total>
                </div>
              </>
            )}
          </div>
        </div>
        {notes && (
          <div style={{ marginTop: 10 }}>
            <div className={FontClasses.subtitle} style={headerStyle}>
              Notes
            </div>
            <div style={{ ...bodyStyle, display: 'flex' }}>
              <div
                style={{
                  flex: '1 1 0px',
                  width: 0,
                  whiteSpace: 'pre-wrap',
                }}
              >
                {notes}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const LightCaption = styled.div`
  font-size: ${(p) => p.theme.fontSizes.caption};
  color: ${(p) => p.theme.colors.textLight};
`;

const InvoiceBanner = styled.div<{ color: string }>`
  background: ${(p) => p.color};
  padding: 16px;
  border-radius: 8px;
`;

const Total = styled.span`
  font-weight: ${(p) => p.theme.weights.bold};
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 14;
`;

const RowTitle = styled.div``;

const RowRight = styled.div`
  margin-left: auto;
  text-align: right;
`;

export default OrderSummary;
