import * as React from 'react';
import TwoColumn from './TwoColumn';
import { HardwareTypeEnum } from 'types/graphql';
// disabled eslint for next line because usage of Select and
// MenuItem is based on boolean of if sensor or gateway station
// and is therefore limited according to eslint
// eslint-disable-next-line
import { Select, MenuItem } from '@material-ui/core';
import { SensorTypeOption } from '../AddLineItem';
import VolumePricing from './VolumePricing';
import { colors, FontClasses } from 'services/theme';
import Addons from './SensorAddons';
import Spacer from './Spacer';

interface ReplacementBoardDetailsProps {
  //Sensor or Gateway Board Choice
  type: HardwareTypeEnum;
  //Sensor Options
  sensorType: SensorTypeOption | undefined;
  onChange: (value: SensorTypeOption) => void;
  //Gateway Options
  hasRainGauge: boolean | undefined;
  hasRainGaugeChange: (hasRainGauge: boolean) => void;
  hasWeatherStation: boolean | undefined;
  hasWeatherStationChange: (hasWeatherStation: boolean) => void;
  hasPressureSensor: boolean | undefined;
  hasPressureSensorChange: (hasPressureSensor: boolean) => void;
  //Hardware Version Options
  moistureDepths: number[] | null | undefined;
  moistureDepthsChange: (moistureDepths: number[] | null) => void;
  //Volume Pricing details
  finish: (quantity: number) => void;
  prices:
    | {
        quantity: number;
        price: number;
      }[]
    | null;
  hidePrices: boolean;
}

class ReplacementBoardDetails extends React.Component<
  ReplacementBoardDetailsProps
> {
  watermarkDepths = [
    [4, 8],
    [8, 16],
    [6, 12, 18],
    [6, 12, 18, 24],
    [6, 12, 24, 36],
  ];

  sentekDepths = [
    [2, 6, 10],
    [2, 6, 10, 14, 18, 22],
    [2, 6, 10, 14, 18, 22, 26, 30, 34],
    [2, 6, 10, 14, 18, 22, 26, 30, 34, 38, 42, 46],
  ];
  //handles the event when changing the between watermark and sentek sensors
  handleSensorChange = (
    e: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const val = e.target.value;
    if (val === 'watermark') {
      this.props.onChange('watermark');
    } else {
      this.props.onChange('sentek');
    }
  };
  //handles the event when changing the between types of gateway boards
  //has the option to also allow for rain gauge, but currently not implemented
  handleGatewayChange = (
    e: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const val = e.target.value;
    if (val === 'basic') {
      this.props.hasRainGaugeChange(false);
      this.props.hasWeatherStationChange(false);
      this.props.hasPressureSensorChange(false);
    }
    if (val === 'rg') {
      this.props.hasRainGaugeChange(true);
      this.props.hasWeatherStationChange(false);
      this.props.hasPressureSensorChange(false);
    }
    if (val === 'ws') {
      this.props.hasRainGaugeChange(false);
      this.props.hasWeatherStationChange(true);
      this.props.hasPressureSensorChange(false);
    }
    if (val === 'ps') {
      this.props.hasRainGaugeChange(false);
      this.props.hasWeatherStationChange(false);
      this.props.hasPressureSensorChange(true);
    }
  };

  handleDepthsChange = (
    e: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const val = e.target.value as string;
    const arr: number[] = JSON.parse(val);
    this.props.moistureDepthsChange(arr);
  };

  handleRainGaugeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (this.props.hasRainGaugeChange)
      this.props.hasRainGaugeChange(e.target.checked);
  };

  render() {
    //logic for what type of gateway board is chosen
    let gatewayValue = '';
    if (this.props.hasRainGauge) gatewayValue = 'rg';
    if (this.props.hasWeatherStation) gatewayValue = 'ws';
    if (this.props.hasPressureSensor) gatewayValue = 'ps';

    //logic for what type of sensor board is chosen
    let sensorValue = '';
    if (this.props.sensorType === 'sentek') sensorValue = 'sentek';
    if (this.props.sensorType === 'watermark') sensorValue = 'watermark';

    //booleans for which component to show
    const sensorBoard =
      this.props.type === HardwareTypeEnum.replacementSensorBoard;
    const gatewayBoard =
      this.props.type === HardwareTypeEnum.replacementGatewayBoard;
    const mercuryModule =
      this.props.type === HardwareTypeEnum.replacementMercuryModule;

    const depths =
      this.props.sensorType === 'watermark'
        ? this.watermarkDepths
        : this.sentekDepths;

    // eslint-disable-next-line
    const depthsValue = JSON.stringify(this.props.moistureDepths);

    const showAddons = this.props.sensorType === 'watermark';

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      >
        {sensorBoard && (
          <React.Fragment>
            <TwoColumn
              title="Sensor Board"
              subtitle="Select a sensor board."
              content={
                <>
                  <div
                    style={{ color: colors.textLight }}
                    className={FontClasses.caption}
                  >
                    Sensor Board
                  </div>
                  <Select
                    value={sensorValue}
                    onChange={this.handleSensorChange}
                    style={{ width: 200, marginBottom: 12 }}
                  >
                    <MenuItem value={'sentek'}>Sentek</MenuItem>
                    <MenuItem value={'watermark'}>Watermark</MenuItem>
                  </Select>
                </>
              }
            />
            <div style={{ height: 24 }} />
          </React.Fragment>
        )}
        {gatewayBoard && (
          <React.Fragment>
            <TwoColumn
              title="Gateway Board"
              subtitle="Select a gateway board."
              content={
                <React.Fragment>
                  <Select
                    value={gatewayValue}
                    onChange={this.handleGatewayChange}
                    style={{ width: 200, marginBottom: 12 }}
                  >
                    <MenuItem value={'basic'}>Regular, no add-ons</MenuItem>
                    <MenuItem value={'ps'}>Pressure Sensor</MenuItem>
                    <MenuItem value={'ws'}>Weather Station</MenuItem>
                  </Select>
                </React.Fragment>
              }
            />
            <div style={{ height: 24 }} />
          </React.Fragment>
        )}

        {mercuryModule && (
          <React.Fragment>
            <TwoColumn
              title="Mercury Module"
              subtitle="Select a mercury module."
              content={
                <React.Fragment>
                  <Select style={{ width: 200, marginBottom: 12 }}>
                    <MenuItem value={'r-cb-mm'}>Mercury Module</MenuItem>
                  </Select>
                </React.Fragment>
              }
            />
            <div style={{ height: 24 }} />
          </React.Fragment>
        )}

        {sensorBoard && (
          <React.Fragment>
            <TwoColumn
              title="Sensor Probe"
              subtitle="What sensor probe depths should the circuit board have?"
              content={
                <React.Fragment>
                  <Select
                    value={depthsValue ? depthsValue : ''}
                    onChange={this.handleDepthsChange}
                    style={{ width: 200, marginBottom: 12 }}
                  >
                    {depths.map((d) => {
                      const value = JSON.stringify(d);
                      return (
                        <MenuItem value={value} key={value}>
                          {d.join(', ')}
                          {'"'}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </React.Fragment>
              }
            />
            <div style={{ height: 24 }} />
          </React.Fragment>
        )}

        {showAddons && (
          <React.Fragment>
            <Spacer border />
            <Addons
              hasRainGauge={!!this.props.hasRainGauge}
              onRainGaugeChange={this.handleRainGaugeChange}
            />
          </React.Fragment>
        )}

        <Spacer border />
        <VolumePricing
          finish={this.props.finish}
          disabled={false}
          prices={this.props.prices}
          hidePrices={this.props.hidePrices}
        />
      </div>
    );
  }
}

export default ReplacementBoardDetails;
