import * as React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import { FontClasses } from 'services/theme';

interface EnhancedTableHeadProps {
  columnData: {
    id: string;
    label: string;
    centered?: boolean;
    numeric?: boolean;
    sortDisabled?: boolean;
  }[];
  onRequestSort: (
    event: React.MouseEvent<HTMLElement>,
    property: string
  ) => void;
  order: 'asc' | 'desc';
  orderBy: string;
}

class EnhancedTableHead extends React.PureComponent<EnhancedTableHeadProps> {
  render() {
    const { columnData, order, orderBy, onRequestSort } = this.props;

    const hasOrder = columnData.length > 0;

    return (
      <TableHead className={FontClasses.title}>
        <TableRow>
          {columnData.map(column => {
            const cellStyle: React.CSSProperties = column.centered
              ? { padding: 8, textAlign: 'center' }
              : { padding: 8 };

            return (
              <TableCell
                style={cellStyle}
                key={column.id}
                align={column.numeric ? 'right' : 'left'}
              >
                {column.sortDisabled ? (
                  column.label
                ) : (
                  <Tooltip
                    title="Sort"
                    disableHoverListener={!hasOrder}
                    disableTouchListener={!hasOrder}
                    placement="bottom-start"
                    enterDelay={300}
                  >
                    <TableSortLabel
                      active={orderBy === column.id}
                      direction={order}
                      onClick={e => onRequestSort(e, column.id)}
                      style={{ marginLeft: column.centered ? 20 : 0 }}
                    >
                      {column.label}
                    </TableSortLabel>
                  </Tooltip>
                )}
              </TableCell>
            );
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}

export default EnhancedTableHead;
