export const maxPaperWidth = 400;

export const loginWrapperStyle: React.CSSProperties = {
  flexDirection: 'column',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  height: 600,
};

export const loginPaperStyle: React.CSSProperties = {
  justifyContent: 'flex-start',
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
  maxWidth: maxPaperWidth,
};

export const loginLogoStyle: React.CSSProperties = {
  margin: 'auto',
  display: 'block',
  marginTop: 64,
  width: 128,
  marginBottom: 64,
};

export const loginFormFieldStyle: React.CSSProperties = {
  width: '80%',
  marginLeft: '10%',
  marginRight: '10%',
};

export const loginFormButtonStyle: React.CSSProperties = {
  width: '60%',
  margin: '6px 20% 6px 20%',
  borderRadius: 10,
  height: 48,
};

export const loginFormStyle: React.CSSProperties = {
  marginBottom: 10,
};

export const bgImageStyle: React.CSSProperties = {
  position: 'fixed',
  zIndex: -1,
  minWidth: '100%',
  minHeight: '100%',
  overflow: 'hidden',
  filter: 'blur(7px) saturate(0.8)',
  top: 0,
};
