import React, { useState } from 'react';
import gql from 'graphql-tag';
import styled from 'styled-components';

import Loading from 'components/Loading/Loading';
import GenericError from 'components/Errors/GenericError';

import { usePricingQuery, HardwareTypeEnum } from 'types/graphql';

import AddLineItem from './AddLineItem/AddLineItem';
import OrderTableRow from './OrderTableRow';

import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import AddIcon from '@material-ui/icons/Add';

import { Model, computeUniqueModelId, makeGetPricing } from 'services/model';
import { formatCurrency } from 'services/currency';

gql`
  query Pricing {
    currentDistributor {
      id
      pricing {
        id
        hardware {
          modelId
          prices {
            quantity
            price
          }
        }
      }
    }
  }
`;

interface OrderTableProps {
  nextStep: () => void;
  previousStep: () => void;
  initialItems: LineItem[];
  initialManagedPrice: number | null;
  saveItems: (items: LineItem[]) => void;
  isManaged: boolean;
}

export interface LineItem {
  model: Model;
  amount: number | null;
}

const OrderTable: React.FC<OrderTableProps> = ({
  initialItems,
  nextStep,
  previousStep,
  isManaged,
  saveItems,
}) => {
  const [addLineItemOpen, setAddLineItemOpen] = useState(false);
  const [lineItems, setLineItems] = useState(initialItems);

  const { data, error, loading } = usePricingQuery();

  if (loading) return <Loading />;
  if (error || !data) return <GenericError error={error} />;

  const addLineItem = (model: Model, amount: number) => {
    const newModelId = computeUniqueModelId(model);
    // If the exact model was already added don't add it again
    if (lineItems.some((i) => computeUniqueModelId(i.model) === newModelId))
      return;

    const item = {
      model,
      amount,
    };
    const newItems = [...lineItems, item];
    setLineItems(newItems);
    saveItems(newItems);
  };

  const removeLineItem = (ndx: number) => () => {
    const newItems = [...lineItems];
    newItems.splice(ndx, 1);
    setLineItems(newItems);
    saveItems(newItems);
  };

  const changeLineItemAmount = (ndx: number) => (amount: number | null) => {
    const newItems = [...lineItems];
    newItems[ndx].amount = amount;
    setLineItems(newItems);
    saveItems(newItems);
  };

  const goNext = () => {
    // filter out empty items
    const items = lineItems.filter((item) => item.amount && item.amount > 0);
    saveItems(items);
    nextStep();
  };

  const {
    currentDistributor: { pricing },
  } = data;

  let rgCount = 0;
  lineItems.forEach((item) => {
    if (item.model.hasRainGauge) rgCount += item.amount || 0;
  });

  const getPricing = makeGetPricing(pricing, rgCount);

  const getRgPrice = (amount: number) =>
    getPricing(
      { type: HardwareTypeEnum.replacementRainGauge },
      rgCount + amount
    );

  let total = 0;
  lineItems.forEach((item) => {
    const price = getPricing(item.model, item.amount);
    total += price * (item.amount || 0);
  });

  const totalStr = formatCurrency(total);

  const canContinue = isManaged ? lineItems.length > 0 : total !== 0;

  return (
    <>
      <Container>
        <HeaderRow>
          <HeaderText>New Order</HeaderText>
          <Button
            onClick={() => setAddLineItemOpen(true)}
            color="primary"
            variant="contained"
          >
            <AddIcon />
            Add Item
          </Button>
        </HeaderRow>
        {lineItems.map((item, i) => {
          const price = getPricing(item.model, item.amount);
          const key = computeUniqueModelId(item.model);
          return (
            <OrderTableRow
              key={key}
              item={item}
              onAmountChange={changeLineItemAmount(i)}
              pricePerUnit={price}
              remove={removeLineItem(i)}
              showPrice={!isManaged}
            />
          );
        })}
        <FooterRow>
          <div>
            <Button onClick={previousStep} variant="contained">
              Back
            </Button>
            <Button
              style={{ marginLeft: 16 }}
              onClick={goNext}
              variant="contained"
              disabled={!canContinue}
            >
              Continue
            </Button>
          </div>
          {!isManaged && <TotalText>Total: {totalStr}</TotalText>}
        </FooterRow>
      </Container>
      <AddLineItem
        open={addLineItemOpen}
        onClose={() => setAddLineItemOpen(false)}
        addLineItem={addLineItem}
        priceSheets={pricing.hardware}
        getRgPrice={getRgPrice}
        hidePrices={isManaged}
      />
    </>
  );
};

const Container = styled(Paper)`
  padding: 16px;
  max-width: 800px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 164px;
`;

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const HeaderText = styled.div`
  font-size: ${(p) => p.theme.fontSizes.title};
`;

const FooterRow = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const TotalText = styled.div`
  font-size: ${(p) => p.theme.fontSizes.subtitle};
`;

export default OrderTable;
