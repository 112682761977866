import React from 'react';
import gql from 'graphql-tag';

import { useIsMobile } from 'providers/Responsive';
import { Maybe, OrderStatus, useOrdersMockupQuery } from 'types/graphql';
import moment from 'moment';
import { getModelTitles } from 'services/model';
import SearchBar from 'components/SearchBar';
import { Link } from '@reach/router';
import OrderPopup from 'components/OrderPopup';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Loading from 'components/Loading/Loading';

gql`
  query OrdersMockup {
    currentDistributor {
      id
      isDistributor
      orders {
        id
        approved
        placedTimestamp
        status
        shipments {
          id
          hardware {
            id
            quantity
            model {
              type
              antennaLength
              moistureDepths
              temperatureDepths
              ecDepths
              hasWeatherStation
              hasPressureSensor
              hasRainGauge
              moistureMethod
              type
              probeless
              custom
            }
            boundIds
          }
          trackingNumber
          carrier
        }
        unshippedHardware {
          id
          quantity
          model {
            type
            antennaLength
            moistureDepths
            temperatureDepths
            ecDepths
            hasWeatherStation
            hasPressureSensor
            hasRainGauge
            moistureMethod
            type
            probeless
            custom
          }
          boundIds
        }
        details {
          hardware {
            id
            quantity
            model {
              type
              antennaLength
              moistureDepths
              temperatureDepths
              ecDepths
              hasWeatherStation
              hasPressureSensor
              hasRainGauge
              moistureMethod
              type
              probeless
              custom
            }
            boundIds
          }
          customer {
            id
            name {
              first
              last
            }
          }
          rep {
            id
            name {
              first
              last
            }
          }
        }
      }
    }
  }
`;

interface OrdersProps {}

const Orders: React.FC<OrdersProps> = () => {
  const isMobile = useIsMobile();
  const [orderPopupId, setOrderPopupId] = React.useState<number | undefined>(
    undefined
  );
  const [searchString, setSearchString] = React.useState('');

  const { data, loading } = useOrdersMockupQuery({
    fetchPolicy: 'no-cache',
  });

  if (loading) return <Loading />;

  const marginAmount = isMobile ? 0 : 24;
  const buttonWidth = isMobile ? '100%' : undefined;

  const orders = data?.currentDistributor.orders || [];

  type TransformedOrders = Array<{
    id: number;
    status: OrderStatus;
    placedTimestamp: string;
    customer: string;
    unshippedHardware: {
      titles: {
        title: string;
        subtitle: string;
      };
      boundIds: string[];
      quantity: string;
    }[];
    shipments: {
      hardware: {
        titles: {
          title: string;
          subtitle: string;
        };
        boundIds: string[];
        quantity: string;
      }[];
      __typename: 'OrderShipment';
      id: number;
      trackingNumber: Maybe<string>;
      carrier: string;
    }[];
  }>;

  let transformedOrders: TransformedOrders = orders
    .map((order) => {
      const transformedOrderReturn = {
        id: order.id,
        status: order.status,
        placedTimestamp: moment(order.placedTimestamp).format('LL'),
        customer: `${order.details.customer?.name?.first} ${order.details.customer?.name?.last}`,
        unshippedHardware: order.unshippedHardware.map((hardware) => ({
          titles: getModelTitles(hardware.model),
          boundIds: hardware.boundIds,
          quantity: `Quantity: ${hardware.quantity}`,
        })),
        shipments: order.shipments.map((shipment) => ({
          ...shipment,
          hardware: shipment.hardware.map((hardware) => ({
            titles: getModelTitles(hardware.model),
            boundIds: hardware.boundIds,
            quantity: `Quantity: ${hardware.quantity}`,
          })),
        })),
      };
      return transformedOrderReturn;
    })
    .filter((transformedOrder) => {
      return transformedOrder.status !== OrderStatus.rejected;
    });

  if (searchString) {
    // simply looks in json for searchstring, so not very exact but good enough for finding IDs, customers, dates, etc.
    transformedOrders = transformedOrders.filter((order) =>
      JSON.stringify(order).toLowerCase().includes(searchString.toLowerCase())
    );
  }
  transformedOrders.sort((a, b) => b.id - a.id);

  return (
    <>
      <div
        style={{
          flexGrow: 1,
          margin: marginAmount,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: marginAmount,
            height: 36,
          }}
        >
          {data?.currentDistributor.isDistributor && (
            <Link to="/orders/order" style={{ textDecoration: 'none' }}>
              <Button
                variant="contained"
                color="primary"
                id="placeOrder"
                style={{ width: buttonWidth }}
              >
                Place Order
              </Button>
            </Link>
          )}
          <Link to="/oldOrders" style={{ textAlign: 'right', flexGrow: 1 }}>
            Old Orders Page
          </Link>
        </div>
        <SearchBar
          content={searchString}
          onChange={(e) => setSearchString(e.target.value)}
          onClear={() => setSearchString('')}
        />
        <OrderPopup
          orderId={orderPopupId || 0}
          closeFunction={() => setOrderPopupId(undefined)}
          open={orderPopupId !== undefined}
        />
        {transformedOrders.map((order) => (
          <Paper
            key={order.id}
            style={{
              marginTop: 20,
              alignSelf: 'stretch',
              overflow: 'auto',
            }}
          >
            <div
              style={{
                flexDirection: 'row',
                display: 'flex',
                padding: 10,
                color: 'gray',
                borderBottom: '1px solid lightgray',
              }}
            >
              <div style={{ flexBasis: '30%' }}>
                <div style={{ textTransform: 'uppercase' }}>Order Placed</div>
                <div>
                  <span>{order.placedTimestamp}</span>
                </div>
              </div>
              <div style={{ flexGrow: 5 }}>
                <div style={{ textTransform: 'uppercase' }}>Customer</div>
                <span>{order.customer}</span>
              </div>
              <div style={{ textAlign: 'right' }}>
                <a
                  href="javascript:;"
                  onClick={() => {
                    setOrderPopupId(order.id);
                    return false;
                  }}
                >
                  Order #{order.id}
                </a>
              </div>
            </div>
            <div style={{ padding: 10 }}>
              {order.unshippedHardware.length !== 0 ? (
                <>
                  {order.status === 'processing' ? (
                    <h2 style={{ marginBottom: 10 }}>Not yet shipped</h2>
                  ) : (
                    <h2 style={{ marginBottom: 10 }}>Approval pending</h2>
                  )}
                  {order.unshippedHardware.map((hardware, idx) => {
                    return (
                      <div key={idx} style={{ marginBottom: 10 }}>
                        <div>{hardware.titles.title}</div>
                        <div style={{ color: 'gray', fontSize: '.8rem' }}>
                          {hardware.titles.subtitle}
                        </div>
                        <div style={{ color: 'gray', fontSize: '.8rem' }}>
                          {hardware.quantity}
                        </div>
                        {hardware.boundIds.length > 0 ? (
                          <div style={{ color: 'gray', fontSize: '.8rem' }}>
                            ID: {hardware.boundIds.join(', ')}
                          </div>
                        ) : undefined}
                      </div>
                    );
                  })}
                </>
              ) : undefined}
              {order.shipments.map((shipment) => (
                <React.Fragment key={shipment.id}>
                  <h2 style={{ textTransform: 'capitalize', marginBottom: 0 }}>
                    Shipped
                  </h2>
                  <div
                    style={{
                      color: 'gray',
                      fontSize: '.8rem',
                      marginBottom: 10,
                    }}
                  >
                    {shipment.trackingNumber ? (
                      <>Tracking Number: {shipment.trackingNumber}</>
                    ) : undefined}
                  </div>
                  {shipment.hardware.map((hardware, idx) => {
                    return (
                      <div key={idx} style={{ marginBottom: 10 }}>
                        <div>{hardware.titles.title}</div>
                        <div style={{ color: 'gray', fontSize: '.8rem' }}>
                          {hardware.titles.subtitle}
                        </div>
                        <div style={{ color: 'gray', fontSize: '.8rem' }}>
                          {hardware.quantity}
                        </div>
                        {hardware.boundIds.length > 0 ? (
                          <div style={{ color: 'gray', fontSize: '.8rem' }}>
                            ID: {hardware.boundIds.join(', ')}
                          </div>
                        ) : undefined}
                      </div>
                    );
                  })}
                </React.Fragment>
              ))}
            </div>
          </Paper>
        ))}
      </div>
    </>
  );
};

export default Orders;
