import BatteryAlert from '@material-ui/icons/BatteryAlert';
import SignalCellularNull from '@material-ui/icons/SignalCellularNull';
import AccessTime from '@material-ui/icons/AccessTime';
import ADCZERO from '@material-ui/icons/Power';
import MONEY_OFF from '@material-ui/icons/MoneyOff';

import { HardwareWarningEnum } from 'types/graphql';
const {
  BATTERY_LOW,
  NOT_REPORTED_RECENTLY,
  SIGNAL_LOW,
  ZERO_ADC,
  INACTIVE_SUB,
} = HardwareWarningEnum;

export const hardwareWarningToIcon = (warning: HardwareWarningEnum) => {
  switch (warning) {
    case BATTERY_LOW:
      return BatteryAlert;
    case SIGNAL_LOW:
      return SignalCellularNull;
    case NOT_REPORTED_RECENTLY:
      return AccessTime;
    case ZERO_ADC:
      return ADCZERO;
    case INACTIVE_SUB:
      return MONEY_OFF;
    default:
      return null;
  }
};
