import React, { Suspense, lazy } from 'react';
import Loading from 'components/Loading/Loading';

const AdminStatusMap = lazy(() =>
  import(/* webpackChunkName: "AdminStatusMap" */ './AdminStatusMap')
);

const LoadableAdminStatusMap: React.FC = props => (
  <Suspense fallback={<Loading />}>
    <AdminStatusMap {...props} />
  </Suspense>
);

export default LoadableAdminStatusMap;
