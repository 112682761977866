import React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { colors, FontClasses } from 'services/theme';
import { MoistureMethod } from 'types/graphql';

import VolumePricing from './VolumePricing';
import TwoColumn from './TwoColumn';

interface Options {
  unit: string;
  lengths: number[];
  diameters: number[];
}

const watermark: Options = {
  unit: 'ft',
  lengths: [2, 4],
  diameters: [2.75, 2],
};

const sentek: Options = {
  unit: 'cm',
  lengths: [30, 60, 90, 120],
  diameters: [],
};

interface AugerProps {
  moistureMethod: MoistureMethod;
  length: number | undefined;
  setLength: (length: number) => void;
  finish: (quantity: number) => void;
  prices:
    | {
        quantity: number;
        price: number;
      }[]
    | null;
  hidePrices: boolean;
}

const Auger: React.FC<AugerProps> = (props) => {
  const { unit, lengths, diameters } =
    props.moistureMethod === MoistureMethod.tension ? watermark : sentek;
  return (
    <>
      <TwoColumn
        title="Length"
        subtitle="Select the length of the auger drill bit"
        content={
          <>
            <div
              style={{ color: colors.textLight }}
              className={FontClasses.caption}
            >
              Length
            </div>
            <Select
              value={props.length || ''}
              onChange={(e) => props.setLength(Number(e.target.value))}
              style={{ width: 200 }}
            >
              {lengths.map((length, i) => {
                const diameter = diameters[i];
                return (
                  <MenuItem value={String(length) || ''} key={length}>
                    {length} {unit} {diameter && `(${diameter}" diameter)`}
                  </MenuItem>
                );
              })}
            </Select>
          </>
        }
      />
      <div style={{ height: 24 }} />
      <VolumePricing
        finish={props.finish}
        disabled={props.length === undefined}
        prices={props.prices}
        hidePrices={props.hidePrices}
      />
    </>
  );
};

export default Auger;
