import * as React from 'react';
import TwoColumn from './TwoColumn';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

interface AddonsProps {
  hasRainGauge: boolean;
  onRainGaugeChange: (ev: React.ChangeEvent<HTMLInputElement>) => void;
}

class Addons extends React.Component<AddonsProps> {
  render() {
    return (
      <TwoColumn
        title="Add-ons"
        subtitle="Include a rain gauge with your sensor to see rain data on your dashboard"
        content={
          <FormControlLabel
            control={
              <Checkbox
                checked={this.props.hasRainGauge}
                onChange={this.props.onRainGaugeChange}
              />
            }
            label="With Davis Instruments AeroCone Rain Gauge"
          />
        }
      />
    );
  }
}

export default Addons;
