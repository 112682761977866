import React, { Suspense, lazy } from 'react';
import Loading from 'components/Loading/Loading';

const AdminServiceLogs = lazy(() =>
  import(/* webpackChunkName: "AdminServiceLogs" */ './AdminServiceLogs')
);

const LoadableAdminServiceLogs: React.FC = props => (
  <Suspense fallback={<Loading />}>
    <AdminServiceLogs {...props} />
  </Suspense>
);

export default LoadableAdminServiceLogs;
