import './chat.css';

interface Zoho {
  salesiq: {
    floatbutton?: {
      visible: (visibility: 'show' | 'hide') => void;
    };
  };
}

function showText() {
  // If you take this out after several refreshes it doesn’t always reload
  const element = document.getElementById('titlediv');
  if (element) element.style.display = 'block';
}

function hideText() {
  const element = document.getElementById('titlediv');
  if (element) element.classList.add('zoho-chat-hidden');
}

/**
 * Returned typed version of zoho
 */
const getZoho = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (window as any).$zoho as (Zoho | undefined);
};

const chat = (drawerOpen: boolean, isMobile: boolean) => {
  let show = true;
  if (!drawerOpen) show = false;
  if (!isMobile) show = true;

  const zoho = getZoho();

  if (zoho && zoho.salesiq.floatbutton) {
    if (show) {
      zoho.salesiq.floatbutton.visible('show');
      showText();
      setTimeout(() => hideText(), 15000);
    } else {
      zoho.salesiq.floatbutton.visible('hide');
    }
  }

  return;
};

export default chat;
