import * as React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { FontClasses, colors } from 'services/theme';
import TwoColumn from './TwoColumn';
import { formatCurrency } from 'services/currency';

interface Price {
  quantity: number;
  price: number;
}

interface VolumePricingProps {
  prices: Price[] | null;
  finish: (quantity: number) => void;
  disabled: boolean;
  rgPrices?: Price[] | null;
  rg?: boolean;
  customPrice?: number;
  hidePrices: boolean;
}

interface VolumePricingState {
  quantity: number;
}

class VolumePricing extends React.Component<
  VolumePricingProps,
  VolumePricingState
> {
  state = { quantity: 1 };

  onQuantityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (value === '') return this.setState({ quantity: 0 });
    let quantity = Number(value);
    if (quantity < 0) quantity = 0;
    else this.setState({ quantity });
  };

  render() {
    const {
      prices,
      rg,
      rgPrices,
      customPrice: custom,
      hidePrices,
    } = this.props;
    const currentQuantity = this.state.quantity;
    let rgPrice = 0;
    if (rg && rgPrices) {
      rgPrices.forEach(({ price, quantity }) => {
        if (currentQuantity >= quantity) rgPrice = price;
      });
    }
    const customPrice = custom || 0;

    return (
      <div>
        <TwoColumn
          title="Quantity"
          subtitle="This can be changed later"
          leftContent={
            <TextField
              value={this.state.quantity}
              onChange={this.onQuantityChange}
              style={{ width: 82, marginTop: 20 }}
              inputProps={{ style: { textAlign: 'right' } }}
              type="number"
              variant="outlined"
            />
          }
          content={
            hidePrices ? (
              <div></div>
            ) : (
              <div>
                <div
                  className={FontClasses.caption}
                  style={{ color: colors.textLight, marginBottom: 4 }}
                >
                  Volume Pricing
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  {prices &&
                    prices.map(({ quantity, price }, i) => {
                      const adjPrice = price + rgPrice + customPrice;
                      const priceStr = formatCurrency(adjPrice);
                      const next: Price | undefined = prices[i + 1];
                      let qtyText = `${quantity}+`;
                      let isActive = false;
                      if (!next) isActive = currentQuantity >= quantity;
                      else {
                        if (
                          currentQuantity >= quantity &&
                          currentQuantity < next.quantity
                        ) {
                          isActive = true;
                        }
                        qtyText = `${quantity}-${next.quantity - 1}`;
                      }
                      return (
                        <div
                          className={FontClasses.caption}
                          key={quantity}
                          style={{
                            borderColor: colors.grey,
                            borderWidth: 1,
                            borderStyle: 'solid',
                            backgroundColor: !isActive
                              ? colors.primaryExtraLight
                              : undefined,
                          }}
                        >
                          <div
                            style={{
                              padding: 8,
                              borderWidth: 0,
                              borderBottomWidth: 1,
                              borderStyle: 'solid',
                              borderColor: colors.grey,
                            }}
                          >
                            {qtyText}
                          </div>
                          <div style={{ padding: 8 }}>{priceStr}</div>
                        </div>
                      );
                    })}
                </div>
              </div>
            )
          }
        />
        <div style={{ height: 16 }} />
        <Button
          color="primary"
          variant="contained"
          onClick={() => this.props.finish(this.state.quantity)}
          disabled={this.props.disabled || this.state.quantity === 0 || !prices}
        >
          Add to order
        </Button>
      </div>
    );
  }
}

export default VolumePricing;
