import React, { useState } from 'react';
import moment from 'moment';

import { SingleDatePicker, SingleDatePickerShape } from 'react-dates';

import './ReactDates.css';

const defaultProps: Partial<SingleDatePickerShape> = {
  // input related props
  placeholder: 'Date',
  required: true,
  small: true,

  // calendar presentation and interaction related props
  orientation: 'horizontal',
  anchorDirection: 'left',
  horizontalMargin: 0,
  numberOfMonths: 1,

  // day presentation and interaction related props
  enableOutsideDays: false,
  isDayBlocked: () => false,
  isOutsideRange: () => false,
  isDayHighlighted: () => false,
};

interface RequiredProps {
  date: moment.Moment | null;
  onDateChange: (date: moment.Moment | null) => void;
  openDirection?: 'up' | 'down';
}

type Omit<T, K> = Pick<T, Exclude<keyof T, K>>;

type SingleDatePickerWrapperProps = Omit<
  Partial<SingleDatePickerShape>,
  keyof RequiredProps
> &
  RequiredProps;

const SingleDatePickerWrapper: React.FC<
  SingleDatePickerWrapperProps
> = props => {
  const [focused, setFocused] = useState(false);
  const onFocusChange = ({ focused }: { focused: boolean | null }) => {
    setFocused(!!focused);
  };
  return (
    <React.Fragment>
      <SingleDatePicker
        {...defaultProps}
        {...props}
        id="date_input"
        focused={focused}
        onFocusChange={onFocusChange}
      />
    </React.Fragment>
  );
};

export default SingleDatePickerWrapper;
