import React, { Suspense, lazy } from 'react';
import Loading from 'components/Loading/Loading';

const AdminShipping = lazy(() =>
  import(/* webpackChunkName: "AdminShipping" */ './AdminShipping')
);

const LoadableAdminShipping: React.FC = props => (
  <Suspense fallback={<Loading />}>
    <AdminShipping {...props} />
  </Suspense>
);

export default LoadableAdminShipping;
