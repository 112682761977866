const Signal25 = require('./assets/25signal.svg');
const Signal50 = require('./assets/50signal.svg');
const Signal75 = require('./assets/75signal.svg');
const Signal100 = require('./assets/100signal.svg');

const Battery0 = require('./assets/0battery.svg');
const Battery25 = require('./assets/25battery.svg');
const Battery50 = require('./assets/50battery.svg');
const Battery75 = require('./assets/75battery.svg');
const Battery100 = require('./assets/100battery.svg');

type HardwareTypeString = 'sensor' | 'gateway' | 'Sensor' | 'Gateway';

export const signalToIcon = (
  type: HardwareTypeString,
  signal: number | null
) => {
  switch (type.toLowerCase()) {
    case 'sensor':
      return sensorSignalToIcon(signal);
    case 'gateway':
      return gatewaySignalToIcon(signal);
    default:
      return null;
  }
};

export const batteryToIcon = (
  type: HardwareTypeString,
  battery: number | null
) => {
  switch (type.toLowerCase()) {
    case 'sensor':
      return sensorBatteryToIcon(battery);
    case 'gateway':
      return gatewayBatteryToIcon(battery);
    default:
      return null;
  }
};

function sensorSignalToIcon(signal: number | null) {
  if (signal === null) return Signal25;
  if (signal > -90) {
    return Signal100;
  } else if (signal > -95) {
    return Signal75;
  } else if (signal > -105) {
    return Signal50;
  } else {
    return Signal25;
  }
}

function gatewaySignalToIcon(signal: number | null) {
  if (signal === null) return Signal25;
  if (signal > -95) {
    return Signal100;
  } else if (signal > -100) {
    return Signal75;
  } else if (signal > -110) {
    return Signal50;
  } else {
    return Signal25;
  }
}

function sensorBatteryToIcon(battery: number | null) {
  if (battery === null) return Battery0;
  if (battery > 3.4) {
    return Battery100;
  } else if (battery > 3.3) {
    return Battery75;
  } else if (battery > 3.15) {
    return Battery50;
  } else if (battery > 3.0) {
    return Battery25;
  } else {
    return Battery0;
  }
}

function gatewayBatteryToIcon(battery: number | null) {
  if (battery === null) return Battery0;
  if (battery > 3400) {
    return Battery100;
  } else if (battery > 3300) {
    return Battery75;
  } else if (battery > 3150) {
    return Battery50;
  } else if (battery > 3000) {
    return Battery25;
  } else {
    return Battery0;
  }
}

export const getRGMapIconURL = (state?: number | null) => {
  if (state === null || state === undefined)
    return 'https://i.imgur.com/bWTEQDg.png?3';
  if (state < 0) {
    return 'https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|FF4026';
  } else if (state < 1) {
    return 'https://i.imgur.com/HQJtsye.png?3';
  } else if (state < 3) {
    return 'https://i.imgur.com/4yMYi8T.png?3';
  } else {
    return 'https://i.imgur.com/pUkCphK.png?3';
  }
};
