import * as GoogleAnalytics from 'react-ga';

// Only initialize once ever
let initialized = false;
let analyticsEnabled = false;
const initializeIfNeeded = (_analyticsEnabled: boolean) => {
  if (initialized) return;
  analyticsEnabled = _analyticsEnabled;
  if (!analyticsEnabled) return;

  GoogleAnalytics.initialize('UA-69562088-6');

  initialized = true;
};

const set = (fields: GoogleAnalytics.FieldsObject) => {
  if (!analyticsEnabled) return;
  GoogleAnalytics.set(fields);
};

const pageview = (page: string) => {
  if (!analyticsEnabled) return;
  GoogleAnalytics.pageview(page);
};

export default {
  event,
  initializeIfNeeded,
  set,
  pageview,
};
