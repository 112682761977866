import * as React from 'react';
import { Link } from '@reach/router';

import Drawer from '@material-ui/core/Drawer';
import ButtonBase from '@material-ui/core/ButtonBase';

import { colors } from 'services/theme';

import SidebarButton from './components/SidebarButton';

const TrellisDots = require('../assets/trellis_logo_white_dots_only.svg');

const OrderIcon = require('../assets/orders.png');
const ManageIcon = require('../assets/manage2.png');
const StatusIcon = require('../assets/status.png');
const ProductsIcon = require('../assets/hardware.png');
const HelpIcon = require('../assets/help.png');
const AdminIcon = require('../assets/admin.png');

interface SidebarProps {
  isOpen: boolean;
  toggleDrawer: () => void;
  drawerType: 'permanent' | 'persistent' | 'temporary' | undefined;
  sideBarWidth: number;
  isDistributor: boolean;
  isAdmin: boolean;
  isRep: boolean;
}

const Sidebar: React.FC<SidebarProps> = props => {
  const {
    isOpen,
    toggleDrawer,
    drawerType,
    sideBarWidth,
    isDistributor,
    isAdmin,
  } = props;
  return (
    <Drawer
      variant={drawerType}
      open={isOpen}
      onClose={toggleDrawer}
      style={{ zIndex: 1400 }}
    >
      <div
        style={{
          background: colors.primary,
          height: '100%',
          width: sideBarWidth,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div style={{ height: 24, flex: '0 0 auto' }} />

        {/* Trellis logo */}
        <Link to="/" style={{ flex: '0 0 auto' }}>
          <ButtonBase
            style={{
              padding: 0,
              width: '100%',
            }}
            disableRipple
          >
            <img
              src={TrellisDots}
              alt="Logo"
              style={{
                width: '65%',
              }}
            />
          </ButtonBase>
        </Link>

        <div style={{ height: 24, flex: '0 0 auto' }} />

        {/* Navigation buttons */}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            background: colors.primary,
            flex: '1 1 auto',
          }}
        >
          <SidebarButton path="/orders" icon={OrderIcon} text="Orders" />
          <SidebarButton
            path="/manage"
            icon={ManageIcon}
            text="Manage Customers"
          />
          <SidebarButton path="/status" icon={StatusIcon} text="Status" />
          {!isDistributor && (
            <SidebarButton
              path="/products"
              icon={ProductsIcon}
              text="Products"
            />
          )}
          {isAdmin && (
            <SidebarButton path="/admin" icon={AdminIcon} text="Admin" />
          )}
          <SidebarButton path="/help" icon={HelpIcon} text="Help" />
          {/* Padding for zoho button */}
          <div style={{ height: 96 }} />
        </div>
      </div>
    </Drawer>
  );
};

export default Sidebar;
