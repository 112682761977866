import React from 'react';
import styled from 'styled-components';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { ApolloError } from 'apollo-client';
import Button from '@material-ui/core/Button';
import GraphQLError from 'components/Errors/GraphQLError';

interface ManagedServiceCardProps {
  cardStyle: React.CSSProperties;
  managedServiceStatuses: Array<{
    initialPaymentMade: boolean;
    invoiceUrl: string | null;
    orderId: number;
    subscriptionStarted: boolean;
  }>;
  startSubscription: (orderId: number) => void;
  startSubscriptionStatus: { loading: boolean; error?: ApolloError };
}

const ManagedServiceCard: React.FC<ManagedServiceCardProps> = ({
  cardStyle,
  managedServiceStatuses,
  startSubscription,
  startSubscriptionStatus,
}) => {
  return (
    <Card style={cardStyle}>
      <CardHeader
        title="Managed Service"
        style={{ backgroundColor: 'rgb(242,242,242)' }}
      />
      <CardContent>
        {managedServiceStatuses.map((s) => {
          const canStartSubscription =
            !s.subscriptionStarted && s.initialPaymentMade;
          return (
            <Section key={s.orderId}>
              <OrderHeader>Order #{s.orderId}</OrderHeader>
              <InvoiceText>
                {s.invoiceUrl ? (
                  <>
                    Order has an unpaid invoice:{' '}
                    <InvoiceLink
                      href={s.invoiceUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      view here
                    </InvoiceLink>
                  </>
                ) : (
                  <>The invoice for this order have been paid.</>
                )}
                {canStartSubscription && (
                  <SubscriptionSection>
                    <div>
                      The customer has not yet started their subscription. The
                      customer can activate it on their dashboard, or you can
                      start it using the button below.
                    </div>
                    <Button
                      disabled={startSubscriptionStatus.loading}
                      onClick={() => startSubscription(s.orderId)}
                      color="primary"
                      variant="contained"
                      style={{ marginTop: 8 }}
                    >
                      Start Subscription
                    </Button>
                    <GraphQLError error={startSubscriptionStatus.error} />
                  </SubscriptionSection>
                )}
              </InvoiceText>
            </Section>
          );
        })}
      </CardContent>
    </Card>
  );
};

const Section = styled.div`
  margin-bottom: 16px;
`;

const OrderHeader = styled.div(
  (p) => `
  font-size: ${p.theme.fontSizes.subtitle};
`
);

const InvoiceLink = styled.a``;

const InvoiceText = styled.div``;

const SubscriptionSection = styled.div`
  margin-top: 16px;
`;

export default ManagedServiceCard;
