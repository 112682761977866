import * as React from 'react';
import { ApolloError } from 'apollo-client';

import { colors } from 'services/theme';

interface GraphQLErrorProps {
  error?: ApolloError;
  message?: string;
}

const GraphQLError: React.SFC<GraphQLErrorProps> = ({ error, message }) => {
  if (!error && !message) return null;
  let errorMessage;
  if (error) {
    errorMessage = error.message;
    if (error.graphQLErrors) {
      const graphQLError = error.graphQLErrors[0];
      if (graphQLError) {
        if (graphQLError.message) errorMessage = graphQLError.message;
        if (!!graphQLError.extensions && graphQLError.extensions.exception) {
          const exception = graphQLError.extensions.exception;
          if (exception.error) errorMessage = exception.error;
          if (exception.message) errorMessage = exception.message;
        }
      }
    }
  } else if (message) {
    errorMessage = message;
  }
  return <span style={{ color: colors.error }}>{errorMessage}</span>;
};

export default GraphQLError;
