import React from 'react';
import styled from 'styled-components';
import { Link } from '@reach/router';
import gql from 'graphql-tag';

import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import { useDistributorBillingStatusQuery } from 'types/graphql';

import GraphQLError from 'components/Errors/GraphQLError';
import Loading from 'components/Loading/Loading';

gql`
  query DistributorBillingStatus {
    currentDistributor {
      id
      stripeInfo {
        stripeURL
      }
    }
  }
`;

interface OrderIntroProps {
  setManagedService: (isManaged: boolean) => void;
  nextStep: () => void;
}

const OrderIntro: React.FC<OrderIntroProps> = ({
  nextStep,
  setManagedService,
}) => {
  const { data, loading, error } = useDistributorBillingStatusQuery();

  if (loading) return <Loading />;
  if (error || !data) return <GraphQLError error={error} />;

  function managed() {
    setManagedService(true);
    nextStep();
  }

  function hardwareOnly() {
    setManagedService(false);
    nextStep();
  }

  const hasStripeSetup = !!data.currentDistributor.stripeInfo;

  return (
    <Container>
      <Header>What type of order is this?</Header>
      <Row>
        <Button
          style={{ margin: '10px' }}
          onClick={hardwareOnly}
          variant="contained"
          color="primary"
          size="large"
          fullWidth
        >
          <div style={{ fontSize: '20px' }}>Hardware Purchase</div>
        </Button>
        <Button
          style={{ margin: '10px' }}
          onClick={managed}
          variant="contained"
          color="primary"
          size="large"
          disabled={!hasStripeSetup}
          fullWidth
        >
          <div style={{ fontSize: '20px' }}>Managed Service</div>
        </Button>
      </Row>
      {!hasStripeSetup && (
        <Row>
          <Flex1>
            <StripeSetupBlurb>
              {' '}
              <b>
                <Link to="/billing">Click here to get started.</Link>
              </b>
              <br></br>
            </StripeSetupBlurb>
          </Flex1>
        </Row>
      )}
    </Container>
  );
};

const Container = styled(Paper)`
  max-width: 800px;
  margin: auto;
  padding: 16px;
`;

const Header = styled.div`
  font-size: ${(p) => p.theme.fontSizes.subtitle};
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 32px;
`;

const StripeSetupBlurb = styled.div`
  font-size: ${(p) => p.theme.fontSizes.subtitle};
  padding-right: 10px;
`;

const Flex1 = styled.div`
  flex: 1;
  text-align: right;
`;

export default OrderIntro;
