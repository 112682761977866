import React, { useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';

import { colors, FontClasses } from 'services/theme';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Tooltip from '@material-ui/core/Tooltip';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

import SingleDatePicker from 'components/SingleDatePicker/SingleDatePicker';

interface AccountManagementProps {
  selectedCustomerId: string;
  customers: {
    id: string;
    name: { first: string | null; last: string | null } | null;
  }[];
  handleCustomerChange: (val: string) => void;
  button: (type: string) => void;
  disabled: boolean;
  selectedCustomer: string;
  prepaid: boolean;
  onPrepaidChange: () => void;
  prepaidMonths: number;
  onPrepaidMonthsChange: (months: number) => void;
  emailErrors: boolean[];
  sharingEmails: string[];
  onSharingEmailChange: (row: number, value: string) => void;
  onSharingEmailAdd: () => void;
  onSharingEmailRemove: (row: number) => void;
  prepaidStart: moment.Moment | null;
  onPrepaidStartChange: (date: moment.Moment | null) => void;
  repId: string;
  customerFirstName: string | null;
  customerLastName: string | null;
  customerEmail: string | null;
  customerPhone: string | null;
  isManaged: boolean;
  managedPrice: string;
  setManagedPrice: (price: string) => void;
  managedPrepaid: boolean;
  setManagedPrepaid: (maanged: boolean) => void;
  managedMonths: string;
  setManagedMonths: (months: string) => void;
  managedMonthsIsValid: boolean;
  managedPriceIsValid: boolean;
}

const AccountManagement: React.FC<AccountManagementProps> = (props) => {
  const {
    customers,
    selectedCustomerId: selectedId,
    disabled,
    isManaged,
    managedPrice,
    setManagedPrice,
    managedMonths,
    setManagedMonths,
    managedMonthsIsValid,
    managedPriceIsValid,
  } = props;
  const [owner, setOwner] = useState('');

  const setOwnerGrower = () => {
    props.handleCustomerChange('');
    setOwner('Grower');
  };

  const setOwnerDealer = () => {
    props.handleCustomerChange(props.repId);
    setOwner('Dealer');
  };

  return (
    <div>
      <div className={FontClasses.subtitle}>
        {isManaged ? 'Managed Service Details ' : 'Software Subscription'}
      </div>

      {isManaged ? (
        <div style={{ color: colors.textLight, marginBottom: 16 }}>
          Information about managed service
        </div>
      ) : (
        <FormControl>
          <FormLabel>
            <div
              className={FontClasses.body}
              style={{ marginBottom: 16, color: colors.text }}
            >
              Who will pay the software subscription?
            </div>
          </FormLabel>
          <RadioGroup
            value={owner}
            onChange={(_, val) =>
              val === 'Grower' ? setOwnerGrower() : setOwnerDealer()
            }
          >
            <FormControlLabel
              value="Grower"
              control={<Radio />}
              style={{ marginBottom: 16 }}
              label={<div className={FontClasses.body}>Customer</div>}
            />
            <FormControlLabel
              value="Dealer"
              control={<Radio />}
              style={{ marginBottom: 16 }}
              label={<div className={FontClasses.body}>Dealer</div>}
            />
          </RadioGroup>
        </FormControl>
      )}

      {owner === 'Dealer' && (
        <>
          <div>
            If you would like the items in this order to be automatically shared
            with anyone, enter their email addresses below:
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {props.sharingEmails.map((email, i) => {
              const error = props.emailErrors[i];
              return (
                <div style={{ display: 'flex', alignItems: 'center' }} key={i}>
                  <IconButton onClick={() => props.onSharingEmailRemove(i)}>
                    <RemoveIcon />
                  </IconButton>
                  <TextField
                    label="Email Address"
                    margin="dense"
                    variant="outlined"
                    value={email}
                    onChange={(e) =>
                      props.onSharingEmailChange(i, e.target.value)
                    }
                    style={{ minWidth: 300, marginRight: 16 }}
                  />
                  {error && <Error>Invalid email</Error>}
                </div>
              );
            })}
            <Button onClick={props.onSharingEmailAdd}>
              <AddIcon />
              Add Row
            </Button>
          </div>
        </>
      )}

      {(owner === 'Grower' || isManaged) && (
        <>
          <div className={FontClasses.subtitle}>Customer</div>
          <div style={{ color: colors.textLight }}>
            {isManaged ? (
              <>Select or create a customer account below.</>
            ) : (
              <>
                Select or create a customer account below. This equipment will
                be immediately added to their customer account.
              </>
            )}
          </div>
          <Tooltip
            title="Please choose a sales rep first"
            disableFocusListener={!disabled}
            disableHoverListener={!disabled}
            disableTouchListener={!disabled}
          >
            <Select
              style={{ width: 200, marginRight: 20, marginTop: 0 }}
              value={selectedId}
              onChange={(e) => {
                const val = e.target.value as string;
                if (val === 'addNewCustomer') {
                  props.button('customer');
                  props.handleCustomerChange('');
                } else {
                  props.handleCustomerChange(val);
                }
              }}
              disabled={disabled}
              data-test="selectCustomer"
            >
              {customers.map(({ id, name }) => (
                <MenuItem value={id} key={id}>
                  {name && name.first} {name && name.last}
                </MenuItem>
              ))}
              <MenuItem
                value="addNewCustomer"
                style={{ background: colors.primary, color: 'white' }}
                data-test="addNewCustomer"
              >
                Add New Customer
              </MenuItem>
            </Select>
          </Tooltip>
          <div style={{ height: 24 }} />
          {selectedId && (
            <div>
              <div className={FontClasses.title}>
                {props.customerFirstName} {props.customerLastName}
              </div>
              <div>{props.customerEmail}</div>
              <div>{props.customerPhone}</div>
            </div>
          )}
        </>
      )}
      {owner !== '' && (
        <div>
          <span>Add Pre-paid Subscription</span>
          <Checkbox
            checked={props.prepaid}
            onChange={props.onPrepaidChange}
            disabled={props.selectedCustomer === ''}
            color="primary"
          />
          {props.prepaid && (
            <div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <span style={{ marginRight: 16 }}>Months to Prepay:</span>
                <TextField
                  margin="dense"
                  type="number"
                  variant="outlined"
                  value={props.prepaidMonths}
                  onChange={(event) =>
                    props.onPrepaidMonthsChange(
                      Number(event.target.value) >= 0
                        ? Number(event.target.value)
                        : 0
                    )
                  }
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <div style={{ marginRight: 16 }}>Prepaid start date:</div>
                <SingleDatePicker
                  openDirection="up"
                  date={props.prepaidStart}
                  onDateChange={props.onPrepaidStartChange}
                />
              </div>
            </div>
          )}
        </div>
      )}

      {isManaged && (
        <ManagedServiceSection>
          <Row>
            <Checkbox
              checked={props.managedPrepaid}
              onChange={() => props.setManagedPrepaid(!props.managedPrepaid)}
              color="primary"
            />
            Customer will pay up front.
          </Row>
          <SectionHeader>Pricing</SectionHeader>
          <div style={{ color: colors.textLight }}>
            Enter the monthly price and duration for the managed service.
          </div>
          <Row>
            <TextField
              label="Monthly Price"
              InputProps={{ startAdornment: '$' }}
              type="number"
              value={managedPrice}
              onChange={(e) => setManagedPrice(e.target.value)}
              error={!managedPriceIsValid}
            />
          </Row>
          <Row>
            <TextField
              label="Number of Months"
              type="number"
              value={managedMonths}
              onChange={(e) => setManagedMonths(e.target.value)}
              error={!managedMonthsIsValid}
            />
          </Row>
        </ManagedServiceSection>
      )}
    </div>
  );
};

const Error = styled.div`
  color: ${(p) => p.theme.colors.error};
`;

const ManagedServiceSection = styled.div`
  margin-top: 16px;
`;

const SectionHeader = styled.div`
  font-size: ${(p) => p.theme.fontSizes.subtitle};
`;

const Row = styled.div`
  margin-top: 12px;
  margin-bottom: 12px;
`;

export default AccountManagement;
