import * as React from 'react';

import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

const TrellisBGImage = require('../TrellisBackgroundQ17.JPG');

const logo = require('../trellis_logo_full_color_dots_only.png');
import { login } from 'services/auth';

import {
  bgImageStyle,
  loginFormButtonStyle,
  loginFormFieldStyle,
  loginFormStyle,
  loginLogoStyle,
  loginPaperStyle,
  loginWrapperStyle,
} from '../styles';

import ResetDialog from './ResetDialog';
import { IsMobileConsumer } from 'providers/Responsive';
import { ApolloConsumer } from '@apollo/react-common';
import ApolloClient from 'apollo-client';

interface LoginProps {
  client: ApolloClient<object>;
}

interface LoginState {
  email: {
    text: string;
    hasError: boolean;
    errorMessage: string;
  };
  password: {
    text: string;
    hasError: boolean;
    errorMessage: string;
  };
  resetOpen: boolean;
}

class Login extends React.Component<LoginProps, LoginState> {
  login: () => void;
  emailChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  passwordChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  constructor(props: LoginProps) {
    super(props);

    this.state = {
      email: {
        text: '',
        hasError: false,
        errorMessage: '',
      },
      password: {
        text: '',
        hasError: false,
        errorMessage: '',
      },
      resetOpen: false,
    };

    this.emailChange = (event) => {
      this.setState({
        email: {
          text: event.target.value,
          hasError: false,
          errorMessage: '',
        },
      });
    };

    this.passwordChange = (event) => {
      this.setState({
        password: {
          text: event.target.value,
          hasError: false,
          errorMessage: '',
        },
      });
    };

    this.login = async () => {
      const email = this.state.email.text.replace(/\s/g, '');
      const pw = this.state.password.text;
      login(email, pw).catch((err) => {
        switch (err.code) {
          case 'auth/invalid-email':
            this.setState({
              email: {
                text: email,
                hasError: true,
                errorMessage: 'Invalid email or password',
              },
              password: {
                text: pw,
                hasError: true,
                errorMessage: 'Invalid email or password',
              },
            });
            break;
          case 'auth/user-disabled':
            this.setState({
              email: {
                text: email,
                hasError: true,
                errorMessage: 'Invalid email or password',
              },
              password: {
                text: pw,
                hasError: true,
                errorMessage: 'Invalid email or password',
              },
            });
            break;
          case 'auth/user-not-found':
            this.setState({
              email: {
                text: email,
                hasError: true,
                errorMessage: 'Account does not exist',
              },
              password: {
                text: pw,
                hasError: true,
                errorMessage: 'Account does not exist',
              },
            });
            break;
          case 'auth/wrong-password':
            this.setState({
              email: {
                text: email,
                hasError: true,
                errorMessage: 'Invalid email or password',
              },
              password: {
                text: pw,
                hasError: true,
                errorMessage: 'Invalid email or password',
              },
            });
            break;
          default:
            console.error('Unknown error: ', err);
            break;
        }
      });
    };
  }

  render() {
    return (
      <IsMobileConsumer>
        {({ isMobile }) => {
          const elevation = !isMobile ? 4 : 0;

          return (
            <div style={loginWrapperStyle}>
              <ApolloConsumer>
                {(client) => (
                  <ResetDialog
                    open={this.state.resetOpen}
                    handleRequestClose={() =>
                      this.setState({ resetOpen: false })
                    }
                    initialEmail={this.state.email.text}
                    client={client}
                  />
                )}
              </ApolloConsumer>
              <img src={TrellisBGImage} style={bgImageStyle} />
              <Paper elevation={elevation} style={loginPaperStyle}>
                <img src={logo} style={loginLogoStyle} alt="logo" />
                <form style={loginFormStyle}>
                  <TextField
                    error={this.state.email.hasError}
                    helperText={this.state.email.errorMessage}
                    style={loginFormFieldStyle}
                    label="Email"
                    margin="normal"
                    onChange={this.emailChange}
                    autoComplete="email"
                    id="email"
                  />
                  <TextField
                    error={this.state.password.hasError}
                    helperText={this.state.password.errorMessage}
                    style={loginFormFieldStyle}
                    label="Password"
                    margin="normal"
                    type="password"
                    onChange={this.passwordChange}
                    onKeyDown={(e) => (e.keyCode === 13 ? this.login() : null)}
                    id="password"
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    style={loginFormButtonStyle}
                    onClick={this.login}
                    type="button"
                    id="signIn"
                  >
                    Sign in
                  </Button>
                  <Button
                    style={loginFormButtonStyle}
                    onClick={() => this.setState({ resetOpen: true })}
                    type="button"
                  >
                    Forgot password?
                  </Button>
                </form>
              </Paper>
            </div>
          );
        }}
      </IsMobileConsumer>
    );
  }
}

export default Login;
