/* THIS IS A GENERATED FILE, DO NOT EDIT DIRECTLY */
/* eslint-disable */
import gql from 'graphql-tag';
import * as React from 'react';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export enum AboveOrBelow {
  above = 'above',
  below = 'below',
}

export type ActivateCustomerInput = {
  /** The id of the customer to activate */
  customerId: Scalars['String'];
};

export type ActivateCustomerResponse = {
  __typename: 'ActivateCustomerResponse';
  userId: Maybe<Scalars['String']>;
};

export type ActivationAddCardToUserInput = {
  last4: Scalars['String'];
  brand: Scalars['String'];
  /** Stripe card token */
  stripeToken: Scalars['String'];
  userId: Scalars['String'];
  /** The users activation token */
  token: Scalars['String'];
};

export type ActivationChangeDataAccessDataInput = {
  id: Scalars['String'];
  plan?: Maybe<SensorDataPlan>;
  gateway?: Maybe<Scalars['Boolean']>;
};

export type ActivationChangeDataAccessInput = {
  dataAccessData?: Maybe<Array<Maybe<ActivationChangeDataAccessDataInput>>>;
  token: Scalars['String'];
  userId: Scalars['String'];
};

export type ActivationChangeMutationResponse = {
  __typename: 'ActivationChangeMutationResponse';
  /** The id of the user modified */
  userId: Maybe<Scalars['String']>;
};

export type ActivationChangeNameInput = {
  name: NameInput;
  token: Scalars['String'];
  userId: Scalars['String'];
};

export type ActivationChangePasswordInput = {
  password: Scalars['String'];
  token: Scalars['String'];
  userId: Scalars['String'];
};

export type ActivationFinishInput = {
  token: Scalars['String'];
  userId: Scalars['String'];
  notifications?: Maybe<ActivationFinishNotificationsInput>;
  sharing?: Maybe<Array<Maybe<ActivationFinishSharingInput>>>;
};

export type ActivationFinishNotificationsInput = {
  triggerAlertThresholdHours: Scalars['Float'];
  textEnabled: Scalars['Boolean'];
  phone: Scalars['String'];
  emailEnabled: Scalars['Boolean'];
  email: Scalars['String'];
};

export type ActivationFinishPrepaidNotificationsInput = {
  triggerAlertThresholdHours: Scalars['Float'];
  textEnabled: Scalars['Boolean'];
  phone: Scalars['String'];
  emailEnabled: Scalars['Boolean'];
  email: Scalars['String'];
};

export type ActivationFinishSharingInput = {
  email: Scalars['String'];
  name: Scalars['String'];
  andEdit: Scalars['Boolean'];
};

export type ActivationPrepaidFinishInput = {
  token: Scalars['String'];
  userId: Scalars['String'];
  notifications?: Maybe<ActivationFinishPrepaidNotificationsInput>;
  sharing?: Maybe<Array<Maybe<ActivationFinishSharingInput>>>;
};

export type ActivationProgress = {
  __typename: 'ActivationProgress';
  billing: Scalars['Boolean'];
  dataAccess: Scalars['Boolean'];
  name: Scalars['Boolean'];
  password: Scalars['Boolean'];
};

export type ActivationProgressInput = {
  userId: Scalars['String'];
  token: Scalars['String'];
};

export type ActivationProgressResponse = {
  __typename: 'ActivationProgressResponse';
  /** Activation URL for customer */
  activationUrl: Maybe<Scalars['String']>;
  /** The id of the user */
  id: Scalars['String'];
  /** The users name, if they have set it yet */
  name: Maybe<Name>;
  email: Maybe<Scalars['String']>;
  /** The users selected data plans */
  dataAccess: Maybe<Array<ActivationProgressResponseDataAccess>>;
  cardBrand: Maybe<Scalars['String']>;
  cardLast4: Maybe<Scalars['String']>;
  /** The email of the distributor who activated the customer */
  distributorEmail: Maybe<Scalars['String']>;
  /** The name of the distributor who activated the customer */
  distributorName: Maybe<Scalars['String']>;
  /** The email of the rep who activated the customer */
  repEmail: Maybe<Scalars['String']>;
  /** The name of the rep who activated the customer */
  repName: Maybe<Scalars['String']>;
  /** A list of the users gateways */
  gateways: Array<Gateway>;
  /** The type of activation.  Different questions will be asked based on how the user got an account. */
  type: Maybe<Scalars['String']>;
  /** How far the user has made it through the activation form */
  progress: ActivationProgress;
  /** A list of the users sensors */
  sensors: Array<Sensor>;
};

export type ActivationProgressResponseDataAccess = {
  __typename: 'ActivationProgressResponseDataAccess';
  id: Scalars['String'];
  plan: Maybe<SensorDataPlan>;
};

export type ActivationSharedFinishInput = {
  token: Scalars['String'];
  userId: Scalars['String'];
};

export type AddCustomerAddressInput = {
  /** The id of the customer to add the address to */
  customerId: Scalars['String'];
  address: AddressInput;
};

export type AddCustomerAddressResponse = {
  __typename: 'AddCustomerAddressResponse';
  id: Maybe<Scalars['String']>;
};

export type AddCustomerInput = {
  /** Used when distributor is creating customer for rep */
  repId?: Maybe<Scalars['String']>;
  address?: Maybe<AddressInput>;
  email: Scalars['String'];
  name: NameInput;
  phone?: Maybe<Scalars['String']>;
};

export type AddCustomerResponse = {
  __typename: 'AddCustomerResponse';
  /** The id of the new customer */
  id: Maybe<Scalars['String']>;
};

export type AddDistributorInput = {
  email: Scalars['String'];
  name: NameInput;
  phone: Scalars['String'];
  /** ID of pricing sheet to use */
  pricing: Scalars['String'];
  salesRep: Scalars['String'];
};

export type AddDistributorInputBranding = {
  name?: Maybe<Scalars['String']>;
};

export type AddDistributorResponse = {
  __typename: 'AddDistributorResponse';
  /** The id of the new distributor */
  id: Maybe<Scalars['String']>;
};

export type AddFieldInput = {
  boundary?: Maybe<Array<NNLocationInput>>;
  boundarySoilStats?: Maybe<Array<FieldBoundaryInfoSoilStatInput>>;
  boundarySensorInfo?: Maybe<Array<FieldBoundaryInfoResultSensorInput>>;
  name: Scalars['String'];
  gateways?: Maybe<Array<Scalars['String']>>;
  /** Information about a fields irrigation */
  irrigationMetadata?: Maybe<FieldInputIrrigationMetadata>;
  sensors?: Maybe<Array<Scalars['String']>>;
  /** If a rep is adding a field to a customer, this must be supplied */
  customerId?: Maybe<Scalars['String']>;
  sensorWeights?: Maybe<Array<SensorWeightInput>>;
  /** Acreage estimate entered during new field creation */
  estimatedAcreage?: Maybe<Scalars['Float']>;
  /** Acreage calculated from field boundary input */
  calculatedAcreage?: Maybe<Scalars['Float']>;
  /** Crop ID corresponding to this field's crop_type */
  crop_type?: Maybe<Scalars['String']>;
  /** String designating specific crop variety */
  crop_variety?: Maybe<Scalars['String']>;
  /** Yield of this field */
  yield?: Maybe<YieldInput>;
};

export type AddOrderInput = {
  address: AddressInput;
  hardware: Array<AddOrderInputHardware>;
  repId: Scalars['String'];
  customerId?: Maybe<Scalars['String']>;
  prepaid?: Maybe<AddOrderInputPrepaid>;
  notes?: Maybe<Scalars['String']>;
  /**
   * If emails are provided, they will have the hardware shared with them upon
   * the order being bound
   * If set, sharingLevel and sharingName must also be set
   */
  sharingEmails?: Maybe<Array<Scalars['String']>>;
  sharingNames?: Maybe<Array<Scalars['String']>>;
  /** The level the sharingEmails should be shared at */
  sharingLevel?: Maybe<PermissionLevelInput>;
  /** If the order is managed service, this will be set */
  managedServiceOptions?: Maybe<AddOrderManagedServiceOptionsInput>;
};

export type AddOrderInputHardware = {
  model: ModelInput;
  quantity: Scalars['Int'];
};

export type AddOrderInputPrepaid = {
  plan: SensorDataPlan;
  months: Scalars['Int'];
  /**
   * Start date
   * If not set, will start when bound
   */
  start?: Maybe<Scalars['Float']>;
};

export type AddOrderManagedServiceOptionsInput = {
  /** The monthly cost of order */
  price: Scalars['Float'];
  /** Number of months it is for */
  months: Scalars['Int'];
  /** Whether or not it is prepaid managed service or monthly */
  prepaid: Scalars['Boolean'];
};

export type AddOrderResponse = {
  __typename: 'AddOrderResponse';
  /** The id of the new order */
  id: Maybe<Scalars['Int']>;
};

export type AddOrderResponseHardware = {
  __typename: 'AddOrderResponseHardware';
  model: Maybe<Model>;
  tempId: Maybe<Scalars['String']>;
};

export type AddPricingInput = {
  name: Scalars['String'];
  hardware: Array<AddPricingInputHardware>;
};

export type AddPricingInputHardware = {
  modelId: Scalars['String'];
  prices: Array<AddPricingInputHardwarePrice>;
};

export type AddPricingInputHardwarePrice = {
  quantity: Scalars['Int'];
  price: Scalars['Float'];
};

export type AddPricingResponse = {
  __typename: 'AddPricingResponse';
  /** The id of the new pricing object */
  id: Maybe<Scalars['String']>;
};

export type AddRepAddressInput = {
  repId: Scalars['String'];
  address: AddressInput;
};

export type AddRepAddressResponse = {
  __typename: 'AddRepAddressResponse';
  /** The id of the new address */
  id: Maybe<Scalars['String']>;
};

export type AddRepInput = {
  email: Scalars['String'];
  name: NameInput;
  phone: Scalars['String'];
  /** Used for creating rep as admin */
  distributorId?: Maybe<Scalars['String']>;
};

export type AddRepResponse = {
  __typename: 'AddRepResponse';
  /** The id of the new rep */
  id: Maybe<Scalars['String']>;
};

export type Address = {
  __typename: 'Address';
  city: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Maybe<Name>;
  state: Maybe<Scalars['String']>;
  street: Maybe<Scalars['String']>;
  zip: Maybe<Scalars['String']>;
};

export type AddressInput = {
  city: Scalars['String'];
  name: NameInput;
  state: Scalars['String'];
  street: Scalars['String'];
  zip: Scalars['String'];
};

export type AddSoilInput = {
  name: Scalars['String'];
  t_wet?: Maybe<Scalars['Float']>;
  t_dry?: Maybe<Scalars['Float']>;
  c_wet?: Maybe<Scalars['Float']>;
  c_dry?: Maybe<Scalars['Float']>;
  clay_percent?: Maybe<Scalars['Float']>;
};

export type AddSoilResponse = {
  __typename: 'AddSoilResponse';
  success: Scalars['Boolean'];
  soil: Soil;
};

export type Alert = {
  __typename: 'Alert';
  last_monthly_report: Maybe<Scalars['Float']>;
  last_weekly_report: Maybe<Scalars['Float']>;
  last_daily_report: Maybe<Scalars['Float']>;
  last_login_nag: Maybe<Scalars['Float']>;
  last_soil_alert: Maybe<Scalars['Float']>;
  did_you_knows: Array<UsedDidYouKnows>;
  last_registration_reminder: Maybe<Scalars['Float']>;
  total_registration_reminders: Maybe<Scalars['Float']>;
  irrigationNeeded: Maybe<Scalars['Float']>;
};

export type ApplyPrepaidToUserInput = {
  email: Scalars['String'];
  /** Timestamp in ms */
  start: Scalars['Float'];
  end: Scalars['Float'];
  sensorIds: Array<Scalars['String']>;
  gatewayIds: Array<Scalars['String']>;
};

export type ApproveOrderInput = {
  /** The id of the order to approve */
  orderId: Scalars['Int'];
  /** A boolean designating if the order is to be approved or rejected */
  approved: Scalars['Boolean'];
};

export type ApproveOrderResponse = {
  __typename: 'ApproveOrderResponse';
  /** The id of the approved order */
  id: Maybe<Scalars['Int']>;
  /** Temporary ids given to hardware */
  hardware: Maybe<Array<Maybe<AddOrderResponseHardware>>>;
};

export type ArchivedField = {
  __typename: 'ArchivedField';
  /** YYYY-MM-DD */
  archiveDate: Scalars['String'];
  gateways: Array<ArchivedGateway>;
  id: Scalars['String'];
  irrigationMetadata: IrrigationMetadata;
  irrigationEvents: Array<IrrigationEvent>;
  moistureData: FieldMoistureDataResult;
  cropType: Maybe<Crop>;
  name: Scalars['String'];
  permissionLevel: Maybe<PermissionLevel>;
  /** If field contains multiple hardware rainfall data generators, they will be returned in this array */
  rainfallSources: Maybe<Array<Maybe<ArchivedHardware>>>;
  /** Season total rainfall entered by user during archive */
  seasonTotalRainfall: Maybe<Scalars['Float']>;
  /** Season total irrigation entered by user during archive, may not match cumulative total from Irrigation Events */
  seasonTotalIrrigation: Maybe<Scalars['Float']>;
  sensors: Array<ArchivedSensor>;
  sensorWeights: Array<SensorWeight>;
  owner: Maybe<HardwareUser>;
  users: Array<HardwareUser>;
  yield: YieldType;
  yieldData: YieldData;
};

export type ArchivedGateway = ArchivedHardware & {
  __typename: 'ArchivedGateway';
  /** YYYY-MM-DD */
  archiveDate: Scalars['String'];
  archiveId: Scalars['String'];
  dataView: Maybe<SensorDataView>;
  fields: Array<Maybe<Field>>;
  hasDataAccess: Scalars['Boolean'];
  userHasSensorAccess: Scalars['Boolean'];
  /** Returns true if this model of gateway has a rain gauge */
  hasRainGauge: Scalars['Boolean'];
  id: Scalars['String'];
  originalId: Scalars['String'];
  location: Maybe<Location>;
  model: Maybe<Model>;
  name: Scalars['String'];
  owner: Maybe<HardwareUser>;
  permissionLevel: Maybe<PermissionLevel>;
  /** Always null */
  plan: Maybe<SensorDataPlan>;
  sharingMaxUsers: Scalars['Int'];
  users: Array<HardwareUser>;
};

/** Archived Sensor or Gateway */
export type ArchivedHardware = {
  id: Scalars['String'];
  dataView: Maybe<SensorDataView>;
  hasDataAccess: Scalars['Boolean'];
  userHasSensorAccess: Scalars['Boolean'];
  hasRainGauge: Scalars['Boolean'];
  name: Scalars['String'];
  sharingMaxUsers: Scalars['Int'];
  owner: Maybe<HardwareUser>;
  plan: Maybe<SensorDataPlan>;
  permissionLevel: Maybe<PermissionLevel>;
  users: Array<HardwareUser>;
};

export type ArchivedIrrigationSystem = {
  __typename: 'ArchivedIrrigationSystem';
  /** YYYY-MM-DD */
  archiveDate: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  sensors: Array<ArchivedTrellisPressureSensor>;
  users: Array<HardwareUser>;
};

/** A sensor that has been archived */
export type ArchivedSensor = ArchivedHardware &
  SensorBase & {
    __typename: 'ArchivedSensor';
    /** YYYY-MM-DD */
    archiveDate: Scalars['String'];
    canDownloadData: Scalars['Boolean'];
    crop_type: Maybe<Crop>;
    crop_variety: Maybe<Scalars['String']>;
    dataView: Maybe<SensorDataView>;
    field: Maybe<Field>;
    /** YYYY-MM-DD */
    harvest_date: Maybe<Scalars['String']>;
    hasDataAccess: Scalars['Boolean'];
    hasRainfallData: Scalars['Boolean'];
    userHasSensorAccess: Scalars['Boolean'];
    id: Scalars['String'];
    originalId: Scalars['String'];
    location: Maybe<Location>;
    managementPlan: Maybe<SensorManagementPlan>;
    model: Maybe<Model>;
    moistureMethod: Maybe<MoistureMethod>;
    name: Scalars['String'];
    owner: Maybe<HardwareUser>;
    users: Array<HardwareUser>;
    isOwner: Scalars['Boolean'];
    permissionLevel: Maybe<PermissionLevel>;
    /** Always null */
    plan: Maybe<SensorDataPlan>;
    plant_date: Maybe<Scalars['String']>;
    sharingMaxUsers: Scalars['Int'];
    soil_type: Maybe<Soil>;
    /** Returns true if this model of sensor has a rain gauge */
    hasRainGauge: Scalars['Boolean'];
    weather: Weather;
  };

export type ArchivedTrellisPressureSensor = ArchivedTrellisSensor & {
  __typename: 'ArchivedTrellisPressureSensor';
  data: PressureSensorData;
  plan: Maybe<PressureSensorPlan>;
  irrigationSystem: Maybe<IrrigationSystem>;
  id: Scalars['String'];
  name: Scalars['String'];
  location: Maybe<Location>;
  isOwner: Scalars['Boolean'];
  owner: Maybe<HardwareUser>;
  permissionLevel: Maybe<PermissionLevel>;
  users: Array<HardwareUser>;
};

export type ArchivedTrellisSensor = {
  id: Scalars['String'];
  name: Scalars['String'];
  location: Maybe<Location>;
  isOwner: Scalars['Boolean'];
  owner: Maybe<HardwareUser>;
  permissionLevel: Maybe<PermissionLevel>;
  users: Array<HardwareUser>;
};

export type ArchiveFieldInput = {
  fieldId: Scalars['String'];
  seasonStartDate: Scalars['Float'];
  seasonEndDate: Scalars['Float'];
  yieldData?: Maybe<ArchiveFieldYieldDataInput>;
  /** Season total rainfall entered by user during archive */
  seasonTotalRainfall?: Maybe<Scalars['Float']>;
  /** Season total irrigation entered by user during archive, may not match cumulative total from Irrigation Events */
  seasonTotalIrrigation?: Maybe<Scalars['Float']>;
};

export type ArchiveFieldResponse = {
  __typename: 'ArchiveFieldResponse';
  success: Scalars['Boolean'];
};

export type ArchiveFieldYieldDataInput = {
  actualYield?: Maybe<YieldInput>;
  targetYield?: Maybe<YieldInput>;
  performance?: Maybe<YieldDataPerformance>;
};

export type ArchiveGatewayInput = {
  gatewayId: Scalars['String'];
  seasonStartDate: Scalars['Float'];
  seasonEndDate: Scalars['Float'];
};

export type ArchiveGatewayResponse = {
  __typename: 'ArchiveGatewayResponse';
  success: Scalars['Boolean'];
  archivedId: Scalars['String'];
};

export type ArchiveIrrigationSystemInput = {
  irrigationSystemId: Scalars['String'];
  seasonStartDate: Scalars['Float'];
  seasonEndDate: Scalars['Float'];
};

export type ArchiveIrrigationSystemResponse = {
  __typename: 'ArchiveIrrigationSystemResponse';
  success: Scalars['Boolean'];
};

export type ArchiveSensorInput = {
  sensorId: Scalars['String'];
  seasonStartDate: Scalars['Float'];
  seasonEndDate: Scalars['Float'];
};

export type ArchiveSensorResponse = {
  __typename: 'ArchiveSensorResponse';
  success: Scalars['Boolean'];
  archivedId: Scalars['String'];
};

export type BindOrderHardwareIds = {
  /** The temporary id given to the hardware */
  tempId: Scalars['String'];
  /** The id of the piece of hardware being bound */
  newId: Scalars['String'];
  model: ModelInput;
};

export type BindOrderInput = {
  /** The id of the order to update */
  orderId: Scalars['Int'];
  hardwareIds?: Maybe<Array<BindOrderHardwareIds>>;
};

export type BindOrderResponse = {
  __typename: 'BindOrderResponse';
  /** The id of the order that was bound */
  id: Maybe<Scalars['Int']>;
};

export enum CacheControlScope {
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE',
}

export type CancelPrepaidToUserInput = {
  email: Scalars['String'];
  sensorIds: Array<Scalars['String']>;
  gatewayIds: Array<Scalars['String']>;
};

export type ChangeCardInput = {
  stripeToken: Scalars['String'];
};

export type ChangeCardResponse = {
  __typename: 'ChangeCardResponse';
  /** The id of the user updated */
  id: Maybe<Scalars['String']>;
};

export enum ClaimHardwareIdType {
  SENSOR = 'SENSOR',
  GATEWAY = 'GATEWAY',
}

export type CommissionStructure = {
  __typename: 'CommissionStructure';
  value: Maybe<Scalars['Float']>;
  rate: Maybe<Scalars['Float']>;
};

export enum ConnectionStatus {
  online = 'online',
  warning = 'warning',
  offline = 'offline',
}

export type Crop = {
  __typename: 'Crop';
  id: Scalars['String'];
  name: Scalars['String'];
  didYouKnows: Array<CropDidYouKnow>;
  custom: Scalars['Boolean'];
};

export type CropAddInput = {
  name: Scalars['String'];
};

export type CropAddResponse = {
  __typename: 'CropAddResponse';
  crop: Crop;
};

export type CropDeleteInput = {
  id: Scalars['String'];
};

export type CropDeleteResponse = {
  __typename: 'CropDeleteResponse';
  success: Scalars['Boolean'];
};

export type CropDidYouKnow = {
  __typename: 'CropDidYouKnow';
  id: Scalars['String'];
  content: Scalars['String'];
};

export type CropUpdateInput = {
  id: Scalars['String'];
  name: Scalars['String'];
};

export type CropUpdateResponse = {
  __typename: 'CropUpdateResponse';
  crop: Crop;
};

export type CurrentPressure = {
  __typename: 'CurrentPressure';
  /** Latest data point */
  latest: SensorPressureData;
  /** Whether or not the pressure sensor is currently on */
  isOn: Scalars['Boolean'];
  scheduleResult: PressureScheduleResult;
};

export type CurrentPressureResult = CurrentPressure | SensorOffline;

export type CurrentWeatherResult = WeatherStationData | SensorOffline;

/** A static copy of a User that was saved when the distibutor added the customer. Can be edited independently. */
export type Customer = {
  __typename: 'Customer';
  addresses: Array<Address>;
  email: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Maybe<Name>;
  phone: Maybe<Scalars['String']>;
  repId: Maybe<Scalars['String']>;
  /** The user object of this customer.  Can be used to get sensors, data plans, etc */
  user: User;
};

export type CustomerConnection = {
  __typename: 'CustomerConnection';
  edges: Array<CustomerEdge>;
  pageInfo: PageInfo;
};

export type CustomerEdge = {
  __typename: 'CustomerEdge';
  cursor: Scalars['String'];
  node: Customer;
};

export type DeleteDealerInput = {
  /** The id of the delear to be deleted */
  dealerId: Scalars['String'];
};

export type DeleteDealerResponse = {
  __typename: 'DeleteDealerResponse';
  /** The id of the deleted dealer */
  id: Scalars['String'];
};

export type DeleteFieldInput = {
  fieldId: Scalars['String'];
  /** If a rep is deleting a customers field, this must be supplied */
  customerId?: Maybe<Scalars['String']>;
};

export type DeleteSoilInput = {
  id: Scalars['String'];
};

export type DeleteSoilResponse = {
  __typename: 'DeleteSoilResponse';
  /** The id of the soil deleted */
  id: Scalars['String'];
};

export type Distributor = {
  __typename: 'Distributor';
  activationCompleted: Scalars['Boolean'];
  alert_history: Maybe<Alert>;
  /** For a rep, this is all of their direct customers.  For a distributor, this is'direct customers + their rep's customers */
  fastCustomers: CustomerConnection;
  customers: Array<Customer>;
  email: Maybe<Scalars['String']>;
  id: Scalars['String'];
  /** Used on the distributor portal to determine if the admin stuff should show up */
  isAdmin: Maybe<Scalars['Boolean']>;
  isRep: Maybe<Scalars['Boolean']>;
  isDistributor: Maybe<Scalars['Boolean']>;
  name: Maybe<Name>;
  /** If this is a rep account, it will be the distributor that created the rep */
  parent: Maybe<Distributor>;
  phone: Maybe<Scalars['String']>;
  pricing: Pricing;
  reps: Array<DistributorRepCopy>;
  orders: Array<Order>;
  salesRep: Maybe<SalesRep>;
  stripeInfo: Maybe<DistributorStripeInfo>;
  /** Hardware that the distributor has purchased but not assigned to a customer */
  unassigned: DistributorUnassigned;
};

export type DistributorfastCustomersArgs = {
  first: Maybe<Scalars['Int']>;
  after: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
};

export type DistributorBranding = {
  __typename: 'DistributorBranding';
  name: Maybe<Scalars['String']>;
};

export type DistributorChangePasswordInput = {
  password: Scalars['String'];
  token: Scalars['String'];
  userId: Scalars['String'];
};

export type DistributorChangePasswordResponse = {
  __typename: 'DistributorChangePasswordResponse';
  /** The id of the new distributor changed */
  id: Maybe<Scalars['String']>;
};

/** A static copy of a Rep that was saved when the distributor added the rep. Can be edited independently. */
export type DistributorRepCopy = {
  __typename: 'DistributorRepCopy';
  addresses: Array<Address>;
  email: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Maybe<Name>;
  phone: Maybe<Scalars['String']>;
};

export type DistributorSignTC = {
  __typename: 'DistributorSignTC';
  id: Maybe<Scalars['String']>;
};

export type DistributorSignTCInput = {
  token: Scalars['String'];
  userId: Scalars['String'];
  tcText: Scalars['String'];
};

export type DistributorStripeInfo = {
  __typename: 'DistributorStripeInfo';
  /** Per Stripe: 'The URL expires a brief period after you retrieve it from the login_link API, so you should use it promptly.' */
  stripeURL: Scalars['String'];
};

export type DistributorStripeRegisterInput = {
  authCode: Scalars['String'];
};

export type DistributorStripeRegisterResponse = {
  __typename: 'DistributorStripeRegisterResponse';
  success: Scalars['Boolean'];
};

export type DistributorUnassigned = {
  __typename: 'DistributorUnassigned';
  sensors: Array<Sensor>;
  gateways: Array<Gateway>;
};

export type DistributorUnassignedUnbound = {
  __typename: 'DistributorUnassignedUnbound';
  id: Scalars['String'];
  model: Maybe<Model>;
  order: Maybe<Order>;
  orderId: Maybe<Scalars['Int']>;
};

export type DripStats = {
  /** Emitter flow rate (gallons/min) */
  emitterFlow?: Maybe<Scalars['Float']>;
  /** Emitter spacing along the line (cm) */
  emitterSpacing?: Maybe<Scalars['Float']>;
  /** Distance between drip lines (in) */
  dripDistance?: Maybe<Scalars['Float']>;
};

export type DripStatsType = {
  __typename: 'DripStatsType';
  emitterFlow: Maybe<Scalars['Float']>;
  emitterSpacing: Maybe<Scalars['Float']>;
  dripDistance: Maybe<Scalars['Float']>;
};

export type EditCustomerHardwareAddInputType = {
  sensorIds?: Maybe<Array<Scalars['String']>>;
  gatewayIds?: Maybe<Array<Scalars['String']>>;
};

export type EditCustomerHardwareInputType = {
  /** The id of the customer to edit */
  customerId: Scalars['String'];
  add?: Maybe<EditCustomerHardwareAddInputType>;
  remove?: Maybe<EditCustomerHardwareRemoveInputType>;
};

export type EditCustomerHardwareRemoveInputType = {
  sensorIds?: Maybe<Array<Scalars['String']>>;
  gatewayIds?: Maybe<Array<Scalars['String']>>;
};

export type EditCustomerHardwareResponse = {
  __typename: 'EditCustomerHardwareResponse';
  /** The id of the customer that was changed */
  id: Maybe<Scalars['String']>;
};

export type FakeHistoricalWeatherDataInput = {
  /** The id of the sensor to populate */
  sensorId: Scalars['String'];
  /** Timestamp ms to start */
  start: Scalars['Float'];
  /** Timestamp ms to end */
  end: Scalars['Float'];
};

export type FakeLocationDataInput = {
  /** The id of the sensor to populate */
  sensorId: Scalars['String'];
  /** The gw of the sensor to fake. Default is null */
  gatewayId?: Maybe<Scalars['String']>;
};

export type FakeLocationDataResponse = {
  __typename: 'FakeLocationDataResponse';
  loc: Maybe<Array<Maybe<Scalars['Float']>>>;
};

export type FakeSensorDataInput = {
  /** The id of the sensor to populate */
  sensorId: Scalars['String'];
  /** The gw of the sensor. Default is null */
  gatewayId?: Maybe<Scalars['String']>;
  /** Timestamp ms to start */
  start: Scalars['Float'];
  /** Timestamp ms to end */
  end: Scalars['Float'];
  /** The kind of data to populate it with */
  type: FakeSensorDataType;
};

export type FakeSensorDataResponse = {
  __typename: 'FakeSensorDataResponse';
  sensorId: Maybe<Scalars['String']>;
};

export enum FakeSensorDataType {
  /** No sensor data */
  none = 'none',
  /** 2 Moisture, 2 Temp */
  WWTT = 'WWTT',
  /** 3 Moisture, 1 Temp */
  WWWT = 'WWWT',
  /** Rain Gauge */
  RG = 'RG',
}

export type Field = {
  __typename: 'Field';
  boundary: Maybe<Array<Location>>;
  boundarySoilStats: Maybe<Array<FieldBoundaryInfoSoilStat>>;
  boundarySensorInfo: Maybe<Array<FieldBoundaryInfoResultSensor>>;
  crop_type: Maybe<Crop>;
  crop_variety: Maybe<Scalars['String']>;
  current_state: Maybe<Scalars['Int']>;
  current_tension: Maybe<Scalars['Int']>;
  gateways: Array<Gateway>;
  hoursToDry: HoursToDry;
  id: Scalars['String'];
  active: Maybe<Scalars['Boolean']>;
  irrigationMetadata: IrrigationMetadata;
  irrigationEvents: Array<IrrigationEvent>;
  moistureData: FieldMoistureDataResult;
  moistureProjection: FieldMoistureProjection;
  name: Scalars['String'];
  permissionLevel: Maybe<PermissionLevel>;
  /** If field contains multiple hardware rainfall data generators, they will be returned in this array */
  rainfallSources: Maybe<Array<Maybe<Hardware>>>;
  sensors: Array<Sensor>;
  sensorWeights: Array<SensorWeight>;
  owner: Maybe<HardwareUser>;
  users: Array<HardwareUser>;
  weatherStation: Maybe<TrellisWeatherSensor>;
  yield: YieldType;
  /** new acreage calculations */
  estimatedAcreage: Maybe<Scalars['Float']>;
  calculatedAcreage: Maybe<Scalars['Float']>;
};

export type FieldBoundaryInfoInput = {
  bounds: Array<NNLocationInput>;
  sensors: Array<FieldBoundaryInfoInputSensor>;
};

export type FieldBoundaryInfoInputSensor = {
  id: Scalars['String'];
  location: NNLocationInput;
};

export type FieldBoundaryInfoResult = {
  __typename: 'FieldBoundaryInfoResult';
  sensorsInBounds: Array<FieldBoundaryInfoResultSensor>;
  soilStats: Array<FieldBoundaryInfoSoilStat>;
  calculatedAcreage: Maybe<Scalars['Float']>;
};

export type FieldBoundaryInfoResultSensor = {
  __typename: 'FieldBoundaryInfoResultSensor';
  id: Scalars['String'];
  soil: Maybe<Soil>;
  /** % between 0 - 100 */
  soilPercent: Maybe<Scalars['Int']>;
};

export type FieldBoundaryInfoResultSensorInput = {
  id: Scalars['String'];
  soilId: Scalars['String'];
  soilPercent: Scalars['Int'];
};

export type FieldBoundaryInfoSoilStat = {
  __typename: 'FieldBoundaryInfoSoilStat';
  soil: Soil;
  soilPercent: Scalars['Int'];
};

export type FieldBoundaryInfoSoilStatInput = {
  soilId: Scalars['String'];
  soilPercent: Scalars['Int'];
};

export type FieldInputIrrigationMetadata = {
  irrigationType?: Maybe<Scalars['String']>;
  /** Source of irrigation water */
  waterSource?: Maybe<Scalars['String']>;
  /** Source of irrigation power */
  powerSource?: Maybe<Scalars['String']>;
  /** Vendor of the irrigation equipment */
  vendor?: Maybe<Scalars['String']>;
  /** The size of the field */
  acreage?: Maybe<Scalars['Float']>;
  /** Drip irrigation metadata */
  dripStats?: Maybe<DripStats>;
};

export type FieldMoistureData = {
  __typename: 'FieldMoistureData';
  moistureAvg: Array<FieldMoistureDataPoint>;
  /** Ordered list of soil threshold averages */
  thresholdsAvg: Array<MoistureThresholdPoint>;
  /** Moisture method of the data */
  moistureMethod: MoistureMethod;
  /** Errors if some sensors could not be used in the calculation */
  errors: Array<Scalars['String']>;
};

export type FieldMoistureDataError = {
  __typename: 'FieldMoistureDataError';
  messages: Array<Scalars['String']>;
};

export type FieldMoistureDataPoint = {
  __typename: 'FieldMoistureDataPoint';
  /** Time in unix ms */
  t: Scalars['Float'];
  /** Average soil moisture tension */
  sm: Scalars['Float'];
};

export type FieldMoistureDataResult =
  | FieldMoistureData
  | FieldMoistureDataError;

export type FieldMoistureProjection = {
  __typename: 'FieldMoistureProjection';
  data: Maybe<Array<FieldMoistureProjectionPoint>>;
  /** Any errors about projecting any of the sensors */
  errors: Array<Scalars['String']>;
};

export type FieldMoistureProjectionPoint = {
  __typename: 'FieldMoistureProjectionPoint';
  t: Scalars['Float'];
  sm: Scalars['Float'];
};

export type FieldMutationResponse = {
  __typename: 'FieldMutationResponse';
  success: Scalars['Boolean'];
  field: Maybe<Field>;
};

export type FieldSharingUpdateInput = {
  fieldId: Scalars['String'];
  /** The email of the user to share it with */
  email: Scalars['String'];
  /** The permission level to give the user, or null to remove the permission */
  level?: Maybe<PermissionLevelInput>;
};

export type FieldSharingUpdateResponse = {
  __typename: 'FieldSharingUpdateResponse';
  success: Scalars['Boolean'];
  sharedUid: Scalars['String'];
  field: Field;
};

export type FlashLibraryEntry = {
  __typename: 'FlashLibraryEntry';
  name: Scalars['String'];
  memoryLocation: Scalars['Float'];
  defaultValue: Scalars['String'];
  min: Maybe<Scalars['String']>;
  max: Maybe<Scalars['String']>;
};

export type Gateway = Hardware & {
  __typename: 'Gateway';
  /**
   * Whether or not the sensor is active.  Controls alerts, visibility on
   * dashboard, etc... Set true when the sensor's data is paid for, and false when
   * the sensor is archived.
   */
  active: Scalars['Boolean'];
  cell_signal: Maybe<Scalars['Float']>;
  /** The latest values reported by the gateway */
  configuration: Maybe<GatewayConfiguration>;
  configurationUpdated: Maybe<Scalars['Float']>;
  gpsUpdated: Maybe<Scalars['Float']>;
  gpsLocked: Maybe<Scalars['Boolean']>;
  /** Is the gateway online or offline? */
  connectionStatus: ConnectionStatus;
  currentPressure: Maybe<SensorPressureData>;
  currentWeather: Maybe<WeatherStationData>;
  data: Maybe<Array<GatewayData>>;
  dataView: Maybe<SensorDataView>;
  fields: Array<Maybe<Field>>;
  gateway_battery: Maybe<Scalars['Float']>;
  gateway_time: Maybe<Scalars['Float']>;
  /** Current gateway reporting interval in ms */
  gateway_time_interval: Maybe<Scalars['Int']>;
  hasDataAccess: Scalars['Boolean'];
  userHasSensorAccess: Scalars['Boolean'];
  /** Returns true if this model of gateway has a rain gauge */
  hasRainGauge: Scalars['Boolean'];
  id: Scalars['String'];
  /** Whether or not the sensor should appear on the distributor portal status page */
  inactive: Scalars['Boolean'];
  location: Maybe<Location>;
  mailbox: Array<MailboxSlot>;
  model: Maybe<Model>;
  name: Scalars['String'];
  network: Maybe<GatewayNetwork>;
  order: Maybe<Order>;
  owner: Maybe<HardwareUser>;
  isOwner: Scalars['Boolean'];
  permissionLevel: Maybe<PermissionLevel>;
  /**
   * If stripeSubActiveUntil is in the future, this will be it
   * This will be set regardless of if the sensor is cancelled or not
   */
  planActiveUntil: Maybe<Scalars['Float']>;
  /**
   * If stripeSubCancelledAt is in the future, this will be it
   * This should be set only if the sensor is scheduled to be cancelled at the end of the cycle
   */
  planCancelDate: Maybe<Scalars['Float']>;
  plan: Maybe<SensorDataPlan>;
  /** The currently selected plan for the gateway OR null if the gateway does not have an active subscription */
  activePlan: Maybe<SensorDataPlan>;
  /** Stripe plan ID. Use "plan" for frontend stuff. */
  planId: Maybe<StripeDataPlan>;
  /** Whether or not this piece of hardware needs a plan to access data */
  planRequired: Scalars['Boolean'];
  sensorData: Maybe<Array<SensorData>>;
  sensorIds: Array<Scalars['String']>;
  sensors: Array<Sensor>;
  serviceLogs: Array<ServiceLog>;
  sharingMaxUsers: Scalars['Int'];
  /** List of warnings for gateway */
  statusWarnings: Array<HardwareWarningEnum>;
  users: Array<HardwareUser>;
};

export type GatewaydataArgs = {
  start: Maybe<Scalars['Float']>;
  limit: Maybe<Scalars['Int']>;
};

export type GatewayConfiguration = {
  __typename: 'GatewayConfiguration';
  boot: Maybe<Scalars['Boolean']>;
  crit_th: Maybe<Scalars['Float']>;
  freq: Maybe<Scalars['Float']>;
  hw_ver: Maybe<Scalars['String']>;
  led_th: Maybe<Scalars['Float']>;
  sw_ver: Maybe<Scalars['String']>;
  voltage_th: Maybe<Scalars['Float']>;
  ws_fw_ver: Maybe<Scalars['String']>;
};

export type GatewayData = {
  __typename: 'GatewayData';
  cell_signal: Maybe<Scalars['Float']>;
  netOpenRetry: Maybe<Scalars['Float']>;
  tcpOpenRetry: Maybe<Scalars['Float']>;
  tcpSendRetry: Maybe<Scalars['Float']>;
  failCounter: Maybe<Scalars['Float']>;
  resetCounter: Maybe<Scalars['Float']>;
  rebootCounter: Maybe<Scalars['Float']>;
  rxBadCounter: Maybe<Scalars['Float']>;
  localCounter: Maybe<Scalars['Float']>;
  rebootLimitCounter: Maybe<Scalars['Float']>;
  isCharging: Maybe<Scalars['Float']>;
  pwrOnRstCounter: Maybe<Scalars['Float']>;
  brnOutRstCounter: Maybe<Scalars['Float']>;
  extRstCounter: Maybe<Scalars['Float']>;
  wdtRstCounter: Maybe<Scalars['Float']>;
  tcpFailID: Maybe<Scalars['Float']>;
  msg: Maybe<Scalars['Float']>;
  time: Maybe<Scalars['Float']>;
  vbat: Maybe<Scalars['Float']>;
  vsol: Maybe<Scalars['Float']>;
  rbat: Maybe<Scalars['Float']>;
  lock: Maybe<Scalars['Boolean']>;
};

export type GatewayFlashInput = {
  gatewayId: Scalars['String'];
  location: Scalars['String'];
  value: Scalars['String'];
  name: Scalars['String'];
};

export type GatewayFlashResponse = {
  __typename: 'GatewayFlashResponse';
  success: Scalars['Boolean'];
};

export type GatewayNetwork = {
  __typename: 'GatewayNetwork';
  availableServices: Maybe<Array<Maybe<Scalars['String']>>>;
  ccid: Maybe<Scalars['String']>;
  currentService: Maybe<Scalars['String']>;
  firmwareVersion: Maybe<Scalars['String']>;
};

export type GatewayReprogramInput = {
  gatewayId: Scalars['String'];
  softwareVersion: Scalars['String'];
  hardwareVersion: Scalars['String'];
  variant: Scalars['String'];
  name: Scalars['String'];
};

export type GatewayReprogramResponse = {
  __typename: 'GatewayReprogramResponse';
  success: Scalars['Boolean'];
};

export type GatewayResetInput = {
  gatewayId: Scalars['String'];
  name: Scalars['String'];
};

export type GatewayResetResponse = {
  __typename: 'GatewayResetResponse';
  success: Scalars['Boolean'];
};

export type GatewaySimcomInput = {
  gatewayId: Scalars['String'];
  payload: Scalars['String'];
  name: Scalars['String'];
};

export type GatewaySimcomResponse = {
  __typename: 'GatewaySimcomResponse';
  success: Scalars['Boolean'];
};

export type GatewayStatusMutationResponse = {
  __typename: 'GatewayStatusMutationResponse';
  /** The id of the gateway modified */
  id: Maybe<Scalars['String']>;
};

/** Sensor or Gateway */
export type Hardware = {
  id: Scalars['String'];
  dataView: Maybe<SensorDataView>;
  hasDataAccess: Scalars['Boolean'];
  userHasSensorAccess: Scalars['Boolean'];
  hasRainGauge: Scalars['Boolean'];
  location: Maybe<Location>;
  name: Scalars['String'];
  planRequired: Scalars['Boolean'];
  sharingMaxUsers: Scalars['Int'];
  owner: Maybe<HardwareUser>;
  plan: Maybe<SensorDataPlan>;
  activePlan: Maybe<SensorDataPlan>;
  permissionLevel: Maybe<PermissionLevel>;
  users: Array<HardwareUser>;
};

export enum HardwareTypeEnum {
  sensor = 'sensor',
  gateway = 'gateway',
  replacementSensorProbe = 'replacementSensorProbe',
  replacementEnclosure = 'replacementEnclosure',
  replacementAntenna = 'replacementAntenna',
  replacementGatewayEnclosure = 'replacementGatewayEnclosure',
  replacementSensorEnclosure = 'replacementSensorEnclosure',
  replacementRainGauge = 'replacementRainGauge',
  replacementWeatherStation = 'replacementWeatherStation',
  watermarkSensor = 'watermarkSensor',
  auger = 'auger',
  replacementCircuitBoard = 'replacementCircuitBoard',
  replacementSensorBoard = 'replacementSensorBoard',
  replacementGatewayBoard = 'replacementGatewayBoard',
  replacementMercuryModule = 'replacementMercuryModule',
}

/**
 * A limited version of a user, used for sharing
 * Admins can get more information via the user field
 */
export type HardwareUser = {
  __typename: 'HardwareUser';
  id: Scalars['String'];
  name: Maybe<Name>;
  accountEmail: Maybe<Scalars['String']>;
  permissionLevel: PermissionLevel;
  /** Admin only */
  user: User;
};

export type HardwareVersions = {
  __typename: 'HardwareVersions';
  name: Scalars['String'];
  variants: Array<Scalars['String']>;
};

export enum HardwareWarningEnum {
  BATTERY_LOW = 'BATTERY_LOW',
  SIGNAL_LOW = 'SIGNAL_LOW',
  NOT_REPORTED_RECENTLY = 'NOT_REPORTED_RECENTLY',
  ZERO_ADC = 'ZERO_ADC',
  INACTIVE_SUB = 'INACTIVE_SUB',
}

export type HoursToDry = {
  __typename: 'HoursToDry';
  prediction: Maybe<Scalars['Int']>;
  errors: Array<Scalars['String']>;
};

export type InstallModeInput = {
  hwid: Scalars['String'];
  options: Scalars['String'];
};

export type InstallModeMutationResponse = {
  __typename: 'InstallModeMutationResponse';
  success: Scalars['Boolean'];
};

export type Invoice = {
  __typename: 'Invoice';
  date: Scalars['Float'];
  amount: Scalars['Float'];
};

export type Irrigation = {
  __typename: 'Irrigation';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type IrrigationEvent = {
  __typename: 'IrrigationEvent';
  id: Scalars['String'];
  /** Amount of irrigation in inches */
  amount: Scalars['Float'];
  /** Date of irrigation event (YYYY-MM-DD) */
  date: Scalars['String'];
  /** note about irrigation event */
  note: Maybe<Scalars['String']>;
  /** the source of the irrigation event (rainfall or irrigation) */
  source: IrrigationEventSource;
  /** If this event was automatically created, this is the origin */
  automatedOrigin: Maybe<IrrigationEventAutomatedOriginEnum>;
};

export type IrrigationEventAddInput = {
  /** id of field event took place in */
  fieldId: Scalars['String'];
  /** Amount of irrigation in inches */
  amount: Scalars['Float'];
  /** Date of irrigation event (YYYY-MM-DD) */
  date: Scalars['String'];
  /** note about irrigation event */
  note?: Maybe<Scalars['String']>;
  /**
   * the source of the irrigation event (rainfall or irrigation)
   * Default is irrigation
   */
  source?: Maybe<IrrigationEventSource>;
  /** If this is created automatically, include this */
  automatedOrigin?: Maybe<IrrigationEventAutomatedOriginEnum>;
};

export type IrrigationEventAddResponse = {
  __typename: 'IrrigationEventAddResponse';
  success: Scalars['Boolean'];
  /** The created irrigation event */
  irrigationEvent: IrrigationEvent;
};

export enum IrrigationEventAutomatedOriginEnum {
  DETECTED = 'DETECTED',
  WISECONN = 'WISECONN',
}

/** Deleted irrigation event */
export type IrrigationEventDeleteInput = {
  /** id of irrigation event to delete */
  id: Scalars['String'];
  /** id of field event took place in */
  fieldId: Scalars['String'];
};

export type IrrigationEventDeleteResponse = {
  __typename: 'IrrigationEventDeleteResponse';
  success: Scalars['Boolean'];
};

export enum IrrigationEventSource {
  IRRIGATION = 'IRRIGATION',
  RAINFALL = 'RAINFALL',
}

/** Updated irrigation event */
export type IrrigationEventUpdateInput = {
  /** id of irrigation event to update */
  id: Scalars['String'];
  /** id of field event took place in */
  fieldId: Scalars['String'];
  /** Updated amount of irrigation in inches */
  amount?: Maybe<Scalars['Float']>;
  /** Updated date of irrigation event (YYYY-MM-DD) */
  date?: Maybe<Scalars['String']>;
  /** Updates note */
  note?: Maybe<Scalars['String']>;
  /**
   * the source of the irrigation event (rainfall or irrigation)
   * Default is irrigation
   */
  source?: Maybe<IrrigationEventSource>;
};

export type IrrigationEventUpdateResponse = {
  __typename: 'IrrigationEventUpdateResponse';
  success: Scalars['Boolean'];
  /** The created irrigation event */
  irrigationEvent: IrrigationEvent;
};

export type IrrigationMetadata = {
  __typename: 'IrrigationMetadata';
  irrigationType: Maybe<Scalars['String']>;
  /** Source of irrigation water */
  waterSource: Maybe<Scalars['String']>;
  /** Source of irrigation power */
  powerSource: Maybe<Scalars['String']>;
  /** Vendor of the irrigation equipment */
  vendor: Maybe<Scalars['String']>;
  /** The size of the field */
  acreage: Maybe<Scalars['Float']>;
  /** Drip irrigation metadata */
  dripStats: DripStatsType;
};

export type IrrigationSystem = {
  __typename: 'IrrigationSystem';
  id: Scalars['String'];
  name: Scalars['String'];
  sensors: Array<TrellisPressureSensor>;
  users: Array<HardwareUser>;
};

export type IrrigationSystemAddInput = {
  name: Scalars['String'];
  sensorIds: Array<Scalars['String']>;
  sensorPlans: Array<PressureSensorPlanInput>;
};

export type IrrigationSystemDeleteResponse = {
  __typename: 'IrrigationSystemDeleteResponse';
  success: Scalars['Boolean'];
};

export type IrrigationSystemUpdateInput = {
  name: Scalars['String'];
  sensorIds: Array<Scalars['String']>;
  sensorPlans: Array<PressureSensorPlanInput>;
};

export type Location = {
  __typename: 'Location';
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

export type LocationInput = {
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

export type MailboxSlot = {
  __typename: 'MailboxSlot';
  toDevice: Maybe<Scalars['String']>;
  toServer: Maybe<Scalars['String']>;
};

export type ManagedServiceOptions = {
  __typename: 'ManagedServiceOptions';
  /** The monthly cost of order */
  price: Scalars['Float'];
  /** Number of months it is for */
  months: Scalars['Int'];
  /** Whether or not it is prepaid managed service or monthly */
  prepaid: Scalars['Boolean'];
  /** When the service started */
  startTime: Maybe<Scalars['Float']>;
};

export type ManagedServiceStatus = {
  __typename: 'ManagedServiceStatus';
  orderId: Scalars['Int'];
  invoiceUrl: Maybe<Scalars['String']>;
  /** If the first payment for the order has been made */
  initialPaymentMade: Scalars['Boolean'];
  prepaid: Scalars['Boolean'];
  /** Whether or not the subscription has been started */
  subscriptionStarted: Scalars['Boolean'];
  /** Unix ms start timestamp */
  subscriptionStartTime: Maybe<Scalars['Float']>;
  /** The last month that has been paid (1 is first) */
  lastPaidMonth: Maybe<Scalars['Int']>;
  /** True if all invoices for order have been paid */
  paidInFull: Scalars['Boolean'];
};

export type Model = {
  __typename: 'Model';
  antennaLength: Maybe<Scalars['Float']>;
  moistureDepths: Maybe<Array<Scalars['Float']>>;
  temperatureDepths: Maybe<Array<Scalars['Float']>>;
  ecDepths: Maybe<Array<Scalars['Float']>>;
  hasWeatherStation: Scalars['Boolean'];
  hasRainGauge: Scalars['Boolean'];
  hasPressureSensor: Scalars['Boolean'];
  moistureMethod: Maybe<MoistureMethod>;
  probeless: Scalars['Boolean'];
  custom: Scalars['Boolean'];
  type: HardwareTypeEnum;
  /**
   * For a standalone watermark senosr, the lead length in feet
   * For a watermark auger, the length of the auger in feet
   * For a sentek auger, the length of the auger in cm
   */
  length: Maybe<Scalars['Float']>;
  hasSpigot: Scalars['Boolean'];
  softwareVersion: Maybe<Scalars['String']>;
};

export type ModelInput = {
  antennaLength?: Maybe<Scalars['Float']>;
  moistureDepths?: Maybe<Array<Scalars['Float']>>;
  temperatureDepths?: Maybe<Array<Scalars['Float']>>;
  ecDepths?: Maybe<Array<Scalars['Float']>>;
  hasWeatherStation?: Maybe<Scalars['Boolean']>;
  hasRainGauge?: Maybe<Scalars['Boolean']>;
  hasPressureSensor?: Maybe<Scalars['Boolean']>;
  moistureMethod?: Maybe<MoistureMethod>;
  type: HardwareTypeEnum;
  probeless?: Maybe<Scalars['Boolean']>;
  custom?: Maybe<Scalars['Boolean']>;
  /**
   * For a standalone watermark senosr, the lead length in feet
   * For a watermark auger, the length of the auger in feet
   * For a sentek auger, the length of the auger in cm
   */
  length?: Maybe<Scalars['Float']>;
  hasSpigot?: Maybe<Scalars['Boolean']>;
  softwareVersion?: Maybe<Scalars['String']>;
};

export enum MoistureMethod {
  capacitance = 'capacitance',
  tension = 'tension',
}

export type MoistureThresholdPoint = {
  __typename: 'MoistureThresholdPoint';
  wet: Scalars['Float'];
  dry: Scalars['Float'];
  time: Scalars['Float'];
};

export type MonthlyInvoiceManagedServiceInput = {
  orderId: Scalars['Int'];
};

export type MonthlyInvoiceManagedServiceResponse = {
  __typename: 'MonthlyInvoiceManagedServiceResponse';
  success: Scalars['Boolean'];
};

/** Trellis mutations */
export type Mutation = {
  __typename: 'Mutation';
  /** Initiate install mode for a Gateway or Sensor */
  installMode: Maybe<InstallModeMutationResponse>;
  /** Register a new user */
  registerUser: Maybe<RegistrationMutationResponse>;
  /** Send user verification email */
  sendVerificationEmail: Maybe<RegistrationMutationResponse>;
  /** Send push notification */
  sendPushNotification: Maybe<PushNotificationMutationResponse>;
  /** Add a field */
  addField: Maybe<FieldMutationResponse>;
  /** Delete a field */
  deleteField: Maybe<FieldMutationResponse>;
  /** Update a field */
  updateField: Maybe<UpdateFieldMutationResponse>;
  /** Update sensor status */
  updateSensorStatus: Maybe<SensorMutationResponse>;
  /** Update a sensor */
  updateSensor: Maybe<SensorMutationResponse>;
  /** Update sensor alert */
  updateSensorAlert: Maybe<SensorMutationResponse>;
  /** Update sensor state */
  updateSensorState: Maybe<SensorMutationResponse>;
  /** Add a card to a user */
  activationAddCardToUser: Maybe<ActivationChangeMutationResponse>;
  /** Change a users name */
  activationChangeName: Maybe<ActivationChangeMutationResponse>;
  /** Change a users data access */
  activationChangeDataAccess: Maybe<ActivationChangeMutationResponse>;
  /** Change a users password */
  activationChangePassword: Maybe<ActivationChangeMutationResponse>;
  /** Finish user activation process */
  activationFinish: Maybe<ActivationChangeMutationResponse>;
  /** Finish shared user activation process */
  activationSharedFinish: Maybe<ActivationChangeMutationResponse>;
  /** Finish prepaid user activation process */
  activationPrepaidFinish: Maybe<ActivationChangeMutationResponse>;
  /** Resend activation email */
  activationResendEmail: Scalars['Boolean'];
  /** Redirect to stripe billing portal */
  stripeBillingPortal: StripeBillingPortalResponse;
  /** Update a user's data plan */
  updateDataPlan: Maybe<UpdateDataPlanResponse>;
  /** Change a user's card */
  changeCard: Maybe<ChangeCardResponse>;
  /** Change a user's preferences */
  updateUserPreferences: Maybe<UpdateUserPreferencesResponse>;
  /** Apply prepaid to a user */
  applyPrepaidToUser: Maybe<Scalars['Boolean']>;
  /** Cancel prepaid to a user */
  cancelPrepaidToUser: Maybe<Scalars['Boolean']>;
  /** Change a user's profile */
  updateUserProfile: Maybe<UpdateUserProfileResponse>;
  /** Add a login for a user */
  updateLoginTracking: Maybe<UpdateLoginResponse>;
  /** Change a user's alert history */
  updateAlertHistory: Maybe<UpdateAlertHistoryResponse>;
  /** Set a user's push token */
  userSetPushToken: Maybe<UserSetPushTokenResponse>;
  /** Update gateway hardware_status */
  updateGatewayStatus: Maybe<GatewayStatusMutationResponse>;
  /** Update gateway */
  updateGateway: Maybe<UpdateGatewayMutationResponse>;
  /** Add a pricing object */
  addPricing: Maybe<AddPricingResponse>;
  /** Add a distributor */
  addDistributor: Maybe<AddDistributorResponse>;
  /** Add a Rep */
  addRep: Maybe<AddRepResponse>;
  /** Delete a dealer */
  deleteDealer: DeleteDealerResponse;
  /** Add a shipping address for a Rep */
  addRepAddress: Maybe<AddRepAddressResponse>;
  /** Change a distributors password */
  changeDistributorPassword: Maybe<DistributorChangePasswordResponse>;
  /** Activate a customer's account */
  activateCustomer: Maybe<ActivateCustomerResponse>;
  /** Add a customer for the logged in user */
  addCustomer: Maybe<AddCustomerResponse>;
  /** Add an address to a customer for the logged in user */
  addCustomerAddress: Maybe<AddCustomerAddressResponse>;
  /** Add/remove from customers hardware */
  editCustomerHardware: Maybe<EditCustomerHardwareResponse>;
  /** Remove a customer */
  removeCustomer: Maybe<RemoveCustomerResponse>;
  /** Add an order */
  addOrder: Maybe<AddOrderResponse>;
  /** Approve an order */
  approveOrder: Maybe<ApproveOrderResponse>;
  /** Bind an order (Set the ids) */
  bindOrder: Maybe<BindOrderResponse>;
  /** Mark an order as shipped */
  shipOrder: Maybe<ShipOrderResponse>;
  /** Populate a sensor with fake data */
  fakeSensorData: Maybe<FakeSensorDataResponse>;
  /** Populate a sensor with fake location data */
  fakeLocationData: Maybe<FakeLocationDataResponse>;
  /** Populate a sensor with fake historical weather data */
  fakeHistoricalWeatherData: Maybe<FakeSensorDataResponse>;
  /** Add a soil for current user */
  addSoil: AddSoilResponse;
  /** Delete a soil for current user */
  deleteSoil: DeleteSoilResponse;
  /** Update a soil for current user */
  updateSoil: UpdateSoilResponse;
  /** Add a crop type for current user */
  cropAdd: CropAddResponse;
  /** Delete a crop type for current user */
  cropDelete: CropDeleteResponse;
  /** Update a crop type for current user */
  cropUpdate: CropUpdateResponse;
  /** Share or unshare a sensor */
  updateSensorSharing: Maybe<UpdateSensorSharingResponse>;
  /** Share or unshare a gateway */
  updateGatewaySharing: Maybe<UpdateGatewaySharingResponse>;
  /** Share a field and the hardware in it */
  fieldSharingUpdate: Maybe<FieldSharingUpdateResponse>;
  /** Add an irrigation event */
  irrigationEventAdd: Maybe<IrrigationEventAddResponse>;
  /** Update an irrigation event */
  irrigationEventUpdate: Maybe<IrrigationEventUpdateResponse>;
  /** Delete an irrigation event */
  irrigationEventDelete: Maybe<IrrigationEventDeleteResponse>;
  /** Create a service log */
  serviceLogCreate: Maybe<ServiceLogCreateReponse>;
  /** Update a service log */
  serviceLogUpdate: Maybe<ServiceLogUpdateReponse>;
  /** Delete a service log */
  serviceLogDelete: Maybe<ServiceLogDeleteReponse>;
  /** Add announcement about something new! */
  notificationAnnouncementAdd: Maybe<NotificationAnnouncementAdd>;
  /** Add announcement about new release! */
  notificationReleaseAdd: Maybe<NotificationReleaseAdd>;
  /** Add notification about a dry sensor */
  notificationDrySensorAdd: Maybe<NotificationDrySensorAdd>;
  /** Add notification about a sensor soil type being populated automatically */
  notificationSoilTypePopulateAdd: Maybe<NotificationSoilTypePopulateAdd>;
  /** Mark notifications as read */
  notificationMarkAsRead: Maybe<NotificationMarkAsRead>;
  /** Archive a sensor */
  archiveSensor: Maybe<ArchiveSensorResponse>;
  /** Archive a gateway */
  archiveGateway: Maybe<ArchiveGatewayResponse>;
  /** Archive a field */
  archiveField: Maybe<ArchiveFieldResponse>;
  /** Archive an irrigation system */
  archiveIrrigationSystem: ArchiveIrrigationSystemResponse;
  /** Sign distributor terms and conditions */
  distributorSignTC: Maybe<DistributorSignTC>;
  /** Claim hardware id (returns the id) */
  claimHardwareId: Scalars['String'];
  sensorManagementPlanAdd: SensorManagementPlanAddResponse;
  sensorManagementPlanUpdate: SensorManagementPlanResponse;
  sensorManagementPlanDelete: SensorManagementPlanResponse;
  weatherStationManagementPlanAdd: WeatherStationManagementPlanResponse;
  weatherStationManagementPlanUpdate: WeatherStationManagementPlanResponse;
  weatherStationManagementPlanDelete: WeatherStationManagementPlanResponse;
  postPerformance: PostPerformanceResponse;
  commandGatewayResetSend: GatewayResetResponse;
  commandGatewayFlashSend: GatewayFlashResponse;
  commandGatewaySimcomSend: GatewaySimcomResponse;
  commandGatewayReprogramSend: GatewayReprogramResponse;
  commandSensorResetSend: SensorResetResponse;
  commandSensorRetrySend: SensorRetryResponse;
  commandSensorEditSend: SensorEditResponse;
  commandSensorReprogramSend: SensorReprogramResponse;
  desktopDebuggerSendLogs: Scalars['Boolean'];
  weatherStationUpdate: WeatherStationUpdateResponse;
  pressureSensorUpdate: TrellisPressureSensor;
  irrigationSystemAdd: IrrigationSystem;
  irrigationSystemUpdate: IrrigationSystem;
  irrigationSystemDelete: IrrigationSystemDeleteResponse;
  distributorStripeRegister: DistributorStripeRegisterResponse;
  /**
   * Managed service orders do not begin when they are paid
   * Instead, users or dealers trigger the start
   */
  startManagedService: StartManagedServiceResponse;
  /**
   * Renew managed service.  Should be called by backend a week before the next
   * year starts
   */
  renewManagedService: RenewManagedServiceResponse;
  transferFundsManagedService: TransferFundsManagedServiceReponse;
  monthlyInvoiceManagedService: MonthlyInvoiceManagedServiceResponse;
  sendSupportMessage: SendSupportMessageResponse;
};

/** Trellis mutations */
export type MutationinstallModeArgs = {
  input: InstallModeInput;
};

/** Trellis mutations */
export type MutationregisterUserArgs = {
  input: RegisterUserInput;
};

/** Trellis mutations */
export type MutationsendVerificationEmailArgs = {
  input: SendVerificationEmailInput;
};

/** Trellis mutations */
export type MutationsendPushNotificationArgs = {
  input: SendPushNotificationInput;
};

/** Trellis mutations */
export type MutationaddFieldArgs = {
  input: AddFieldInput;
};

/** Trellis mutations */
export type MutationdeleteFieldArgs = {
  input: Maybe<DeleteFieldInput>;
};

/** Trellis mutations */
export type MutationupdateFieldArgs = {
  fieldId: Scalars['String'];
  input: UpdateFieldInput;
};

/** Trellis mutations */
export type MutationupdateSensorStatusArgs = {
  sensorId: Scalars['String'];
  input: UpdateSensorStatusInput;
};

/** Trellis mutations */
export type MutationupdateSensorArgs = {
  sensorId: Scalars['String'];
  input: UpdateSensorInput;
  admin: Maybe<Scalars['Boolean']>;
};

/** Trellis mutations */
export type MutationupdateSensorAlertArgs = {
  id: Scalars['String'];
  type: Scalars['String'];
};

/** Trellis mutations */
export type MutationupdateSensorStateArgs = {
  input: UpdateSensorStateInput;
};

/** Trellis mutations */
export type MutationactivationAddCardToUserArgs = {
  input: ActivationAddCardToUserInput;
};

/** Trellis mutations */
export type MutationactivationChangeNameArgs = {
  input: ActivationChangeNameInput;
};

/** Trellis mutations */
export type MutationactivationChangeDataAccessArgs = {
  input: ActivationChangeDataAccessInput;
};

/** Trellis mutations */
export type MutationactivationChangePasswordArgs = {
  input: ActivationChangePasswordInput;
};

/** Trellis mutations */
export type MutationactivationFinishArgs = {
  input: ActivationFinishInput;
};

/** Trellis mutations */
export type MutationactivationSharedFinishArgs = {
  input: ActivationSharedFinishInput;
};

/** Trellis mutations */
export type MutationactivationPrepaidFinishArgs = {
  input: ActivationPrepaidFinishInput;
};

/** Trellis mutations */
export type MutationactivationResendEmailArgs = {
  customerId: Scalars['String'];
};

/** Trellis mutations */
export type MutationstripeBillingPortalArgs = {
  userId: Scalars['String'];
};

/** Trellis mutations */
export type MutationupdateDataPlanArgs = {
  input: UpdateDataPlanInput;
};

/** Trellis mutations */
export type MutationchangeCardArgs = {
  input: ChangeCardInput;
};

/** Trellis mutations */
export type MutationupdateUserPreferencesArgs = {
  input: UpdateUserPreferencesInput;
};

/** Trellis mutations */
export type MutationapplyPrepaidToUserArgs = {
  input: ApplyPrepaidToUserInput;
};

/** Trellis mutations */
export type MutationcancelPrepaidToUserArgs = {
  input: CancelPrepaidToUserInput;
};

/** Trellis mutations */
export type MutationupdateUserProfileArgs = {
  input: UpdateUserProfileInput;
};

/** Trellis mutations */
export type MutationupdateLoginTrackingArgs = {
  input: UpdateLoginInput;
};

/** Trellis mutations */
export type MutationupdateAlertHistoryArgs = {
  input: UpdateAlertHistoryInput;
};

/** Trellis mutations */
export type MutationuserSetPushTokenArgs = {
  input: UserSetPushTokenInput;
};

/** Trellis mutations */
export type MutationupdateGatewayStatusArgs = {
  input: UpdateGatewayStatusInput;
};

/** Trellis mutations */
export type MutationupdateGatewayArgs = {
  input: UpdateGatewayInput;
  admin: Maybe<Scalars['Boolean']>;
};

/** Trellis mutations */
export type MutationaddPricingArgs = {
  input: AddPricingInput;
};

/** Trellis mutations */
export type MutationaddDistributorArgs = {
  input: AddDistributorInput;
};

/** Trellis mutations */
export type MutationaddRepArgs = {
  input: AddRepInput;
};

/** Trellis mutations */
export type MutationdeleteDealerArgs = {
  input: DeleteDealerInput;
};

/** Trellis mutations */
export type MutationaddRepAddressArgs = {
  input: AddRepAddressInput;
};

/** Trellis mutations */
export type MutationchangeDistributorPasswordArgs = {
  input: DistributorChangePasswordInput;
};

/** Trellis mutations */
export type MutationactivateCustomerArgs = {
  input: ActivateCustomerInput;
};

/** Trellis mutations */
export type MutationaddCustomerArgs = {
  input: AddCustomerInput;
};

/** Trellis mutations */
export type MutationaddCustomerAddressArgs = {
  input: AddCustomerAddressInput;
};

/** Trellis mutations */
export type MutationeditCustomerHardwareArgs = {
  input: EditCustomerHardwareInputType;
};

/** Trellis mutations */
export type MutationremoveCustomerArgs = {
  input: RemoveCustomerInput;
};

/** Trellis mutations */
export type MutationaddOrderArgs = {
  input: AddOrderInput;
};

/** Trellis mutations */
export type MutationapproveOrderArgs = {
  input: ApproveOrderInput;
};

/** Trellis mutations */
export type MutationbindOrderArgs = {
  input: BindOrderInput;
};

/** Trellis mutations */
export type MutationshipOrderArgs = {
  input: ShipOrderInput;
};

/** Trellis mutations */
export type MutationfakeSensorDataArgs = {
  input: FakeSensorDataInput;
};

/** Trellis mutations */
export type MutationfakeLocationDataArgs = {
  input: FakeLocationDataInput;
};

/** Trellis mutations */
export type MutationfakeHistoricalWeatherDataArgs = {
  input: FakeHistoricalWeatherDataInput;
};

/** Trellis mutations */
export type MutationaddSoilArgs = {
  input: AddSoilInput;
};

/** Trellis mutations */
export type MutationdeleteSoilArgs = {
  input: DeleteSoilInput;
};

/** Trellis mutations */
export type MutationupdateSoilArgs = {
  input: UpdateSoilInput;
};

/** Trellis mutations */
export type MutationcropAddArgs = {
  input: CropAddInput;
};

/** Trellis mutations */
export type MutationcropDeleteArgs = {
  input: CropDeleteInput;
};

/** Trellis mutations */
export type MutationcropUpdateArgs = {
  input: CropUpdateInput;
};

/** Trellis mutations */
export type MutationupdateSensorSharingArgs = {
  input: UpdateSensorSharingInput;
};

/** Trellis mutations */
export type MutationupdateGatewaySharingArgs = {
  input: UpdateGatewaySharingInput;
};

/** Trellis mutations */
export type MutationfieldSharingUpdateArgs = {
  input: FieldSharingUpdateInput;
};

/** Trellis mutations */
export type MutationirrigationEventAddArgs = {
  input: IrrigationEventAddInput;
  admin: Maybe<Scalars['Boolean']>;
};

/** Trellis mutations */
export type MutationirrigationEventUpdateArgs = {
  input: IrrigationEventUpdateInput;
};

/** Trellis mutations */
export type MutationirrigationEventDeleteArgs = {
  input: IrrigationEventDeleteInput;
};

/** Trellis mutations */
export type MutationserviceLogCreateArgs = {
  input: ServiceLogCreateInput;
};

/** Trellis mutations */
export type MutationserviceLogUpdateArgs = {
  input: ServiceLogUpdateInput;
};

/** Trellis mutations */
export type MutationserviceLogDeleteArgs = {
  input: ServiceLogDeleteInput;
};

/** Trellis mutations */
export type MutationnotificationAnnouncementAddArgs = {
  input: Maybe<NotificationAnnouncementInput>;
};

/** Trellis mutations */
export type MutationnotificationReleaseAddArgs = {
  input: Maybe<NotificationReleaseInput>;
};

/** Trellis mutations */
export type MutationnotificationDrySensorAddArgs = {
  input: Maybe<NotificationDrySensorInput>;
};

/** Trellis mutations */
export type MutationnotificationSoilTypePopulateAddArgs = {
  input: Maybe<NotificationSoilTypePopulateInput>;
};

/** Trellis mutations */
export type MutationnotificationMarkAsReadArgs = {
  input: Maybe<NotificationMarkAsReadInput>;
};

/** Trellis mutations */
export type MutationarchiveSensorArgs = {
  input: ArchiveSensorInput;
};

/** Trellis mutations */
export type MutationarchiveGatewayArgs = {
  input: ArchiveGatewayInput;
};

/** Trellis mutations */
export type MutationarchiveFieldArgs = {
  input: ArchiveFieldInput;
};

/** Trellis mutations */
export type MutationarchiveIrrigationSystemArgs = {
  input: ArchiveIrrigationSystemInput;
};

/** Trellis mutations */
export type MutationdistributorSignTCArgs = {
  input: DistributorSignTCInput;
};

/** Trellis mutations */
export type MutationclaimHardwareIdArgs = {
  type: ClaimHardwareIdType;
};

/** Trellis mutations */
export type MutationsensorManagementPlanAddArgs = {
  sensorId: Scalars['String'];
  input: SensorManagementPlanInput;
};

/** Trellis mutations */
export type MutationsensorManagementPlanUpdateArgs = {
  planId: Scalars['String'];
  input: SensorManagementPlanInput;
};

/** Trellis mutations */
export type MutationsensorManagementPlanDeleteArgs = {
  planId: Scalars['String'];
};

/** Trellis mutations */
export type MutationweatherStationManagementPlanAddArgs = {
  input: WeatherStationManagementPlanInput;
};

/** Trellis mutations */
export type MutationweatherStationManagementPlanUpdateArgs = {
  planId: Scalars['String'];
  input: WeatherStationManagementPlanInput;
};

/** Trellis mutations */
export type MutationweatherStationManagementPlanDeleteArgs = {
  planId: Scalars['String'];
};

/** Trellis mutations */
export type MutationpostPerformanceArgs = {
  input: PerformanceStatisticsInput;
};

/** Trellis mutations */
export type MutationcommandGatewayResetSendArgs = {
  input: GatewayResetInput;
};

/** Trellis mutations */
export type MutationcommandGatewayFlashSendArgs = {
  input: GatewayFlashInput;
};

/** Trellis mutations */
export type MutationcommandGatewaySimcomSendArgs = {
  input: GatewaySimcomInput;
};

/** Trellis mutations */
export type MutationcommandGatewayReprogramSendArgs = {
  input: GatewayReprogramInput;
};

/** Trellis mutations */
export type MutationcommandSensorResetSendArgs = {
  input: SensorResetInput;
};

/** Trellis mutations */
export type MutationcommandSensorRetrySendArgs = {
  input: SensorRetryInput;
};

/** Trellis mutations */
export type MutationcommandSensorEditSendArgs = {
  input: SensorEditInput;
};

/** Trellis mutations */
export type MutationcommandSensorReprogramSendArgs = {
  input: SensorReprogramInput;
};

/** Trellis mutations */
export type MutationdesktopDebuggerSendLogsArgs = {
  data: Scalars['String'];
};

/** Trellis mutations */
export type MutationweatherStationUpdateArgs = {
  id: Scalars['String'];
  input: WeatherStationUpdateInput;
};

/** Trellis mutations */
export type MutationpressureSensorUpdateArgs = {
  id: Scalars['String'];
  input: PressureSensorUpdateInput;
};

/** Trellis mutations */
export type MutationirrigationSystemAddArgs = {
  input: IrrigationSystemAddInput;
};

/** Trellis mutations */
export type MutationirrigationSystemUpdateArgs = {
  id: Scalars['String'];
  input: IrrigationSystemUpdateInput;
};

/** Trellis mutations */
export type MutationirrigationSystemDeleteArgs = {
  id: Scalars['String'];
};

/** Trellis mutations */
export type MutationdistributorStripeRegisterArgs = {
  input: DistributorStripeRegisterInput;
};

/** Trellis mutations */
export type MutationstartManagedServiceArgs = {
  input: StartManagedServiceInput;
};

/** Trellis mutations */
export type MutationrenewManagedServiceArgs = {
  input: RenewManagedServiceInput;
};

/** Trellis mutations */
export type MutationtransferFundsManagedServiceArgs = {
  input: TransferFundsManagedServiceInput;
};

/** Trellis mutations */
export type MutationmonthlyInvoiceManagedServiceArgs = {
  input: MonthlyInvoiceManagedServiceInput;
};

/** Trellis mutations */
export type MutationsendSupportMessageArgs = {
  body: Scalars['String'];
};

export type Name = {
  __typename: 'Name';
  first: Maybe<Scalars['String']>;
  last: Maybe<Scalars['String']>;
  /** Combined first and last name */
  full: Maybe<Scalars['String']>;
};

export type NameInput = {
  first: Scalars['String'];
  last: Scalars['String'];
};

export type NNLocationInput = {
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

/** Notifications that show up on dashboard */
export type Notification = {
  id: Scalars['String'];
  read: Scalars['Boolean'];
  timestamp: Maybe<Scalars['Float']>;
};

/** Dashboard Announcement */
export type NotificationAnnouncement = Notification & {
  __typename: 'NotificationAnnouncement';
  id: Scalars['String'];
  read: Scalars['Boolean'];
  timestamp: Maybe<Scalars['Float']>;
  primary: Scalars['String'];
  secondary: Scalars['String'];
  href: Maybe<Scalars['String']>;
  /** 7/9 graphqlgen crashes if it's named popup or popupId :) */
  popupToShow: Maybe<PopupId>;
};

export type NotificationAnnouncementAdd = {
  __typename: 'NotificationAnnouncementAdd';
  success: Scalars['Boolean'];
};

export type NotificationAnnouncementInput = {
  userId: Scalars['String'];
  primary: Scalars['String'];
  secondary: Scalars['String'];
  href?: Maybe<Scalars['String']>;
  /** 7/9 graphqlgen crashes if it's named popup or popupId :) */
  popupToShow?: Maybe<PopupId>;
};

/** Dry sensor notification */
export type NotificationDrySensor = Notification & {
  __typename: 'NotificationDrySensor';
  id: Scalars['String'];
  read: Scalars['Boolean'];
  timestamp: Maybe<Scalars['Float']>;
  sensorId: Scalars['String'];
};

export type NotificationDrySensorAdd = {
  __typename: 'NotificationDrySensorAdd';
  success: Scalars['Boolean'];
};

export type NotificationDrySensorInput = {
  userId: Scalars['String'];
  timestamp: Scalars['Float'];
  sensorId: Scalars['String'];
};

export type NotificationMarkAsRead = {
  __typename: 'NotificationMarkAsRead';
  success: Scalars['Boolean'];
  notifications: Array<Notification>;
};

export type NotificationMarkAsReadInput = {
  notificationIds: Array<Scalars['String']>;
};

/** Dashboard Release */
export type NotificationRelease = Notification & {
  __typename: 'NotificationRelease';
  id: Scalars['String'];
  read: Scalars['Boolean'];
  timestamp: Maybe<Scalars['Float']>;
  primary: Scalars['String'];
  secondary: Scalars['String'];
  /** Blog post link */
  href: Scalars['String'];
  header: Scalars['String'];
  body: Scalars['String'];
  img: Scalars['String'];
  minVersion: Scalars['String'];
};

export type NotificationReleaseAdd = {
  __typename: 'NotificationReleaseAdd';
  success: Scalars['Boolean'];
};

export type NotificationReleaseInput = {
  userId: Scalars['String'];
  primary: Scalars['String'];
  secondary: Scalars['String'];
  /** Blog post link */
  href: Scalars['String'];
  header: Scalars['String'];
  body: Scalars['String'];
  img: Scalars['String'];
  minVersion: Scalars['String'];
};

/** Soil Type Populated Notification */
export type NotificationSoilTypePopulate = Notification & {
  __typename: 'NotificationSoilTypePopulate';
  id: Scalars['String'];
  read: Scalars['Boolean'];
  timestamp: Maybe<Scalars['Float']>;
  sensorId: Scalars['String'];
  sensorName: Scalars['String'];
  /** The name of the auto populated soil type */
  soilType: Scalars['String'];
};

export type NotificationSoilTypePopulateAdd = {
  __typename: 'NotificationSoilTypePopulateAdd';
  success: Scalars['Boolean'];
};

export type NotificationSoilTypePopulateInput = {
  userId: Scalars['String'];
  timestamp: Scalars['Float'];
  sensorId: Scalars['String'];
  soilType: Scalars['String'];
  sensorName: Scalars['String'];
};

/** An order request */
export type Order = {
  __typename: 'Order';
  approved: Scalars['Boolean'];
  details: OrderDetails;
  id: Scalars['Int'];
  notes: Maybe<Scalars['String']>;
  placedTimestamp: Scalars['Float'];
  prepaid: Maybe<OrderPrepaid>;
  boundIds: Array<Scalars['String']>;
  sharingEmails: Array<Scalars['String']>;
  status: OrderStatus;
  totalPrice: Scalars['Float'];
  unboundHardware: Array<OrderUnboundHardware>;
  zohoSalesOrder: Maybe<ZohoSalesOrder>;
  managedServiceOptions: Maybe<ManagedServiceOptions>;
  shipments: Array<OrderShipment>;
  unshippedHardware: Array<OrderDetailsHardware>;
};

export type OrderDetails = {
  __typename: 'OrderDetails';
  address: Maybe<Address>;
  hardware: Array<OrderDetailsHardware>;
  customer: Maybe<OrderDetailsCustomerCopy>;
  rep: Maybe<OrderDetailsRepCopy>;
};

export type OrderDetailsCustomerCopy = {
  __typename: 'OrderDetailsCustomerCopy';
  id: Scalars['String'];
  email: Maybe<Scalars['String']>;
  name: Maybe<Name>;
  phone: Maybe<Scalars['String']>;
};

export type OrderDetailsHardware = {
  __typename: 'OrderDetailsHardware';
  id: Maybe<Scalars['String']>;
  model: Model;
  pricePerUnit: Scalars['Float'];
  type: HardwareTypeEnum;
  quantity: Scalars['Int'];
  boundIds: Array<Scalars['String']>;
};

export type OrderDetailsRepCopy = {
  __typename: 'OrderDetailsRepCopy';
  id: Scalars['String'];
  email: Maybe<Scalars['String']>;
  name: Maybe<Name>;
  phone: Maybe<Scalars['String']>;
};

export type OrderPrepaid = {
  __typename: 'OrderPrepaid';
  plan: SensorDataPlan;
  months: Scalars['Int'];
  start: Scalars['Float'];
};

export type OrderShipment = {
  __typename: 'OrderShipment';
  id: Scalars['Int'];
  amount: Scalars['Float'];
  carrier: Scalars['String'];
  trackingNumber: Maybe<Scalars['String']>;
  deliveredDate: Maybe<Scalars['String']>;
  hardware: Array<OrderDetailsHardware>;
  boundIds: Array<Scalars['String']>;
};

export enum OrderStatus {
  pending = 'pending',
  processing = 'processing',
  shipped = 'shipped',
  fulfilled = 'fulfilled',
  rejected = 'rejected',
}

export type OrderUnboundHardware = {
  __typename: 'OrderUnboundHardware';
  id: Scalars['String'];
  model: Model;
};

export type PageInfo = {
  __typename: 'PageInfo';
  endCursor: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor: Maybe<Scalars['String']>;
};

export type PerformanceStatistics = {
  __typename: 'PerformanceStatistics';
  percentOnline: Scalars['Float'];
  percentWarning: Scalars['Float'];
  percentOffline: Scalars['Float'];
  time: Scalars['Float'];
};

export type PerformanceStatisticsInput = {
  percentOnline: Scalars['Float'];
  percentWarning: Scalars['Float'];
  percentOffline: Scalars['Float'];
  time: Scalars['Float'];
};

export enum PermissionLevel {
  owner = 'owner',
  admin = 'admin',
  viewer = 'viewer',
}

export enum PermissionLevelInput {
  admin = 'admin',
  viewer = 'viewer',
}

export type PlanInformation = {
  __typename: 'PlanInformation';
  active: Maybe<Scalars['Boolean']>;
  email_update: Maybe<Scalars['Boolean']>;
  export_data: Maybe<Scalars['Boolean']>;
  text_update: Maybe<Scalars['Boolean']>;
};

/** Corresponds to popup modals on the client */
export enum PopupId {
  SensorManagementPlanRelease = 'SensorManagementPlanRelease',
  IrrigationRecommendationRelease = 'IrrigationRecommendationRelease',
  ChatSupportRelease = 'ChatSupportRelease',
}

export type PostPerformanceResponse = {
  __typename: 'PostPerformanceResponse';
  success: Scalars['Boolean'];
};

export type PressureNoSchedule = {
  __typename: 'PressureNoSchedule';
  /** This type doesn't need any extra information */
  void: Scalars['Boolean'];
};

export type PressureOffSchedule = {
  __typename: 'PressureOffSchedule';
  /** Whether or not the sensor is on schedule right now */
  currentlyOnSchedule: Scalars['Boolean'];
  /** List of times in the past 24 hours where the sensor was off schedule */
  offScheduleTimes: Array<PressureOffScheduleTime>;
};

export type PressureOffScheduleTime = {
  __typename: 'PressureOffScheduleTime';
  /** Time of issue in hh:mm a */
  time: Scalars['String'];
  /** Whether or not we expected the sensor to be on or off */
  shouldBeOn: Scalars['Boolean'];
  /** Explanation about off schedule */
  type: PressureOffScheduleType;
};

export enum PressureOffScheduleType {
  unexpected = 'unexpected',
  missed = 'missed',
  lowPressure = 'lowPressure',
  highPressure = 'highPressure',
}

export type PressureOnSchedule = {
  __typename: 'PressureOnSchedule';
  /** This type doesn't need any extra information */
  void: Scalars['Boolean'];
};

export type PressureScheduleResult =
  | PressureOnSchedule
  | PressureOffSchedule
  | PressureNoSchedule;

export type PressureSensorData = {
  __typename: 'PressureSensorData';
  currentPressure: CurrentPressureResult;
  onOffTimes: Array<SensorPressureOnOffData>;
  pressure: Array<SensorPressureData>;
};

export type PressureSensorDatapressureArgs = {
  start: Maybe<Scalars['Float']>;
  end: Maybe<Scalars['Float']>;
};

export type PressureSensorDataResult = PressureSensorData | SensorNoPlan;

export type PressureSensorPlan = {
  __typename: 'PressureSensorPlan';
  timeZone: Scalars['String'];
  expectedPressure: Maybe<Scalars['Float']>;
  stages: Array<PressureSensorPlanStage>;
};

export type PressureSensorPlanInput = {
  sensorId: Scalars['String'];
  /** Time zone string e.g. America/New_York */
  timeZone: Scalars['String'];
  expectedPressure?: Maybe<Scalars['Float']>;
  stages: Array<PressureSensorPlanStageInput>;
};

export type PressureSensorPlanStage = {
  __typename: 'PressureSensorPlanStage';
  /** YYYY-MM-DD */
  startDate: Scalars['String'];
  onTimes: Array<PressureSensorPlanStageTime>;
};

export type PressureSensorPlanStageInput = {
  /** YYYY-MM-DD */
  startDate: Scalars['String'];
  onTimes: Array<PressureSensorPlanStageTimeInput>;
};

export type PressureSensorPlanStageTime = {
  __typename: 'PressureSensorPlanStageTime';
  /** Start time in minutes (0 is midnight) */
  start: Scalars['Float'];
  /** End time in minutes (0 is midnight) */
  end: Scalars['Float'];
};

export type PressureSensorPlanStageTimeInput = {
  /** Start time in minutes (0 is midnight) */
  start: Scalars['Float'];
  /** End time in minutes (0 is midnight) */
  end: Scalars['Float'];
};

export type PressureSensorUpdateInput = {
  name?: Maybe<Scalars['String']>;
  location?: Maybe<LocationInput>;
};

export type Pricing = {
  __typename: 'Pricing';
  id: Scalars['String'];
  name: Maybe<Scalars['String']>;
  hardware: Array<PricingHardware>;
};

export type PricingHardware = {
  __typename: 'PricingHardware';
  modelId: Scalars['String'];
  prices: Array<PricingHardwarePrices>;
};

export type PricingHardwarePrices = {
  __typename: 'PricingHardwarePrices';
  /** If someone buys >= this amount, they get the discounted price */
  quantity: Scalars['Int'];
  price: Scalars['Float'];
};

export type PushNotificationMutationResponse = {
  __typename: 'PushNotificationMutationResponse';
  success: Scalars['Boolean'];
};

/** Trellis queries */
export type Query = {
  __typename: 'Query';
  /** Get info of a field */
  field: Maybe<Field>;
  /** Check a users activation progress */
  activationProgress: ActivationProgressResponse;
  /** Get info of a sensor */
  sensor: Maybe<Sensor>;
  weatherStation: TrellisWeatherSensor;
  pressureSensor: TrellisPressureSensor;
  irrigationSystem: IrrigationSystem;
  /** Get all sensors */
  allSensors: Array<Sensor>;
  /** Get all weather stations */
  allWeatherStations: Array<TrellisWeatherSensor>;
  /** Get all irrigation systems */
  irrigationSystems: Array<IrrigationSystem>;
  /** Get info of a gateway */
  gateway: Maybe<Gateway>;
  /** Get all gateways */
  allGateways: Array<Gateway>;
  /** Get the info of the logged in user. */
  me: User;
  /** Get a user by id */
  user: Maybe<User>;
  /** Get a distributor by id */
  distributor: Maybe<Distributor>;
  /** Get all dashboard users. */
  allUsers: Array<User>;
  /** Get all fields */
  allFields: Array<Field>;
  /** Get all crop types */
  crops: Array<Crop>;
  /** Get all soil types (Trellis defaults + User defined) */
  soils: Array<Soil>;
  /** Get an single soil type */
  soil: Maybe<Soil>;
  /** Get all irrigation types */
  irrigationTypes: Array<Irrigation>;
  /** Get distributor portal for the logged in user. */
  currentDistributor: Distributor;
  /** Get all distributors */
  allDistributors: Array<Distributor>;
  /** Get a rep of currentDistributor */
  rep: Maybe<Distributor>;
  /** Get an order */
  order: Maybe<Order>;
  /** Get all Open Orders */
  openOrders: Array<Order>;
  /** Get all Orders */
  allOrders: Array<Order>;
  /** Get a customer */
  customer: Maybe<Customer>;
  /** Get the list of pricings */
  pricings: Array<Pricing>;
  /** Get the registration url for a user/distributor */
  registrationUrl: Maybe<Scalars['String']>;
  /** Get an archived sensor */
  archivedSensor: Maybe<ArchivedSensor>;
  /** Get an archived gateway */
  archivedGateway: Maybe<ArchivedGateway>;
  /** Get info of an archived field */
  archivedField: Maybe<ArchivedField>;
  /** Get info of an archived irrigation system */
  archivedIrrigationSystem: ArchivedIrrigationSystem;
  archivedPressureSensor: ArchivedTrellisPressureSensor;
  /** Get all archived sensors */
  archivedSensors: Array<ArchivedSensor>;
  /** Sales Rep information */
  salesRep: Maybe<SalesRep>;
  /** All Sales Reps */
  salesReps: Array<SalesRep>;
  /** Get soil type from lat/lng (using Web Soil Survey) */
  soilTypeFromLocation: Maybe<Scalars['String']>;
  /** Daily performance statistics for all of the hardware */
  performanceStatistics: Array<PerformanceStatistics>;
  sensorManagementPlan: Maybe<SensorManagementPlan>;
  gatewaySoftwareVersions: Array<SoftwareVersion>;
  gatewayFlashLibrary: Array<FlashLibraryEntry>;
  gatewaySimcomLibrary: Array<Scalars['String']>;
  sensorSoftwareVersions: Array<SoftwareVersion>;
  sensorFlashLibrary: Array<FlashLibraryEntry>;
  averageUsageStats: Maybe<UsageStats>;
  /** Get soil type from lat/lng (using Web Soil Survey) */
  fieldBoundaryInfo: Maybe<FieldBoundaryInfoResult>;
  salesRepForCustomer: Maybe<SalesRep>;
  supportChatLog: SupportChatLog;
};

/** Trellis queries */
export type QueryfieldArgs = {
  id: Scalars['String'];
  admin: Maybe<Scalars['Boolean']>;
};

/** Trellis queries */
export type QueryactivationProgressArgs = {
  input: ActivationProgressInput;
};

/** Trellis queries */
export type QuerysensorArgs = {
  id: Scalars['String'];
  admin: Maybe<Scalars['Boolean']>;
};

/** Trellis queries */
export type QueryweatherStationArgs = {
  id: Scalars['String'];
  admin: Maybe<Scalars['Boolean']>;
};

/** Trellis queries */
export type QuerypressureSensorArgs = {
  id: Scalars['String'];
  admin: Maybe<Scalars['Boolean']>;
};

/** Trellis queries */
export type QueryirrigationSystemArgs = {
  id: Scalars['String'];
};

/** Trellis queries */
export type QueryallSensorsArgs = {
  onlyRG: Maybe<Scalars['Boolean']>;
};

/** Trellis queries */
export type QuerygatewayArgs = {
  id: Maybe<Scalars['String']>;
  admin: Maybe<Scalars['Boolean']>;
};

/** Trellis queries */
export type QueryuserArgs = {
  id: Scalars['String'];
};

/** Trellis queries */
export type QuerydistributorArgs = {
  id: Scalars['String'];
};

/** Trellis queries */
export type QuerysoilArgs = {
  id: Scalars['String'];
};

/** Trellis queries */
export type QueryrepArgs = {
  repId: Scalars['String'];
};

/** Trellis queries */
export type QueryorderArgs = {
  orderId: Scalars['Int'];
};

/** Trellis queries */
export type QuerycustomerArgs = {
  customerId: Maybe<Scalars['String']>;
  includeZoho: Maybe<Scalars['Boolean']>;
};

/** Trellis queries */
export type QueryregistrationUrlArgs = {
  distributor: Maybe<Scalars['Boolean']>;
  email: Scalars['String'];
};

/** Trellis queries */
export type QueryarchivedSensorArgs = {
  id: Scalars['String'];
  admin: Maybe<Scalars['Boolean']>;
};

/** Trellis queries */
export type QueryarchivedGatewayArgs = {
  id: Scalars['String'];
  admin: Maybe<Scalars['Boolean']>;
};

/** Trellis queries */
export type QueryarchivedFieldArgs = {
  id: Scalars['String'];
};

/** Trellis queries */
export type QueryarchivedIrrigationSystemArgs = {
  id: Scalars['String'];
  admin: Maybe<Scalars['Boolean']>;
};

/** Trellis queries */
export type QueryarchivedPressureSensorArgs = {
  id: Scalars['String'];
  admin: Maybe<Scalars['Boolean']>;
};

/** Trellis queries */
export type QueryarchivedSensorsArgs = {
  onlyRG: Maybe<Scalars['Boolean']>;
};

/** Trellis queries */
export type QuerysalesRepArgs = {
  id: Scalars['String'];
};

/** Trellis queries */
export type QuerysoilTypeFromLocationArgs = {
  location: NNLocationInput;
};

/** Trellis queries */
export type QuerysensorManagementPlanArgs = {
  id: Scalars['String'];
};

/** Trellis queries */
export type QueryfieldBoundaryInfoArgs = {
  input: FieldBoundaryInfoInput;
};

/** Trellis queries */
export type QuerysalesRepForCustomerArgs = {
  email: Scalars['String'];
};

export type RegisterUserInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber: Scalars['String'];
  userId: Scalars['String'];
  token: Scalars['String'];
  password: Scalars['String'];
  textEnabled: Scalars['Boolean'];
  emailEnabled: Scalars['Boolean'];
  termsCheckbox: Scalars['Boolean'];
};

export type RegistrationMutationResponse = {
  __typename: 'RegistrationMutationResponse';
  success: Scalars['Boolean'];
  userId: Scalars['String'];
};

export type RemoveCustomerInput = {
  /** ID of customer to remove */
  customerId: Scalars['String'];
};

export type RemoveCustomerResponse = {
  __typename: 'RemoveCustomerResponse';
  /** The id of the removed customer */
  id: Maybe<Scalars['String']>;
};

export type RenewManagedServiceInput = {
  orderId: Scalars['Int'];
};

export type RenewManagedServiceResponse = {
  __typename: 'RenewManagedServiceResponse';
  success: Scalars['Boolean'];
};

export type SalesRep = {
  __typename: 'SalesRep';
  id: Scalars['String'];
  email: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  commissionStructure: Array<CommissionStructure>;
  orders: Array<SalesRepOrder>;
  /** Calculate commission for a sales rep over a period */
  periodCommission: Maybe<Scalars['Float']>;
};

export type SalesRepperiodCommissionArgs = {
  periodStart: Maybe<Scalars['Float']>;
  periodEnd: Maybe<Scalars['Float']>;
};

export type SalesRepOrder = {
  __typename: 'SalesRepOrder';
  amount: Scalars['Float'];
  cost: Scalars['Float'];
  time: Scalars['Float'];
  source: Scalars['String'];
  id: Scalars['String'];
};

export type SendPushNotificationInput = {
  customerId: Scalars['String'];
  title: Scalars['String'];
  body: Scalars['String'];
  data?: Maybe<Scalars['String']>;
};

export type SendSupportMessageResponse = {
  __typename: 'SendSupportMessageResponse';
  success: Scalars['Boolean'];
  supportChatLog: SupportChatLog;
};

export type SendVerificationEmailInput = {
  email: Scalars['String'];
  isDistPortal?: Maybe<Scalars['Boolean']>;
};

export type Sensor = Hardware &
  SensorBase & {
    __typename: 'Sensor';
    /**
     * Whether or not the sensor is active.  Controls alerts, visibility on
     * dashboard, etc... Set true when the sensor's data is paid for, and false when
     * the sensor is archived.
     */
    active: Scalars['Boolean'];
    alerts: SensorAlerts;
    battery: Maybe<Scalars['Float']>;
    canDownloadData: Scalars['Boolean'];
    configuration: Maybe<SensorConfiguration>;
    configurationUpdated: Maybe<Scalars['Float']>;
    crop_type: Maybe<Crop>;
    crop_variety: Maybe<Scalars['String']>;
    current_state: Maybe<Scalars['Int']>;
    data: Maybe<Array<SensorData>>;
    dataView: Maybe<SensorDataView>;
    current_tension: Maybe<Scalars['Int']>;
    errors: Array<SensorData>;
    field: Maybe<Field>;
    gateway: Maybe<Gateway>;
    gatewayId: Maybe<Scalars['String']>;
    /** Format: YYYY-MM-DD */
    harvest_date: Maybe<Scalars['String']>;
    hasDataAccess: Scalars['Boolean'];
    userHasSensorAccess: Scalars['Boolean'];
    id: Scalars['String'];
    /** Used to prevent hw from showing up on the dist portal */
    inactive: Scalars['Boolean'];
    location: Maybe<Location>;
    gpsUpdated: Maybe<Scalars['Float']>;
    gpsLocked: Maybe<Scalars['Boolean']>;
    managementPlan: Maybe<SensorManagementPlan>;
    /**
     * A list of the users sensor management plans that have
     * compatible models
     */
    compatibleManagementPlans: Array<SensorManagementPlan>;
    /** Is the sensor online or offline? */
    connectionStatus: ConnectionStatus;
    /**
     * If the sensor has a management plan, this will be
     * the current stage
     */
    currentStage: Maybe<SensorManagementPlanStage>;
    model: Maybe<Model>;
    moistureMethod: Maybe<MoistureMethod>;
    name: Scalars['String'];
    order: Maybe<Order>;
    owner: Maybe<HardwareUser>;
    isOwner: Scalars['Boolean'];
    permissionLevel: Maybe<PermissionLevel>;
    persistence: Maybe<Scalars['Float']>;
    /**
     * If the stripeSubActiveUntil is in the future, this will be it
     * This will be set regardless of if the sensor is cancelled or not
     */
    planActiveUntil: Maybe<Scalars['Float']>;
    /**
     * If stripeSubCancelledAt is in the future, this will be it
     * This should be set only if the sensor is scheduled to be cancelled at the end of the cycle
     */
    planCancelDate: Maybe<Scalars['Float']>;
    /** The currently selected plan for the sensor OR null if the sensor does not have an active subscription */
    activePlan: Maybe<SensorDataPlan>;
    /** The currently selected plan for the sensor */
    plan: Maybe<SensorDataPlan>;
    /** Stripe plan ID. Use "plan" for frontend stuff. */
    planId: Maybe<StripeDataPlan>;
    /** Whether or not this piece of hardware needs a plan to access data */
    planRequired: Scalars['Boolean'];
    plant_date: Maybe<Scalars['String']>;
    rssi: Maybe<Scalars['Float']>;
    sensor_time: Maybe<Scalars['Float']>;
    serviceLogs: Array<ServiceLog>;
    sharingMaxUsers: Scalars['Int'];
    soil_type: Maybe<Soil>;
    /** List of warnings for sensor */
    statusWarnings: Array<HardwareWarningEnum>;
    /** Returns true if the sensors has had at least 1 rain gauge message */
    hasRainfallData: Scalars['Boolean'];
    /** Returns true if this model of sensor has a rain gauge */
    hasRainGauge: Scalars['Boolean'];
    /** Returns true if this sensor is a child of a gateway with a pressure sensor */
    hasPressureSensor: Scalars['Boolean'];
    usage_stats: Maybe<UsageStats>;
    users: Array<HardwareUser>;
    weather: Weather;
  };

export type SensordataArgs = {
  limit: Maybe<Scalars['Int']>;
  start: Maybe<Scalars['Float']>;
  end: Maybe<Scalars['Float']>;
};

export type SensordataViewArgs = {
  start: Maybe<Scalars['Float']>;
  end: Maybe<Scalars['Float']>;
};

export type SensorAlerts = {
  __typename: 'SensorAlerts';
  dry: Maybe<Scalars['Float']>;
};

/** Class that contains fields for both Sensor and ArchivedSensor */
export type SensorBase = {
  canDownloadData: Scalars['Boolean'];
  crop_type: Maybe<Crop>;
  crop_variety: Maybe<Scalars['String']>;
  dataView: Maybe<SensorDataView>;
  field: Maybe<Field>;
  harvest_date: Maybe<Scalars['String']>;
  hasDataAccess: Scalars['Boolean'];
  hasRainfallData: Scalars['Boolean'];
  userHasSensorAccess: Scalars['Boolean'];
  id: Scalars['String'];
  location: Maybe<Location>;
  managementPlan: Maybe<SensorManagementPlan>;
  model: Maybe<Model>;
  moistureMethod: Maybe<MoistureMethod>;
  name: Scalars['String'];
  owner: Maybe<HardwareUser>;
  users: Array<HardwareUser>;
  isOwner: Scalars['Boolean'];
  permissionLevel: Maybe<PermissionLevel>;
  plan: Maybe<SensorDataPlan>;
  plant_date: Maybe<Scalars['String']>;
  sharingMaxUsers: Scalars['Int'];
  soil_type: Maybe<Soil>;
  hasRainGauge: Scalars['Boolean'];
  weather: Weather;
};

export type SensorConfiguration = {
  __typename: 'SensorConfiguration';
  ack_timeout: Maybe<Scalars['Float']>;
  boot: Maybe<Scalars['Boolean']>;
  freq: Maybe<Scalars['Float']>;
  gps_enable: Maybe<Scalars['Boolean']>;
  hibernate_enable: Maybe<Scalars['Boolean']>;
  historical_enable: Maybe<Scalars['Boolean']>;
  hw_ver: Maybe<Scalars['String']>;
  missed_message_sleep: Maybe<Scalars['Float']>;
  rpt_int: Maybe<Scalars['Float']>;
  sensor_var: Maybe<Scalars['String']>;
  sw_ver: Maybe<Scalars['String']>;
  t_sleep: Maybe<Scalars['Float']>;
  tx_high_threshold: Maybe<Scalars['Float']>;
  tx_low_threshold: Maybe<Scalars['Float']>;
  tx_power: Maybe<Scalars['Float']>;
};

export type SensorData = {
  __typename: 'SensorData';
  adc: Maybe<Array<Maybe<Scalars['Float']>>>;
  counters: Maybe<Array<Maybe<Scalars['Float']>>>;
  cumulativeCount: Maybe<Scalars['Float']>;
  vic: Maybe<Array<Maybe<Scalars['Float']>>>;
  gateway: Maybe<Scalars['String']>;
  meas_no: Maybe<Scalars['Float']>;
  moistures: Maybe<Array<Maybe<Scalars['Float']>>>;
  node: Maybe<Scalars['String']>;
  old_server_time: Maybe<Scalars['Float']>;
  /** If there is an error message sent for this serial number, query for it. */
  error: Maybe<Scalars['String']>;
  /** The raw data object (stringified).  Useful for prototyping stuff, don't use in prod. */
  raw: Scalars['String'];
  rainfall: Maybe<Array<SensorDataRainfall>>;
  rssi: Maybe<Scalars['Float']>;
  rtc_time: Maybe<Scalars['String']>;
  tcount: Maybe<Array<Maybe<Scalars['Float']>>>;
  bootCounter: Maybe<Scalars['Float']>;
  bootLimitCounter: Maybe<Scalars['Float']>;
  sessionRetry: Maybe<Scalars['Float']>;
  sessionFail: Maybe<Scalars['Float']>;
  historicalMessages: Maybe<Scalars['Float']>;
  pwrOnRstCounter: Maybe<Scalars['Float']>;
  brnOutRstCounter: Maybe<Scalars['Float']>;
  extRstCounter: Maybe<Scalars['Float']>;
  wdtRstCounter: Maybe<Scalars['Float']>;
  temp: Maybe<Array<Scalars['Float']>>;
  tensions: Maybe<Array<Maybe<Scalars['Float']>>>;
  tensionsWithTemp: Maybe<Array<Maybe<Scalars['Float']>>>;
  pressures: Maybe<Array<Scalars['Float']>>;
  voltages: Maybe<Array<Scalars['Float']>>;
  /** Pressure in psi */
  averagePressure: Maybe<Scalars['Float']>;
  /** Voltage in mV */
  averageVoltage: Maybe<Scalars['Float']>;
  /** Runtime in seconds */
  runtime: Maybe<Scalars['Float']>;
  time: Scalars['Float'];
  vin: Maybe<Scalars['Float']>;
  /** Power pack voltage [V] */
  vpp: Maybe<Scalars['Float']>;
  /** Telemetry data point - contains no measurement data */
  nonsens: Maybe<Scalars['Int']>;
};

export enum SensorDataPlan {
  active = 'active',
}

export type SensorDataRainfall = {
  __typename: 'SensorDataRainfall';
  time: Scalars['Float'];
  /** Hourly rainfall in inches */
  amount: Scalars['Float'];
};

export type SensorDataView = {
  __typename: 'SensorDataView';
  soil: Array<SensorSoilData>;
  rainfall: Array<SensorRainfallData>;
  weather: Array<WeatherStationData>;
  pressure: Array<SensorPressureData>;
};

export type SensorDataViewrainfallArgs = {
  tzoffset?: Maybe<Scalars['Int']>;
  limit: Maybe<Scalars['Int']>;
};

export type SensorEditInput = {
  sensorId: Scalars['String'];
  location: Scalars['String'];
  value: Scalars['String'];
  name: Scalars['String'];
};

export type SensorEditResponse = {
  __typename: 'SensorEditResponse';
  success: Scalars['Boolean'];
};

/**
 * A plan for how to interpret data from a sensor
 * for the duration of a season
 */
export type SensorManagementPlan = {
  __typename: 'SensorManagementPlan';
  id: Scalars['String'];
  name: Scalars['String'];
  model: Maybe<Model>;
  stages: Array<SensorManagementPlanStage>;
};

export type SensorManagementPlanAddResponse = {
  __typename: 'SensorManagementPlanAddResponse';
  success: Scalars['Boolean'];
  sensorManagementPlan: SensorManagementPlan;
  sensor: Sensor;
};

export type SensorManagementPlanInput = {
  name: Scalars['String'];
  stages: Array<SensorManagementPlanStageInput>;
};

export type SensorManagementPlanResponse = {
  __typename: 'SensorManagementPlanResponse';
  success: Scalars['Boolean'];
  sensorManagementPlan: Maybe<SensorManagementPlan>;
};

/**
 * A representation of how a sensor's data should
 * be interpreted for a window of time
 */
export type SensorManagementPlanStage = {
  __typename: 'SensorManagementPlanStage';
  name: Scalars['String'];
  wetTH: Scalars['Float'];
  dryTH: Scalars['Float'];
  weights: Array<Scalars['Float']>;
  DAP: Scalars['Int'];
};

export type SensorManagementPlanStageInput = {
  name: Scalars['String'];
  wetTH: Scalars['Float'];
  dryTH: Scalars['Float'];
  weights: Array<Scalars['Float']>;
  DAP: Scalars['Int'];
};

export type SensorMutationResponse = {
  __typename: 'SensorMutationResponse';
  /** The id of the sensor modified */
  sensorId: Maybe<Scalars['String']>;
  sensor: Maybe<Sensor>;
  success: Scalars['Boolean'];
};

export type SensorNoPlan = {
  __typename: 'SensorNoPlan';
  message: Scalars['String'];
};

export type SensorOffline = {
  __typename: 'SensorOffline';
  message: Scalars['String'];
};

export type SensorPlanDetails = {
  __typename: 'SensorPlanDetails';
  /** If the sensor is prepaid, it's subscription start date might be in the future */
  activeAfter: Maybe<Scalars['Float']>;
  /**
   * If the stripeSubActiveUntil is in the future, this will be it
   * This will be set regardless of if the sensor is cancelled or not
   */
  activeUntil: Maybe<Scalars['Float']>;
  /**
   * If stripeSubCancelledAt is in the future, this will be it
   * This should be set only if the sensor is scheduled to be cancelled at the end of the cycle
   */
  cancelDate: Maybe<Scalars['Float']>;
  /** True if the sensor has an active data plan */
  active: Scalars['Boolean'];
};

export type SensorPressureData = {
  __typename: 'SensorPressureData';
  /** Time in unix ms */
  t: Scalars['Float'];
  /** Average pressure in psi */
  avgP: Scalars['Float'];
  /** Runtime in seconds (0-120) */
  rt: Scalars['Float'];
};

export type SensorPressureOnOffData = {
  __typename: 'SensorPressureOnOffData';
  /** Time in unix ms (start of change) */
  t: Scalars['Float'];
  /** Whether this is turning on, off, offline */
  state: SensorPressureOnOffDataState;
  /** Average pressure in psi during on/off */
  avgP: Scalars['Float'];
};

export enum SensorPressureOnOffDataState {
  on = 'on',
  off = 'off',
  offline = 'offline',
}

export type SensorRainfallData = {
  __typename: 'SensorRainfallData';
  /** Start of rainfall bucket.  Format: YYYY-MM-DD */
  t: Scalars['String'];
  /** Amount of rainfall in inches */
  a: Scalars['Float'];
};

export type SensorReprogramInput = {
  sensorId: Scalars['String'];
  softwareVersion: Scalars['String'];
  hardwareVersion: Scalars['String'];
  variant: Scalars['String'];
  name: Scalars['String'];
};

export type SensorReprogramResponse = {
  __typename: 'SensorReprogramResponse';
  success: Scalars['Boolean'];
};

export type SensorResetInput = {
  sensorId: Scalars['String'];
  name: Scalars['String'];
};

export type SensorResetResponse = {
  __typename: 'SensorResetResponse';
  success: Scalars['Boolean'];
};

export type SensorRetryInput = {
  sensorId: Scalars['String'];
  measurement: Scalars['String'];
  name: Scalars['String'];
};

export type SensorRetryResponse = {
  __typename: 'SensorRetryResponse';
  success: Scalars['Boolean'];
};

export type SensorSoilData = {
  __typename: 'SensorSoilData';
  /** Time in unix ms */
  t: Scalars['Float'];
  /** Soil moisture tensions */
  sm: Array<Maybe<Scalars['Float']>>;
  /** Soil moisture tensions with temp */
  smt: Array<Maybe<Scalars['Float']>>;
  /** Soil temperature */
  st: Array<Maybe<Scalars['Float']>>;
  /** Volumetric Ion Content of soil */
  vic: Array<Maybe<Scalars['Float']>>;
  /** VIC converted to EC in dS/m */
  ec: Array<Maybe<Scalars['Float']>>;
};

export type SensorWeight = {
  __typename: 'SensorWeight';
  id: Scalars['String'];
  weight: Scalars['Int'];
};

export type SensorWeightInput = {
  id: Scalars['String'];
  weight: Scalars['Int'];
};

export type ServiceLog = {
  __typename: 'ServiceLog';
  /** Key of the log in firebase */
  id: Scalars['String'];
  /** Author of service log */
  name: Scalars['String'];
  /** Date the service happened */
  date: Scalars['Float'];
  /** Location of the service */
  location: Scalars['String'];
  odometerTo: Scalars['Float'];
  odometerFrom: Scalars['Float'];
  /** Time spent servicing this hardware */
  timeSpent: Scalars['String'];
  /** Reason for the service */
  reason: Scalars['String'];
  /** Result of the service */
  result: Scalars['String'];
  /** Follow up tasks for after the service */
  followUpTasks: Scalars['String'];
};

export type ServiceLogCreateInput = {
  /** Id of the hardware */
  hw: Scalars['String'];
  /** Type of hardware, either gateway or sensor */
  type: Scalars['String'];
  /** Author of service log */
  name: Scalars['String'];
  /** Date the service happened */
  date: Scalars['Float'];
  /** Location of the service */
  location: Scalars['String'];
  odometerTo: Scalars['Float'];
  odometerFrom: Scalars['Float'];
  /** Time spent servicing this hardware */
  timeSpent: Scalars['String'];
  /** Reason for the service */
  reason: Scalars['String'];
  /** Result of the service */
  result: Scalars['String'];
  /** Follow up tasks for after the service */
  followUpTasks: Scalars['String'];
};

export type ServiceLogCreateReponse = {
  __typename: 'ServiceLogCreateReponse';
  /** If the creation succeeded */
  success: Scalars['Boolean'];
  /** If creation succeeded, the log */
  log: ServiceLog;
};

export type ServiceLogDeleteInput = {
  /** Key of the service log */
  id: Scalars['String'];
  /** Id of the hardware */
  hw: Scalars['String'];
  /** Type of hardware, either gateway or sensor */
  type: Scalars['String'];
};

export type ServiceLogDeleteReponse = {
  __typename: 'ServiceLogDeleteReponse';
  /** If the delete succeeded */
  success: Scalars['Boolean'];
};

export type ServiceLogUpdateInput = {
  /** Key of the service log */
  id: Scalars['String'];
  /** Id of the hardware */
  hw: Scalars['String'];
  /** Type of hardware, either gateway or sensor */
  type: Scalars['String'];
  /** Author of service log */
  name: Scalars['String'];
  /** Date the service happened */
  date: Scalars['Float'];
  /** Location of the service */
  location: Scalars['String'];
  odometerTo: Scalars['Float'];
  odometerFrom: Scalars['Float'];
  /** Time spent servicing this hardware */
  timeSpent: Scalars['String'];
  /** Reason for the service */
  reason: Scalars['String'];
  /** Result of the service */
  result: Scalars['String'];
  /** Follow up tasks for after the service */
  followUpTasks: Scalars['String'];
};

export type ServiceLogUpdateReponse = {
  __typename: 'ServiceLogUpdateReponse';
  /** If the update succeeded */
  success: Scalars['Boolean'];
  /** If update succeeded, the log */
  log: ServiceLog;
};

export type ShipOrderInput = {
  /** The id of the order to mark as shpped */
  orderId: Scalars['Int'];
  /** Tracking number of the shipment (required) */
  trackingNumber: Scalars['String'];
  /** Carrier being used (USPS) */
  carrier: Scalars['String'];
  /** Shipping cost in USD */
  amount: Scalars['Float'];
  /** Whether or not to send shipping email */
  sendShippingEmail: Scalars['Boolean'];
};

export type ShipOrderResponse = {
  __typename: 'ShipOrderResponse';
  /** The id of the order that was marked as shipped */
  id: Maybe<Scalars['Int']>;
};

export type SoftwareVersion = {
  __typename: 'SoftwareVersion';
  name: Scalars['String'];
  hardwareVersions: Array<HardwareVersions>;
};

export type Soil = {
  __typename: 'Soil';
  id: Scalars['String'];
  name: Scalars['String'];
  /** Tension threshold beneath which soil is considered dry */
  t_dry: Scalars['Float'];
  /** Tension threshold above which soil is considered wet */
  t_wet: Scalars['Float'];
  /** Capacitance threshold beneath which soil is considered dry */
  c_dry: Scalars['Float'];
  /** Capacitance threshold above which soil is considered wet */
  c_wet: Scalars['Float'];
  /** True if a user created the soil type */
  custom: Scalars['Boolean'];
  clay_percent: Scalars['Float'];
};

export type StartManagedServiceInput = {
  orderId: Scalars['Int'];
};

export type StartManagedServiceResponse = {
  __typename: 'StartManagedServiceResponse';
  success: Scalars['Boolean'];
};

export type StripeBillingPortalResponse = {
  __typename: 'StripeBillingPortalResponse';
  /** Redirect url for stripe billing page */
  sessionUrl: Scalars['String'];
};

export type StripeCard = {
  __typename: 'StripeCard';
  id: Scalars['String'];
  brand: Maybe<Scalars['String']>;
  last4: Maybe<Scalars['String']>;
};

export enum StripeDataPlan {
  TrellisDashboardPrepaid = 'TrellisDashboardPrepaid',
  TrellisDashboard = 'TrellisDashboard',
  TrellisDashboard15 = 'TrellisDashboard15',
  TrellisDashboard25 = 'TrellisDashboard25',
  TrellisDashboard30 = 'TrellisDashboard30',
  TrellisDashboard25Volume = 'TrellisDashboard25Volume',
  TrellisMonthly35 = 'TrellisMonthly35',
}

export type StripeSubscription = {
  __typename: 'StripeSubscription';
  id: Scalars['String'];
  canceled: Scalars['Boolean'];
  periodEnd: Scalars['Float'];
};

export type Subscription = {
  __typename: 'Subscription';
  /**
   * In the future, we may return a SupportChatLog here so the client doesn't need to refresh.
   * Boolean acts as a placeholder.
   */
  updateSupportChat: Scalars['Boolean'];
};

export type SupportChatLog = {
  __typename: 'SupportChatLog';
  messages: Array<SupportMessage>;
  closed: Scalars['Boolean'];
};

export type SupportMessage = {
  __typename: 'SupportMessage';
  from: Scalars['String'];
  body: Scalars['String'];
  id: Scalars['String'];
  time: Scalars['String'];
};

export type TransferFundsManagedServiceInput = {
  /** ID of distributor to transfer funds to */
  distributorId: Scalars['String'];
  /** Amount in cents */
  amount: Scalars['Int'];
};

export type TransferFundsManagedServiceReponse = {
  __typename: 'TransferFundsManagedServiceReponse';
  success: Scalars['Boolean'];
};

export type TrellisPressureSensor = TrellisSensor & {
  __typename: 'TrellisPressureSensor';
  data: PressureSensorDataResult;
  plan: Maybe<PressureSensorPlan>;
  irrigationSystem: Maybe<IrrigationSystem>;
  hasDataAccess: Scalars['Boolean'];
  id: Scalars['String'];
  name: Scalars['String'];
  /**
   * Whether or not the sensor is active.  Controls alerts, visibility on
   * dashboard, etc... Set true when the sensor's data is paid for, and false when
   * the sensor is archived.
   */
  active: Scalars['Boolean'];
  /** Is the sensor online or offline? */
  connectionStatus: ConnectionStatus;
  dataPlan: SensorPlanDetails;
  lastReportedTime: Maybe<Scalars['Float']>;
  location: Maybe<Location>;
  isOwner: Scalars['Boolean'];
  owner: Maybe<HardwareUser>;
  permissionLevel: Maybe<PermissionLevel>;
  users: Array<HardwareUser>;
};

export type TrellisSensor = {
  id: Scalars['String'];
  name: Scalars['String'];
  /**
   * Whether or not the sensor is active.  Controls alerts, visibility on
   * dashboard, etc... Set true when the sensor's data is paid for, and false when
   * the sensor is archived.
   */
  active: Scalars['Boolean'];
  /** Is the sensor online or offline? */
  connectionStatus: ConnectionStatus;
  dataPlan: SensorPlanDetails;
  lastReportedTime: Maybe<Scalars['Float']>;
  location: Maybe<Location>;
  isOwner: Scalars['Boolean'];
  owner: Maybe<HardwareUser>;
  permissionLevel: Maybe<PermissionLevel>;
  users: Array<HardwareUser>;
};

export type TrellisWeatherSensor = TrellisSensor & {
  __typename: 'TrellisWeatherSensor';
  data: WeatherSensorDataResult;
  managementPlan: Maybe<WeatherStationManagementPlan>;
  fields: Array<Field>;
  id: Scalars['String'];
  name: Scalars['String'];
  /**
   * Whether or not the sensor is active.  Controls alerts, visibility on
   * dashboard, etc... Set true when the sensor's data is paid for, and false when
   * the sensor is archived.
   */
  active: Scalars['Boolean'];
  /** Is the sensor online or offline? */
  connectionStatus: ConnectionStatus;
  dataPlan: SensorPlanDetails;
  lastReportedTime: Maybe<Scalars['Float']>;
  location: Maybe<Location>;
  isOwner: Scalars['Boolean'];
  owner: Maybe<HardwareUser>;
  permissionLevel: Maybe<PermissionLevel>;
  users: Array<HardwareUser>;
};

export type UpdateAlertHistoryInput = {
  /** ID of user to update */
  uid: Scalars['String'];
  /** Update the last login reminder time */
  loginReminderSent?: Maybe<Scalars['Boolean']>;
  /** Update the last daily alert time */
  dailySent?: Maybe<Scalars['Boolean']>;
  /** Update the last weekly alert time */
  weeklySent?: Maybe<Scalars['Boolean']>;
  /** Update the last monthly alert time */
  monthlySent?: Maybe<Scalars['Boolean']>;
  /** Update the dyk used */
  dykId?: Maybe<Scalars['String']>;
  /** Update the last registration reminder time */
  registrationReminderSent?: Maybe<Scalars['Boolean']>;
  /** Update the last irrigation needed alert time */
  irrigationNeeded?: Maybe<Scalars['Boolean']>;
};

export type UpdateAlertHistoryResponse = {
  __typename: 'UpdateAlertHistoryResponse';
  /** The id of the user updated */
  id: Maybe<Scalars['String']>;
};

/** A list of all gateways owned by the user with a plan. */
export type UpdateDataPlanGatewayInput = {
  /** ID of the gateway */
  id: Scalars['String'];
  /** Data plan, or null for none */
  plan?: Maybe<SensorDataPlan>;
};

export type UpdateDataPlanInput = {
  sensors?: Maybe<Array<UpdateDataPlanSensorInput>>;
  gateways?: Maybe<Array<UpdateDataPlanGatewayInput>>;
};

export type UpdateDataPlanResponse = {
  __typename: 'UpdateDataPlanResponse';
  /** The id of the user updated */
  id: Maybe<Scalars['String']>;
};

/** A list of all sensors owned by the user with a plan. */
export type UpdateDataPlanSensorInput = {
  /** ID of the sensor */
  id: Scalars['String'];
  /** Data plan, or null for none */
  plan?: Maybe<SensorDataPlan>;
};

export type UpdateFieldInput = {
  boundary?: Maybe<Array<NNLocationInput>>;
  boundarySoilStats?: Maybe<Array<FieldBoundaryInfoSoilStatInput>>;
  boundarySensorInfo?: Maybe<Array<FieldBoundaryInfoResultSensorInput>>;
  field_name?: Maybe<Scalars['String']>;
  /** New list of sensor ids */
  field_sensors?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** New list of gateway ids */
  field_gateways?: Maybe<Array<Maybe<Scalars['String']>>>;
  field_notes?: Maybe<Scalars['String']>;
  irrigationMetadata?: Maybe<FieldInputIrrigationMetadata>;
  sensorWeights?: Maybe<Array<SensorWeightInput>>;
  /** Crop ID corresponding to this field's crop_type */
  crop_type?: Maybe<Scalars['String']>;
  /** String designating specific crop variety */
  crop_variety?: Maybe<Scalars['String']>;
  /** Yield of this field */
  yield?: Maybe<YieldInput>;
};

export type UpdateFieldMutationResponse = {
  __typename: 'UpdateFieldMutationResponse';
  success: Scalars['Boolean'];
  field: Maybe<Field>;
};

export type UpdateGatewayInput = {
  id: Scalars['String'];
  active?: Maybe<Scalars['Boolean']>;
  location?: Maybe<LocationInput>;
};

export type UpdateGatewayMutationResponse = {
  __typename: 'UpdateGatewayMutationResponse';
  success: Scalars['Boolean'];
  gateway: Maybe<Gateway>;
};

export type UpdateGatewaySharingInput = {
  gatewayId: Scalars['String'];
  /** The email of the user to share it with */
  email: Scalars['String'];
  /** The permission level to give the user, or null to remove the permission */
  level?: Maybe<PermissionLevelInput>;
};

export type UpdateGatewaySharingResponse = {
  __typename: 'UpdateGatewaySharingResponse';
  /** The id of the gateway modified */
  success: Scalars['Boolean'];
  /** ID of user that was shared with */
  sharedUid: Scalars['String'];
  gateway: Gateway;
};

export type UpdateGatewayStatusInput = {
  id: Scalars['String'];
  inactive: Scalars['Boolean'];
};

export type UpdateLoginInput = {
  id: Scalars['String'];
  timestamp: Scalars['Float'];
};

export type UpdateLoginResponse = {
  __typename: 'UpdateLoginResponse';
  /** The id of the login added */
  id: Maybe<Scalars['String']>;
};

export type UpdateSensorInput = {
  /** Format: YYYY-MM-DD */
  harvest_date?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  crop_type?: Maybe<Scalars['String']>;
  crop_variety?: Maybe<Scalars['String']>;
  /** If calling as a rep, this must be set */
  customerId?: Maybe<Scalars['String']>;
  soil_type?: Maybe<Scalars['String']>;
  plant_date?: Maybe<Scalars['String']>;
  location?: Maybe<LocationInput>;
  active?: Maybe<Scalars['Boolean']>;
  managementPlan?: Maybe<Scalars['String']>;
};

export type UpdateSensorSharingInput = {
  sensorId: Scalars['String'];
  /** The email of the user to share it with */
  email: Scalars['String'];
  /** The permission level to give the user, or null to remove the permission */
  level?: Maybe<PermissionLevelInput>;
};

export type UpdateSensorSharingResponse = {
  __typename: 'UpdateSensorSharingResponse';
  /** The id of the sensor modified */
  success: Scalars['Boolean'];
  /** ID of user that was shared with */
  sharedUid: Scalars['String'];
  sensor: Sensor;
};

export type UpdateSensorStateInput = {
  sensorId: Scalars['String'];
  state: Scalars['Int'];
  tension: Scalars['Float'];
};

export type UpdateSensorStatusInput = {
  inactive?: Maybe<Scalars['Boolean']>;
  battery?: Maybe<Scalars['Float']>;
  rssi?: Maybe<Scalars['Float']>;
  sensor_time?: Maybe<Scalars['Float']>;
  usage_stats?: Maybe<UsageStatsInput>;
};

export type UpdateSoilInput = {
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  t_wet?: Maybe<Scalars['Float']>;
  t_dry?: Maybe<Scalars['Float']>;
  c_wet?: Maybe<Scalars['Float']>;
  c_dry?: Maybe<Scalars['Float']>;
};

export type UpdateSoilResponse = {
  __typename: 'UpdateSoilResponse';
  success: Scalars['Boolean'];
  soil: Soil;
};

export type UpdateUserPreferencesInput = {
  textEnabled?: Maybe<Scalars['Boolean']>;
  emailEnabled?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  triggerAlertThresholdHours?: Maybe<Scalars['Float']>;
};

export type UpdateUserPreferencesResponse = {
  __typename: 'UpdateUserPreferencesResponse';
  success: Scalars['Boolean'];
  /** The user that was updated */
  me: User;
};

export type UpdateUserProfileInput = {
  name?: Maybe<NameInput>;
};

export type UpdateUserProfileResponse = {
  __typename: 'UpdateUserProfileResponse';
  /** The id of the user updated */
  id: Maybe<Scalars['String']>;
};

export type UsageStats = {
  __typename: 'UsageStats';
  dry: Scalars['Float'];
  ok: Scalars['Float'];
  wet: Scalars['Float'];
  online: Scalars['Float'];
  offline: Scalars['Float'];
  logins: Scalars['Float'];
};

export type UsageStatsInput = {
  dry: Scalars['Float'];
  ok: Scalars['Float'];
  wet: Scalars['Float'];
  online: Scalars['Float'];
  offline: Scalars['Float'];
  logins: Scalars['Float'];
};

export type UsedDidYouKnows = {
  __typename: 'UsedDidYouKnows';
  id: Scalars['String'];
  timestamp: Scalars['Float'];
};

export type User = {
  __typename: 'User';
  /** Firebase email associated with uid */
  accountEmail: Maybe<Scalars['String']>;
  /** True if the user has been activated by their distributor */
  activated: Maybe<Scalars['Boolean']>;
  /** True if the user has finished activating their account */
  activationCompleted: Maybe<Scalars['Boolean']>;
  alert_history: Maybe<Alert>;
  archivedSensors: Array<ArchivedSensor>;
  archivedGateways: Array<ArchivedGateway>;
  archivedFields: Array<ArchivedField>;
  archivedIrrigationSystems: Array<ArchivedIrrigationSystem>;
  cards: Maybe<Array<Maybe<StripeCard>>>;
  /** Email used for email alerts */
  email: Maybe<Scalars['String']>;
  distributor: Maybe<Distributor>;
  fields: Array<Field>;
  gateways: Array<Gateway>;
  gatewayIds: Array<Scalars['String']>;
  hasAccountIssues: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  /** Past invoices */
  invoices: Maybe<Array<Invoice>>;
  logins: Array<Maybe<Scalars['String']>>;
  managedServiceStatuses: Array<ManagedServiceStatus>;
  name: Name;
  notifications: Maybe<Array<Notification>>;
  /** Phone number used for text alerts */
  phone: Maybe<Scalars['String']>;
  planID: Maybe<StripeDataPlan>;
  preferences: UserPreferences;
  irrigationSystems: Array<IrrigationSystem>;
  pressureSensors: Array<TrellisPressureSensor>;
  prepaid: Maybe<Scalars['Boolean']>;
  prepaidStart: Maybe<Scalars['Float']>;
  prepaidUntil: Maybe<Scalars['Float']>;
  pushToken: Maybe<Scalars['String']>;
  sensors: Array<Sensor>;
  sensorIds: Array<Scalars['String']>;
  stripeSubID: Maybe<Scalars['String']>;
  stripeSubscription: Maybe<StripeSubscription>;
  stripeID: Maybe<Scalars['String']>;
  /** Sensors which have not been assigned to a field for this user */
  unassignedSensors: Array<Sensor>;
  upcomingInvoice: Maybe<Invoice>;
  /** All of the user's management plans */
  weatherManagementPlans: Array<WeatherStationManagementPlan>;
  weatherStations: Array<TrellisWeatherSensor>;
};

export type UserfieldsArgs = {
  onlyOwned: Maybe<Scalars['Boolean']>;
  onlyAdmin: Maybe<Scalars['Boolean']>;
};

export type UsergatewaysArgs = {
  onlyOwned: Maybe<Scalars['Boolean']>;
  onlyAdmin: Maybe<Scalars['Boolean']>;
  onlyRG: Maybe<Scalars['Boolean']>;
  onlyAvailable: Maybe<Scalars['Boolean']>;
};

export type UsersensorsArgs = {
  onlyOwned: Maybe<Scalars['Boolean']>;
  onlyAdmin: Maybe<Scalars['Boolean']>;
  onlyEditable: Maybe<Scalars['Boolean']>;
  onlyAvailable: Maybe<Scalars['Boolean']>;
};

export type UserPreferences = {
  __typename: 'UserPreferences';
  /** How many hours before the dry threshold a user wants to be notified */
  triggerAlertThresholdHours: Scalars['Float'];
  emailAlerts: Maybe<Scalars['Boolean']>;
  emailDailyReport: Maybe<Scalars['Boolean']>;
  emailWeeklyReport: Maybe<Scalars['Boolean']>;
  emailMonthlyReport: Maybe<Scalars['Boolean']>;
  textAlerts: Maybe<Scalars['Boolean']>;
};

export type UserSetPushTokenInput = {
  token: Scalars['String'];
};

export type UserSetPushTokenResponse = {
  __typename: 'UserSetPushTokenResponse';
  success: Scalars['Boolean'];
};

export type Weather = {
  __typename: 'Weather';
  weeklyForecast: Array<WeatherWeeklyForecast>;
  historical: Array<WeatherHistorical>;
};

export type WeatherHistorical = {
  __typename: 'WeatherHistorical';
  /** 1 Hour rainfall amount (in) */
  precipAmount: Scalars['Float'];
  /** Time in unix ms */
  time: Scalars['Float'];
  /** Humidity (%) (0 - 100) */
  hum: Maybe<Scalars['Float']>;
  /** UV Index */
  uvIndex: Maybe<Scalars['Float']>;
  /** High Temperature (F) */
  tempHigh: Maybe<Scalars['Float']>;
  /** Low Temperature (F) */
  tempLow: Maybe<Scalars['Float']>;
  /** Wind speed (mph) */
  windSpd: Maybe<Scalars['Float']>;
  /** Precipitation intensity */
  precipIntensity: Maybe<Scalars['Float']>;
  /** Cloud cover (%) (0 - 100) */
  cloudCover: Maybe<Scalars['Float']>;
  /** pressure */
  pressure: Maybe<Scalars['Float']>;
};

export type WeatherSensorData = {
  __typename: 'WeatherSensorData';
  currentWeather: CurrentWeatherResult;
  dailyRainfall: Array<SensorRainfallData>;
  weather: Array<WeatherStationData>;
};

export type WeatherSensorDatadailyRainfallArgs = {
  tzoffset?: Maybe<Scalars['Int']>;
  start: Maybe<Scalars['Float']>;
};

export type WeatherSensorDataweatherArgs = {
  start: Maybe<Scalars['Float']>;
};

export type WeatherSensorDataResult = WeatherSensorData | SensorNoPlan;

export type WeatherStationAlert = {
  __typename: 'WeatherStationAlert';
  type: WeatherStationAlertType;
  relationToThreshold: AboveOrBelow;
  threshold: Scalars['Float'];
};

export type WeatherStationAlertInput = {
  type: WeatherStationAlertType;
  relationToThreshold: AboveOrBelow;
  threshold: Scalars['Float'];
};

export enum WeatherStationAlertType {
  humidity = 'humidity',
  rainrate = 'rainrate',
  solar = 'solar',
  temperature = 'temperature',
  wetBulb = 'wetBulb',
  wind = 'wind',
}

export type WeatherStationData = {
  __typename: 'WeatherStationData';
  /** Time in unix ms */
  time: Scalars['Float'];
  /** Humidity (%) (0 - 100) */
  hum: Scalars['Float'];
  /** Max Wind Direction (N/NE/etc) */
  mWindDir: Scalars['String'];
  /** Max wind speed (mph) */
  mWindSpd: Scalars['Float'];
  /** Rain Count */
  rnCnt: Scalars['Float'];
  /** Rain rate (in/hr) */
  rnRate: Scalars['Float'];
  /** Rain total (in/hr) */
  rnTotal: Scalars['Float'];
  /** Solar radiation (W/m²) */
  solarRad: Scalars['Float'];
  /** Temperature (F) */
  temp: Scalars['Float'];
  /** Wet bulb temperature (F) */
  wbTemp: Maybe<Scalars['Float']>;
  /** Wind direction (N/NE/etc) */
  windDir: Scalars['String'];
  /** Wind speed (mph) */
  windSpd: Scalars['Float'];
  /** Wind error */
  windErr: Maybe<Scalars['Boolean']>;
  /** Rain rate error */
  rnRateErr: Maybe<Scalars['Boolean']>;
  /** Solar radiation error */
  solarRadErr: Maybe<Scalars['Boolean']>;
  /** Temperature error */
  tempErr: Maybe<Scalars['Boolean']>;
  /** Max wind error */
  mWindErr: Maybe<Scalars['Boolean']>;
  /** Humidity error */
  humErr: Maybe<Scalars['Boolean']>;
  /** Rain count error */
  rnCntErr: Maybe<Scalars['Boolean']>;
};

export type WeatherStationManagementPlan = {
  __typename: 'WeatherStationManagementPlan';
  id: Scalars['String'];
  name: Scalars['String'];
  alerts: Array<WeatherStationAlert>;
};

export type WeatherStationManagementPlanInput = {
  name: Scalars['String'];
  alerts: Array<WeatherStationAlertInput>;
};

export type WeatherStationManagementPlanResponse = {
  __typename: 'WeatherStationManagementPlanResponse';
  success: Scalars['Boolean'];
  weatherStationManagementPlan: Maybe<WeatherStationManagementPlan>;
};

/** Null values will be ignored */
export type WeatherStationUpdateInput = {
  name?: Maybe<Scalars['String']>;
  managementPlan?: Maybe<Scalars['String']>;
  location?: Maybe<LocationInput>;
};

export type WeatherStationUpdateResponse = {
  __typename: 'WeatherStationUpdateResponse';
  success: Scalars['Boolean'];
  weatherStation: TrellisWeatherSensor;
};

export type WeatherWeeklyForecast = {
  __typename: 'WeatherWeeklyForecast';
  /** 24 Hour rainfall amount (in) */
  precipAmount: Scalars['Float'];
  /** Probability of precipitation (100-0) */
  precipProbability: Scalars['Float'];
  icon: Maybe<Scalars['String']>;
  tempHigh: Scalars['Float'];
  tempLow: Scalars['Float'];
  /** Time in unix ms */
  time: Scalars['Float'];
};

/** Yield data entered during archiving process */
export type YieldData = {
  __typename: 'YieldData';
  actualYield: Maybe<YieldType>;
  targetYield: Maybe<YieldType>;
  performance: Maybe<YieldDataPerformance>;
};

export enum YieldDataPerformance {
  over = 'over',
  equal = 'equal',
  under = 'under',
}

export type YieldInput = {
  amount?: Maybe<Scalars['Float']>;
  units?: Maybe<YieldUnits>;
};

export type YieldType = {
  __typename: 'YieldType';
  amount: Maybe<Scalars['Float']>;
  units: Maybe<YieldUnits>;
};

export enum YieldUnits {
  lbs = 'lbs',
  bu = 'bu',
  crt = 'crt',
}

export type ZohoInvoice = {
  __typename: 'ZohoInvoice';
  due: Scalars['Float'];
  paymentUrl: Scalars['String'];
  status: ZohoInvoiceStatus;
};

export enum ZohoInvoiceStatus {
  paid = 'paid',
  sent = 'sent',
  draft = 'draft',
  overdue = 'overdue',
}

export type ZohoSalesOrder = {
  __typename: 'ZohoSalesOrder';
  invoice: Maybe<ZohoInvoice>;
};

export type IdentityQueryVariables = {};

export type IdentityQuery = {
  __typename: 'Query';
  currentDistributor: {
    __typename: 'Distributor';
    id: string;
    isDistributor: Maybe<boolean>;
    isAdmin: Maybe<boolean>;
  };
};

export type AnalyticsInfoQueryVariables = {};

export type AnalyticsInfoQuery = {
  __typename: 'Query';
  me: {
    __typename: 'User';
    id: string;
    accountEmail: Maybe<string>;
    name: { __typename: 'Name'; first: Maybe<string>; last: Maybe<string> };
  };
};

export type DeleteFieldMutationVariables = {
  input: DeleteFieldInput;
};

export type DeleteFieldMutation = {
  __typename: 'Mutation';
  deleteField: Maybe<{ __typename: 'FieldMutationResponse'; success: boolean }>;
};

export type OrderQueryVariables = {
  orderId: Scalars['Int'];
};

export type OrderQuery = {
  __typename: 'Query';
  order: Maybe<{
    __typename: 'Order';
    id: number;
    placedTimestamp: number;
    notes: Maybe<string>;
    sharingEmails: Array<string>;
    zohoSalesOrder: Maybe<{
      __typename: 'ZohoSalesOrder';
      invoice: Maybe<{
        __typename: 'ZohoInvoice';
        due: number;
        paymentUrl: string;
        status: ZohoInvoiceStatus;
      }>;
    }>;
    prepaid: Maybe<{
      __typename: 'OrderPrepaid';
      plan: SensorDataPlan;
      months: number;
      start: number;
    }>;
    details: {
      __typename: 'OrderDetails';
      customer: Maybe<{
        __typename: 'OrderDetailsCustomerCopy';
        id: string;
        email: Maybe<string>;
        name: Maybe<{
          __typename: 'Name';
          first: Maybe<string>;
          last: Maybe<string>;
        }>;
      }>;
      hardware: Array<{
        __typename: 'OrderDetailsHardware';
        id: Maybe<string>;
        pricePerUnit: number;
        quantity: number;
        type: HardwareTypeEnum;
        model: { __typename: 'Model' } & ModelFieldsFragment;
      }>;
      address: Maybe<{
        __typename: 'Address';
        id: string;
        street: Maybe<string>;
        city: Maybe<string>;
        state: Maybe<string>;
        zip: Maybe<string>;
        name: Maybe<{
          __typename: 'Name';
          first: Maybe<string>;
          last: Maybe<string>;
        }>;
      }>;
      rep: Maybe<{
        __typename: 'OrderDetailsRepCopy';
        id: string;
        email: Maybe<string>;
        phone: Maybe<string>;
        name: Maybe<{
          __typename: 'Name';
          first: Maybe<string>;
          last: Maybe<string>;
        }>;
      }>;
    };
    managedServiceOptions: Maybe<{
      __typename: 'ManagedServiceOptions';
      months: number;
      prepaid: boolean;
      price: number;
    }>;
  }>;
};

export type GetSensorLogsQueryVariables = {
  id: Scalars['String'];
};

export type GetSensorLogsQuery = {
  __typename: 'Query';
  sensor: Maybe<{
    __typename: 'Sensor';
    id: string;
    serviceLogs: Array<{ __typename: 'ServiceLog'; id: string }>;
  }>;
};

export type GetGatewayLogsQueryVariables = {
  id: Scalars['String'];
};

export type GetGatewayLogsQuery = {
  __typename: 'Query';
  gateway: Maybe<{
    __typename: 'Gateway';
    id: string;
    serviceLogs: Array<{ __typename: 'ServiceLog'; id: string }>;
  }>;
};

export type CreateLogMutationVariables = {
  input: ServiceLogCreateInput;
};

export type CreateLogMutation = {
  __typename: 'Mutation';
  serviceLogCreate: Maybe<{
    __typename: 'ServiceLogCreateReponse';
    success: boolean;
    log: {
      __typename: 'ServiceLog';
      name: string;
      date: number;
      location: string;
      reason: string;
      result: string;
      odometerTo: number;
      odometerFrom: number;
      timeSpent: string;
      followUpTasks: string;
      id: string;
    };
  }>;
};

export type UpdateLogMutationVariables = {
  input: ServiceLogUpdateInput;
};

export type UpdateLogMutation = {
  __typename: 'Mutation';
  serviceLogUpdate: Maybe<{
    __typename: 'ServiceLogUpdateReponse';
    success: boolean;
    log: {
      __typename: 'ServiceLog';
      name: string;
      date: number;
      location: string;
      reason: string;
      result: string;
      odometerTo: number;
      odometerFrom: number;
      timeSpent: string;
      followUpTasks: string;
      id: string;
    };
  }>;
};

export type DeleteLogMutationVariables = {
  input: ServiceLogDeleteInput;
};

export type DeleteLogMutation = {
  __typename: 'Mutation';
  serviceLogDelete: Maybe<{
    __typename: 'ServiceLogDeleteReponse';
    success: boolean;
  }>;
};

export type AddCustomerMutationVariables = {
  input: AddCustomerInput;
};

export type AddCustomerMutation = {
  __typename: 'Mutation';
  addCustomer: Maybe<{ __typename: 'AddCustomerResponse'; id: Maybe<string> }>;
};

export type AddRepMutationVariables = {
  input: AddRepInput;
};

export type AddRepMutation = {
  __typename: 'Mutation';
  addRep: Maybe<{ __typename: 'AddRepResponse'; id: Maybe<string> }>;
};

export type AddFieldsDataQueryVariables = {
  customerId: Scalars['String'];
};

export type AddFieldsDataQuery = {
  __typename: 'Query';
  customer: Maybe<{
    __typename: 'Customer';
    id: string;
    user: {
      __typename: 'User';
      id: string;
      sensors: Array<{
        __typename: 'Sensor';
        id: string;
        name: string;
        model: Maybe<{
          __typename: 'Model';
          type: HardwareTypeEnum;
          antennaLength: Maybe<number>;
          moistureDepths: Maybe<Array<number>>;
          hasRainGauge: boolean;
        }>;
        field: Maybe<{ __typename: 'Field'; id: string }>;
        location: Maybe<{ __typename: 'Location'; lat: number; lng: number }>;
      }>;
      gateways: Array<{
        __typename: 'Gateway';
        id: string;
        model: Maybe<{
          __typename: 'Model';
          type: HardwareTypeEnum;
          antennaLength: Maybe<number>;
          hasRainGauge: boolean;
        }>;
        fields: Array<Maybe<{ __typename: 'Field'; id: string }>>;
        location: Maybe<{ __typename: 'Location'; lat: number; lng: number }>;
      }>;
    };
  }>;
  soils: Array<{ __typename: 'Soil'; id: string; name: string }>;
  crops: Array<{ __typename: 'Crop'; id: string; name: string }>;
  irrigationTypes: Array<{
    __typename: 'Irrigation';
    id: string;
    name: string;
  }>;
};

export type AddFieldMutationVariables = {
  input: AddFieldInput;
};

export type AddFieldMutation = {
  __typename: 'Mutation';
  addField: Maybe<{ __typename: 'FieldMutationResponse'; success: boolean }>;
};

export type UpdateSensorAddFieldMutationVariables = {
  sensorId: Scalars['String'];
  input: UpdateSensorInput;
};

export type UpdateSensorAddFieldMutation = {
  __typename: 'Mutation';
  updateSensor: Maybe<{
    __typename: 'SensorMutationResponse';
    sensorId: Maybe<string>;
  }>;
};

export type sensorInfoFragment = {
  __typename: 'Sensor';
  id: string;
  name: string;
  moistureMethod: Maybe<MoistureMethod>;
  owner: Maybe<{
    __typename: 'HardwareUser';
    id: string;
    name: Maybe<{
      __typename: 'Name';
      first: Maybe<string>;
      last: Maybe<string>;
    }>;
  }>;
  users: Array<{
    __typename: 'HardwareUser';
    id: string;
    permissionLevel: PermissionLevel;
    name: Maybe<{
      __typename: 'Name';
      first: Maybe<string>;
      last: Maybe<string>;
    }>;
  }>;
  order: Maybe<{
    __typename: 'Order';
    id: number;
    details: {
      __typename: 'OrderDetails';
      rep: Maybe<{
        __typename: 'OrderDetailsRepCopy';
        id: string;
        name: Maybe<{
          __typename: 'Name';
          first: Maybe<string>;
          last: Maybe<string>;
        }>;
      }>;
    };
  }>;
};

export type gatewayInfoFragment = {
  __typename: 'Gateway';
  id: string;
  owner: Maybe<{
    __typename: 'HardwareUser';
    id: string;
    name: Maybe<{
      __typename: 'Name';
      first: Maybe<string>;
      last: Maybe<string>;
    }>;
  }>;
  users: Array<{
    __typename: 'HardwareUser';
    id: string;
    permissionLevel: PermissionLevel;
    name: Maybe<{
      __typename: 'Name';
      first: Maybe<string>;
      last: Maybe<string>;
    }>;
  }>;
  order: Maybe<{
    __typename: 'Order';
    id: number;
    details: {
      __typename: 'OrderDetails';
      rep: Maybe<{
        __typename: 'OrderDetailsRepCopy';
        id: string;
        name: Maybe<{
          __typename: 'Name';
          first: Maybe<string>;
          last: Maybe<string>;
        }>;
      }>;
    };
  }>;
};

export type AllSensorsQueryVariables = {};

export type AllSensorsQuery = {
  __typename: 'Query';
  allUsers: Array<{
    __typename: 'User';
    id: string;
    sensors: Array<{ __typename: 'Sensor'; id: string } & sensorInfoFragment>;
    gateways: Array<
      { __typename: 'Gateway'; id: string } & gatewayInfoFragment
    >;
  }>;
  allDistributors: Array<{
    __typename: 'Distributor';
    id: string;
    name: Maybe<{
      __typename: 'Name';
      first: Maybe<string>;
      last: Maybe<string>;
    }>;
    unassigned: {
      __typename: 'DistributorUnassigned';
      sensors: Array<{ __typename: 'Sensor'; id: string }>;
      gateways: Array<{ __typename: 'Gateway'; id: string }>;
    };
  }>;
};

export type AddDistributorMutationVariables = {
  input: AddDistributorInput;
};

export type AddDistributorMutation = {
  __typename: 'Mutation';
  addDistributor: Maybe<{
    __typename: 'AddDistributorResponse';
    id: Maybe<string>;
  }>;
};

export type PricingsQueryVariables = {};

export type PricingsQuery = {
  __typename: 'Query';
  pricings: Array<{ __typename: 'Pricing'; id: string; name: Maybe<string> }>;
  salesReps: Array<{ __typename: 'SalesRep'; id: string; name: Maybe<string> }>;
};

export type AllUsersQueryVariables = {};

export type AllUsersQuery = {
  __typename: 'Query';
  allUsers: Array<{
    __typename: 'User';
    id: string;
    email: Maybe<string>;
    phone: Maybe<string>;
    activated: Maybe<boolean>;
    sensorIds: Array<string>;
    gatewayIds: Array<string>;
    name: { __typename: 'Name'; first: Maybe<string>; last: Maybe<string> };
    distributor: Maybe<{
      __typename: 'Distributor';
      id: string;
      name: Maybe<{
        __typename: 'Name';
        first: Maybe<string>;
        last: Maybe<string>;
      }>;
      salesRep: Maybe<{
        __typename: 'SalesRep';
        id: string;
        name: Maybe<string>;
      }>;
    }>;
  }>;
};

export type AllDistributorsQueryVariables = {};

export type AllDistributorsQuery = {
  __typename: 'Query';
  allDistributors: Array<{
    __typename: 'Distributor';
    id: string;
    email: Maybe<string>;
    phone: Maybe<string>;
    isRep: Maybe<boolean>;
    name: Maybe<{
      __typename: 'Name';
      first: Maybe<string>;
      last: Maybe<string>;
    }>;
    reps: Array<{ __typename: 'DistributorRepCopy'; id: string }>;
  }>;
};

export type AdminOrdersQueryVariables = {};

export type AdminOrdersQuery = {
  __typename: 'Query';
  allOrders: Array<{
    __typename: 'Order';
    id: number;
    placedTimestamp: number;
    status: OrderStatus;
    details: {
      __typename: 'OrderDetails';
      hardware: Array<{
        __typename: 'OrderDetailsHardware';
        id: Maybe<string>;
        quantity: number;
      }>;
      customer: Maybe<{
        __typename: 'OrderDetailsCustomerCopy';
        id: string;
        name: Maybe<{
          __typename: 'Name';
          first: Maybe<string>;
          last: Maybe<string>;
        }>;
      }>;
      rep: Maybe<{
        __typename: 'OrderDetailsRepCopy';
        id: string;
        name: Maybe<{
          __typename: 'Name';
          first: Maybe<string>;
          last: Maybe<string>;
        }>;
      }>;
    };
  }>;
};

export type AdminPrepayUsersQueryVariables = {};

export type AdminPrepayUsersQuery = {
  __typename: 'Query';
  allUsers: Array<{
    __typename: 'User';
    id: string;
    email: Maybe<string>;
    name: { __typename: 'Name'; first: Maybe<string>; last: Maybe<string> };
  }>;
};

export type AdminPrepayUserQueryVariables = {
  id: Scalars['String'];
};

export type AdminPrepayUserQuery = {
  __typename: 'Query';
  user: Maybe<{
    __typename: 'User';
    id: string;
    accountEmail: Maybe<string>;
    sensors: Array<{
      __typename: 'Sensor';
      id: string;
      planRequired: boolean;
      planId: Maybe<StripeDataPlan>;
      planActiveUntil: Maybe<number>;
    }>;
    gateways: Array<{
      __typename: 'Gateway';
      id: string;
      planRequired: boolean;
      planId: Maybe<StripeDataPlan>;
      planActiveUntil: Maybe<number>;
    }>;
  }>;
};

export type ApplyPrepaidMutationVariables = {
  input: ApplyPrepaidToUserInput;
};

export type ApplyPrepaidMutation = {
  __typename: 'Mutation';
  applyPrepaidToUser: Maybe<boolean>;
};

export type CancelPrepaidMutationVariables = {
  input: CancelPrepaidToUserInput;
};

export type CancelPrepaidMutation = {
  __typename: 'Mutation';
  cancelPrepaidToUser: Maybe<boolean>;
};

export type AllServiceQueryVariables = {};

export type AllServiceQuery = {
  __typename: 'Query';
  allSensors: Array<{
    __typename: 'Sensor';
    id: string;
    serviceLogs: Array<
      { __typename: 'ServiceLog'; id: string } & AllServiceLogFragment
    >;
  }>;
  allGateways: Array<{
    __typename: 'Gateway';
    id: string;
    serviceLogs: Array<
      { __typename: 'ServiceLog'; id: string } & AllServiceLogFragment
    >;
  }>;
};

export type AllServiceLogFragment = {
  __typename: 'ServiceLog';
  name: string;
  date: number;
  location: string;
  reason: string;
  result: string;
  timeSpent: string;
  followUpTasks: string;
  id: string;
};

export type AdminShippingQueryVariables = {};

export type AdminShippingQuery = {
  __typename: 'Query';
  openOrders: Array<{
    __typename: 'Order';
    id: number;
    placedTimestamp: number;
    status: OrderStatus;
    details: {
      __typename: 'OrderDetails';
      hardware: Array<{
        __typename: 'OrderDetailsHardware';
        id: Maybe<string>;
        quantity: number;
      }>;
      customer: Maybe<{
        __typename: 'OrderDetailsCustomerCopy';
        id: string;
        name: Maybe<{
          __typename: 'Name';
          first: Maybe<string>;
          last: Maybe<string>;
        }>;
      }>;
      rep: Maybe<{
        __typename: 'OrderDetailsRepCopy';
        id: string;
        name: Maybe<{
          __typename: 'Name';
          first: Maybe<string>;
          last: Maybe<string>;
        }>;
      }>;
    };
  }>;
};

export type AdminStatusQueryVariables = {};

export type AdminStatusQuery = {
  __typename: 'Query';
  allSensors: Array<{
    __typename: 'Sensor';
    id: string;
    battery: Maybe<number>;
    gatewayId: Maybe<string>;
    rssi: Maybe<number>;
    inactive: boolean;
    connectionStatus: ConnectionStatus;
    sensor_time: Maybe<number>;
    statusWarnings: Array<HardwareWarningEnum>;
    owner: Maybe<{
      __typename: 'HardwareUser';
      id: string;
      name: Maybe<{
        __typename: 'Name';
        first: Maybe<string>;
        last: Maybe<string>;
      }>;
      user: {
        __typename: 'User';
        id: string;
        email: Maybe<string>;
        distributor: Maybe<{
          __typename: 'Distributor';
          id: string;
          email: Maybe<string>;
          name: Maybe<{
            __typename: 'Name';
            first: Maybe<string>;
            last: Maybe<string>;
          }>;
          salesRep: Maybe<{
            __typename: 'SalesRep';
            id: string;
            name: Maybe<string>;
          }>;
        }>;
      };
    }>;
    model: Maybe<{ __typename: 'Model'; hasRainGauge: boolean }>;
    usage_stats: Maybe<{
      __typename: 'UsageStats';
      dry: number;
      ok: number;
      wet: number;
      online: number;
      offline: number;
      logins: number;
    }>;
  }>;
  allGateways: Array<{
    __typename: 'Gateway';
    id: string;
    gateway_battery: Maybe<number>;
    sensorIds: Array<string>;
    cell_signal: Maybe<number>;
    inactive: boolean;
    connectionStatus: ConnectionStatus;
    gateway_time: Maybe<number>;
    statusWarnings: Array<HardwareWarningEnum>;
    owner: Maybe<{
      __typename: 'HardwareUser';
      id: string;
      name: Maybe<{
        __typename: 'Name';
        first: Maybe<string>;
        last: Maybe<string>;
      }>;
      user: {
        __typename: 'User';
        id: string;
        email: Maybe<string>;
        distributor: Maybe<{
          __typename: 'Distributor';
          id: string;
          email: Maybe<string>;
          name: Maybe<{
            __typename: 'Name';
            first: Maybe<string>;
            last: Maybe<string>;
          }>;
          salesRep: Maybe<{
            __typename: 'SalesRep';
            id: string;
            name: Maybe<string>;
          }>;
        }>;
      };
    }>;
    model: Maybe<{ __typename: 'Model'; hasRainGauge: boolean }>;
  }>;
  allDistributors: Array<{
    __typename: 'Distributor';
    id: string;
    name: Maybe<{ __typename: 'Name'; full: Maybe<string> }>;
    unassigned: {
      __typename: 'DistributorUnassigned';
      sensors: Array<{ __typename: 'Sensor'; id: string }>;
      gateways: Array<{ __typename: 'Gateway'; id: string }>;
    };
  }>;
};

export type UpdateGatewayStatusMutationVariables = {
  input: UpdateGatewayStatusInput;
};

export type UpdateGatewayStatusMutation = {
  __typename: 'Mutation';
  updateGatewayStatus: Maybe<{
    __typename: 'GatewayStatusMutationResponse';
    id: Maybe<string>;
  }>;
};

export type UpdateSensorStatusMutationVariables = {
  sensorId: Scalars['String'];
  input: UpdateSensorStatusInput;
};

export type UpdateSensorStatusMutation = {
  __typename: 'Mutation';
  updateSensorStatus: Maybe<{
    __typename: 'SensorMutationResponse';
    sensorId: Maybe<string>;
  }>;
};

export type UpdateSensorFragment = {
  __typename: 'Sensor';
  id: string;
  inactive: boolean;
};

export type UpdateGatewayFragment = {
  __typename: 'Gateway';
  id: string;
  inactive: boolean;
};

export type GatewayStatusQueryVariables = {
  gatewayId: Scalars['String'];
};

export type GatewayStatusQuery = {
  __typename: 'Query';
  gateway: Maybe<{
    __typename: 'Gateway';
    id: string;
    cell_signal: Maybe<number>;
    gateway_battery: Maybe<number>;
    gateway_time: Maybe<number>;
    gateway_time_interval: Maybe<number>;
    statusWarnings: Array<HardwareWarningEnum>;
    inactive: boolean;
    configurationUpdated: Maybe<number>;
    sensorIds: Array<string>;
    gpsUpdated: Maybe<number>;
    gpsLocked: Maybe<boolean>;
    configuration: Maybe<{
      __typename: 'GatewayConfiguration';
      boot: Maybe<boolean>;
      crit_th: Maybe<number>;
      freq: Maybe<number>;
      hw_ver: Maybe<string>;
      led_th: Maybe<number>;
      sw_ver: Maybe<string>;
      voltage_th: Maybe<number>;
      ws_fw_ver: Maybe<string>;
    }>;
    network: Maybe<{
      __typename: 'GatewayNetwork';
      availableServices: Maybe<Array<Maybe<string>>>;
      ccid: Maybe<string>;
      currentService: Maybe<string>;
      firmwareVersion: Maybe<string>;
    }>;
    sensors: Array<{
      __typename: 'Sensor';
      id: string;
      connectionStatus: ConnectionStatus;
    }>;
    model: Maybe<{
      __typename: 'Model';
      hasRainGauge: boolean;
      hasWeatherStation: boolean;
      hasPressureSensor: boolean;
    }>;
    owner: Maybe<{
      __typename: 'HardwareUser';
      id: string;
      accountEmail: Maybe<string>;
      name: Maybe<{
        __typename: 'Name';
        first: Maybe<string>;
        last: Maybe<string>;
      }>;
      user: {
        __typename: 'User';
        id: string;
        activationCompleted: Maybe<boolean>;
        hasAccountIssues: Maybe<boolean>;
        logins: Array<Maybe<string>>;
        phone: Maybe<string>;
        sensors: Array<{ __typename: 'Sensor'; id: string; active: boolean }>;
        gateways: Array<{ __typename: 'Gateway'; id: string; active: boolean }>;
        distributor: Maybe<{
          __typename: 'Distributor';
          id: string;
          name: Maybe<{
            __typename: 'Name';
            first: Maybe<string>;
            last: Maybe<string>;
          }>;
          salesRep: Maybe<{
            __typename: 'SalesRep';
            id: string;
            name: Maybe<string>;
          }>;
        }>;
      };
    }>;
    users: Array<{
      __typename: 'HardwareUser';
      id: string;
      user: {
        __typename: 'User';
        id: string;
        email: Maybe<string>;
        name: { __typename: 'Name'; first: Maybe<string>; last: Maybe<string> };
      };
    }>;
    location: Maybe<{ __typename: 'Location'; lat: number; lng: number }>;
    mailbox: Array<{
      __typename: 'MailboxSlot';
      toDevice: Maybe<string>;
      toServer: Maybe<string>;
    }>;
    serviceLogs: Array<{
      __typename: 'ServiceLog';
      name: string;
      date: number;
      location: string;
      reason: string;
      result: string;
      timeSpent: string;
      followUpTasks: string;
      id: string;
    }>;
  }>;
  sensor: Maybe<{
    __typename: 'Sensor';
    id: string;
    errors: Array<{
      __typename: 'SensorData';
      error: Maybe<string>;
      time: number;
    }>;
  }>;
};

export type GatewayStatusDataDataFragment = {
  __typename: 'GatewayData';
  cell_signal: Maybe<number>;
  msg: Maybe<number>;
  time: Maybe<number>;
  vbat: Maybe<number>;
  vsol: Maybe<number>;
  rbat: Maybe<number>;
  netOpenRetry: Maybe<number>;
  tcpOpenRetry: Maybe<number>;
  tcpSendRetry: Maybe<number>;
  resetCounter: Maybe<number>;
  failCounter: Maybe<number>;
  rebootCounter: Maybe<number>;
  rxBadCounter: Maybe<number>;
  localCounter: Maybe<number>;
  rebootLimitCounter: Maybe<number>;
  isCharging: Maybe<number>;
  pwrOnRstCounter: Maybe<number>;
  brnOutRstCounter: Maybe<number>;
  extRstCounter: Maybe<number>;
  wdtRstCounter: Maybe<number>;
  tcpFailID: Maybe<number>;
};

export type GatewayStatusVanillaDataQueryVariables = {
  gatewayId: Scalars['String'];
  start?: Maybe<Scalars['Float']>;
};

export type GatewayStatusVanillaDataQuery = {
  __typename: 'Query';
  gateway: Maybe<{
    __typename: 'Gateway';
    id: string;
    data: Maybe<
      Array<{ __typename: 'GatewayData' } & GatewayStatusDataDataFragment>
    >;
  }>;
};

export type GatewayStatusWeatherDataQueryVariables = {
  gatewayId: Scalars['String'];
  start?: Maybe<Scalars['Float']>;
};

export type GatewayStatusWeatherDataQuery = {
  __typename: 'Query';
  gateway: Maybe<{
    __typename: 'Gateway';
    id: string;
    data: Maybe<
      Array<{ __typename: 'GatewayData' } & GatewayStatusDataDataFragment>
    >;
  }>;
  sensor: Maybe<{
    __typename: 'Sensor';
    id: string;
    dataView: Maybe<{
      __typename: 'SensorDataView';
      weather: Array<{
        __typename: 'WeatherStationData';
        time: number;
        hum: number;
        mWindSpd: number;
        rnCnt: number;
        rnRate: number;
        rnTotal: number;
        solarRad: number;
        temp: number;
        windSpd: number;
        windErr: Maybe<boolean>;
        rnRateErr: Maybe<boolean>;
        solarRadErr: Maybe<boolean>;
        tempErr: Maybe<boolean>;
        mWindErr: Maybe<boolean>;
        humErr: Maybe<boolean>;
        rnCntErr: Maybe<boolean>;
      }>;
    }>;
  }>;
};

export type GatewayStatusPressureDataQueryVariables = {
  gatewayId: Scalars['String'];
  start?: Maybe<Scalars['Float']>;
};

export type GatewayStatusPressureDataQuery = {
  __typename: 'Query';
  gateway: Maybe<{
    __typename: 'Gateway';
    id: string;
    data: Maybe<
      Array<{ __typename: 'GatewayData' } & GatewayStatusDataDataFragment>
    >;
  }>;
  sensor: Maybe<{
    __typename: 'Sensor';
    id: string;
    dataView: Maybe<{
      __typename: 'SensorDataView';
      pressure: Array<{
        __typename: 'SensorPressureData';
        t: number;
        avgP: number;
        rt: number;
      }>;
    }>;
  }>;
};

export type GatewayCommandSendQueryVariables = {
  gatewayId?: Maybe<Scalars['String']>;
};

export type GatewayCommandSendQuery = {
  __typename: 'Query';
  gatewaySimcomLibrary: Array<string>;
  gateway: Maybe<{
    __typename: 'Gateway';
    id: string;
    cell_signal: Maybe<number>;
    gateway_battery: Maybe<number>;
    gateway_time: Maybe<number>;
    configuration: Maybe<{
      __typename: 'GatewayConfiguration';
      hw_ver: Maybe<string>;
    }>;
  }>;
  gatewaySoftwareVersions: Array<{
    __typename: 'SoftwareVersion';
    name: string;
    hardwareVersions: Array<{
      __typename: 'HardwareVersions';
      name: string;
      variants: Array<string>;
    }>;
  }>;
  gatewayFlashLibrary: Array<{
    __typename: 'FlashLibraryEntry';
    name: string;
    defaultValue: string;
    max: Maybe<string>;
    min: Maybe<string>;
  }>;
};

export type GatewayFlashMutationVariables = {
  input: GatewayFlashInput;
};

export type GatewayFlashMutation = {
  __typename: 'Mutation';
  commandGatewayFlashSend: {
    __typename: 'GatewayFlashResponse';
    success: boolean;
  };
};

export type GatewayReprogramMutationVariables = {
  input: GatewayReprogramInput;
};

export type GatewayReprogramMutation = {
  __typename: 'Mutation';
  commandGatewayReprogramSend: {
    __typename: 'GatewayReprogramResponse';
    success: boolean;
  };
};

export type GatewayResetMutationVariables = {
  input: GatewayResetInput;
};

export type GatewayResetMutation = {
  __typename: 'Mutation';
  commandGatewayResetSend: {
    __typename: 'GatewayResetResponse';
    success: boolean;
  };
};

export type GatewaySimcomMutationVariables = {
  input: GatewaySimcomInput;
};

export type GatewaySimcomMutation = {
  __typename: 'Mutation';
  commandGatewaySimcomSend: {
    __typename: 'GatewaySimcomResponse';
    success: boolean;
  };
};

export type AdminStatusMapQueryVariables = {};

export type AdminStatusMapQuery = {
  __typename: 'Query';
  allSensors: Array<{
    __typename: 'Sensor';
    id: string;
    battery: Maybe<number>;
    gatewayId: Maybe<string>;
    rssi: Maybe<number>;
    inactive: boolean;
    connectionStatus: ConnectionStatus;
    sensor_time: Maybe<number>;
    statusWarnings: Array<HardwareWarningEnum>;
    owner: Maybe<{
      __typename: 'HardwareUser';
      id: string;
      name: Maybe<{
        __typename: 'Name';
        first: Maybe<string>;
        last: Maybe<string>;
      }>;
      user: {
        __typename: 'User';
        id: string;
        email: Maybe<string>;
        distributor: Maybe<{
          __typename: 'Distributor';
          id: string;
          email: Maybe<string>;
          name: Maybe<{
            __typename: 'Name';
            first: Maybe<string>;
            last: Maybe<string>;
          }>;
          salesRep: Maybe<{
            __typename: 'SalesRep';
            id: string;
            name: Maybe<string>;
          }>;
        }>;
      };
    }>;
    model: Maybe<{ __typename: 'Model'; hasRainGauge: boolean }>;
    location: Maybe<{ __typename: 'Location'; lat: number; lng: number }>;
  }>;
  allGateways: Array<{
    __typename: 'Gateway';
    id: string;
    gateway_battery: Maybe<number>;
    sensorIds: Array<string>;
    cell_signal: Maybe<number>;
    inactive: boolean;
    connectionStatus: ConnectionStatus;
    gateway_time: Maybe<number>;
    statusWarnings: Array<HardwareWarningEnum>;
    owner: Maybe<{
      __typename: 'HardwareUser';
      id: string;
      name: Maybe<{
        __typename: 'Name';
        first: Maybe<string>;
        last: Maybe<string>;
      }>;
      user: {
        __typename: 'User';
        id: string;
        email: Maybe<string>;
        distributor: Maybe<{
          __typename: 'Distributor';
          id: string;
          email: Maybe<string>;
          name: Maybe<{
            __typename: 'Name';
            first: Maybe<string>;
            last: Maybe<string>;
          }>;
          salesRep: Maybe<{
            __typename: 'SalesRep';
            id: string;
            name: Maybe<string>;
          }>;
        }>;
      };
    }>;
    model: Maybe<{
      __typename: 'Model';
      hasRainGauge: boolean;
      hasWeatherStation: boolean;
    }>;
    location: Maybe<{ __typename: 'Location'; lat: number; lng: number }>;
  }>;
};

export type SensorStatusQueryVariables = {
  sensorId: Scalars['String'];
};

export type SensorStatusQuery = {
  __typename: 'Query';
  sensor: Maybe<{
    __typename: 'Sensor';
    id: string;
    battery: Maybe<number>;
    persistence: Maybe<number>;
    inactive: boolean;
    rssi: Maybe<number>;
    statusWarnings: Array<HardwareWarningEnum>;
    name: string;
    gpsUpdated: Maybe<number>;
    gpsLocked: Maybe<boolean>;
    configurationUpdated: Maybe<number>;
    field: Maybe<{ __typename: 'Field'; id: string; name: string }>;
    gateway: Maybe<{
      __typename: 'Gateway';
      id: string;
      connectionStatus: ConnectionStatus;
      sensorIds: Array<string>;
      sensors: Array<{
        __typename: 'Sensor';
        id: string;
        connectionStatus: ConnectionStatus;
      }>;
    }>;
    crop_type: Maybe<{ __typename: 'Crop'; id: string; name: string }>;
    soil_type: Maybe<{ __typename: 'Soil'; id: string; name: string }>;
    location: Maybe<{ __typename: 'Location'; lat: number; lng: number }>;
    owner: Maybe<{
      __typename: 'HardwareUser';
      id: string;
      accountEmail: Maybe<string>;
      name: Maybe<{
        __typename: 'Name';
        first: Maybe<string>;
        last: Maybe<string>;
      }>;
      user: {
        __typename: 'User';
        id: string;
        hasAccountIssues: Maybe<boolean>;
        logins: Array<Maybe<string>>;
        activationCompleted: Maybe<boolean>;
        phone: Maybe<string>;
        sensors: Array<{ __typename: 'Sensor'; id: string; active: boolean }>;
        gateways: Array<{ __typename: 'Gateway'; id: string; active: boolean }>;
        distributor: Maybe<{
          __typename: 'Distributor';
          id: string;
          name: Maybe<{
            __typename: 'Name';
            first: Maybe<string>;
            last: Maybe<string>;
          }>;
          salesRep: Maybe<{
            __typename: 'SalesRep';
            id: string;
            name: Maybe<string>;
          }>;
        }>;
      };
    }>;
    users: Array<{
      __typename: 'HardwareUser';
      id: string;
      user: {
        __typename: 'User';
        id: string;
        email: Maybe<string>;
        name: { __typename: 'Name'; first: Maybe<string>; last: Maybe<string> };
      };
    }>;
    configuration: Maybe<{
      __typename: 'SensorConfiguration';
      ack_timeout: Maybe<number>;
      boot: Maybe<boolean>;
      freq: Maybe<number>;
      historical_enable: Maybe<boolean>;
      hw_ver: Maybe<string>;
      missed_message_sleep: Maybe<number>;
      rpt_int: Maybe<number>;
      sensor_var: Maybe<string>;
      sw_ver: Maybe<string>;
      t_sleep: Maybe<number>;
      tx_high_threshold: Maybe<number>;
      tx_low_threshold: Maybe<number>;
      tx_power: Maybe<number>;
    }>;
    serviceLogs: Array<{
      __typename: 'ServiceLog';
      name: string;
      date: number;
      location: string;
      reason: string;
      result: string;
      timeSpent: string;
      followUpTasks: string;
      id: string;
    }>;
  }>;
};

export type SensorStatusDataQueryVariables = {
  sensorId: Scalars['String'];
};

export type SensorStatusDataQuery = {
  __typename: 'Query';
  sensor: Maybe<{
    __typename: 'Sensor';
    id: string;
    data: Maybe<
      Array<{
        __typename: 'SensorData';
        adc: Maybe<Array<Maybe<number>>>;
        gateway: Maybe<string>;
        meas_no: Maybe<number>;
        moistures: Maybe<Array<Maybe<number>>>;
        rssi: Maybe<number>;
        temp: Maybe<Array<number>>;
        tensions: Maybe<Array<Maybe<number>>>;
        tensionsWithTemp: Maybe<Array<Maybe<number>>>;
        time: number;
        vin: Maybe<number>;
        bootCounter: Maybe<number>;
        bootLimitCounter: Maybe<number>;
        pwrOnRstCounter: Maybe<number>;
        brnOutRstCounter: Maybe<number>;
        extRstCounter: Maybe<number>;
        wdtRstCounter: Maybe<number>;
        sessionRetry: Maybe<number>;
        sessionFail: Maybe<number>;
        historicalMessages: Maybe<number>;
        raw: string;
        vic: Maybe<Array<Maybe<number>>>;
        vpp: Maybe<number>;
        nonsens: Maybe<number>;
      }>
    >;
  }>;
};

export type SensorCommandSendQueryVariables = {
  sensorId: Scalars['String'];
};

export type SensorCommandSendQuery = {
  __typename: 'Query';
  sensor: Maybe<{
    __typename: 'Sensor';
    id: string;
    battery: Maybe<number>;
    rssi: Maybe<number>;
    sensor_time: Maybe<number>;
    configuration: Maybe<{
      __typename: 'SensorConfiguration';
      hw_ver: Maybe<string>;
    }>;
  }>;
  sensorSoftwareVersions: Array<{
    __typename: 'SoftwareVersion';
    name: string;
    hardwareVersions: Array<{
      __typename: 'HardwareVersions';
      name: string;
      variants: Array<string>;
    }>;
  }>;
  sensorFlashLibrary: Array<{
    __typename: 'FlashLibraryEntry';
    name: string;
    defaultValue: string;
    max: Maybe<string>;
    min: Maybe<string>;
  }>;
};

export type SensorEditMutationVariables = {
  input: SensorEditInput;
};

export type SensorEditMutation = {
  __typename: 'Mutation';
  commandSensorEditSend: { __typename: 'SensorEditResponse'; success: boolean };
};

export type SensorReprogramMutationVariables = {
  input: SensorReprogramInput;
};

export type SensorReprogramMutation = {
  __typename: 'Mutation';
  commandSensorReprogramSend: {
    __typename: 'SensorReprogramResponse';
    success: boolean;
  };
};

export type SensorResetMutationVariables = {
  input: SensorResetInput;
};

export type SensorResetMutation = {
  __typename: 'Mutation';
  commandSensorResetSend: {
    __typename: 'SensorResetResponse';
    success: boolean;
  };
};

export type SensorRetryMutationVariables = {
  input: SensorRetryInput;
};

export type SensorRetryMutation = {
  __typename: 'Mutation';
  commandSensorRetrySend: {
    __typename: 'SensorRetryResponse';
    success: boolean;
  };
};

export type RegisterStripeAccountMutationVariables = {
  input: DistributorStripeRegisterInput;
};

export type RegisterStripeAccountMutation = {
  __typename: 'Mutation';
  distributorStripeRegister: {
    __typename: 'DistributorStripeRegisterResponse';
    success: boolean;
  };
};

export type BillingQueryVariables = {};

export type BillingQuery = {
  __typename: 'Query';
  currentDistributor: {
    __typename: 'Distributor';
    id: string;
    email: Maybe<string>;
    name: Maybe<{
      __typename: 'Name';
      first: Maybe<string>;
      last: Maybe<string>;
    }>;
    stripeInfo: Maybe<{
      __typename: 'DistributorStripeInfo';
      stripeURL: string;
    }>;
  };
};

export type GetBindOrderQueryVariables = {
  orderId: Scalars['Int'];
};

export type GetBindOrderQuery = {
  __typename: 'Query';
  order: Maybe<{
    __typename: 'Order';
    id: number;
    status: OrderStatus;
    boundIds: Array<string>;
    details: {
      __typename: 'OrderDetails';
      hardware: Array<{
        __typename: 'OrderDetailsHardware';
        id: Maybe<string>;
        quantity: number;
        model: { __typename: 'Model' } & ModelFieldsFragment;
      }>;
    };
    unboundHardware: Array<{
      __typename: 'OrderUnboundHardware';
      id: string;
      model: { __typename: 'Model' } & ModelFieldsFragment;
    }>;
  }>;
};

export type BindOrderMutationVariables = {
  input: BindOrderInput;
};

export type BindOrderMutation = {
  __typename: 'Mutation';
  bindOrder: Maybe<{ __typename: 'BindOrderResponse'; id: Maybe<number> }>;
};

export type ShipOrderMutationVariables = {
  input: ShipOrderInput;
};

export type ShipOrderMutation = {
  __typename: 'Mutation';
  shipOrder: Maybe<{ __typename: 'ShipOrderResponse'; id: Maybe<number> }>;
};

export type EditHardwareQueryVariables = {
  customerId: Scalars['String'];
};

export type EditHardwareQuery = {
  __typename: 'Query';
  customer: Maybe<{
    __typename: 'Customer';
    id: string;
    name: Maybe<{
      __typename: 'Name';
      first: Maybe<string>;
      last: Maybe<string>;
    }>;
    user: {
      __typename: 'User';
      id: string;
      sensors: Array<{
        __typename: 'Sensor';
        id: string;
        soil_type: Maybe<{ __typename: 'Soil'; id: string; name: string }>;
        crop_type: Maybe<{ __typename: 'Crop'; id: string; name: string }>;
        model: Maybe<{
          __typename: 'Model';
          type: HardwareTypeEnum;
          antennaLength: Maybe<number>;
          moistureDepths: Maybe<Array<number>>;
        }>;
        order: Maybe<{
          __typename: 'Order';
          id: number;
          placedTimestamp: number;
        }>;
      }>;
      gateways: Array<{
        __typename: 'Gateway';
        id: string;
        model: Maybe<{
          __typename: 'Model';
          type: HardwareTypeEnum;
          antennaLength: Maybe<number>;
        }>;
        order: Maybe<{
          __typename: 'Order';
          id: number;
          placedTimestamp: number;
        }>;
      }>;
    };
  }>;
  soils: Array<{ __typename: 'Soil'; id: string; name: string }>;
  crops: Array<{ __typename: 'Crop'; id: string; name: string }>;
  currentDistributor: {
    __typename: 'Distributor';
    id: string;
    unassigned: {
      __typename: 'DistributorUnassigned';
      sensors: Array<{
        __typename: 'Sensor';
        id: string;
        name: string;
        model: Maybe<{
          __typename: 'Model';
          type: HardwareTypeEnum;
          moistureDepths: Maybe<Array<number>>;
          antennaLength: Maybe<number>;
        }>;
        order: Maybe<{
          __typename: 'Order';
          id: number;
          placedTimestamp: number;
        }>;
        soil_type: Maybe<{ __typename: 'Soil'; id: string; name: string }>;
        crop_type: Maybe<{ __typename: 'Crop'; id: string; name: string }>;
      }>;
      gateways: Array<{
        __typename: 'Gateway';
        id: string;
        name: string;
        model: Maybe<{
          __typename: 'Model';
          type: HardwareTypeEnum;
          moistureDepths: Maybe<Array<number>>;
          antennaLength: Maybe<number>;
        }>;
        order: Maybe<{
          __typename: 'Order';
          id: number;
          placedTimestamp: number;
        }>;
      }>;
    };
  };
};

export type EditCustomerHardwareMutationVariables = {
  input: EditCustomerHardwareInputType;
};

export type EditCustomerHardwareMutation = {
  __typename: 'Mutation';
  editCustomerHardware: Maybe<{
    __typename: 'EditCustomerHardwareResponse';
    id: Maybe<string>;
  }>;
};

export type UpdateSensorMutationVariables = {
  sensorId: Scalars['String'];
  input: UpdateSensorInput;
};

export type UpdateSensorMutation = {
  __typename: 'Mutation';
  updateSensor: Maybe<{
    __typename: 'SensorMutationResponse';
    sensorId: Maybe<string>;
  }>;
};

export type SendVerificationEmailMutationVariables = {
  input: SendVerificationEmailInput;
};

export type SendVerificationEmailMutation = {
  __typename: 'Mutation';
  sendVerificationEmail: Maybe<{
    __typename: 'RegistrationMutationResponse';
    success: boolean;
  }>;
};

export type ChangePasswordMutationVariables = {
  input: DistributorChangePasswordInput;
};

export type ChangePasswordMutation = {
  __typename: 'Mutation';
  changeDistributorPassword: Maybe<{
    __typename: 'DistributorChangePasswordResponse';
    id: Maybe<string>;
  }>;
};

export type SignTCMutationVariables = {
  input: DistributorSignTCInput;
};

export type SignTCMutation = {
  __typename: 'Mutation';
  distributorSignTC: Maybe<{
    __typename: 'DistributorSignTC';
    id: Maybe<string>;
  }>;
};

export type CustomerQueryVariables = {
  customerId: Scalars['String'];
};

export type CustomerQuery = {
  __typename: 'Query';
  customer: Maybe<{
    __typename: 'Customer';
    id: string;
    email: Maybe<string>;
    phone: Maybe<string>;
    repId: Maybe<string>;
    name: Maybe<{
      __typename: 'Name';
      first: Maybe<string>;
      last: Maybe<string>;
    }>;
    addresses: Array<{
      __typename: 'Address';
      id: string;
      city: Maybe<string>;
      state: Maybe<string>;
      zip: Maybe<string>;
      street: Maybe<string>;
      name: Maybe<{
        __typename: 'Name';
        first: Maybe<string>;
        last: Maybe<string>;
      }>;
    }>;
    user: {
      __typename: 'User';
      id: string;
      activated: Maybe<boolean>;
      activationCompleted: Maybe<boolean>;
      prepaid: Maybe<boolean>;
      prepaidStart: Maybe<number>;
      prepaidUntil: Maybe<number>;
      managedServiceStatuses: Array<{
        __typename: 'ManagedServiceStatus';
        initialPaymentMade: boolean;
        invoiceUrl: Maybe<string>;
        orderId: number;
        subscriptionStarted: boolean;
      }>;
      sensors: Array<{
        __typename: 'Sensor';
        id: string;
        isOwner: boolean;
        name: string;
        activePlan: Maybe<SensorDataPlan>;
        soil_type: Maybe<{ __typename: 'Soil'; id: string; name: string }>;
        crop_type: Maybe<{ __typename: 'Crop'; id: string; name: string }>;
        model: Maybe<{
          __typename: 'Model';
          type: HardwareTypeEnum;
          antennaLength: Maybe<number>;
          moistureDepths: Maybe<Array<number>>;
        }>;
        order: Maybe<{
          __typename: 'Order';
          id: number;
          placedTimestamp: number;
        }>;
      }>;
      gateways: Array<{
        __typename: 'Gateway';
        id: string;
        isOwner: boolean;
        activePlan: Maybe<SensorDataPlan>;
        hasRainGauge: boolean;
        model: Maybe<{
          __typename: 'Model';
          type: HardwareTypeEnum;
          antennaLength: Maybe<number>;
        }>;
        order: Maybe<{
          __typename: 'Order';
          id: number;
          placedTimestamp: number;
        }>;
      }>;
      fields: Array<{
        __typename: 'Field';
        id: string;
        name: string;
        sensors: Array<{
          __typename: 'Sensor';
          id: string;
          crop_variety: Maybe<string>;
          crop_type: Maybe<{ __typename: 'Crop'; id: string; name: string }>;
        }>;
        irrigationMetadata: {
          __typename: 'IrrigationMetadata';
          irrigationType: Maybe<string>;
        };
      }>;
    };
  }>;
  currentDistributor: { __typename: 'Distributor'; id: string };
};

export type StartManagedServiceSubscriptionMutationVariables = {
  input: StartManagedServiceInput;
};

export type StartManagedServiceSubscriptionMutation = {
  __typename: 'Mutation';
  startManagedService: {
    __typename: 'StartManagedServiceResponse';
    success: boolean;
  };
};

export type CustomerRepInfoQueryVariables = {
  repId: Scalars['String'];
};

export type CustomerRepInfoQuery = {
  __typename: 'Query';
  rep: Maybe<{
    __typename: 'Distributor';
    id: string;
    name: Maybe<{
      __typename: 'Name';
      first: Maybe<string>;
      last: Maybe<string>;
    }>;
  }>;
};

export type RemoveCustomerMutationVariables = {
  input: RemoveCustomerInput;
};

export type RemoveCustomerMutation = {
  __typename: 'Mutation';
  removeCustomer: Maybe<{
    __typename: 'RemoveCustomerResponse';
    id: Maybe<string>;
  }>;
};

export type ActivateCustomerMutationVariables = {
  input: ActivateCustomerInput;
};

export type ActivateCustomerMutation = {
  __typename: 'Mutation';
  activateCustomer: Maybe<{
    __typename: 'ActivateCustomerResponse';
    userId: Maybe<string>;
  }>;
};

export type ResendActivationEmailMutationVariables = {
  customerId: Scalars['String'];
};

export type ResendActivationEmailMutation = {
  __typename: 'Mutation';
  activationResendEmail: boolean;
};

export type FastCustomersQueryVariables = {
  first: Scalars['Int'];
  after?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
};

export type FastCustomersQuery = {
  __typename: 'Query';
  currentDistributor: {
    __typename: 'Distributor';
    id: string;
    fastCustomers: {
      __typename: 'CustomerConnection';
      edges: Array<{
        __typename: 'CustomerEdge';
        cursor: string;
        node: {
          __typename: 'Customer';
          email: Maybe<string>;
          id: string;
          phone: Maybe<string>;
          name: Maybe<{
            __typename: 'Name';
            first: Maybe<string>;
            last: Maybe<string>;
          }>;
          user: {
            __typename: 'User';
            id: string;
            gateways: Array<{ __typename: 'Gateway'; id: string }>;
            sensors: Array<{ __typename: 'Sensor'; id: string }>;
          };
        };
      }>;
      pageInfo: {
        __typename: 'PageInfo';
        endCursor: Maybe<string>;
        hasNextPage: boolean;
      };
    };
  };
};

export type OrderDetailsQueryVariables = {
  customerId?: Maybe<Scalars['String']>;
  customer: Scalars['Boolean'];
};

export type OrderDetailsQuery = {
  __typename: 'Query';
  customer: Maybe<{
    __typename: 'Customer';
    id: string;
    email: Maybe<string>;
    name: Maybe<{
      __typename: 'Name';
      first: Maybe<string>;
      last: Maybe<string>;
    }>;
  }>;
  currentDistributor: {
    __typename: 'Distributor';
    id: string;
    pricing: {
      __typename: 'Pricing';
      id: string;
      hardware: Array<{
        __typename: 'PricingHardware';
        modelId: string;
        prices: Array<{
          __typename: 'PricingHardwarePrices';
          quantity: number;
          price: number;
        }>;
      }>;
    };
    reps: Array<{
      __typename: 'DistributorRepCopy';
      id: string;
      email: Maybe<string>;
      phone: Maybe<string>;
      name: Maybe<{
        __typename: 'Name';
        first: Maybe<string>;
        last: Maybe<string>;
      }>;
      addresses: Array<{
        __typename: 'Address';
        id: string;
        street: Maybe<string>;
        city: Maybe<string>;
        state: Maybe<string>;
        zip: Maybe<string>;
        name: Maybe<{
          __typename: 'Name';
          first: Maybe<string>;
          last: Maybe<string>;
        }>;
      }>;
    }>;
  };
};

export type AddOrderMutationVariables = {
  input: AddOrderInput;
};

export type AddOrderMutation = {
  __typename: 'Mutation';
  addOrder: Maybe<{ __typename: 'AddOrderResponse'; id: Maybe<number> }>;
};

export type DistributorBillingStatusQueryVariables = {};

export type DistributorBillingStatusQuery = {
  __typename: 'Query';
  currentDistributor: {
    __typename: 'Distributor';
    id: string;
    stripeInfo: Maybe<{
      __typename: 'DistributorStripeInfo';
      stripeURL: string;
    }>;
  };
};

export type RepsQueryVariables = {};

export type RepsQuery = {
  __typename: 'Query';
  currentDistributor: {
    __typename: 'Distributor';
    id: string;
    reps: Array<{
      __typename: 'DistributorRepCopy';
      id: string;
      email: Maybe<string>;
      phone: Maybe<string>;
      name: Maybe<{
        __typename: 'Name';
        first: Maybe<string>;
        last: Maybe<string>;
      }>;
      addresses: Array<{
        __typename: 'Address';
        id: string;
        street: Maybe<string>;
        city: Maybe<string>;
        state: Maybe<string>;
        zip: Maybe<string>;
        name: Maybe<{
          __typename: 'Name';
          first: Maybe<string>;
          last: Maybe<string>;
        }>;
      }>;
    }>;
    customers: Array<{
      __typename: 'Customer';
      id: string;
      repId: Maybe<string>;
      email: Maybe<string>;
      phone: Maybe<string>;
      name: Maybe<{
        __typename: 'Name';
        first: Maybe<string>;
        last: Maybe<string>;
      }>;
    }>;
  };
};

export type AddRepAddressMutationVariables = {
  input: AddRepAddressInput;
};

export type AddRepAddressMutation = {
  __typename: 'Mutation';
  addRepAddress: Maybe<{
    __typename: 'AddRepAddressResponse';
    id: Maybe<string>;
  }>;
};

export type PricingQueryVariables = {};

export type PricingQuery = {
  __typename: 'Query';
  currentDistributor: {
    __typename: 'Distributor';
    id: string;
    pricing: {
      __typename: 'Pricing';
      id: string;
      hardware: Array<{
        __typename: 'PricingHardware';
        modelId: string;
        prices: Array<{
          __typename: 'PricingHardwarePrices';
          quantity: number;
          price: number;
        }>;
      }>;
    };
  };
};

export type OrdersQueryVariables = {};

export type OrdersQuery = {
  __typename: 'Query';
  currentDistributor: {
    __typename: 'Distributor';
    id: string;
    isDistributor: Maybe<boolean>;
    orders: Array<{
      __typename: 'Order';
      id: number;
      approved: boolean;
      placedTimestamp: number;
      status: OrderStatus;
      details: {
        __typename: 'OrderDetails';
        hardware: Array<{
          __typename: 'OrderDetailsHardware';
          id: Maybe<string>;
          quantity: number;
        }>;
        customer: Maybe<{
          __typename: 'OrderDetailsCustomerCopy';
          id: string;
          name: Maybe<{
            __typename: 'Name';
            first: Maybe<string>;
            last: Maybe<string>;
          }>;
        }>;
        rep: Maybe<{
          __typename: 'OrderDetailsRepCopy';
          id: string;
          name: Maybe<{
            __typename: 'Name';
            first: Maybe<string>;
            last: Maybe<string>;
          }>;
        }>;
      };
    }>;
  };
};

export type OrdersMockupQueryVariables = {};

export type OrdersMockupQuery = {
  __typename: 'Query';
  currentDistributor: {
    __typename: 'Distributor';
    id: string;
    isDistributor: Maybe<boolean>;
    orders: Array<{
      __typename: 'Order';
      id: number;
      approved: boolean;
      placedTimestamp: number;
      status: OrderStatus;
      shipments: Array<{
        __typename: 'OrderShipment';
        id: number;
        trackingNumber: Maybe<string>;
        carrier: string;
        hardware: Array<{
          __typename: 'OrderDetailsHardware';
          id: Maybe<string>;
          quantity: number;
          boundIds: Array<string>;
          model: {
            __typename: 'Model';
            type: HardwareTypeEnum;
            antennaLength: Maybe<number>;
            moistureDepths: Maybe<Array<number>>;
            temperatureDepths: Maybe<Array<number>>;
            ecDepths: Maybe<Array<number>>;
            hasWeatherStation: boolean;
            hasPressureSensor: boolean;
            hasRainGauge: boolean;
            moistureMethod: Maybe<MoistureMethod>;
            probeless: boolean;
            custom: boolean;
          };
        }>;
      }>;
      unshippedHardware: Array<{
        __typename: 'OrderDetailsHardware';
        id: Maybe<string>;
        quantity: number;
        boundIds: Array<string>;
        model: {
          __typename: 'Model';
          type: HardwareTypeEnum;
          antennaLength: Maybe<number>;
          moistureDepths: Maybe<Array<number>>;
          temperatureDepths: Maybe<Array<number>>;
          ecDepths: Maybe<Array<number>>;
          hasWeatherStation: boolean;
          hasPressureSensor: boolean;
          hasRainGauge: boolean;
          moistureMethod: Maybe<MoistureMethod>;
          probeless: boolean;
          custom: boolean;
        };
      }>;
      details: {
        __typename: 'OrderDetails';
        hardware: Array<{
          __typename: 'OrderDetailsHardware';
          id: Maybe<string>;
          quantity: number;
          boundIds: Array<string>;
          model: {
            __typename: 'Model';
            type: HardwareTypeEnum;
            antennaLength: Maybe<number>;
            moistureDepths: Maybe<Array<number>>;
            temperatureDepths: Maybe<Array<number>>;
            ecDepths: Maybe<Array<number>>;
            hasWeatherStation: boolean;
            hasPressureSensor: boolean;
            hasRainGauge: boolean;
            moistureMethod: Maybe<MoistureMethod>;
            probeless: boolean;
            custom: boolean;
          };
        }>;
        customer: Maybe<{
          __typename: 'OrderDetailsCustomerCopy';
          id: string;
          name: Maybe<{
            __typename: 'Name';
            first: Maybe<string>;
            last: Maybe<string>;
          }>;
        }>;
        rep: Maybe<{
          __typename: 'OrderDetailsRepCopy';
          id: string;
          name: Maybe<{
            __typename: 'Name';
            first: Maybe<string>;
            last: Maybe<string>;
          }>;
        }>;
      };
    }>;
  };
};

export type UserNameQueryVariables = {};

export type UserNameQuery = {
  __typename: 'Query';
  currentDistributor: {
    __typename: 'Distributor';
    id: string;
    isRep: Maybe<boolean>;
    isDistributor: Maybe<boolean>;
    isAdmin: Maybe<boolean>;
    name: Maybe<{
      __typename: 'Name';
      first: Maybe<string>;
      last: Maybe<string>;
    }>;
  };
};

export type SensorStatusFragmentFragment = {
  __typename: 'Sensor';
  id: string;
  name: string;
  rssi: Maybe<number>;
  battery: Maybe<number>;
  current_state: Maybe<number>;
  connectionStatus: ConnectionStatus;
  sensor_time: Maybe<number>;
  gatewayId: Maybe<string>;
  statusWarnings: Array<HardwareWarningEnum>;
  owner: Maybe<{
    __typename: 'HardwareUser';
    id: string;
    name: Maybe<{
      __typename: 'Name';
      first: Maybe<string>;
      last: Maybe<string>;
    }>;
  }>;
  location: Maybe<{ __typename: 'Location'; lat: number; lng: number }>;
};

export type GatewayStatusFragmentFragment = {
  __typename: 'Gateway';
  id: string;
  cell_signal: Maybe<number>;
  gateway_battery: Maybe<number>;
  connectionStatus: ConnectionStatus;
  gateway_time: Maybe<number>;
  sensorIds: Array<string>;
  statusWarnings: Array<HardwareWarningEnum>;
  model: Maybe<{ __typename: 'Model'; type: HardwareTypeEnum }>;
  location: Maybe<{ __typename: 'Location'; lat: number; lng: number }>;
  owner: Maybe<{
    __typename: 'HardwareUser';
    id: string;
    name: Maybe<{
      __typename: 'Name';
      first: Maybe<string>;
      last: Maybe<string>;
    }>;
  }>;
};

export type ActiveQueryVariables = {};

export type ActiveQuery = {
  __typename: 'Query';
  currentDistributor: {
    __typename: 'Distributor';
    id: string;
    unassigned: {
      __typename: 'DistributorUnassigned';
      sensors: Array<
        { __typename: 'Sensor'; id: string } & SensorStatusFragmentFragment
      >;
      gateways: Array<
        { __typename: 'Gateway'; id: string } & GatewayStatusFragmentFragment
      >;
    };
    customers: Array<{
      __typename: 'Customer';
      id: string;
      name: Maybe<{
        __typename: 'Name';
        first: Maybe<string>;
        last: Maybe<string>;
      }>;
      user: {
        __typename: 'User';
        id: string;
        sensors: Array<
          { __typename: 'Sensor'; id: string } & SensorStatusFragmentFragment
        >;
        gateways: Array<
          { __typename: 'Gateway'; id: string } & GatewayStatusFragmentFragment
        >;
      };
    }>;
  };
};

export type ModelFieldsFragment = {
  __typename: 'Model';
  antennaLength: Maybe<number>;
  moistureDepths: Maybe<Array<number>>;
  temperatureDepths: Maybe<Array<number>>;
  ecDepths: Maybe<Array<number>>;
  hasWeatherStation: boolean;
  hasPressureSensor: boolean;
  hasRainGauge: boolean;
  moistureMethod: Maybe<MoistureMethod>;
  type: HardwareTypeEnum;
  probeless: boolean;
  custom: boolean;
};

export const sensorInfoFragmentDoc = gql`
  fragment sensorInfo on Sensor {
    id
    name
    moistureMethod
    owner {
      id
      name {
        first
        last
      }
    }
    users {
      id
      name {
        first
        last
      }
      permissionLevel
    }
    order {
      id
      details {
        rep {
          id
          name {
            first
            last
          }
        }
      }
    }
  }
`;
export const gatewayInfoFragmentDoc = gql`
  fragment gatewayInfo on Gateway {
    id
    owner {
      id
      name {
        first
        last
      }
    }
    users {
      id
      name {
        first
        last
      }
      permissionLevel
    }
    order {
      id
      details {
        rep {
          id
          name {
            first
            last
          }
        }
      }
    }
  }
`;
export const AllServiceLogFragmentDoc = gql`
  fragment AllServiceLog on ServiceLog {
    name
    date
    location
    reason
    result
    timeSpent
    followUpTasks
    id
  }
`;
export const UpdateSensorFragmentDoc = gql`
  fragment UpdateSensor on Sensor {
    id
    inactive
  }
`;
export const UpdateGatewayFragmentDoc = gql`
  fragment UpdateGateway on Gateway {
    id
    inactive
  }
`;
export const GatewayStatusDataDataFragmentDoc = gql`
  fragment GatewayStatusDataData on GatewayData {
    cell_signal
    msg
    time
    vbat
    vsol
    rbat
    netOpenRetry
    tcpOpenRetry
    tcpSendRetry
    resetCounter
    failCounter
    rebootCounter
    rxBadCounter
    localCounter
    rebootLimitCounter
    isCharging
    pwrOnRstCounter
    brnOutRstCounter
    extRstCounter
    wdtRstCounter
    tcpFailID
  }
`;
export const SensorStatusFragmentFragmentDoc = gql`
  fragment SensorStatusFragment on Sensor {
    id
    owner {
      id
      name {
        first
        last
      }
    }
    location {
      lat
      lng
    }
    name
    rssi
    battery
    current_state
    connectionStatus
    sensor_time
    gatewayId
    statusWarnings
  }
`;
export const GatewayStatusFragmentFragmentDoc = gql`
  fragment GatewayStatusFragment on Gateway {
    id
    model {
      type
    }
    location {
      lat
      lng
    }
    cell_signal
    gateway_battery
    connectionStatus
    gateway_time
    sensorIds
    statusWarnings
    owner {
      id
      name {
        first
        last
      }
    }
  }
`;
export const ModelFieldsFragmentDoc = gql`
  fragment ModelFields on Model {
    antennaLength
    moistureDepths
    temperatureDepths
    ecDepths
    hasWeatherStation
    hasPressureSensor
    hasRainGauge
    moistureMethod
    type
    probeless
    custom
  }
`;
export const IdentityDocument = gql`
  query Identity {
    currentDistributor {
      id
      isDistributor
      isAdmin
    }
  }
`;
export type IdentityComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    IdentityQuery,
    IdentityQueryVariables
  >,
  'query'
>;

export const IdentityComponent = (props: IdentityComponentProps) => (
  <ApolloReactComponents.Query<IdentityQuery, IdentityQueryVariables>
    query={IdentityDocument}
    {...props}
  />
);

/**
 * __useIdentityQuery__
 *
 * To run a query within a React component, call `useIdentityQuery` and pass it any options that fit your needs.
 * When your component renders, `useIdentityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIdentityQuery({
 *   variables: {
 *   },
 * });
 */
export function useIdentityQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    IdentityQuery,
    IdentityQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<IdentityQuery, IdentityQueryVariables>(
    IdentityDocument,
    baseOptions
  );
}
export function useIdentityLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IdentityQuery,
    IdentityQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<IdentityQuery, IdentityQueryVariables>(
    IdentityDocument,
    baseOptions
  );
}
export type IdentityQueryHookResult = ReturnType<typeof useIdentityQuery>;
export type IdentityLazyQueryHookResult = ReturnType<
  typeof useIdentityLazyQuery
>;
export type IdentityQueryResult = ApolloReactCommon.QueryResult<
  IdentityQuery,
  IdentityQueryVariables
>;
export const AnalyticsInfoDocument = gql`
  query AnalyticsInfo {
    me {
      id
      accountEmail
      name {
        first
        last
      }
    }
  }
`;
export type AnalyticsInfoComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    AnalyticsInfoQuery,
    AnalyticsInfoQueryVariables
  >,
  'query'
>;

export const AnalyticsInfoComponent = (props: AnalyticsInfoComponentProps) => (
  <ApolloReactComponents.Query<AnalyticsInfoQuery, AnalyticsInfoQueryVariables>
    query={AnalyticsInfoDocument}
    {...props}
  />
);

/**
 * __useAnalyticsInfoQuery__
 *
 * To run a query within a React component, call `useAnalyticsInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnalyticsInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnalyticsInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useAnalyticsInfoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AnalyticsInfoQuery,
    AnalyticsInfoQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    AnalyticsInfoQuery,
    AnalyticsInfoQueryVariables
  >(AnalyticsInfoDocument, baseOptions);
}
export function useAnalyticsInfoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AnalyticsInfoQuery,
    AnalyticsInfoQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    AnalyticsInfoQuery,
    AnalyticsInfoQueryVariables
  >(AnalyticsInfoDocument, baseOptions);
}
export type AnalyticsInfoQueryHookResult = ReturnType<
  typeof useAnalyticsInfoQuery
>;
export type AnalyticsInfoLazyQueryHookResult = ReturnType<
  typeof useAnalyticsInfoLazyQuery
>;
export type AnalyticsInfoQueryResult = ApolloReactCommon.QueryResult<
  AnalyticsInfoQuery,
  AnalyticsInfoQueryVariables
>;
export const DeleteFieldDocument = gql`
  mutation DeleteField($input: DeleteFieldInput!) {
    deleteField(input: $input) {
      success
    }
  }
`;
export type DeleteFieldMutationFn = ApolloReactCommon.MutationFunction<
  DeleteFieldMutation,
  DeleteFieldMutationVariables
>;
export type DeleteFieldComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteFieldMutation,
    DeleteFieldMutationVariables
  >,
  'mutation'
>;

export const DeleteFieldComponent = (props: DeleteFieldComponentProps) => (
  <ApolloReactComponents.Mutation<
    DeleteFieldMutation,
    DeleteFieldMutationVariables
  >
    mutation={DeleteFieldDocument}
    {...props}
  />
);

/**
 * __useDeleteFieldMutation__
 *
 * To run a mutation, you first call `useDeleteFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFieldMutation, { data, loading, error }] = useDeleteFieldMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteFieldMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteFieldMutation,
    DeleteFieldMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DeleteFieldMutation,
    DeleteFieldMutationVariables
  >(DeleteFieldDocument, baseOptions);
}
export type DeleteFieldMutationHookResult = ReturnType<
  typeof useDeleteFieldMutation
>;
export type DeleteFieldMutationResult = ApolloReactCommon.MutationResult<
  DeleteFieldMutation
>;
export type DeleteFieldMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteFieldMutation,
  DeleteFieldMutationVariables
>;
export const OrderDocument = gql`
  query Order($orderId: Int!) {
    order(orderId: $orderId) {
      id
      placedTimestamp
      notes
      sharingEmails
      zohoSalesOrder {
        invoice {
          due
          paymentUrl
          status
        }
      }
      prepaid {
        plan
        months
        start
      }
      details {
        customer {
          id
          email
          name {
            first
            last
          }
        }
        hardware {
          id
          model {
            ...ModelFields
          }
          pricePerUnit
          quantity
          type
        }
        address {
          id
          name {
            first
            last
          }
          street
          city
          state
          zip
        }
        rep {
          id
          email
          phone
          name {
            first
            last
          }
        }
      }
      managedServiceOptions {
        months
        prepaid
        price
      }
    }
  }
  ${ModelFieldsFragmentDoc}
`;
export type OrderComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<OrderQuery, OrderQueryVariables>,
  'query'
> &
  ({ variables: OrderQueryVariables; skip?: boolean } | { skip: boolean });

export const OrderComponent = (props: OrderComponentProps) => (
  <ApolloReactComponents.Query<OrderQuery, OrderQueryVariables>
    query={OrderDocument}
    {...props}
  />
);

/**
 * __useOrderQuery__
 *
 * To run a query within a React component, call `useOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useOrderQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    OrderQuery,
    OrderQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<OrderQuery, OrderQueryVariables>(
    OrderDocument,
    baseOptions
  );
}
export function useOrderLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OrderQuery,
    OrderQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<OrderQuery, OrderQueryVariables>(
    OrderDocument,
    baseOptions
  );
}
export type OrderQueryHookResult = ReturnType<typeof useOrderQuery>;
export type OrderLazyQueryHookResult = ReturnType<typeof useOrderLazyQuery>;
export type OrderQueryResult = ApolloReactCommon.QueryResult<
  OrderQuery,
  OrderQueryVariables
>;
export const GetSensorLogsDocument = gql`
  query GetSensorLogs($id: String!) {
    sensor(admin: true, id: $id) {
      id
      serviceLogs {
        id
      }
    }
  }
`;
export type GetSensorLogsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetSensorLogsQuery,
    GetSensorLogsQueryVariables
  >,
  'query'
> &
  (
    | { variables: GetSensorLogsQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const GetSensorLogsComponent = (props: GetSensorLogsComponentProps) => (
  <ApolloReactComponents.Query<GetSensorLogsQuery, GetSensorLogsQueryVariables>
    query={GetSensorLogsDocument}
    {...props}
  />
);

/**
 * __useGetSensorLogsQuery__
 *
 * To run a query within a React component, call `useGetSensorLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSensorLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSensorLogsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSensorLogsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetSensorLogsQuery,
    GetSensorLogsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetSensorLogsQuery,
    GetSensorLogsQueryVariables
  >(GetSensorLogsDocument, baseOptions);
}
export function useGetSensorLogsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetSensorLogsQuery,
    GetSensorLogsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetSensorLogsQuery,
    GetSensorLogsQueryVariables
  >(GetSensorLogsDocument, baseOptions);
}
export type GetSensorLogsQueryHookResult = ReturnType<
  typeof useGetSensorLogsQuery
>;
export type GetSensorLogsLazyQueryHookResult = ReturnType<
  typeof useGetSensorLogsLazyQuery
>;
export type GetSensorLogsQueryResult = ApolloReactCommon.QueryResult<
  GetSensorLogsQuery,
  GetSensorLogsQueryVariables
>;
export const GetGatewayLogsDocument = gql`
  query GetGatewayLogs($id: String!) {
    gateway(admin: true, id: $id) {
      id
      serviceLogs {
        id
      }
    }
  }
`;
export type GetGatewayLogsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetGatewayLogsQuery,
    GetGatewayLogsQueryVariables
  >,
  'query'
> &
  (
    | { variables: GetGatewayLogsQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const GetGatewayLogsComponent = (
  props: GetGatewayLogsComponentProps
) => (
  <ApolloReactComponents.Query<
    GetGatewayLogsQuery,
    GetGatewayLogsQueryVariables
  >
    query={GetGatewayLogsDocument}
    {...props}
  />
);

/**
 * __useGetGatewayLogsQuery__
 *
 * To run a query within a React component, call `useGetGatewayLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGatewayLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGatewayLogsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetGatewayLogsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetGatewayLogsQuery,
    GetGatewayLogsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetGatewayLogsQuery,
    GetGatewayLogsQueryVariables
  >(GetGatewayLogsDocument, baseOptions);
}
export function useGetGatewayLogsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetGatewayLogsQuery,
    GetGatewayLogsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetGatewayLogsQuery,
    GetGatewayLogsQueryVariables
  >(GetGatewayLogsDocument, baseOptions);
}
export type GetGatewayLogsQueryHookResult = ReturnType<
  typeof useGetGatewayLogsQuery
>;
export type GetGatewayLogsLazyQueryHookResult = ReturnType<
  typeof useGetGatewayLogsLazyQuery
>;
export type GetGatewayLogsQueryResult = ApolloReactCommon.QueryResult<
  GetGatewayLogsQuery,
  GetGatewayLogsQueryVariables
>;
export const CreateLogDocument = gql`
  mutation CreateLog($input: ServiceLogCreateInput!) {
    serviceLogCreate(input: $input) {
      success
      log {
        name
        date
        location
        reason
        result
        odometerTo
        odometerFrom
        timeSpent
        followUpTasks
        id
      }
    }
  }
`;
export type CreateLogMutationFn = ApolloReactCommon.MutationFunction<
  CreateLogMutation,
  CreateLogMutationVariables
>;
export type CreateLogComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateLogMutation,
    CreateLogMutationVariables
  >,
  'mutation'
>;

export const CreateLogComponent = (props: CreateLogComponentProps) => (
  <ApolloReactComponents.Mutation<CreateLogMutation, CreateLogMutationVariables>
    mutation={CreateLogDocument}
    {...props}
  />
);

/**
 * __useCreateLogMutation__
 *
 * To run a mutation, you first call `useCreateLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLogMutation, { data, loading, error }] = useCreateLogMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateLogMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateLogMutation,
    CreateLogMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateLogMutation,
    CreateLogMutationVariables
  >(CreateLogDocument, baseOptions);
}
export type CreateLogMutationHookResult = ReturnType<
  typeof useCreateLogMutation
>;
export type CreateLogMutationResult = ApolloReactCommon.MutationResult<
  CreateLogMutation
>;
export type CreateLogMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateLogMutation,
  CreateLogMutationVariables
>;
export const UpdateLogDocument = gql`
  mutation UpdateLog($input: ServiceLogUpdateInput!) {
    serviceLogUpdate(input: $input) {
      success
      log {
        name
        date
        location
        reason
        result
        odometerTo
        odometerFrom
        timeSpent
        followUpTasks
        id
      }
    }
  }
`;
export type UpdateLogMutationFn = ApolloReactCommon.MutationFunction<
  UpdateLogMutation,
  UpdateLogMutationVariables
>;
export type UpdateLogComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateLogMutation,
    UpdateLogMutationVariables
  >,
  'mutation'
>;

export const UpdateLogComponent = (props: UpdateLogComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateLogMutation, UpdateLogMutationVariables>
    mutation={UpdateLogDocument}
    {...props}
  />
);

/**
 * __useUpdateLogMutation__
 *
 * To run a mutation, you first call `useUpdateLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLogMutation, { data, loading, error }] = useUpdateLogMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLogMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateLogMutation,
    UpdateLogMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateLogMutation,
    UpdateLogMutationVariables
  >(UpdateLogDocument, baseOptions);
}
export type UpdateLogMutationHookResult = ReturnType<
  typeof useUpdateLogMutation
>;
export type UpdateLogMutationResult = ApolloReactCommon.MutationResult<
  UpdateLogMutation
>;
export type UpdateLogMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateLogMutation,
  UpdateLogMutationVariables
>;
export const DeleteLogDocument = gql`
  mutation DeleteLog($input: ServiceLogDeleteInput!) {
    serviceLogDelete(input: $input) {
      success
    }
  }
`;
export type DeleteLogMutationFn = ApolloReactCommon.MutationFunction<
  DeleteLogMutation,
  DeleteLogMutationVariables
>;
export type DeleteLogComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteLogMutation,
    DeleteLogMutationVariables
  >,
  'mutation'
>;

export const DeleteLogComponent = (props: DeleteLogComponentProps) => (
  <ApolloReactComponents.Mutation<DeleteLogMutation, DeleteLogMutationVariables>
    mutation={DeleteLogDocument}
    {...props}
  />
);

/**
 * __useDeleteLogMutation__
 *
 * To run a mutation, you first call `useDeleteLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLogMutation, { data, loading, error }] = useDeleteLogMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteLogMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteLogMutation,
    DeleteLogMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DeleteLogMutation,
    DeleteLogMutationVariables
  >(DeleteLogDocument, baseOptions);
}
export type DeleteLogMutationHookResult = ReturnType<
  typeof useDeleteLogMutation
>;
export type DeleteLogMutationResult = ApolloReactCommon.MutationResult<
  DeleteLogMutation
>;
export type DeleteLogMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteLogMutation,
  DeleteLogMutationVariables
>;
export const AddCustomerDocument = gql`
  mutation AddCustomer($input: AddCustomerInput!) {
    addCustomer(input: $input) {
      id
    }
  }
`;
export type AddCustomerMutationFn = ApolloReactCommon.MutationFunction<
  AddCustomerMutation,
  AddCustomerMutationVariables
>;
export type AddCustomerComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    AddCustomerMutation,
    AddCustomerMutationVariables
  >,
  'mutation'
>;

export const AddCustomerComponent = (props: AddCustomerComponentProps) => (
  <ApolloReactComponents.Mutation<
    AddCustomerMutation,
    AddCustomerMutationVariables
  >
    mutation={AddCustomerDocument}
    {...props}
  />
);

/**
 * __useAddCustomerMutation__
 *
 * To run a mutation, you first call `useAddCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCustomerMutation, { data, loading, error }] = useAddCustomerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddCustomerMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddCustomerMutation,
    AddCustomerMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    AddCustomerMutation,
    AddCustomerMutationVariables
  >(AddCustomerDocument, baseOptions);
}
export type AddCustomerMutationHookResult = ReturnType<
  typeof useAddCustomerMutation
>;
export type AddCustomerMutationResult = ApolloReactCommon.MutationResult<
  AddCustomerMutation
>;
export type AddCustomerMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddCustomerMutation,
  AddCustomerMutationVariables
>;
export const AddRepDocument = gql`
  mutation AddRep($input: AddRepInput!) {
    addRep(input: $input) {
      id
    }
  }
`;
export type AddRepMutationFn = ApolloReactCommon.MutationFunction<
  AddRepMutation,
  AddRepMutationVariables
>;
export type AddRepComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    AddRepMutation,
    AddRepMutationVariables
  >,
  'mutation'
>;

export const AddRepComponent = (props: AddRepComponentProps) => (
  <ApolloReactComponents.Mutation<AddRepMutation, AddRepMutationVariables>
    mutation={AddRepDocument}
    {...props}
  />
);

/**
 * __useAddRepMutation__
 *
 * To run a mutation, you first call `useAddRepMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRepMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRepMutation, { data, loading, error }] = useAddRepMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddRepMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddRepMutation,
    AddRepMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<AddRepMutation, AddRepMutationVariables>(
    AddRepDocument,
    baseOptions
  );
}
export type AddRepMutationHookResult = ReturnType<typeof useAddRepMutation>;
export type AddRepMutationResult = ApolloReactCommon.MutationResult<
  AddRepMutation
>;
export type AddRepMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddRepMutation,
  AddRepMutationVariables
>;
export const AddFieldsDataDocument = gql`
  query AddFieldsData($customerId: String!) {
    customer(customerId: $customerId) {
      id
      user {
        id
        sensors {
          id
          name
          model {
            type
            antennaLength
            moistureDepths
            hasRainGauge
          }
          field {
            id
          }
          location {
            lat
            lng
          }
        }
        gateways {
          id
          model {
            type
            antennaLength
            hasRainGauge
          }
          fields {
            id
          }
          location {
            lat
            lng
          }
        }
      }
    }
    soils {
      id
      name
    }
    crops {
      id
      name
    }
    irrigationTypes {
      id
      name
    }
  }
`;
export type AddFieldsDataComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    AddFieldsDataQuery,
    AddFieldsDataQueryVariables
  >,
  'query'
> &
  (
    | { variables: AddFieldsDataQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const AddFieldsDataComponent = (props: AddFieldsDataComponentProps) => (
  <ApolloReactComponents.Query<AddFieldsDataQuery, AddFieldsDataQueryVariables>
    query={AddFieldsDataDocument}
    {...props}
  />
);

/**
 * __useAddFieldsDataQuery__
 *
 * To run a query within a React component, call `useAddFieldsDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddFieldsDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddFieldsDataQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useAddFieldsDataQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AddFieldsDataQuery,
    AddFieldsDataQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    AddFieldsDataQuery,
    AddFieldsDataQueryVariables
  >(AddFieldsDataDocument, baseOptions);
}
export function useAddFieldsDataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AddFieldsDataQuery,
    AddFieldsDataQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    AddFieldsDataQuery,
    AddFieldsDataQueryVariables
  >(AddFieldsDataDocument, baseOptions);
}
export type AddFieldsDataQueryHookResult = ReturnType<
  typeof useAddFieldsDataQuery
>;
export type AddFieldsDataLazyQueryHookResult = ReturnType<
  typeof useAddFieldsDataLazyQuery
>;
export type AddFieldsDataQueryResult = ApolloReactCommon.QueryResult<
  AddFieldsDataQuery,
  AddFieldsDataQueryVariables
>;
export const AddFieldDocument = gql`
  mutation AddField($input: AddFieldInput!) {
    addField(input: $input) {
      success
    }
  }
`;
export type AddFieldMutationFn = ApolloReactCommon.MutationFunction<
  AddFieldMutation,
  AddFieldMutationVariables
>;
export type AddFieldComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    AddFieldMutation,
    AddFieldMutationVariables
  >,
  'mutation'
>;

export const AddFieldComponent = (props: AddFieldComponentProps) => (
  <ApolloReactComponents.Mutation<AddFieldMutation, AddFieldMutationVariables>
    mutation={AddFieldDocument}
    {...props}
  />
);

/**
 * __useAddFieldMutation__
 *
 * To run a mutation, you first call `useAddFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addFieldMutation, { data, loading, error }] = useAddFieldMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddFieldMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddFieldMutation,
    AddFieldMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    AddFieldMutation,
    AddFieldMutationVariables
  >(AddFieldDocument, baseOptions);
}
export type AddFieldMutationHookResult = ReturnType<typeof useAddFieldMutation>;
export type AddFieldMutationResult = ApolloReactCommon.MutationResult<
  AddFieldMutation
>;
export type AddFieldMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddFieldMutation,
  AddFieldMutationVariables
>;
export const UpdateSensorAddFieldDocument = gql`
  mutation UpdateSensorAddField(
    $sensorId: String!
    $input: UpdateSensorInput!
  ) {
    updateSensor(sensorId: $sensorId, input: $input) {
      sensorId
    }
  }
`;
export type UpdateSensorAddFieldMutationFn = ApolloReactCommon.MutationFunction<
  UpdateSensorAddFieldMutation,
  UpdateSensorAddFieldMutationVariables
>;
export type UpdateSensorAddFieldComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateSensorAddFieldMutation,
    UpdateSensorAddFieldMutationVariables
  >,
  'mutation'
>;

export const UpdateSensorAddFieldComponent = (
  props: UpdateSensorAddFieldComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UpdateSensorAddFieldMutation,
    UpdateSensorAddFieldMutationVariables
  >
    mutation={UpdateSensorAddFieldDocument}
    {...props}
  />
);

/**
 * __useUpdateSensorAddFieldMutation__
 *
 * To run a mutation, you first call `useUpdateSensorAddFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSensorAddFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSensorAddFieldMutation, { data, loading, error }] = useUpdateSensorAddFieldMutation({
 *   variables: {
 *      sensorId: // value for 'sensorId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSensorAddFieldMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateSensorAddFieldMutation,
    UpdateSensorAddFieldMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateSensorAddFieldMutation,
    UpdateSensorAddFieldMutationVariables
  >(UpdateSensorAddFieldDocument, baseOptions);
}
export type UpdateSensorAddFieldMutationHookResult = ReturnType<
  typeof useUpdateSensorAddFieldMutation
>;
export type UpdateSensorAddFieldMutationResult = ApolloReactCommon.MutationResult<
  UpdateSensorAddFieldMutation
>;
export type UpdateSensorAddFieldMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateSensorAddFieldMutation,
  UpdateSensorAddFieldMutationVariables
>;
export const AllSensorsDocument = gql`
  query AllSensors {
    allUsers {
      id
      sensors(onlyOwned: true) {
        id
        ...sensorInfo
      }
      gateways(onlyOwned: true) {
        id
        ...gatewayInfo
      }
    }
    allDistributors {
      id
      name {
        first
        last
      }
      unassigned {
        sensors {
          id
        }
        gateways {
          id
        }
      }
    }
  }
  ${sensorInfoFragmentDoc}
  ${gatewayInfoFragmentDoc}
`;
export type AllSensorsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    AllSensorsQuery,
    AllSensorsQueryVariables
  >,
  'query'
>;

export const AllSensorsComponent = (props: AllSensorsComponentProps) => (
  <ApolloReactComponents.Query<AllSensorsQuery, AllSensorsQueryVariables>
    query={AllSensorsDocument}
    {...props}
  />
);

/**
 * __useAllSensorsQuery__
 *
 * To run a query within a React component, call `useAllSensorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllSensorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllSensorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllSensorsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AllSensorsQuery,
    AllSensorsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<AllSensorsQuery, AllSensorsQueryVariables>(
    AllSensorsDocument,
    baseOptions
  );
}
export function useAllSensorsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AllSensorsQuery,
    AllSensorsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    AllSensorsQuery,
    AllSensorsQueryVariables
  >(AllSensorsDocument, baseOptions);
}
export type AllSensorsQueryHookResult = ReturnType<typeof useAllSensorsQuery>;
export type AllSensorsLazyQueryHookResult = ReturnType<
  typeof useAllSensorsLazyQuery
>;
export type AllSensorsQueryResult = ApolloReactCommon.QueryResult<
  AllSensorsQuery,
  AllSensorsQueryVariables
>;
export const AddDistributorDocument = gql`
  mutation AddDistributor($input: AddDistributorInput!) {
    addDistributor(input: $input) {
      id
    }
  }
`;
export type AddDistributorMutationFn = ApolloReactCommon.MutationFunction<
  AddDistributorMutation,
  AddDistributorMutationVariables
>;
export type AddDistributorComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    AddDistributorMutation,
    AddDistributorMutationVariables
  >,
  'mutation'
>;

export const AddDistributorComponent = (
  props: AddDistributorComponentProps
) => (
  <ApolloReactComponents.Mutation<
    AddDistributorMutation,
    AddDistributorMutationVariables
  >
    mutation={AddDistributorDocument}
    {...props}
  />
);

/**
 * __useAddDistributorMutation__
 *
 * To run a mutation, you first call `useAddDistributorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDistributorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDistributorMutation, { data, loading, error }] = useAddDistributorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddDistributorMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddDistributorMutation,
    AddDistributorMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    AddDistributorMutation,
    AddDistributorMutationVariables
  >(AddDistributorDocument, baseOptions);
}
export type AddDistributorMutationHookResult = ReturnType<
  typeof useAddDistributorMutation
>;
export type AddDistributorMutationResult = ApolloReactCommon.MutationResult<
  AddDistributorMutation
>;
export type AddDistributorMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddDistributorMutation,
  AddDistributorMutationVariables
>;
export const PricingsDocument = gql`
  query Pricings {
    pricings {
      id
      name
    }
    salesReps {
      id
      name
    }
  }
`;
export type PricingsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    PricingsQuery,
    PricingsQueryVariables
  >,
  'query'
>;

export const PricingsComponent = (props: PricingsComponentProps) => (
  <ApolloReactComponents.Query<PricingsQuery, PricingsQueryVariables>
    query={PricingsDocument}
    {...props}
  />
);

/**
 * __usePricingsQuery__
 *
 * To run a query within a React component, call `usePricingsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePricingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePricingsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePricingsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    PricingsQuery,
    PricingsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<PricingsQuery, PricingsQueryVariables>(
    PricingsDocument,
    baseOptions
  );
}
export function usePricingsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PricingsQuery,
    PricingsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<PricingsQuery, PricingsQueryVariables>(
    PricingsDocument,
    baseOptions
  );
}
export type PricingsQueryHookResult = ReturnType<typeof usePricingsQuery>;
export type PricingsLazyQueryHookResult = ReturnType<
  typeof usePricingsLazyQuery
>;
export type PricingsQueryResult = ApolloReactCommon.QueryResult<
  PricingsQuery,
  PricingsQueryVariables
>;
export const AllUsersDocument = gql`
  query AllUsers {
    allUsers {
      id
      email
      phone
      activated
      name {
        first
        last
      }
      sensorIds
      gatewayIds
      distributor {
        id
        name {
          first
          last
        }
        salesRep {
          id
          name
        }
      }
    }
  }
`;
export type AllUsersComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    AllUsersQuery,
    AllUsersQueryVariables
  >,
  'query'
>;

export const AllUsersComponent = (props: AllUsersComponentProps) => (
  <ApolloReactComponents.Query<AllUsersQuery, AllUsersQueryVariables>
    query={AllUsersDocument}
    {...props}
  />
);

/**
 * __useAllUsersQuery__
 *
 * To run a query within a React component, call `useAllUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllUsersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AllUsersQuery,
    AllUsersQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<AllUsersQuery, AllUsersQueryVariables>(
    AllUsersDocument,
    baseOptions
  );
}
export function useAllUsersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AllUsersQuery,
    AllUsersQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<AllUsersQuery, AllUsersQueryVariables>(
    AllUsersDocument,
    baseOptions
  );
}
export type AllUsersQueryHookResult = ReturnType<typeof useAllUsersQuery>;
export type AllUsersLazyQueryHookResult = ReturnType<
  typeof useAllUsersLazyQuery
>;
export type AllUsersQueryResult = ApolloReactCommon.QueryResult<
  AllUsersQuery,
  AllUsersQueryVariables
>;
export const AllDistributorsDocument = gql`
  query AllDistributors {
    allDistributors {
      id
      email
      phone
      isRep
      name {
        first
        last
      }
      reps {
        id
      }
    }
  }
`;
export type AllDistributorsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    AllDistributorsQuery,
    AllDistributorsQueryVariables
  >,
  'query'
>;

export const AllDistributorsComponent = (
  props: AllDistributorsComponentProps
) => (
  <ApolloReactComponents.Query<
    AllDistributorsQuery,
    AllDistributorsQueryVariables
  >
    query={AllDistributorsDocument}
    {...props}
  />
);

/**
 * __useAllDistributorsQuery__
 *
 * To run a query within a React component, call `useAllDistributorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllDistributorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllDistributorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllDistributorsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AllDistributorsQuery,
    AllDistributorsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    AllDistributorsQuery,
    AllDistributorsQueryVariables
  >(AllDistributorsDocument, baseOptions);
}
export function useAllDistributorsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AllDistributorsQuery,
    AllDistributorsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    AllDistributorsQuery,
    AllDistributorsQueryVariables
  >(AllDistributorsDocument, baseOptions);
}
export type AllDistributorsQueryHookResult = ReturnType<
  typeof useAllDistributorsQuery
>;
export type AllDistributorsLazyQueryHookResult = ReturnType<
  typeof useAllDistributorsLazyQuery
>;
export type AllDistributorsQueryResult = ApolloReactCommon.QueryResult<
  AllDistributorsQuery,
  AllDistributorsQueryVariables
>;
export const AdminOrdersDocument = gql`
  query AdminOrders {
    allOrders {
      id
      placedTimestamp
      status
      details {
        hardware {
          id
          quantity
        }
        customer {
          id
          name {
            first
            last
          }
        }
        rep {
          id
          name {
            first
            last
          }
        }
      }
    }
  }
`;
export type AdminOrdersComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    AdminOrdersQuery,
    AdminOrdersQueryVariables
  >,
  'query'
>;

export const AdminOrdersComponent = (props: AdminOrdersComponentProps) => (
  <ApolloReactComponents.Query<AdminOrdersQuery, AdminOrdersQueryVariables>
    query={AdminOrdersDocument}
    {...props}
  />
);

/**
 * __useAdminOrdersQuery__
 *
 * To run a query within a React component, call `useAdminOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminOrdersQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminOrdersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AdminOrdersQuery,
    AdminOrdersQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<AdminOrdersQuery, AdminOrdersQueryVariables>(
    AdminOrdersDocument,
    baseOptions
  );
}
export function useAdminOrdersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AdminOrdersQuery,
    AdminOrdersQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    AdminOrdersQuery,
    AdminOrdersQueryVariables
  >(AdminOrdersDocument, baseOptions);
}
export type AdminOrdersQueryHookResult = ReturnType<typeof useAdminOrdersQuery>;
export type AdminOrdersLazyQueryHookResult = ReturnType<
  typeof useAdminOrdersLazyQuery
>;
export type AdminOrdersQueryResult = ApolloReactCommon.QueryResult<
  AdminOrdersQuery,
  AdminOrdersQueryVariables
>;
export const AdminPrepayUsersDocument = gql`
  query AdminPrepayUsers {
    allUsers {
      id
      email
      name {
        first
        last
      }
    }
  }
`;
export type AdminPrepayUsersComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    AdminPrepayUsersQuery,
    AdminPrepayUsersQueryVariables
  >,
  'query'
>;

export const AdminPrepayUsersComponent = (
  props: AdminPrepayUsersComponentProps
) => (
  <ApolloReactComponents.Query<
    AdminPrepayUsersQuery,
    AdminPrepayUsersQueryVariables
  >
    query={AdminPrepayUsersDocument}
    {...props}
  />
);

/**
 * __useAdminPrepayUsersQuery__
 *
 * To run a query within a React component, call `useAdminPrepayUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminPrepayUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminPrepayUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminPrepayUsersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AdminPrepayUsersQuery,
    AdminPrepayUsersQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    AdminPrepayUsersQuery,
    AdminPrepayUsersQueryVariables
  >(AdminPrepayUsersDocument, baseOptions);
}
export function useAdminPrepayUsersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AdminPrepayUsersQuery,
    AdminPrepayUsersQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    AdminPrepayUsersQuery,
    AdminPrepayUsersQueryVariables
  >(AdminPrepayUsersDocument, baseOptions);
}
export type AdminPrepayUsersQueryHookResult = ReturnType<
  typeof useAdminPrepayUsersQuery
>;
export type AdminPrepayUsersLazyQueryHookResult = ReturnType<
  typeof useAdminPrepayUsersLazyQuery
>;
export type AdminPrepayUsersQueryResult = ApolloReactCommon.QueryResult<
  AdminPrepayUsersQuery,
  AdminPrepayUsersQueryVariables
>;
export const AdminPrepayUserDocument = gql`
  query AdminPrepayUser($id: String!) {
    user(id: $id) {
      id
      accountEmail
      sensors {
        id
        planRequired
        planId
        planActiveUntil
      }
      gateways {
        id
        planRequired
        planId
        planActiveUntil
      }
    }
  }
`;
export type AdminPrepayUserComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    AdminPrepayUserQuery,
    AdminPrepayUserQueryVariables
  >,
  'query'
> &
  (
    | { variables: AdminPrepayUserQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const AdminPrepayUserComponent = (
  props: AdminPrepayUserComponentProps
) => (
  <ApolloReactComponents.Query<
    AdminPrepayUserQuery,
    AdminPrepayUserQueryVariables
  >
    query={AdminPrepayUserDocument}
    {...props}
  />
);

/**
 * __useAdminPrepayUserQuery__
 *
 * To run a query within a React component, call `useAdminPrepayUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminPrepayUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminPrepayUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminPrepayUserQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AdminPrepayUserQuery,
    AdminPrepayUserQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    AdminPrepayUserQuery,
    AdminPrepayUserQueryVariables
  >(AdminPrepayUserDocument, baseOptions);
}
export function useAdminPrepayUserLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AdminPrepayUserQuery,
    AdminPrepayUserQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    AdminPrepayUserQuery,
    AdminPrepayUserQueryVariables
  >(AdminPrepayUserDocument, baseOptions);
}
export type AdminPrepayUserQueryHookResult = ReturnType<
  typeof useAdminPrepayUserQuery
>;
export type AdminPrepayUserLazyQueryHookResult = ReturnType<
  typeof useAdminPrepayUserLazyQuery
>;
export type AdminPrepayUserQueryResult = ApolloReactCommon.QueryResult<
  AdminPrepayUserQuery,
  AdminPrepayUserQueryVariables
>;
export const ApplyPrepaidDocument = gql`
  mutation ApplyPrepaid($input: ApplyPrepaidToUserInput!) {
    applyPrepaidToUser(input: $input)
  }
`;
export type ApplyPrepaidMutationFn = ApolloReactCommon.MutationFunction<
  ApplyPrepaidMutation,
  ApplyPrepaidMutationVariables
>;
export type ApplyPrepaidComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    ApplyPrepaidMutation,
    ApplyPrepaidMutationVariables
  >,
  'mutation'
>;

export const ApplyPrepaidComponent = (props: ApplyPrepaidComponentProps) => (
  <ApolloReactComponents.Mutation<
    ApplyPrepaidMutation,
    ApplyPrepaidMutationVariables
  >
    mutation={ApplyPrepaidDocument}
    {...props}
  />
);

/**
 * __useApplyPrepaidMutation__
 *
 * To run a mutation, you first call `useApplyPrepaidMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyPrepaidMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyPrepaidMutation, { data, loading, error }] = useApplyPrepaidMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApplyPrepaidMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ApplyPrepaidMutation,
    ApplyPrepaidMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ApplyPrepaidMutation,
    ApplyPrepaidMutationVariables
  >(ApplyPrepaidDocument, baseOptions);
}
export type ApplyPrepaidMutationHookResult = ReturnType<
  typeof useApplyPrepaidMutation
>;
export type ApplyPrepaidMutationResult = ApolloReactCommon.MutationResult<
  ApplyPrepaidMutation
>;
export type ApplyPrepaidMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ApplyPrepaidMutation,
  ApplyPrepaidMutationVariables
>;
export const CancelPrepaidDocument = gql`
  mutation CancelPrepaid($input: CancelPrepaidToUserInput!) {
    cancelPrepaidToUser(input: $input)
  }
`;
export type CancelPrepaidMutationFn = ApolloReactCommon.MutationFunction<
  CancelPrepaidMutation,
  CancelPrepaidMutationVariables
>;
export type CancelPrepaidComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CancelPrepaidMutation,
    CancelPrepaidMutationVariables
  >,
  'mutation'
>;

export const CancelPrepaidComponent = (props: CancelPrepaidComponentProps) => (
  <ApolloReactComponents.Mutation<
    CancelPrepaidMutation,
    CancelPrepaidMutationVariables
  >
    mutation={CancelPrepaidDocument}
    {...props}
  />
);

/**
 * __useCancelPrepaidMutation__
 *
 * To run a mutation, you first call `useCancelPrepaidMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelPrepaidMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelPrepaidMutation, { data, loading, error }] = useCancelPrepaidMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelPrepaidMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CancelPrepaidMutation,
    CancelPrepaidMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CancelPrepaidMutation,
    CancelPrepaidMutationVariables
  >(CancelPrepaidDocument, baseOptions);
}
export type CancelPrepaidMutationHookResult = ReturnType<
  typeof useCancelPrepaidMutation
>;
export type CancelPrepaidMutationResult = ApolloReactCommon.MutationResult<
  CancelPrepaidMutation
>;
export type CancelPrepaidMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CancelPrepaidMutation,
  CancelPrepaidMutationVariables
>;
export const AllServiceDocument = gql`
  query AllService {
    allSensors {
      id
      serviceLogs {
        id
        ...AllServiceLog
      }
    }
    allGateways {
      id
      serviceLogs {
        id
        ...AllServiceLog
      }
    }
  }
  ${AllServiceLogFragmentDoc}
`;
export type AllServiceComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    AllServiceQuery,
    AllServiceQueryVariables
  >,
  'query'
>;

export const AllServiceComponent = (props: AllServiceComponentProps) => (
  <ApolloReactComponents.Query<AllServiceQuery, AllServiceQueryVariables>
    query={AllServiceDocument}
    {...props}
  />
);

/**
 * __useAllServiceQuery__
 *
 * To run a query within a React component, call `useAllServiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllServiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllServiceQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllServiceQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AllServiceQuery,
    AllServiceQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<AllServiceQuery, AllServiceQueryVariables>(
    AllServiceDocument,
    baseOptions
  );
}
export function useAllServiceLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AllServiceQuery,
    AllServiceQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    AllServiceQuery,
    AllServiceQueryVariables
  >(AllServiceDocument, baseOptions);
}
export type AllServiceQueryHookResult = ReturnType<typeof useAllServiceQuery>;
export type AllServiceLazyQueryHookResult = ReturnType<
  typeof useAllServiceLazyQuery
>;
export type AllServiceQueryResult = ApolloReactCommon.QueryResult<
  AllServiceQuery,
  AllServiceQueryVariables
>;
export const AdminShippingDocument = gql`
  query AdminShipping {
    openOrders {
      id
      placedTimestamp
      status
      details {
        hardware {
          id
          quantity
        }
        customer {
          id
          name {
            first
            last
          }
        }
        rep {
          id
          name {
            first
            last
          }
        }
      }
    }
  }
`;
export type AdminShippingComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    AdminShippingQuery,
    AdminShippingQueryVariables
  >,
  'query'
>;

export const AdminShippingComponent = (props: AdminShippingComponentProps) => (
  <ApolloReactComponents.Query<AdminShippingQuery, AdminShippingQueryVariables>
    query={AdminShippingDocument}
    {...props}
  />
);

/**
 * __useAdminShippingQuery__
 *
 * To run a query within a React component, call `useAdminShippingQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminShippingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminShippingQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminShippingQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AdminShippingQuery,
    AdminShippingQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    AdminShippingQuery,
    AdminShippingQueryVariables
  >(AdminShippingDocument, baseOptions);
}
export function useAdminShippingLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AdminShippingQuery,
    AdminShippingQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    AdminShippingQuery,
    AdminShippingQueryVariables
  >(AdminShippingDocument, baseOptions);
}
export type AdminShippingQueryHookResult = ReturnType<
  typeof useAdminShippingQuery
>;
export type AdminShippingLazyQueryHookResult = ReturnType<
  typeof useAdminShippingLazyQuery
>;
export type AdminShippingQueryResult = ApolloReactCommon.QueryResult<
  AdminShippingQuery,
  AdminShippingQueryVariables
>;
export const AdminStatusDocument = gql`
  query AdminStatus {
    allSensors {
      id
      battery
      gatewayId
      rssi
      battery
      inactive
      connectionStatus
      sensor_time
      statusWarnings
      owner {
        id
        name {
          first
          last
        }
        user {
          id
          email
          distributor {
            id
            email
            name {
              first
              last
            }
            salesRep {
              id
              name
            }
          }
        }
      }
      model {
        hasRainGauge
      }
      usage_stats {
        dry
        ok
        wet
        online
        offline
        logins
      }
    }
    allGateways {
      id
      gateway_battery
      sensorIds
      cell_signal
      inactive
      connectionStatus
      gateway_time
      statusWarnings
      owner {
        id
        name {
          first
          last
        }
        user {
          id
          email
          distributor {
            id
            email
            name {
              first
              last
            }
            salesRep {
              id
              name
            }
          }
        }
      }
      model {
        hasRainGauge
      }
    }
    allDistributors {
      id
      name {
        full
      }
      unassigned {
        sensors {
          id
        }
        gateways {
          id
        }
      }
    }
  }
`;
export type AdminStatusComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    AdminStatusQuery,
    AdminStatusQueryVariables
  >,
  'query'
>;

export const AdminStatusComponent = (props: AdminStatusComponentProps) => (
  <ApolloReactComponents.Query<AdminStatusQuery, AdminStatusQueryVariables>
    query={AdminStatusDocument}
    {...props}
  />
);

/**
 * __useAdminStatusQuery__
 *
 * To run a query within a React component, call `useAdminStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminStatusQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AdminStatusQuery,
    AdminStatusQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<AdminStatusQuery, AdminStatusQueryVariables>(
    AdminStatusDocument,
    baseOptions
  );
}
export function useAdminStatusLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AdminStatusQuery,
    AdminStatusQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    AdminStatusQuery,
    AdminStatusQueryVariables
  >(AdminStatusDocument, baseOptions);
}
export type AdminStatusQueryHookResult = ReturnType<typeof useAdminStatusQuery>;
export type AdminStatusLazyQueryHookResult = ReturnType<
  typeof useAdminStatusLazyQuery
>;
export type AdminStatusQueryResult = ApolloReactCommon.QueryResult<
  AdminStatusQuery,
  AdminStatusQueryVariables
>;
export const UpdateGatewayStatusDocument = gql`
  mutation UpdateGatewayStatus($input: UpdateGatewayStatusInput!) {
    updateGatewayStatus(input: $input) {
      id
    }
  }
`;
export type UpdateGatewayStatusMutationFn = ApolloReactCommon.MutationFunction<
  UpdateGatewayStatusMutation,
  UpdateGatewayStatusMutationVariables
>;
export type UpdateGatewayStatusComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateGatewayStatusMutation,
    UpdateGatewayStatusMutationVariables
  >,
  'mutation'
>;

export const UpdateGatewayStatusComponent = (
  props: UpdateGatewayStatusComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UpdateGatewayStatusMutation,
    UpdateGatewayStatusMutationVariables
  >
    mutation={UpdateGatewayStatusDocument}
    {...props}
  />
);

/**
 * __useUpdateGatewayStatusMutation__
 *
 * To run a mutation, you first call `useUpdateGatewayStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGatewayStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGatewayStatusMutation, { data, loading, error }] = useUpdateGatewayStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateGatewayStatusMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateGatewayStatusMutation,
    UpdateGatewayStatusMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateGatewayStatusMutation,
    UpdateGatewayStatusMutationVariables
  >(UpdateGatewayStatusDocument, baseOptions);
}
export type UpdateGatewayStatusMutationHookResult = ReturnType<
  typeof useUpdateGatewayStatusMutation
>;
export type UpdateGatewayStatusMutationResult = ApolloReactCommon.MutationResult<
  UpdateGatewayStatusMutation
>;
export type UpdateGatewayStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateGatewayStatusMutation,
  UpdateGatewayStatusMutationVariables
>;
export const UpdateSensorStatusDocument = gql`
  mutation UpdateSensorStatus(
    $sensorId: String!
    $input: UpdateSensorStatusInput!
  ) {
    updateSensorStatus(sensorId: $sensorId, input: $input) {
      sensorId
    }
  }
`;
export type UpdateSensorStatusMutationFn = ApolloReactCommon.MutationFunction<
  UpdateSensorStatusMutation,
  UpdateSensorStatusMutationVariables
>;
export type UpdateSensorStatusComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateSensorStatusMutation,
    UpdateSensorStatusMutationVariables
  >,
  'mutation'
>;

export const UpdateSensorStatusComponent = (
  props: UpdateSensorStatusComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UpdateSensorStatusMutation,
    UpdateSensorStatusMutationVariables
  >
    mutation={UpdateSensorStatusDocument}
    {...props}
  />
);

/**
 * __useUpdateSensorStatusMutation__
 *
 * To run a mutation, you first call `useUpdateSensorStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSensorStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSensorStatusMutation, { data, loading, error }] = useUpdateSensorStatusMutation({
 *   variables: {
 *      sensorId: // value for 'sensorId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSensorStatusMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateSensorStatusMutation,
    UpdateSensorStatusMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateSensorStatusMutation,
    UpdateSensorStatusMutationVariables
  >(UpdateSensorStatusDocument, baseOptions);
}
export type UpdateSensorStatusMutationHookResult = ReturnType<
  typeof useUpdateSensorStatusMutation
>;
export type UpdateSensorStatusMutationResult = ApolloReactCommon.MutationResult<
  UpdateSensorStatusMutation
>;
export type UpdateSensorStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateSensorStatusMutation,
  UpdateSensorStatusMutationVariables
>;
export const GatewayStatusDocument = gql`
  query GatewayStatus($gatewayId: String!) {
    gateway(id: $gatewayId, admin: true) {
      id
      cell_signal
      gateway_battery
      gateway_time
      gateway_time_interval
      statusWarnings
      inactive
      configuration {
        boot
        crit_th
        freq
        hw_ver
        led_th
        sw_ver
        voltage_th
        ws_fw_ver
      }
      configurationUpdated
      network {
        availableServices
        ccid
        currentService
        firmwareVersion
      }
      sensorIds
      sensors {
        id
        connectionStatus
      }
      model {
        hasRainGauge
        hasWeatherStation
        hasPressureSensor
      }
      owner {
        id
        accountEmail
        name {
          first
          last
        }
        user {
          id
          activationCompleted
          hasAccountIssues
          logins
          phone
          sensors {
            id
            active
          }
          gateways {
            id
            active
          }
          distributor {
            id
            name {
              first
              last
            }
            salesRep {
              id
              name
            }
          }
        }
      }
      users {
        id
        user {
          id
          email
          name {
            first
            last
          }
        }
      }
      location {
        lat
        lng
      }
      gpsUpdated
      gpsLocked
      mailbox {
        toDevice
        toServer
      }
      serviceLogs {
        name
        date
        location
        reason
        result
        timeSpent
        followUpTasks
        id
      }
    }
    sensor(id: $gatewayId, admin: true) {
      id
      errors {
        error
        time
      }
    }
  }
`;
export type GatewayStatusComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GatewayStatusQuery,
    GatewayStatusQueryVariables
  >,
  'query'
> &
  (
    | { variables: GatewayStatusQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const GatewayStatusComponent = (props: GatewayStatusComponentProps) => (
  <ApolloReactComponents.Query<GatewayStatusQuery, GatewayStatusQueryVariables>
    query={GatewayStatusDocument}
    {...props}
  />
);

/**
 * __useGatewayStatusQuery__
 *
 * To run a query within a React component, call `useGatewayStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGatewayStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGatewayStatusQuery({
 *   variables: {
 *      gatewayId: // value for 'gatewayId'
 *   },
 * });
 */
export function useGatewayStatusQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GatewayStatusQuery,
    GatewayStatusQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GatewayStatusQuery,
    GatewayStatusQueryVariables
  >(GatewayStatusDocument, baseOptions);
}
export function useGatewayStatusLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GatewayStatusQuery,
    GatewayStatusQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GatewayStatusQuery,
    GatewayStatusQueryVariables
  >(GatewayStatusDocument, baseOptions);
}
export type GatewayStatusQueryHookResult = ReturnType<
  typeof useGatewayStatusQuery
>;
export type GatewayStatusLazyQueryHookResult = ReturnType<
  typeof useGatewayStatusLazyQuery
>;
export type GatewayStatusQueryResult = ApolloReactCommon.QueryResult<
  GatewayStatusQuery,
  GatewayStatusQueryVariables
>;
export const GatewayStatusVanillaDataDocument = gql`
  query GatewayStatusVanillaData($gatewayId: String!, $start: Float) {
    gateway(id: $gatewayId, admin: true) {
      id
      data(start: $start) {
        ...GatewayStatusDataData
      }
    }
  }
  ${GatewayStatusDataDataFragmentDoc}
`;
export type GatewayStatusVanillaDataComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GatewayStatusVanillaDataQuery,
    GatewayStatusVanillaDataQueryVariables
  >,
  'query'
> &
  (
    | { variables: GatewayStatusVanillaDataQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const GatewayStatusVanillaDataComponent = (
  props: GatewayStatusVanillaDataComponentProps
) => (
  <ApolloReactComponents.Query<
    GatewayStatusVanillaDataQuery,
    GatewayStatusVanillaDataQueryVariables
  >
    query={GatewayStatusVanillaDataDocument}
    {...props}
  />
);

/**
 * __useGatewayStatusVanillaDataQuery__
 *
 * To run a query within a React component, call `useGatewayStatusVanillaDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGatewayStatusVanillaDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGatewayStatusVanillaDataQuery({
 *   variables: {
 *      gatewayId: // value for 'gatewayId'
 *      start: // value for 'start'
 *   },
 * });
 */
export function useGatewayStatusVanillaDataQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GatewayStatusVanillaDataQuery,
    GatewayStatusVanillaDataQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GatewayStatusVanillaDataQuery,
    GatewayStatusVanillaDataQueryVariables
  >(GatewayStatusVanillaDataDocument, baseOptions);
}
export function useGatewayStatusVanillaDataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GatewayStatusVanillaDataQuery,
    GatewayStatusVanillaDataQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GatewayStatusVanillaDataQuery,
    GatewayStatusVanillaDataQueryVariables
  >(GatewayStatusVanillaDataDocument, baseOptions);
}
export type GatewayStatusVanillaDataQueryHookResult = ReturnType<
  typeof useGatewayStatusVanillaDataQuery
>;
export type GatewayStatusVanillaDataLazyQueryHookResult = ReturnType<
  typeof useGatewayStatusVanillaDataLazyQuery
>;
export type GatewayStatusVanillaDataQueryResult = ApolloReactCommon.QueryResult<
  GatewayStatusVanillaDataQuery,
  GatewayStatusVanillaDataQueryVariables
>;
export const GatewayStatusWeatherDataDocument = gql`
  query GatewayStatusWeatherData($gatewayId: String!, $start: Float) {
    gateway(id: $gatewayId, admin: true) {
      id
      data(start: $start) {
        ...GatewayStatusDataData
      }
    }
    sensor(id: $gatewayId, admin: true) {
      id
      dataView(start: $start) {
        weather {
          time
          hum
          mWindSpd
          rnCnt
          rnRate
          rnTotal
          solarRad
          temp
          windSpd
          windErr
          rnRateErr
          solarRadErr
          tempErr
          mWindErr
          humErr
          rnCntErr
        }
      }
    }
  }
  ${GatewayStatusDataDataFragmentDoc}
`;
export type GatewayStatusWeatherDataComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GatewayStatusWeatherDataQuery,
    GatewayStatusWeatherDataQueryVariables
  >,
  'query'
> &
  (
    | { variables: GatewayStatusWeatherDataQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const GatewayStatusWeatherDataComponent = (
  props: GatewayStatusWeatherDataComponentProps
) => (
  <ApolloReactComponents.Query<
    GatewayStatusWeatherDataQuery,
    GatewayStatusWeatherDataQueryVariables
  >
    query={GatewayStatusWeatherDataDocument}
    {...props}
  />
);

/**
 * __useGatewayStatusWeatherDataQuery__
 *
 * To run a query within a React component, call `useGatewayStatusWeatherDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGatewayStatusWeatherDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGatewayStatusWeatherDataQuery({
 *   variables: {
 *      gatewayId: // value for 'gatewayId'
 *      start: // value for 'start'
 *   },
 * });
 */
export function useGatewayStatusWeatherDataQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GatewayStatusWeatherDataQuery,
    GatewayStatusWeatherDataQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GatewayStatusWeatherDataQuery,
    GatewayStatusWeatherDataQueryVariables
  >(GatewayStatusWeatherDataDocument, baseOptions);
}
export function useGatewayStatusWeatherDataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GatewayStatusWeatherDataQuery,
    GatewayStatusWeatherDataQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GatewayStatusWeatherDataQuery,
    GatewayStatusWeatherDataQueryVariables
  >(GatewayStatusWeatherDataDocument, baseOptions);
}
export type GatewayStatusWeatherDataQueryHookResult = ReturnType<
  typeof useGatewayStatusWeatherDataQuery
>;
export type GatewayStatusWeatherDataLazyQueryHookResult = ReturnType<
  typeof useGatewayStatusWeatherDataLazyQuery
>;
export type GatewayStatusWeatherDataQueryResult = ApolloReactCommon.QueryResult<
  GatewayStatusWeatherDataQuery,
  GatewayStatusWeatherDataQueryVariables
>;
export const GatewayStatusPressureDataDocument = gql`
  query GatewayStatusPressureData($gatewayId: String!, $start: Float) {
    gateway(id: $gatewayId, admin: true) {
      id
      data(start: $start) {
        ...GatewayStatusDataData
      }
    }
    sensor(id: $gatewayId, admin: true) {
      id
      dataView(start: $start) {
        pressure {
          t
          avgP
          rt
        }
      }
    }
  }
  ${GatewayStatusDataDataFragmentDoc}
`;
export type GatewayStatusPressureDataComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GatewayStatusPressureDataQuery,
    GatewayStatusPressureDataQueryVariables
  >,
  'query'
> &
  (
    | { variables: GatewayStatusPressureDataQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const GatewayStatusPressureDataComponent = (
  props: GatewayStatusPressureDataComponentProps
) => (
  <ApolloReactComponents.Query<
    GatewayStatusPressureDataQuery,
    GatewayStatusPressureDataQueryVariables
  >
    query={GatewayStatusPressureDataDocument}
    {...props}
  />
);

/**
 * __useGatewayStatusPressureDataQuery__
 *
 * To run a query within a React component, call `useGatewayStatusPressureDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGatewayStatusPressureDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGatewayStatusPressureDataQuery({
 *   variables: {
 *      gatewayId: // value for 'gatewayId'
 *      start: // value for 'start'
 *   },
 * });
 */
export function useGatewayStatusPressureDataQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GatewayStatusPressureDataQuery,
    GatewayStatusPressureDataQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GatewayStatusPressureDataQuery,
    GatewayStatusPressureDataQueryVariables
  >(GatewayStatusPressureDataDocument, baseOptions);
}
export function useGatewayStatusPressureDataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GatewayStatusPressureDataQuery,
    GatewayStatusPressureDataQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GatewayStatusPressureDataQuery,
    GatewayStatusPressureDataQueryVariables
  >(GatewayStatusPressureDataDocument, baseOptions);
}
export type GatewayStatusPressureDataQueryHookResult = ReturnType<
  typeof useGatewayStatusPressureDataQuery
>;
export type GatewayStatusPressureDataLazyQueryHookResult = ReturnType<
  typeof useGatewayStatusPressureDataLazyQuery
>;
export type GatewayStatusPressureDataQueryResult = ApolloReactCommon.QueryResult<
  GatewayStatusPressureDataQuery,
  GatewayStatusPressureDataQueryVariables
>;
export const GatewayCommandSendDocument = gql`
  query GatewayCommandSend($gatewayId: String) {
    gateway(id: $gatewayId, admin: true) {
      id
      cell_signal
      gateway_battery
      gateway_time
      configuration {
        hw_ver
      }
    }
    gatewaySoftwareVersions {
      name
      hardwareVersions {
        name
        variants
      }
    }
    gatewayFlashLibrary {
      name
      defaultValue
      max
      min
    }
    gatewaySimcomLibrary
  }
`;
export type GatewayCommandSendComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GatewayCommandSendQuery,
    GatewayCommandSendQueryVariables
  >,
  'query'
>;

export const GatewayCommandSendComponent = (
  props: GatewayCommandSendComponentProps
) => (
  <ApolloReactComponents.Query<
    GatewayCommandSendQuery,
    GatewayCommandSendQueryVariables
  >
    query={GatewayCommandSendDocument}
    {...props}
  />
);

/**
 * __useGatewayCommandSendQuery__
 *
 * To run a query within a React component, call `useGatewayCommandSendQuery` and pass it any options that fit your needs.
 * When your component renders, `useGatewayCommandSendQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGatewayCommandSendQuery({
 *   variables: {
 *      gatewayId: // value for 'gatewayId'
 *   },
 * });
 */
export function useGatewayCommandSendQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GatewayCommandSendQuery,
    GatewayCommandSendQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GatewayCommandSendQuery,
    GatewayCommandSendQueryVariables
  >(GatewayCommandSendDocument, baseOptions);
}
export function useGatewayCommandSendLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GatewayCommandSendQuery,
    GatewayCommandSendQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GatewayCommandSendQuery,
    GatewayCommandSendQueryVariables
  >(GatewayCommandSendDocument, baseOptions);
}
export type GatewayCommandSendQueryHookResult = ReturnType<
  typeof useGatewayCommandSendQuery
>;
export type GatewayCommandSendLazyQueryHookResult = ReturnType<
  typeof useGatewayCommandSendLazyQuery
>;
export type GatewayCommandSendQueryResult = ApolloReactCommon.QueryResult<
  GatewayCommandSendQuery,
  GatewayCommandSendQueryVariables
>;
export const GatewayFlashDocument = gql`
  mutation GatewayFlash($input: GatewayFlashInput!) {
    commandGatewayFlashSend(input: $input) {
      success
    }
  }
`;
export type GatewayFlashMutationFn = ApolloReactCommon.MutationFunction<
  GatewayFlashMutation,
  GatewayFlashMutationVariables
>;
export type GatewayFlashComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    GatewayFlashMutation,
    GatewayFlashMutationVariables
  >,
  'mutation'
>;

export const GatewayFlashComponent = (props: GatewayFlashComponentProps) => (
  <ApolloReactComponents.Mutation<
    GatewayFlashMutation,
    GatewayFlashMutationVariables
  >
    mutation={GatewayFlashDocument}
    {...props}
  />
);

/**
 * __useGatewayFlashMutation__
 *
 * To run a mutation, you first call `useGatewayFlashMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGatewayFlashMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gatewayFlashMutation, { data, loading, error }] = useGatewayFlashMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGatewayFlashMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    GatewayFlashMutation,
    GatewayFlashMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    GatewayFlashMutation,
    GatewayFlashMutationVariables
  >(GatewayFlashDocument, baseOptions);
}
export type GatewayFlashMutationHookResult = ReturnType<
  typeof useGatewayFlashMutation
>;
export type GatewayFlashMutationResult = ApolloReactCommon.MutationResult<
  GatewayFlashMutation
>;
export type GatewayFlashMutationOptions = ApolloReactCommon.BaseMutationOptions<
  GatewayFlashMutation,
  GatewayFlashMutationVariables
>;
export const GatewayReprogramDocument = gql`
  mutation GatewayReprogram($input: GatewayReprogramInput!) {
    commandGatewayReprogramSend(input: $input) {
      success
    }
  }
`;
export type GatewayReprogramMutationFn = ApolloReactCommon.MutationFunction<
  GatewayReprogramMutation,
  GatewayReprogramMutationVariables
>;
export type GatewayReprogramComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    GatewayReprogramMutation,
    GatewayReprogramMutationVariables
  >,
  'mutation'
>;

export const GatewayReprogramComponent = (
  props: GatewayReprogramComponentProps
) => (
  <ApolloReactComponents.Mutation<
    GatewayReprogramMutation,
    GatewayReprogramMutationVariables
  >
    mutation={GatewayReprogramDocument}
    {...props}
  />
);

/**
 * __useGatewayReprogramMutation__
 *
 * To run a mutation, you first call `useGatewayReprogramMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGatewayReprogramMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gatewayReprogramMutation, { data, loading, error }] = useGatewayReprogramMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGatewayReprogramMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    GatewayReprogramMutation,
    GatewayReprogramMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    GatewayReprogramMutation,
    GatewayReprogramMutationVariables
  >(GatewayReprogramDocument, baseOptions);
}
export type GatewayReprogramMutationHookResult = ReturnType<
  typeof useGatewayReprogramMutation
>;
export type GatewayReprogramMutationResult = ApolloReactCommon.MutationResult<
  GatewayReprogramMutation
>;
export type GatewayReprogramMutationOptions = ApolloReactCommon.BaseMutationOptions<
  GatewayReprogramMutation,
  GatewayReprogramMutationVariables
>;
export const GatewayResetDocument = gql`
  mutation GatewayReset($input: GatewayResetInput!) {
    commandGatewayResetSend(input: $input) {
      success
    }
  }
`;
export type GatewayResetMutationFn = ApolloReactCommon.MutationFunction<
  GatewayResetMutation,
  GatewayResetMutationVariables
>;
export type GatewayResetComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    GatewayResetMutation,
    GatewayResetMutationVariables
  >,
  'mutation'
>;

export const GatewayResetComponent = (props: GatewayResetComponentProps) => (
  <ApolloReactComponents.Mutation<
    GatewayResetMutation,
    GatewayResetMutationVariables
  >
    mutation={GatewayResetDocument}
    {...props}
  />
);

/**
 * __useGatewayResetMutation__
 *
 * To run a mutation, you first call `useGatewayResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGatewayResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gatewayResetMutation, { data, loading, error }] = useGatewayResetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGatewayResetMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    GatewayResetMutation,
    GatewayResetMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    GatewayResetMutation,
    GatewayResetMutationVariables
  >(GatewayResetDocument, baseOptions);
}
export type GatewayResetMutationHookResult = ReturnType<
  typeof useGatewayResetMutation
>;
export type GatewayResetMutationResult = ApolloReactCommon.MutationResult<
  GatewayResetMutation
>;
export type GatewayResetMutationOptions = ApolloReactCommon.BaseMutationOptions<
  GatewayResetMutation,
  GatewayResetMutationVariables
>;
export const GatewaySimcomDocument = gql`
  mutation GatewaySimcom($input: GatewaySimcomInput!) {
    commandGatewaySimcomSend(input: $input) {
      success
    }
  }
`;
export type GatewaySimcomMutationFn = ApolloReactCommon.MutationFunction<
  GatewaySimcomMutation,
  GatewaySimcomMutationVariables
>;
export type GatewaySimcomComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    GatewaySimcomMutation,
    GatewaySimcomMutationVariables
  >,
  'mutation'
>;

export const GatewaySimcomComponent = (props: GatewaySimcomComponentProps) => (
  <ApolloReactComponents.Mutation<
    GatewaySimcomMutation,
    GatewaySimcomMutationVariables
  >
    mutation={GatewaySimcomDocument}
    {...props}
  />
);

/**
 * __useGatewaySimcomMutation__
 *
 * To run a mutation, you first call `useGatewaySimcomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGatewaySimcomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gatewaySimcomMutation, { data, loading, error }] = useGatewaySimcomMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGatewaySimcomMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    GatewaySimcomMutation,
    GatewaySimcomMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    GatewaySimcomMutation,
    GatewaySimcomMutationVariables
  >(GatewaySimcomDocument, baseOptions);
}
export type GatewaySimcomMutationHookResult = ReturnType<
  typeof useGatewaySimcomMutation
>;
export type GatewaySimcomMutationResult = ApolloReactCommon.MutationResult<
  GatewaySimcomMutation
>;
export type GatewaySimcomMutationOptions = ApolloReactCommon.BaseMutationOptions<
  GatewaySimcomMutation,
  GatewaySimcomMutationVariables
>;
export const AdminStatusMapDocument = gql`
  query AdminStatusMap {
    allSensors {
      id
      battery
      gatewayId
      rssi
      battery
      inactive
      connectionStatus
      sensor_time
      statusWarnings
      owner {
        id
        name {
          first
          last
        }
        user {
          id
          email
          distributor {
            id
            email
            name {
              first
              last
            }
            salesRep {
              id
              name
            }
          }
        }
      }
      model {
        hasRainGauge
      }
      location {
        lat
        lng
      }
    }
    allGateways {
      id
      gateway_battery
      sensorIds
      cell_signal
      inactive
      connectionStatus
      gateway_time
      statusWarnings
      owner {
        id
        name {
          first
          last
        }
        user {
          id
          email
          distributor {
            id
            email
            name {
              first
              last
            }
            salesRep {
              id
              name
            }
          }
        }
      }
      model {
        hasRainGauge
        hasWeatherStation
      }
      location {
        lat
        lng
      }
    }
  }
`;
export type AdminStatusMapComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    AdminStatusMapQuery,
    AdminStatusMapQueryVariables
  >,
  'query'
>;

export const AdminStatusMapComponent = (
  props: AdminStatusMapComponentProps
) => (
  <ApolloReactComponents.Query<
    AdminStatusMapQuery,
    AdminStatusMapQueryVariables
  >
    query={AdminStatusMapDocument}
    {...props}
  />
);

/**
 * __useAdminStatusMapQuery__
 *
 * To run a query within a React component, call `useAdminStatusMapQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminStatusMapQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminStatusMapQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminStatusMapQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AdminStatusMapQuery,
    AdminStatusMapQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    AdminStatusMapQuery,
    AdminStatusMapQueryVariables
  >(AdminStatusMapDocument, baseOptions);
}
export function useAdminStatusMapLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AdminStatusMapQuery,
    AdminStatusMapQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    AdminStatusMapQuery,
    AdminStatusMapQueryVariables
  >(AdminStatusMapDocument, baseOptions);
}
export type AdminStatusMapQueryHookResult = ReturnType<
  typeof useAdminStatusMapQuery
>;
export type AdminStatusMapLazyQueryHookResult = ReturnType<
  typeof useAdminStatusMapLazyQuery
>;
export type AdminStatusMapQueryResult = ApolloReactCommon.QueryResult<
  AdminStatusMapQuery,
  AdminStatusMapQueryVariables
>;
export const SensorStatusDocument = gql`
  query SensorStatus($sensorId: String!) {
    sensor(id: $sensorId, admin: true) {
      id
      battery
      persistence
      inactive
      rssi
      statusWarnings
      field {
        id
        name
      }
      gateway {
        id
        connectionStatus
        sensorIds
        sensors {
          id
          connectionStatus
        }
      }
      name
      crop_type {
        id
        name
      }
      soil_type {
        id
        name
      }
      location {
        lat
        lng
      }
      gpsUpdated
      gpsLocked
      owner {
        id
        accountEmail
        name {
          first
          last
        }
        user {
          id
          hasAccountIssues
          logins
          activationCompleted
          phone
          sensors {
            id
            active
          }
          gateways {
            id
            active
          }
          distributor {
            id
            name {
              first
              last
            }
            salesRep {
              id
              name
            }
          }
        }
      }
      users {
        id
        user {
          id
          email
          name {
            first
            last
          }
        }
      }
      configuration {
        ack_timeout
        boot
        freq
        historical_enable
        hw_ver
        missed_message_sleep
        rpt_int
        sensor_var
        sw_ver
        t_sleep
        tx_high_threshold
        tx_low_threshold
        tx_power
      }
      configurationUpdated
      serviceLogs {
        name
        date
        location
        reason
        result
        timeSpent
        followUpTasks
        id
      }
    }
  }
`;
export type SensorStatusComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    SensorStatusQuery,
    SensorStatusQueryVariables
  >,
  'query'
> &
  (
    | { variables: SensorStatusQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const SensorStatusComponent = (props: SensorStatusComponentProps) => (
  <ApolloReactComponents.Query<SensorStatusQuery, SensorStatusQueryVariables>
    query={SensorStatusDocument}
    {...props}
  />
);

/**
 * __useSensorStatusQuery__
 *
 * To run a query within a React component, call `useSensorStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useSensorStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSensorStatusQuery({
 *   variables: {
 *      sensorId: // value for 'sensorId'
 *   },
 * });
 */
export function useSensorStatusQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    SensorStatusQuery,
    SensorStatusQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    SensorStatusQuery,
    SensorStatusQueryVariables
  >(SensorStatusDocument, baseOptions);
}
export function useSensorStatusLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SensorStatusQuery,
    SensorStatusQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    SensorStatusQuery,
    SensorStatusQueryVariables
  >(SensorStatusDocument, baseOptions);
}
export type SensorStatusQueryHookResult = ReturnType<
  typeof useSensorStatusQuery
>;
export type SensorStatusLazyQueryHookResult = ReturnType<
  typeof useSensorStatusLazyQuery
>;
export type SensorStatusQueryResult = ApolloReactCommon.QueryResult<
  SensorStatusQuery,
  SensorStatusQueryVariables
>;
export const SensorStatusDataDocument = gql`
  query SensorStatusData($sensorId: String!) {
    sensor(id: $sensorId, admin: true) {
      id
      data {
        adc
        gateway
        meas_no
        moistures
        rssi
        temp
        tensions
        tensionsWithTemp
        time
        vin
        bootCounter
        bootLimitCounter
        pwrOnRstCounter
        brnOutRstCounter
        extRstCounter
        wdtRstCounter
        sessionRetry
        sessionFail
        historicalMessages
        raw
        vic
        vpp
        nonsens
      }
    }
  }
`;
export type SensorStatusDataComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    SensorStatusDataQuery,
    SensorStatusDataQueryVariables
  >,
  'query'
> &
  (
    | { variables: SensorStatusDataQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const SensorStatusDataComponent = (
  props: SensorStatusDataComponentProps
) => (
  <ApolloReactComponents.Query<
    SensorStatusDataQuery,
    SensorStatusDataQueryVariables
  >
    query={SensorStatusDataDocument}
    {...props}
  />
);

/**
 * __useSensorStatusDataQuery__
 *
 * To run a query within a React component, call `useSensorStatusDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useSensorStatusDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSensorStatusDataQuery({
 *   variables: {
 *      sensorId: // value for 'sensorId'
 *   },
 * });
 */
export function useSensorStatusDataQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    SensorStatusDataQuery,
    SensorStatusDataQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    SensorStatusDataQuery,
    SensorStatusDataQueryVariables
  >(SensorStatusDataDocument, baseOptions);
}
export function useSensorStatusDataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SensorStatusDataQuery,
    SensorStatusDataQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    SensorStatusDataQuery,
    SensorStatusDataQueryVariables
  >(SensorStatusDataDocument, baseOptions);
}
export type SensorStatusDataQueryHookResult = ReturnType<
  typeof useSensorStatusDataQuery
>;
export type SensorStatusDataLazyQueryHookResult = ReturnType<
  typeof useSensorStatusDataLazyQuery
>;
export type SensorStatusDataQueryResult = ApolloReactCommon.QueryResult<
  SensorStatusDataQuery,
  SensorStatusDataQueryVariables
>;
export const SensorCommandSendDocument = gql`
  query SensorCommandSend($sensorId: String!) {
    sensor(id: $sensorId, admin: true) {
      id
      battery
      rssi
      sensor_time
      configuration {
        hw_ver
      }
    }
    sensorSoftwareVersions {
      name
      hardwareVersions {
        name
        variants
      }
    }
    sensorFlashLibrary {
      name
      defaultValue
      max
      min
    }
  }
`;
export type SensorCommandSendComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    SensorCommandSendQuery,
    SensorCommandSendQueryVariables
  >,
  'query'
> &
  (
    | { variables: SensorCommandSendQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const SensorCommandSendComponent = (
  props: SensorCommandSendComponentProps
) => (
  <ApolloReactComponents.Query<
    SensorCommandSendQuery,
    SensorCommandSendQueryVariables
  >
    query={SensorCommandSendDocument}
    {...props}
  />
);

/**
 * __useSensorCommandSendQuery__
 *
 * To run a query within a React component, call `useSensorCommandSendQuery` and pass it any options that fit your needs.
 * When your component renders, `useSensorCommandSendQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSensorCommandSendQuery({
 *   variables: {
 *      sensorId: // value for 'sensorId'
 *   },
 * });
 */
export function useSensorCommandSendQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    SensorCommandSendQuery,
    SensorCommandSendQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    SensorCommandSendQuery,
    SensorCommandSendQueryVariables
  >(SensorCommandSendDocument, baseOptions);
}
export function useSensorCommandSendLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SensorCommandSendQuery,
    SensorCommandSendQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    SensorCommandSendQuery,
    SensorCommandSendQueryVariables
  >(SensorCommandSendDocument, baseOptions);
}
export type SensorCommandSendQueryHookResult = ReturnType<
  typeof useSensorCommandSendQuery
>;
export type SensorCommandSendLazyQueryHookResult = ReturnType<
  typeof useSensorCommandSendLazyQuery
>;
export type SensorCommandSendQueryResult = ApolloReactCommon.QueryResult<
  SensorCommandSendQuery,
  SensorCommandSendQueryVariables
>;
export const SensorEditDocument = gql`
  mutation SensorEdit($input: SensorEditInput!) {
    commandSensorEditSend(input: $input) {
      success
    }
  }
`;
export type SensorEditMutationFn = ApolloReactCommon.MutationFunction<
  SensorEditMutation,
  SensorEditMutationVariables
>;
export type SensorEditComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    SensorEditMutation,
    SensorEditMutationVariables
  >,
  'mutation'
>;

export const SensorEditComponent = (props: SensorEditComponentProps) => (
  <ApolloReactComponents.Mutation<
    SensorEditMutation,
    SensorEditMutationVariables
  >
    mutation={SensorEditDocument}
    {...props}
  />
);

/**
 * __useSensorEditMutation__
 *
 * To run a mutation, you first call `useSensorEditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSensorEditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sensorEditMutation, { data, loading, error }] = useSensorEditMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSensorEditMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SensorEditMutation,
    SensorEditMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    SensorEditMutation,
    SensorEditMutationVariables
  >(SensorEditDocument, baseOptions);
}
export type SensorEditMutationHookResult = ReturnType<
  typeof useSensorEditMutation
>;
export type SensorEditMutationResult = ApolloReactCommon.MutationResult<
  SensorEditMutation
>;
export type SensorEditMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SensorEditMutation,
  SensorEditMutationVariables
>;
export const SensorReprogramDocument = gql`
  mutation SensorReprogram($input: SensorReprogramInput!) {
    commandSensorReprogramSend(input: $input) {
      success
    }
  }
`;
export type SensorReprogramMutationFn = ApolloReactCommon.MutationFunction<
  SensorReprogramMutation,
  SensorReprogramMutationVariables
>;
export type SensorReprogramComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    SensorReprogramMutation,
    SensorReprogramMutationVariables
  >,
  'mutation'
>;

export const SensorReprogramComponent = (
  props: SensorReprogramComponentProps
) => (
  <ApolloReactComponents.Mutation<
    SensorReprogramMutation,
    SensorReprogramMutationVariables
  >
    mutation={SensorReprogramDocument}
    {...props}
  />
);

/**
 * __useSensorReprogramMutation__
 *
 * To run a mutation, you first call `useSensorReprogramMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSensorReprogramMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sensorReprogramMutation, { data, loading, error }] = useSensorReprogramMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSensorReprogramMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SensorReprogramMutation,
    SensorReprogramMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    SensorReprogramMutation,
    SensorReprogramMutationVariables
  >(SensorReprogramDocument, baseOptions);
}
export type SensorReprogramMutationHookResult = ReturnType<
  typeof useSensorReprogramMutation
>;
export type SensorReprogramMutationResult = ApolloReactCommon.MutationResult<
  SensorReprogramMutation
>;
export type SensorReprogramMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SensorReprogramMutation,
  SensorReprogramMutationVariables
>;
export const SensorResetDocument = gql`
  mutation SensorReset($input: SensorResetInput!) {
    commandSensorResetSend(input: $input) {
      success
    }
  }
`;
export type SensorResetMutationFn = ApolloReactCommon.MutationFunction<
  SensorResetMutation,
  SensorResetMutationVariables
>;
export type SensorResetComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    SensorResetMutation,
    SensorResetMutationVariables
  >,
  'mutation'
>;

export const SensorResetComponent = (props: SensorResetComponentProps) => (
  <ApolloReactComponents.Mutation<
    SensorResetMutation,
    SensorResetMutationVariables
  >
    mutation={SensorResetDocument}
    {...props}
  />
);

/**
 * __useSensorResetMutation__
 *
 * To run a mutation, you first call `useSensorResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSensorResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sensorResetMutation, { data, loading, error }] = useSensorResetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSensorResetMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SensorResetMutation,
    SensorResetMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    SensorResetMutation,
    SensorResetMutationVariables
  >(SensorResetDocument, baseOptions);
}
export type SensorResetMutationHookResult = ReturnType<
  typeof useSensorResetMutation
>;
export type SensorResetMutationResult = ApolloReactCommon.MutationResult<
  SensorResetMutation
>;
export type SensorResetMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SensorResetMutation,
  SensorResetMutationVariables
>;
export const SensorRetryDocument = gql`
  mutation SensorRetry($input: SensorRetryInput!) {
    commandSensorRetrySend(input: $input) {
      success
    }
  }
`;
export type SensorRetryMutationFn = ApolloReactCommon.MutationFunction<
  SensorRetryMutation,
  SensorRetryMutationVariables
>;
export type SensorRetryComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    SensorRetryMutation,
    SensorRetryMutationVariables
  >,
  'mutation'
>;

export const SensorRetryComponent = (props: SensorRetryComponentProps) => (
  <ApolloReactComponents.Mutation<
    SensorRetryMutation,
    SensorRetryMutationVariables
  >
    mutation={SensorRetryDocument}
    {...props}
  />
);

/**
 * __useSensorRetryMutation__
 *
 * To run a mutation, you first call `useSensorRetryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSensorRetryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sensorRetryMutation, { data, loading, error }] = useSensorRetryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSensorRetryMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SensorRetryMutation,
    SensorRetryMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    SensorRetryMutation,
    SensorRetryMutationVariables
  >(SensorRetryDocument, baseOptions);
}
export type SensorRetryMutationHookResult = ReturnType<
  typeof useSensorRetryMutation
>;
export type SensorRetryMutationResult = ApolloReactCommon.MutationResult<
  SensorRetryMutation
>;
export type SensorRetryMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SensorRetryMutation,
  SensorRetryMutationVariables
>;
export const RegisterStripeAccountDocument = gql`
  mutation RegisterStripeAccount($input: DistributorStripeRegisterInput!) {
    distributorStripeRegister(input: $input) {
      success
    }
  }
`;
export type RegisterStripeAccountMutationFn = ApolloReactCommon.MutationFunction<
  RegisterStripeAccountMutation,
  RegisterStripeAccountMutationVariables
>;
export type RegisterStripeAccountComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    RegisterStripeAccountMutation,
    RegisterStripeAccountMutationVariables
  >,
  'mutation'
>;

export const RegisterStripeAccountComponent = (
  props: RegisterStripeAccountComponentProps
) => (
  <ApolloReactComponents.Mutation<
    RegisterStripeAccountMutation,
    RegisterStripeAccountMutationVariables
  >
    mutation={RegisterStripeAccountDocument}
    {...props}
  />
);

/**
 * __useRegisterStripeAccountMutation__
 *
 * To run a mutation, you first call `useRegisterStripeAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterStripeAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerStripeAccountMutation, { data, loading, error }] = useRegisterStripeAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterStripeAccountMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RegisterStripeAccountMutation,
    RegisterStripeAccountMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    RegisterStripeAccountMutation,
    RegisterStripeAccountMutationVariables
  >(RegisterStripeAccountDocument, baseOptions);
}
export type RegisterStripeAccountMutationHookResult = ReturnType<
  typeof useRegisterStripeAccountMutation
>;
export type RegisterStripeAccountMutationResult = ApolloReactCommon.MutationResult<
  RegisterStripeAccountMutation
>;
export type RegisterStripeAccountMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RegisterStripeAccountMutation,
  RegisterStripeAccountMutationVariables
>;
export const BillingDocument = gql`
  query Billing {
    currentDistributor {
      id
      email
      name {
        first
        last
      }
      stripeInfo {
        stripeURL
      }
    }
  }
`;
export type BillingComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    BillingQuery,
    BillingQueryVariables
  >,
  'query'
>;

export const BillingComponent = (props: BillingComponentProps) => (
  <ApolloReactComponents.Query<BillingQuery, BillingQueryVariables>
    query={BillingDocument}
    {...props}
  />
);

/**
 * __useBillingQuery__
 *
 * To run a query within a React component, call `useBillingQuery` and pass it any options that fit your needs.
 * When your component renders, `useBillingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBillingQuery({
 *   variables: {
 *   },
 * });
 */
export function useBillingQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    BillingQuery,
    BillingQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<BillingQuery, BillingQueryVariables>(
    BillingDocument,
    baseOptions
  );
}
export function useBillingLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    BillingQuery,
    BillingQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<BillingQuery, BillingQueryVariables>(
    BillingDocument,
    baseOptions
  );
}
export type BillingQueryHookResult = ReturnType<typeof useBillingQuery>;
export type BillingLazyQueryHookResult = ReturnType<typeof useBillingLazyQuery>;
export type BillingQueryResult = ApolloReactCommon.QueryResult<
  BillingQuery,
  BillingQueryVariables
>;
export const GetBindOrderDocument = gql`
  query GetBindOrder($orderId: Int!) {
    order(orderId: $orderId) {
      id
      status
      boundIds
      details {
        hardware {
          id
          quantity
          model {
            ...ModelFields
          }
        }
      }
      unboundHardware {
        id
        model {
          ...ModelFields
        }
      }
    }
  }
  ${ModelFieldsFragmentDoc}
`;
export type GetBindOrderComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetBindOrderQuery,
    GetBindOrderQueryVariables
  >,
  'query'
> &
  (
    | { variables: GetBindOrderQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const GetBindOrderComponent = (props: GetBindOrderComponentProps) => (
  <ApolloReactComponents.Query<GetBindOrderQuery, GetBindOrderQueryVariables>
    query={GetBindOrderDocument}
    {...props}
  />
);

/**
 * __useGetBindOrderQuery__
 *
 * To run a query within a React component, call `useGetBindOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBindOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBindOrderQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useGetBindOrderQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetBindOrderQuery,
    GetBindOrderQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetBindOrderQuery,
    GetBindOrderQueryVariables
  >(GetBindOrderDocument, baseOptions);
}
export function useGetBindOrderLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetBindOrderQuery,
    GetBindOrderQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetBindOrderQuery,
    GetBindOrderQueryVariables
  >(GetBindOrderDocument, baseOptions);
}
export type GetBindOrderQueryHookResult = ReturnType<
  typeof useGetBindOrderQuery
>;
export type GetBindOrderLazyQueryHookResult = ReturnType<
  typeof useGetBindOrderLazyQuery
>;
export type GetBindOrderQueryResult = ApolloReactCommon.QueryResult<
  GetBindOrderQuery,
  GetBindOrderQueryVariables
>;
export const BindOrderDocument = gql`
  mutation BindOrder($input: BindOrderInput!) {
    bindOrder(input: $input) {
      id
    }
  }
`;
export type BindOrderMutationFn = ApolloReactCommon.MutationFunction<
  BindOrderMutation,
  BindOrderMutationVariables
>;
export type BindOrderComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    BindOrderMutation,
    BindOrderMutationVariables
  >,
  'mutation'
>;

export const BindOrderComponent = (props: BindOrderComponentProps) => (
  <ApolloReactComponents.Mutation<BindOrderMutation, BindOrderMutationVariables>
    mutation={BindOrderDocument}
    {...props}
  />
);

/**
 * __useBindOrderMutation__
 *
 * To run a mutation, you first call `useBindOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBindOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bindOrderMutation, { data, loading, error }] = useBindOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBindOrderMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    BindOrderMutation,
    BindOrderMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    BindOrderMutation,
    BindOrderMutationVariables
  >(BindOrderDocument, baseOptions);
}
export type BindOrderMutationHookResult = ReturnType<
  typeof useBindOrderMutation
>;
export type BindOrderMutationResult = ApolloReactCommon.MutationResult<
  BindOrderMutation
>;
export type BindOrderMutationOptions = ApolloReactCommon.BaseMutationOptions<
  BindOrderMutation,
  BindOrderMutationVariables
>;
export const ShipOrderDocument = gql`
  mutation ShipOrder($input: ShipOrderInput!) {
    shipOrder(input: $input) {
      id
    }
  }
`;
export type ShipOrderMutationFn = ApolloReactCommon.MutationFunction<
  ShipOrderMutation,
  ShipOrderMutationVariables
>;
export type ShipOrderComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    ShipOrderMutation,
    ShipOrderMutationVariables
  >,
  'mutation'
>;

export const ShipOrderComponent = (props: ShipOrderComponentProps) => (
  <ApolloReactComponents.Mutation<ShipOrderMutation, ShipOrderMutationVariables>
    mutation={ShipOrderDocument}
    {...props}
  />
);

/**
 * __useShipOrderMutation__
 *
 * To run a mutation, you first call `useShipOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShipOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shipOrderMutation, { data, loading, error }] = useShipOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useShipOrderMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ShipOrderMutation,
    ShipOrderMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ShipOrderMutation,
    ShipOrderMutationVariables
  >(ShipOrderDocument, baseOptions);
}
export type ShipOrderMutationHookResult = ReturnType<
  typeof useShipOrderMutation
>;
export type ShipOrderMutationResult = ApolloReactCommon.MutationResult<
  ShipOrderMutation
>;
export type ShipOrderMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ShipOrderMutation,
  ShipOrderMutationVariables
>;
export const EditHardwareDocument = gql`
  query EditHardware($customerId: String!) {
    customer(customerId: $customerId) {
      id
      name {
        first
        last
      }
      user {
        id
        sensors(onlyOwned: true) {
          id
          soil_type {
            id
            name
          }
          crop_type {
            id
            name
          }
          model {
            type
            antennaLength
            moistureDepths
          }
          order {
            id
            placedTimestamp
          }
        }
        gateways(onlyOwned: true) {
          id
          model {
            type
            antennaLength
          }
          order {
            id
            placedTimestamp
          }
        }
      }
    }
    soils {
      id
      name
    }
    crops {
      id
      name
    }
    currentDistributor {
      id
      unassigned {
        sensors {
          id
          name
          model {
            type
            moistureDepths
            antennaLength
          }
          order {
            id
            placedTimestamp
          }
          soil_type {
            id
            name
          }
          crop_type {
            id
            name
          }
        }
        gateways {
          id
          name
          model {
            type
            moistureDepths
            antennaLength
          }
          order {
            id
            placedTimestamp
          }
        }
      }
    }
  }
`;
export type EditHardwareComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    EditHardwareQuery,
    EditHardwareQueryVariables
  >,
  'query'
> &
  (
    | { variables: EditHardwareQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const EditHardwareComponent = (props: EditHardwareComponentProps) => (
  <ApolloReactComponents.Query<EditHardwareQuery, EditHardwareQueryVariables>
    query={EditHardwareDocument}
    {...props}
  />
);

/**
 * __useEditHardwareQuery__
 *
 * To run a query within a React component, call `useEditHardwareQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditHardwareQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditHardwareQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useEditHardwareQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    EditHardwareQuery,
    EditHardwareQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    EditHardwareQuery,
    EditHardwareQueryVariables
  >(EditHardwareDocument, baseOptions);
}
export function useEditHardwareLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    EditHardwareQuery,
    EditHardwareQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    EditHardwareQuery,
    EditHardwareQueryVariables
  >(EditHardwareDocument, baseOptions);
}
export type EditHardwareQueryHookResult = ReturnType<
  typeof useEditHardwareQuery
>;
export type EditHardwareLazyQueryHookResult = ReturnType<
  typeof useEditHardwareLazyQuery
>;
export type EditHardwareQueryResult = ApolloReactCommon.QueryResult<
  EditHardwareQuery,
  EditHardwareQueryVariables
>;
export const EditCustomerHardwareDocument = gql`
  mutation EditCustomerHardware($input: EditCustomerHardwareInputType!) {
    editCustomerHardware(input: $input) {
      id
    }
  }
`;
export type EditCustomerHardwareMutationFn = ApolloReactCommon.MutationFunction<
  EditCustomerHardwareMutation,
  EditCustomerHardwareMutationVariables
>;
export type EditCustomerHardwareComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    EditCustomerHardwareMutation,
    EditCustomerHardwareMutationVariables
  >,
  'mutation'
>;

export const EditCustomerHardwareComponent = (
  props: EditCustomerHardwareComponentProps
) => (
  <ApolloReactComponents.Mutation<
    EditCustomerHardwareMutation,
    EditCustomerHardwareMutationVariables
  >
    mutation={EditCustomerHardwareDocument}
    {...props}
  />
);

/**
 * __useEditCustomerHardwareMutation__
 *
 * To run a mutation, you first call `useEditCustomerHardwareMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditCustomerHardwareMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editCustomerHardwareMutation, { data, loading, error }] = useEditCustomerHardwareMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditCustomerHardwareMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    EditCustomerHardwareMutation,
    EditCustomerHardwareMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    EditCustomerHardwareMutation,
    EditCustomerHardwareMutationVariables
  >(EditCustomerHardwareDocument, baseOptions);
}
export type EditCustomerHardwareMutationHookResult = ReturnType<
  typeof useEditCustomerHardwareMutation
>;
export type EditCustomerHardwareMutationResult = ApolloReactCommon.MutationResult<
  EditCustomerHardwareMutation
>;
export type EditCustomerHardwareMutationOptions = ApolloReactCommon.BaseMutationOptions<
  EditCustomerHardwareMutation,
  EditCustomerHardwareMutationVariables
>;
export const UpdateSensorDocument = gql`
  mutation UpdateSensor($sensorId: String!, $input: UpdateSensorInput!) {
    updateSensor(sensorId: $sensorId, input: $input) {
      sensorId
    }
  }
`;
export type UpdateSensorMutationFn = ApolloReactCommon.MutationFunction<
  UpdateSensorMutation,
  UpdateSensorMutationVariables
>;
export type UpdateSensorComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateSensorMutation,
    UpdateSensorMutationVariables
  >,
  'mutation'
>;

export const UpdateSensorComponent = (props: UpdateSensorComponentProps) => (
  <ApolloReactComponents.Mutation<
    UpdateSensorMutation,
    UpdateSensorMutationVariables
  >
    mutation={UpdateSensorDocument}
    {...props}
  />
);

/**
 * __useUpdateSensorMutation__
 *
 * To run a mutation, you first call `useUpdateSensorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSensorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSensorMutation, { data, loading, error }] = useUpdateSensorMutation({
 *   variables: {
 *      sensorId: // value for 'sensorId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSensorMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateSensorMutation,
    UpdateSensorMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateSensorMutation,
    UpdateSensorMutationVariables
  >(UpdateSensorDocument, baseOptions);
}
export type UpdateSensorMutationHookResult = ReturnType<
  typeof useUpdateSensorMutation
>;
export type UpdateSensorMutationResult = ApolloReactCommon.MutationResult<
  UpdateSensorMutation
>;
export type UpdateSensorMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateSensorMutation,
  UpdateSensorMutationVariables
>;
export const SendVerificationEmailDocument = gql`
  mutation SendVerificationEmail($input: SendVerificationEmailInput!) {
    sendVerificationEmail(input: $input) {
      success
    }
  }
`;
export type SendVerificationEmailMutationFn = ApolloReactCommon.MutationFunction<
  SendVerificationEmailMutation,
  SendVerificationEmailMutationVariables
>;
export type SendVerificationEmailComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    SendVerificationEmailMutation,
    SendVerificationEmailMutationVariables
  >,
  'mutation'
>;

export const SendVerificationEmailComponent = (
  props: SendVerificationEmailComponentProps
) => (
  <ApolloReactComponents.Mutation<
    SendVerificationEmailMutation,
    SendVerificationEmailMutationVariables
  >
    mutation={SendVerificationEmailDocument}
    {...props}
  />
);

/**
 * __useSendVerificationEmailMutation__
 *
 * To run a mutation, you first call `useSendVerificationEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendVerificationEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendVerificationEmailMutation, { data, loading, error }] = useSendVerificationEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendVerificationEmailMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SendVerificationEmailMutation,
    SendVerificationEmailMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    SendVerificationEmailMutation,
    SendVerificationEmailMutationVariables
  >(SendVerificationEmailDocument, baseOptions);
}
export type SendVerificationEmailMutationHookResult = ReturnType<
  typeof useSendVerificationEmailMutation
>;
export type SendVerificationEmailMutationResult = ApolloReactCommon.MutationResult<
  SendVerificationEmailMutation
>;
export type SendVerificationEmailMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SendVerificationEmailMutation,
  SendVerificationEmailMutationVariables
>;
export const ChangePasswordDocument = gql`
  mutation ChangePassword($input: DistributorChangePasswordInput!) {
    changeDistributorPassword(input: $input) {
      id
    }
  }
`;
export type ChangePasswordMutationFn = ApolloReactCommon.MutationFunction<
  ChangePasswordMutation,
  ChangePasswordMutationVariables
>;
export type ChangePasswordComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    ChangePasswordMutation,
    ChangePasswordMutationVariables
  >,
  'mutation'
>;

export const ChangePasswordComponent = (
  props: ChangePasswordComponentProps
) => (
  <ApolloReactComponents.Mutation<
    ChangePasswordMutation,
    ChangePasswordMutationVariables
  >
    mutation={ChangePasswordDocument}
    {...props}
  />
);

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangePasswordMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ChangePasswordMutation,
    ChangePasswordMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ChangePasswordMutation,
    ChangePasswordMutationVariables
  >(ChangePasswordDocument, baseOptions);
}
export type ChangePasswordMutationHookResult = ReturnType<
  typeof useChangePasswordMutation
>;
export type ChangePasswordMutationResult = ApolloReactCommon.MutationResult<
  ChangePasswordMutation
>;
export type ChangePasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ChangePasswordMutation,
  ChangePasswordMutationVariables
>;
export const SignTCDocument = gql`
  mutation SignTC($input: DistributorSignTCInput!) {
    distributorSignTC(input: $input) {
      id
    }
  }
`;
export type SignTCMutationFn = ApolloReactCommon.MutationFunction<
  SignTCMutation,
  SignTCMutationVariables
>;
export type SignTCComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    SignTCMutation,
    SignTCMutationVariables
  >,
  'mutation'
>;

export const SignTCComponent = (props: SignTCComponentProps) => (
  <ApolloReactComponents.Mutation<SignTCMutation, SignTCMutationVariables>
    mutation={SignTCDocument}
    {...props}
  />
);

/**
 * __useSignTCMutation__
 *
 * To run a mutation, you first call `useSignTCMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignTCMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signTcMutation, { data, loading, error }] = useSignTCMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignTCMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SignTCMutation,
    SignTCMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<SignTCMutation, SignTCMutationVariables>(
    SignTCDocument,
    baseOptions
  );
}
export type SignTCMutationHookResult = ReturnType<typeof useSignTCMutation>;
export type SignTCMutationResult = ApolloReactCommon.MutationResult<
  SignTCMutation
>;
export type SignTCMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SignTCMutation,
  SignTCMutationVariables
>;
export const CustomerDocument = gql`
  query Customer($customerId: String!) {
    customer(customerId: $customerId, includeZoho: true) {
      id
      email
      phone
      repId
      name {
        first
        last
      }
      addresses {
        id
        city
        state
        zip
        street
        name {
          first
          last
        }
      }
      user {
        id
        activated
        activationCompleted
        prepaid
        prepaidStart
        prepaidUntil
        managedServiceStatuses {
          initialPaymentMade
          invoiceUrl
          orderId
          subscriptionStarted
        }
        sensors {
          id
          isOwner
          name
          activePlan
          soil_type {
            id
            name
          }
          crop_type {
            id
            name
          }
          model {
            type
            antennaLength
            moistureDepths
          }
          order {
            id
            placedTimestamp
          }
        }
        gateways {
          id
          isOwner
          activePlan
          hasRainGauge
          model {
            type
            antennaLength
          }
          order {
            id
            placedTimestamp
          }
        }
        fields {
          id
          name
          sensors {
            id
            crop_type {
              id
              name
            }
            crop_variety
          }
          irrigationMetadata {
            irrigationType
          }
        }
      }
    }
    currentDistributor {
      id
    }
  }
`;
export type CustomerComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    CustomerQuery,
    CustomerQueryVariables
  >,
  'query'
> &
  ({ variables: CustomerQueryVariables; skip?: boolean } | { skip: boolean });

export const CustomerComponent = (props: CustomerComponentProps) => (
  <ApolloReactComponents.Query<CustomerQuery, CustomerQueryVariables>
    query={CustomerDocument}
    {...props}
  />
);

/**
 * __useCustomerQuery__
 *
 * To run a query within a React component, call `useCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useCustomerQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CustomerQuery,
    CustomerQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<CustomerQuery, CustomerQueryVariables>(
    CustomerDocument,
    baseOptions
  );
}
export function useCustomerLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CustomerQuery,
    CustomerQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<CustomerQuery, CustomerQueryVariables>(
    CustomerDocument,
    baseOptions
  );
}
export type CustomerQueryHookResult = ReturnType<typeof useCustomerQuery>;
export type CustomerLazyQueryHookResult = ReturnType<
  typeof useCustomerLazyQuery
>;
export type CustomerQueryResult = ApolloReactCommon.QueryResult<
  CustomerQuery,
  CustomerQueryVariables
>;
export const StartManagedServiceSubscriptionDocument = gql`
  mutation StartManagedServiceSubscription($input: StartManagedServiceInput!) {
    startManagedService(input: $input) {
      success
    }
  }
`;
export type StartManagedServiceSubscriptionMutationFn = ApolloReactCommon.MutationFunction<
  StartManagedServiceSubscriptionMutation,
  StartManagedServiceSubscriptionMutationVariables
>;
export type StartManagedServiceSubscriptionComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    StartManagedServiceSubscriptionMutation,
    StartManagedServiceSubscriptionMutationVariables
  >,
  'mutation'
>;

export const StartManagedServiceSubscriptionComponent = (
  props: StartManagedServiceSubscriptionComponentProps
) => (
  <ApolloReactComponents.Mutation<
    StartManagedServiceSubscriptionMutation,
    StartManagedServiceSubscriptionMutationVariables
  >
    mutation={StartManagedServiceSubscriptionDocument}
    {...props}
  />
);

/**
 * __useStartManagedServiceSubscriptionMutation__
 *
 * To run a mutation, you first call `useStartManagedServiceSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartManagedServiceSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startManagedServiceSubscriptionMutation, { data, loading, error }] = useStartManagedServiceSubscriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStartManagedServiceSubscriptionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    StartManagedServiceSubscriptionMutation,
    StartManagedServiceSubscriptionMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    StartManagedServiceSubscriptionMutation,
    StartManagedServiceSubscriptionMutationVariables
  >(StartManagedServiceSubscriptionDocument, baseOptions);
}
export type StartManagedServiceSubscriptionMutationHookResult = ReturnType<
  typeof useStartManagedServiceSubscriptionMutation
>;
export type StartManagedServiceSubscriptionMutationResult = ApolloReactCommon.MutationResult<
  StartManagedServiceSubscriptionMutation
>;
export type StartManagedServiceSubscriptionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  StartManagedServiceSubscriptionMutation,
  StartManagedServiceSubscriptionMutationVariables
>;
export const CustomerRepInfoDocument = gql`
  query CustomerRepInfo($repId: String!) {
    rep(repId: $repId) {
      id
      name {
        first
        last
      }
    }
  }
`;
export type CustomerRepInfoComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    CustomerRepInfoQuery,
    CustomerRepInfoQueryVariables
  >,
  'query'
> &
  (
    | { variables: CustomerRepInfoQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const CustomerRepInfoComponent = (
  props: CustomerRepInfoComponentProps
) => (
  <ApolloReactComponents.Query<
    CustomerRepInfoQuery,
    CustomerRepInfoQueryVariables
  >
    query={CustomerRepInfoDocument}
    {...props}
  />
);

/**
 * __useCustomerRepInfoQuery__
 *
 * To run a query within a React component, call `useCustomerRepInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerRepInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerRepInfoQuery({
 *   variables: {
 *      repId: // value for 'repId'
 *   },
 * });
 */
export function useCustomerRepInfoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CustomerRepInfoQuery,
    CustomerRepInfoQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    CustomerRepInfoQuery,
    CustomerRepInfoQueryVariables
  >(CustomerRepInfoDocument, baseOptions);
}
export function useCustomerRepInfoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CustomerRepInfoQuery,
    CustomerRepInfoQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    CustomerRepInfoQuery,
    CustomerRepInfoQueryVariables
  >(CustomerRepInfoDocument, baseOptions);
}
export type CustomerRepInfoQueryHookResult = ReturnType<
  typeof useCustomerRepInfoQuery
>;
export type CustomerRepInfoLazyQueryHookResult = ReturnType<
  typeof useCustomerRepInfoLazyQuery
>;
export type CustomerRepInfoQueryResult = ApolloReactCommon.QueryResult<
  CustomerRepInfoQuery,
  CustomerRepInfoQueryVariables
>;
export const RemoveCustomerDocument = gql`
  mutation RemoveCustomer($input: RemoveCustomerInput!) {
    removeCustomer(input: $input) {
      id
    }
  }
`;
export type RemoveCustomerMutationFn = ApolloReactCommon.MutationFunction<
  RemoveCustomerMutation,
  RemoveCustomerMutationVariables
>;
export type RemoveCustomerComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    RemoveCustomerMutation,
    RemoveCustomerMutationVariables
  >,
  'mutation'
>;

export const RemoveCustomerComponent = (
  props: RemoveCustomerComponentProps
) => (
  <ApolloReactComponents.Mutation<
    RemoveCustomerMutation,
    RemoveCustomerMutationVariables
  >
    mutation={RemoveCustomerDocument}
    {...props}
  />
);

/**
 * __useRemoveCustomerMutation__
 *
 * To run a mutation, you first call `useRemoveCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCustomerMutation, { data, loading, error }] = useRemoveCustomerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveCustomerMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RemoveCustomerMutation,
    RemoveCustomerMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    RemoveCustomerMutation,
    RemoveCustomerMutationVariables
  >(RemoveCustomerDocument, baseOptions);
}
export type RemoveCustomerMutationHookResult = ReturnType<
  typeof useRemoveCustomerMutation
>;
export type RemoveCustomerMutationResult = ApolloReactCommon.MutationResult<
  RemoveCustomerMutation
>;
export type RemoveCustomerMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RemoveCustomerMutation,
  RemoveCustomerMutationVariables
>;
export const ActivateCustomerDocument = gql`
  mutation ActivateCustomer($input: ActivateCustomerInput!) {
    activateCustomer(input: $input) {
      userId
    }
  }
`;
export type ActivateCustomerMutationFn = ApolloReactCommon.MutationFunction<
  ActivateCustomerMutation,
  ActivateCustomerMutationVariables
>;
export type ActivateCustomerComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    ActivateCustomerMutation,
    ActivateCustomerMutationVariables
  >,
  'mutation'
>;

export const ActivateCustomerComponent = (
  props: ActivateCustomerComponentProps
) => (
  <ApolloReactComponents.Mutation<
    ActivateCustomerMutation,
    ActivateCustomerMutationVariables
  >
    mutation={ActivateCustomerDocument}
    {...props}
  />
);

/**
 * __useActivateCustomerMutation__
 *
 * To run a mutation, you first call `useActivateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateCustomerMutation, { data, loading, error }] = useActivateCustomerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActivateCustomerMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ActivateCustomerMutation,
    ActivateCustomerMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ActivateCustomerMutation,
    ActivateCustomerMutationVariables
  >(ActivateCustomerDocument, baseOptions);
}
export type ActivateCustomerMutationHookResult = ReturnType<
  typeof useActivateCustomerMutation
>;
export type ActivateCustomerMutationResult = ApolloReactCommon.MutationResult<
  ActivateCustomerMutation
>;
export type ActivateCustomerMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ActivateCustomerMutation,
  ActivateCustomerMutationVariables
>;
export const ResendActivationEmailDocument = gql`
  mutation ResendActivationEmail($customerId: String!) {
    activationResendEmail(customerId: $customerId)
  }
`;
export type ResendActivationEmailMutationFn = ApolloReactCommon.MutationFunction<
  ResendActivationEmailMutation,
  ResendActivationEmailMutationVariables
>;
export type ResendActivationEmailComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    ResendActivationEmailMutation,
    ResendActivationEmailMutationVariables
  >,
  'mutation'
>;

export const ResendActivationEmailComponent = (
  props: ResendActivationEmailComponentProps
) => (
  <ApolloReactComponents.Mutation<
    ResendActivationEmailMutation,
    ResendActivationEmailMutationVariables
  >
    mutation={ResendActivationEmailDocument}
    {...props}
  />
);

/**
 * __useResendActivationEmailMutation__
 *
 * To run a mutation, you first call `useResendActivationEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendActivationEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendActivationEmailMutation, { data, loading, error }] = useResendActivationEmailMutation({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useResendActivationEmailMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ResendActivationEmailMutation,
    ResendActivationEmailMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ResendActivationEmailMutation,
    ResendActivationEmailMutationVariables
  >(ResendActivationEmailDocument, baseOptions);
}
export type ResendActivationEmailMutationHookResult = ReturnType<
  typeof useResendActivationEmailMutation
>;
export type ResendActivationEmailMutationResult = ApolloReactCommon.MutationResult<
  ResendActivationEmailMutation
>;
export type ResendActivationEmailMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ResendActivationEmailMutation,
  ResendActivationEmailMutationVariables
>;
export const FastCustomersDocument = gql`
  query FastCustomers($first: Int!, $after: String, $search: String) {
    currentDistributor {
      id
      fastCustomers(first: $first, after: $after, search: $search) {
        edges {
          cursor
          node {
            email
            id
            phone
            name {
              first
              last
            }
            user {
              id
              gateways {
                id
              }
              sensors {
                id
              }
            }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
`;
export type FastCustomersComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    FastCustomersQuery,
    FastCustomersQueryVariables
  >,
  'query'
> &
  (
    | { variables: FastCustomersQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const FastCustomersComponent = (props: FastCustomersComponentProps) => (
  <ApolloReactComponents.Query<FastCustomersQuery, FastCustomersQueryVariables>
    query={FastCustomersDocument}
    {...props}
  />
);

/**
 * __useFastCustomersQuery__
 *
 * To run a query within a React component, call `useFastCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFastCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFastCustomersQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useFastCustomersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    FastCustomersQuery,
    FastCustomersQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    FastCustomersQuery,
    FastCustomersQueryVariables
  >(FastCustomersDocument, baseOptions);
}
export function useFastCustomersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FastCustomersQuery,
    FastCustomersQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    FastCustomersQuery,
    FastCustomersQueryVariables
  >(FastCustomersDocument, baseOptions);
}
export type FastCustomersQueryHookResult = ReturnType<
  typeof useFastCustomersQuery
>;
export type FastCustomersLazyQueryHookResult = ReturnType<
  typeof useFastCustomersLazyQuery
>;
export type FastCustomersQueryResult = ApolloReactCommon.QueryResult<
  FastCustomersQuery,
  FastCustomersQueryVariables
>;
export const OrderDetailsDocument = gql`
  query OrderDetails($customerId: String, $customer: Boolean!) {
    customer(customerId: $customerId) @include(if: $customer) {
      id
      email
      name {
        first
        last
      }
    }
    currentDistributor {
      id
      pricing {
        id
        hardware {
          modelId
          prices {
            quantity
            price
          }
        }
      }
      reps {
        id
        email
        phone
        name {
          first
          last
        }
        addresses {
          id
          street
          city
          state
          zip
          name {
            first
            last
          }
        }
      }
    }
  }
`;
export type OrderDetailsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    OrderDetailsQuery,
    OrderDetailsQueryVariables
  >,
  'query'
> &
  (
    | { variables: OrderDetailsQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const OrderDetailsComponent = (props: OrderDetailsComponentProps) => (
  <ApolloReactComponents.Query<OrderDetailsQuery, OrderDetailsQueryVariables>
    query={OrderDetailsDocument}
    {...props}
  />
);

/**
 * __useOrderDetailsQuery__
 *
 * To run a query within a React component, call `useOrderDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderDetailsQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      customer: // value for 'customer'
 *   },
 * });
 */
export function useOrderDetailsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    OrderDetailsQuery,
    OrderDetailsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    OrderDetailsQuery,
    OrderDetailsQueryVariables
  >(OrderDetailsDocument, baseOptions);
}
export function useOrderDetailsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OrderDetailsQuery,
    OrderDetailsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    OrderDetailsQuery,
    OrderDetailsQueryVariables
  >(OrderDetailsDocument, baseOptions);
}
export type OrderDetailsQueryHookResult = ReturnType<
  typeof useOrderDetailsQuery
>;
export type OrderDetailsLazyQueryHookResult = ReturnType<
  typeof useOrderDetailsLazyQuery
>;
export type OrderDetailsQueryResult = ApolloReactCommon.QueryResult<
  OrderDetailsQuery,
  OrderDetailsQueryVariables
>;
export const AddOrderDocument = gql`
  mutation AddOrder($input: AddOrderInput!) {
    addOrder(input: $input) {
      id
    }
  }
`;
export type AddOrderMutationFn = ApolloReactCommon.MutationFunction<
  AddOrderMutation,
  AddOrderMutationVariables
>;
export type AddOrderComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    AddOrderMutation,
    AddOrderMutationVariables
  >,
  'mutation'
>;

export const AddOrderComponent = (props: AddOrderComponentProps) => (
  <ApolloReactComponents.Mutation<AddOrderMutation, AddOrderMutationVariables>
    mutation={AddOrderDocument}
    {...props}
  />
);

/**
 * __useAddOrderMutation__
 *
 * To run a mutation, you first call `useAddOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOrderMutation, { data, loading, error }] = useAddOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddOrderMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddOrderMutation,
    AddOrderMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    AddOrderMutation,
    AddOrderMutationVariables
  >(AddOrderDocument, baseOptions);
}
export type AddOrderMutationHookResult = ReturnType<typeof useAddOrderMutation>;
export type AddOrderMutationResult = ApolloReactCommon.MutationResult<
  AddOrderMutation
>;
export type AddOrderMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddOrderMutation,
  AddOrderMutationVariables
>;
export const DistributorBillingStatusDocument = gql`
  query DistributorBillingStatus {
    currentDistributor {
      id
      stripeInfo {
        stripeURL
      }
    }
  }
`;
export type DistributorBillingStatusComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    DistributorBillingStatusQuery,
    DistributorBillingStatusQueryVariables
  >,
  'query'
>;

export const DistributorBillingStatusComponent = (
  props: DistributorBillingStatusComponentProps
) => (
  <ApolloReactComponents.Query<
    DistributorBillingStatusQuery,
    DistributorBillingStatusQueryVariables
  >
    query={DistributorBillingStatusDocument}
    {...props}
  />
);

/**
 * __useDistributorBillingStatusQuery__
 *
 * To run a query within a React component, call `useDistributorBillingStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useDistributorBillingStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDistributorBillingStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useDistributorBillingStatusQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    DistributorBillingStatusQuery,
    DistributorBillingStatusQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    DistributorBillingStatusQuery,
    DistributorBillingStatusQueryVariables
  >(DistributorBillingStatusDocument, baseOptions);
}
export function useDistributorBillingStatusLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    DistributorBillingStatusQuery,
    DistributorBillingStatusQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    DistributorBillingStatusQuery,
    DistributorBillingStatusQueryVariables
  >(DistributorBillingStatusDocument, baseOptions);
}
export type DistributorBillingStatusQueryHookResult = ReturnType<
  typeof useDistributorBillingStatusQuery
>;
export type DistributorBillingStatusLazyQueryHookResult = ReturnType<
  typeof useDistributorBillingStatusLazyQuery
>;
export type DistributorBillingStatusQueryResult = ApolloReactCommon.QueryResult<
  DistributorBillingStatusQuery,
  DistributorBillingStatusQueryVariables
>;
export const RepsDocument = gql`
  query Reps {
    currentDistributor {
      id
      reps {
        id
        email
        phone
        name {
          first
          last
        }
        addresses {
          id
          street
          city
          state
          zip
          name {
            first
            last
          }
        }
      }
      customers {
        id
        repId
        name {
          first
          last
        }
        email
        phone
      }
    }
  }
`;
export type RepsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<RepsQuery, RepsQueryVariables>,
  'query'
>;

export const RepsComponent = (props: RepsComponentProps) => (
  <ApolloReactComponents.Query<RepsQuery, RepsQueryVariables>
    query={RepsDocument}
    {...props}
  />
);

/**
 * __useRepsQuery__
 *
 * To run a query within a React component, call `useRepsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRepsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRepsQuery({
 *   variables: {
 *   },
 * });
 */
export function useRepsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<RepsQuery, RepsQueryVariables>
) {
  return ApolloReactHooks.useQuery<RepsQuery, RepsQueryVariables>(
    RepsDocument,
    baseOptions
  );
}
export function useRepsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    RepsQuery,
    RepsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<RepsQuery, RepsQueryVariables>(
    RepsDocument,
    baseOptions
  );
}
export type RepsQueryHookResult = ReturnType<typeof useRepsQuery>;
export type RepsLazyQueryHookResult = ReturnType<typeof useRepsLazyQuery>;
export type RepsQueryResult = ApolloReactCommon.QueryResult<
  RepsQuery,
  RepsQueryVariables
>;
export const AddRepAddressDocument = gql`
  mutation AddRepAddress($input: AddRepAddressInput!) {
    addRepAddress(input: $input) {
      id
    }
  }
`;
export type AddRepAddressMutationFn = ApolloReactCommon.MutationFunction<
  AddRepAddressMutation,
  AddRepAddressMutationVariables
>;
export type AddRepAddressComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    AddRepAddressMutation,
    AddRepAddressMutationVariables
  >,
  'mutation'
>;

export const AddRepAddressComponent = (props: AddRepAddressComponentProps) => (
  <ApolloReactComponents.Mutation<
    AddRepAddressMutation,
    AddRepAddressMutationVariables
  >
    mutation={AddRepAddressDocument}
    {...props}
  />
);

/**
 * __useAddRepAddressMutation__
 *
 * To run a mutation, you first call `useAddRepAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRepAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRepAddressMutation, { data, loading, error }] = useAddRepAddressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddRepAddressMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddRepAddressMutation,
    AddRepAddressMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    AddRepAddressMutation,
    AddRepAddressMutationVariables
  >(AddRepAddressDocument, baseOptions);
}
export type AddRepAddressMutationHookResult = ReturnType<
  typeof useAddRepAddressMutation
>;
export type AddRepAddressMutationResult = ApolloReactCommon.MutationResult<
  AddRepAddressMutation
>;
export type AddRepAddressMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddRepAddressMutation,
  AddRepAddressMutationVariables
>;
export const PricingDocument = gql`
  query Pricing {
    currentDistributor {
      id
      pricing {
        id
        hardware {
          modelId
          prices {
            quantity
            price
          }
        }
      }
    }
  }
`;
export type PricingComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    PricingQuery,
    PricingQueryVariables
  >,
  'query'
>;

export const PricingComponent = (props: PricingComponentProps) => (
  <ApolloReactComponents.Query<PricingQuery, PricingQueryVariables>
    query={PricingDocument}
    {...props}
  />
);

/**
 * __usePricingQuery__
 *
 * To run a query within a React component, call `usePricingQuery` and pass it any options that fit your needs.
 * When your component renders, `usePricingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePricingQuery({
 *   variables: {
 *   },
 * });
 */
export function usePricingQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    PricingQuery,
    PricingQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<PricingQuery, PricingQueryVariables>(
    PricingDocument,
    baseOptions
  );
}
export function usePricingLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PricingQuery,
    PricingQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<PricingQuery, PricingQueryVariables>(
    PricingDocument,
    baseOptions
  );
}
export type PricingQueryHookResult = ReturnType<typeof usePricingQuery>;
export type PricingLazyQueryHookResult = ReturnType<typeof usePricingLazyQuery>;
export type PricingQueryResult = ApolloReactCommon.QueryResult<
  PricingQuery,
  PricingQueryVariables
>;
export const OrdersDocument = gql`
  query Orders {
    currentDistributor {
      id
      isDistributor
      orders {
        id
        approved
        placedTimestamp
        status
        details {
          hardware {
            id
            quantity
          }
          customer {
            id
            name {
              first
              last
            }
          }
          rep {
            id
            name {
              first
              last
            }
          }
        }
      }
    }
  }
`;
export type OrdersComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    OrdersQuery,
    OrdersQueryVariables
  >,
  'query'
>;

export const OrdersComponent = (props: OrdersComponentProps) => (
  <ApolloReactComponents.Query<OrdersQuery, OrdersQueryVariables>
    query={OrdersDocument}
    {...props}
  />
);

/**
 * __useOrdersQuery__
 *
 * To run a query within a React component, call `useOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrdersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    OrdersQuery,
    OrdersQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<OrdersQuery, OrdersQueryVariables>(
    OrdersDocument,
    baseOptions
  );
}
export function useOrdersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OrdersQuery,
    OrdersQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<OrdersQuery, OrdersQueryVariables>(
    OrdersDocument,
    baseOptions
  );
}
export type OrdersQueryHookResult = ReturnType<typeof useOrdersQuery>;
export type OrdersLazyQueryHookResult = ReturnType<typeof useOrdersLazyQuery>;
export type OrdersQueryResult = ApolloReactCommon.QueryResult<
  OrdersQuery,
  OrdersQueryVariables
>;
export const OrdersMockupDocument = gql`
  query OrdersMockup {
    currentDistributor {
      id
      isDistributor
      orders {
        id
        approved
        placedTimestamp
        status
        shipments {
          id
          hardware {
            id
            quantity
            model {
              type
              antennaLength
              moistureDepths
              temperatureDepths
              ecDepths
              hasWeatherStation
              hasPressureSensor
              hasRainGauge
              moistureMethod
              type
              probeless
              custom
            }
            boundIds
          }
          trackingNumber
          carrier
        }
        unshippedHardware {
          id
          quantity
          model {
            type
            antennaLength
            moistureDepths
            temperatureDepths
            ecDepths
            hasWeatherStation
            hasPressureSensor
            hasRainGauge
            moistureMethod
            type
            probeless
            custom
          }
          boundIds
        }
        details {
          hardware {
            id
            quantity
            model {
              type
              antennaLength
              moistureDepths
              temperatureDepths
              ecDepths
              hasWeatherStation
              hasPressureSensor
              hasRainGauge
              moistureMethod
              type
              probeless
              custom
            }
            boundIds
          }
          customer {
            id
            name {
              first
              last
            }
          }
          rep {
            id
            name {
              first
              last
            }
          }
        }
      }
    }
  }
`;
export type OrdersMockupComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    OrdersMockupQuery,
    OrdersMockupQueryVariables
  >,
  'query'
>;

export const OrdersMockupComponent = (props: OrdersMockupComponentProps) => (
  <ApolloReactComponents.Query<OrdersMockupQuery, OrdersMockupQueryVariables>
    query={OrdersMockupDocument}
    {...props}
  />
);

/**
 * __useOrdersMockupQuery__
 *
 * To run a query within a React component, call `useOrdersMockupQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersMockupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersMockupQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrdersMockupQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    OrdersMockupQuery,
    OrdersMockupQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    OrdersMockupQuery,
    OrdersMockupQueryVariables
  >(OrdersMockupDocument, baseOptions);
}
export function useOrdersMockupLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OrdersMockupQuery,
    OrdersMockupQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    OrdersMockupQuery,
    OrdersMockupQueryVariables
  >(OrdersMockupDocument, baseOptions);
}
export type OrdersMockupQueryHookResult = ReturnType<
  typeof useOrdersMockupQuery
>;
export type OrdersMockupLazyQueryHookResult = ReturnType<
  typeof useOrdersMockupLazyQuery
>;
export type OrdersMockupQueryResult = ApolloReactCommon.QueryResult<
  OrdersMockupQuery,
  OrdersMockupQueryVariables
>;
export const UserNameDocument = gql`
  query UserName {
    currentDistributor {
      id
      isRep
      isDistributor
      isAdmin
      name {
        first
        last
      }
    }
  }
`;
export type UserNameComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    UserNameQuery,
    UserNameQueryVariables
  >,
  'query'
>;

export const UserNameComponent = (props: UserNameComponentProps) => (
  <ApolloReactComponents.Query<UserNameQuery, UserNameQueryVariables>
    query={UserNameDocument}
    {...props}
  />
);

/**
 * __useUserNameQuery__
 *
 * To run a query within a React component, call `useUserNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserNameQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserNameQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    UserNameQuery,
    UserNameQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<UserNameQuery, UserNameQueryVariables>(
    UserNameDocument,
    baseOptions
  );
}
export function useUserNameLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UserNameQuery,
    UserNameQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<UserNameQuery, UserNameQueryVariables>(
    UserNameDocument,
    baseOptions
  );
}
export type UserNameQueryHookResult = ReturnType<typeof useUserNameQuery>;
export type UserNameLazyQueryHookResult = ReturnType<
  typeof useUserNameLazyQuery
>;
export type UserNameQueryResult = ApolloReactCommon.QueryResult<
  UserNameQuery,
  UserNameQueryVariables
>;
export const ActiveDocument = gql`
  query Active {
    currentDistributor {
      id
      unassigned {
        sensors {
          id
          ...SensorStatusFragment
        }
        gateways {
          id
          ...GatewayStatusFragment
        }
      }
      customers {
        id
        name {
          first
          last
        }
        user {
          id
          sensors(onlyOwned: true) {
            id
            ...SensorStatusFragment
          }
          gateways(onlyOwned: true) {
            id
            ...GatewayStatusFragment
          }
        }
      }
    }
  }
  ${SensorStatusFragmentFragmentDoc}
  ${GatewayStatusFragmentFragmentDoc}
`;
export type ActiveComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ActiveQuery,
    ActiveQueryVariables
  >,
  'query'
>;

export const ActiveComponent = (props: ActiveComponentProps) => (
  <ApolloReactComponents.Query<ActiveQuery, ActiveQueryVariables>
    query={ActiveDocument}
    {...props}
  />
);

/**
 * __useActiveQuery__
 *
 * To run a query within a React component, call `useActiveQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveQuery({
 *   variables: {
 *   },
 * });
 */
export function useActiveQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ActiveQuery,
    ActiveQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<ActiveQuery, ActiveQueryVariables>(
    ActiveDocument,
    baseOptions
  );
}
export function useActiveLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ActiveQuery,
    ActiveQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<ActiveQuery, ActiveQueryVariables>(
    ActiveDocument,
    baseOptions
  );
}
export type ActiveQueryHookResult = ReturnType<typeof useActiveQuery>;
export type ActiveLazyQueryHookResult = ReturnType<typeof useActiveLazyQuery>;
export type ActiveQueryResult = ApolloReactCommon.QueryResult<
  ActiveQuery,
  ActiveQueryVariables
>;
