import * as React from 'react';
import { FontClasses, colors, weights } from 'services/theme';
import Button from '@material-ui/core/Button';

interface CardProps {
  title: string;
  description: string | string[];
  onClick: () => void;
}

const Card: React.SFC<CardProps> = props => {
  return (
    <div
      style={{
        width: 225,
        height: 225,
        position: 'relative',
        marginBottom: 24,
      }}
    >
      <div
        className={FontClasses.subtitle}
        style={{ fontWeight: weights.bold }}
      >
        {props.title}
      </div>
      <div style={{ height: 12 }} />
      <div className={FontClasses.body} style={{ color: colors.textLight }}>
        {Array.isArray(props.description)
          ? props.description.map(d => (
              <React.Fragment key={d}>
                {d}
                <br />
                <br />
              </React.Fragment>
            ))
          : props.description}
      </div>
      <div style={{ height: 12 }} />
      <Button
        variant="outlined"
        fullWidth
        style={{ position: 'absolute', bottom: 0 }}
        onClick={props.onClick}
      >
        Select
      </Button>
    </div>
  );
};

export default Card;
