import * as React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';

import { SensorTypeOption } from '../AddLineItem';
import TwoColumn from './TwoColumn';
import { FontClasses, colors } from 'services/theme';

interface SensorProbeProps {
  sensorType: SensorTypeOption;
  sensorDepths: number[] | null | undefined;
  onDepthsChange: (depths: number[] | null) => void;
  temperatureDepthsChange: (depths: number[] | null) => void;
  probeless: boolean | undefined;
  onProbelessChange: (ev: React.ChangeEvent<HTMLInputElement>) => void;
  onCustomChange: (custom: boolean) => void;
  custom: boolean | undefined;
  replacement: boolean | undefined;
}

interface SensorProbeState {
  moistureDepthsText: string;
  includeTemps: boolean;
  tempDepthsText: string;
  moistureError: string;
}

class SensorProbe extends React.Component<SensorProbeProps, SensorProbeState> {
  state: SensorProbeState = {
    moistureDepthsText: '',
    tempDepthsText: '',
    includeTemps: false,
    moistureError: '',
  };

  watermarkDepths = [
    [4, 8],
    [8, 16],
    [6, 12, 18],
    [6, 12, 18, 24],
    [6, 12, 24, 36],
  ];

  sentekDepths = [
    [2, 6, 10],
    [2, 6, 10, 14, 18, 22],
    [2, 6, 10, 14, 18, 22, 26, 30, 34],
    [2, 6, 10, 14, 18, 22, 26, 30, 34, 38, 42, 46],
  ];

  onDepthsChange = (
    e: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const val = e.target.value as string;
    if (val === 'custom') {
      this.props.onCustomChange(true);
    } else {
      try {
        const arr: number[] = JSON.parse(val);
        this.props.onDepthsChange(arr);
        this.props.onCustomChange(false);
      } catch (err) {
        console.error(err);
      }
    }
  };

  onProbelessChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.props.onProbelessChange(e);
  };

  onMoistureDepthsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ moistureDepthsText: e.target.value });
    const newDepthsArr = e.target.value
      .trim()
      .split(',')
      .map((e) => e.trim().replace('"', ''))
      .filter((e) => e !== '')
      .map(Number);
    if (newDepthsArr.length > 5) {
      this.props.onDepthsChange(null);
      this.setState({ moistureError: 'You cannot have >5 depths' });
    } else {
      this.props.onDepthsChange(newDepthsArr);
      this.setState({ moistureError: '' });
    }
  };

  onTempDepthsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ tempDepthsText: e.target.value });
    const newDepthsArr = e.target.value
      .trim()
      .split(',')
      .filter((e) => e !== '')
      .map(Number);
    this.props.temperatureDepthsChange(newDepthsArr);
  };

  render() {
    const depths =
      this.props.sensorType === 'watermark'
        ? this.watermarkDepths
        : this.sentekDepths;

    let headerText = '';
    let sectionDescription = '';
    if (this.props.sensorType === 'watermark') {
      headerText = 'Moisture Depths';
      sectionDescription =
        'This model has a soil temperature sensor at 2” & Watermark soil moisture sensors at various depths.';
    }
    if (this.props.sensorType === 'sentek') {
      headerText = 'Sensor Depths';
      sectionDescription =
        'This probe has soil moisture & temperature sensors every 4 inches. Choose from 4 available probe lengths.';
    }

    if (this.props.sensorType === 'sentek-tri') {
      headerText = 'Sensor Depths';
      sectionDescription =
        'This probe has soil moisture, temperature, & electroconductivity sensors every 4 inches. Choose from 4 available probe lengths.';
    }

    const isSentek = this.props.sensorType !== 'watermark';
    const customProbeless = this.props.probeless && !isSentek;

    let depthsValue = '';
    if (this.props.custom) depthsValue = 'custom';
    else if (this.props.sensorDepths)
      depthsValue = JSON.stringify(this.props.sensorDepths);
    return (
      <TwoColumn
        title="Sensor Probe"
        subtitle={sectionDescription}
        content={
          <React.Fragment>
            <div
              style={{ color: colors.textLight }}
              className={FontClasses.caption}
            >
              {headerText}
            </div>
            {!customProbeless && (
              <Select
                value={depthsValue}
                onChange={this.onDepthsChange}
                style={{ width: 200 }}
              >
                {depths.map((d) => {
                  const value = JSON.stringify(d);
                  return (
                    <MenuItem value={value} key={value}>
                      {d.join(', ')}
                      {'"'}
                    </MenuItem>
                  );
                })}
                {!isSentek && <MenuItem value="custom">Custom Depths</MenuItem>}
              </Select>
            )}
            {this.props.custom && (
              <div style={{ marginBottom: 8 }}>
                *Custom depths add an additional charge of $50 for 2-3 depths,
                and $75 for 4-5 depths
              </div>
            )}
            {!this.props.custom && !this.props.replacement && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!this.props.probeless}
                    onChange={this.onProbelessChange}
                  />
                }
                label="I will be supplying my own sensor probe"
              />
            )}
            {(this.props.custom || customProbeless) && (
              <React.Fragment>
                <div style={{ marginBottom: 8, marginTop: 8 }}>
                  Enter a comma separated list of depths below
                </div>
                <div style={{ marginBottom: 8, marginTop: 8 }}>
                  <TextField
                    label="Sensor Depths (inches)"
                    variant="outlined"
                    placeholder="8, 16, 24"
                    value={this.state.moistureDepthsText}
                    onChange={this.onMoistureDepthsChange}
                    fullWidth
                    error={!!this.state.moistureError}
                  />
                </div>
                {this.state.moistureError && (
                  <div style={{ color: colors.error }}>
                    {this.state.moistureError}
                  </div>
                )}
                <div style={{ marginBottom: 8, marginTop: 8 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.includeTemps}
                        onChange={(e) =>
                          this.setState({ includeTemps: e.target.checked })
                        }
                      />
                    }
                    label="Include temperature sensors"
                  />
                </div>
                {this.state.includeTemps && (
                  <div style={{ marginBottom: 8, marginTop: 8 }}>
                    <TextField
                      label="Temperature Depths (inches)"
                      variant="outlined"
                      placeholder="8, 16, 24"
                      value={this.state.tempDepthsText}
                      onChange={this.onTempDepthsChange}
                      fullWidth
                    />
                  </div>
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        }
      />
    );
  }
}

export default SensorProbe;
