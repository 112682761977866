import * as React from 'react';
import gql from 'graphql-tag';

import { ActiveComponent, ActiveQuery } from 'types/graphql';

import Paper from '@material-ui/core/Paper';

import { IsMobileConsumer } from 'providers/Responsive';

import Loading from 'components/Loading/Loading';
import GenericError from 'components/Errors/GenericError';

import ErrorBoundary from 'components/Errors/ErrorBoundary';
import StatusTable from './components/StatusTable';
import { FontClasses } from 'services/theme';

gql`
  fragment SensorStatusFragment on Sensor {
    id
    owner {
      id
      name {
        first
        last
      }
    }
    location {
      lat
      lng
    }
    name
    rssi
    battery
    current_state
    connectionStatus
    sensor_time
    gatewayId
    statusWarnings
  }

  fragment GatewayStatusFragment on Gateway {
    id
    model {
      type
    }
    location {
      lat
      lng
    }
    cell_signal
    gateway_battery
    connectionStatus
    gateway_time
    sensorIds
    statusWarnings
    owner {
      id
      name {
        first
        last
      }
    }
  }

  query Active {
    currentDistributor {
      id
      unassigned {
        # For unassigned hardware
        sensors {
          id
          ...SensorStatusFragment
        }
        gateways {
          id
          ...GatewayStatusFragment
        }
      }
      customers {
        # For assigned hardware
        id
        name {
          first
          last
        }
        user {
          id
          sensors(onlyOwned: true) {
            id
            ...SensorStatusFragment
          }
          gateways(onlyOwned: true) {
            id
            ...GatewayStatusFragment
          }
        }
      }
    }
  }
`;

class Status extends React.Component {
  render() {
    return (
      <IsMobileConsumer>
        {({ isMobile }) => (
          <ActiveComponent>
            {({ data, loading, error }) => {
              if (loading) return <Loading />;
              if (error || !data) return <GenericError />;
              const { currentDistributor } = data;

              const rootStyle = {
                flexGrow: 1,
                margin: isMobile ? 0 : 50,
                paddingTop: isMobile ? 0 : 50,
              };
              const wrapStyle = {
                padding: isMobile ? 0 : 20,
                paddingTop: 1,
                marginBottom: 50,
              };

              const { unassigned, customers } = currentDistributor;
              const unassignedCombined = [
                ...unassigned.sensors,
                ...unassigned.gateways,
              ];

              type User = ActiveQuery['currentDistributor']['customers'][0]['user'];
              let assigned: Array<
                User['sensors'][0] | User['gateways'][0]
              > = [];

              customers.forEach(({ user }) => {
                const { sensors, gateways } = user;
                assigned = assigned.concat(sensors);
                assigned = assigned.concat(gateways);
              });

              return (
                <div style={rootStyle}>
                  <Paper style={wrapStyle} id="pendingHardware">
                    <div
                      className={FontClasses.subtitle}
                      style={{ marginTop: 10 }}
                    >
                      Unassigned Hardware
                    </div>
                    <div className={FontClasses.body}>
                      Has not been assigned to a customer
                    </div>
                    <ErrorBoundary>
                      <StatusTable hardware={unassignedCombined} />
                    </ErrorBoundary>
                  </Paper>
                  <Paper style={wrapStyle} id="activeHardware">
                    <div
                      className={FontClasses.subtitle}
                      style={{ marginTop: 10 }}
                    >
                      Assigned Hardware
                    </div>
                    <div className={FontClasses.body}>
                      Has been assigned to a customer
                    </div>
                    <ErrorBoundary>
                      <StatusTable hardware={assigned} />
                    </ErrorBoundary>
                  </Paper>
                </div>
              );
            }}
          </ActiveComponent>
        )}
      </IsMobileConsumer>
    );
  }
}

export default Status;
