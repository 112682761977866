import React from 'react';
import gql from 'graphql-tag';
import { RouteComponentProps } from '@reach/router';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';

import { navigate } from '@reach/router';

import { useIsMobile } from 'providers/Responsive';

import Loading from 'components/Loading/Loading';
import HardwareTable from 'components/HardwareTable/HardwareTable';
import FieldsTable from 'components/FieldsTable/FieldsTable';
import GraphQLError from 'components/Errors/GraphQLError';

import CustomerInfoCard from './components/CustomerInfoCard';
import DashboardStatus from './components/DashboardStatus';

import {
  useCustomerQuery,
  useStartManagedServiceSubscriptionMutation,
  CustomerDocument,
} from 'types/graphql';
import ManagedServiceCard from './components/ManagedServiceCard';

gql`
  query Customer($customerId: String!) {
    customer(customerId: $customerId, includeZoho: true) {
      id
      email
      phone
      repId
      name {
        first
        last
      }
      addresses {
        id
        city
        state
        zip
        street
        name {
          first
          last
        }
      }
      user {
        id
        activated
        activationCompleted
        prepaid
        prepaidStart
        prepaidUntil
        managedServiceStatuses {
          initialPaymentMade
          invoiceUrl
          orderId
          subscriptionStarted
        }
        sensors {
          id
          isOwner
          name
          activePlan
          soil_type {
            id
            name
          }
          crop_type {
            id
            name
          }
          model {
            type
            antennaLength
            moistureDepths
          }
          order {
            id
            placedTimestamp
          }
        }
        gateways {
          id
          isOwner
          activePlan
          hasRainGauge
          model {
            type
            antennaLength
          }
          order {
            id
            placedTimestamp
          }
        }
        fields {
          id
          name
          sensors {
            id
            crop_type {
              id
              name
            }
            crop_variety
          }
          irrigationMetadata {
            irrigationType
          }
        }
      }
    }
    currentDistributor {
      id
    }
  }
`;

gql`
  mutation StartManagedServiceSubscription($input: StartManagedServiceInput!) {
    startManagedService(input: $input) {
      success
    }
  }
`;

type ManageCustomerProps = RouteComponentProps<{
  customerId: string;
}>;

const ManageCustomer: React.FC<ManageCustomerProps> = ({ customerId = '' }) => {
  const isMobile = useIsMobile();
  const { data, loading, error, refetch } = useCustomerQuery({
    variables: { customerId },
  });
  const [startSub, startSubStatus] = useStartManagedServiceSubscriptionMutation(
    {
      refetchQueries: [{ query: CustomerDocument, variables: { customerId } }],
    }
  );

  if (loading) return <Loading />;
  if (error || !data || !data.customer) return <GraphQLError error={error} />;
  const { customer, currentDistributor } = data;

  const {
    sensors,
    gateways,
    fields,
    activated,
    activationCompleted,
    prepaid,
    prepaidUntil,
    prepaidStart,
    managedServiceStatuses,
  } = customer.user;

  const hardware = [...sensors, ...gateways];

  const hasActivePlan = hardware.some((e) => !!e.activePlan);

  const marginAmount = isMobile ? 0 : 5;
  const cardStyle = {
    margin: marginAmount,
    minWidth: 300,
    flex: '1 1 300px',
    overflow: 'auto',
  };

  const close = () => {
    navigate('/manage');
  };

  const editHardware = () => {
    navigate(`/manage/${customerId}/editHardware`);
  };

  const editFields = () => {
    navigate(`/manage/${customerId}/editFields`);
  };

  const isManaged = managedServiceStatuses.length > 0;

  const hasPaidFirstMonth =
    isManaged && managedServiceStatuses.some((s) => s.initialPaymentMade);
  const showDashboardStatus = !isManaged || hasPaidFirstMonth;

  return (
    <div
      style={{
        flexGrow: 1,
        padding: marginAmount,
      }}
    >
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        <CustomerInfoCard
          cardStyle={cardStyle}
          customer={customer}
          currentDistributorId={currentDistributor.id}
        />
        {showDashboardStatus && (
          <DashboardStatus
            cardStyle={cardStyle}
            hasActivePlan={hasActivePlan}
            prepaid={!!prepaid}
            prepaidUntil={prepaidUntil || 0}
            prepaidStart={prepaidStart}
            activated={!!activated}
            activationCompleted={!!activationCompleted}
            isMobile={isMobile}
            customerId={customer.id}
            refetch={refetch}
            managedService={managedServiceStatuses.length > 0}
          />
        )}
        {isManaged && (
          <ManagedServiceCard
            cardStyle={cardStyle}
            managedServiceStatuses={managedServiceStatuses}
            startSubscription={(orderId) =>
              startSub({ variables: { input: { orderId } } })
            }
            startSubscriptionStatus={startSubStatus}
          />
        )}
      </div>
      <Card style={cardStyle}>
        <CardHeader
          title="Hardware"
          style={{ backgroundColor: 'rgb(242,242,242)' }}
          action={
            <IconButton onClick={editHardware} id="editHardware">
              <EditIcon />
            </IconButton>
          }
        />
        <CardContent style={{ padding: 0 }}>
          <HardwareTable
            columns={{
              type: true,
              id: true,
              name: true,
              viewOrder: true,
              depths: !isMobile,
              antenna: !isMobile,
              orderDate: !isMobile,
              texture: !isMobile,
              crop: !isMobile,
            }}
            data={hardware}
          />
        </CardContent>
      </Card>
      <Card style={cardStyle}>
        <CardHeader
          title="Fields"
          style={{ backgroundColor: 'rgb(242,242,242)' }}
          action={
            <IconButton onClick={editFields} id="editFields">
              <EditIcon />
            </IconButton>
          }
        />
        <CardContent style={{ padding: 0 }}>
          <FieldsTable
            data={fields}
            customerId={customer.id}
            refetch={refetch}
          />
        </CardContent>
      </Card>
      <div
        style={{
          width: '100%',
          alignItems: 'right',
          marginRight: 20,
          direction: 'rtl',
          marginTop: 10,
          marginBottom: 36,
        }}
      >
        <Button
          variant="contained"
          onClick={close}
          style={{ width: isMobile ? '100%' : undefined }}
        >
          Close
        </Button>
      </div>
    </div>
  );
};

export default ManageCustomer;
