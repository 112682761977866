import React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { colors, FontClasses } from 'services/theme';

import VolumePricing from './VolumePricing';
import TwoColumn from './TwoColumn';

interface WatermarkSensorProps {
  length: number | undefined;
  setLength: (length: number) => void;
  hasSpigot: boolean | undefined;
  setHasSpigot: (hasSpigot: boolean) => void;
  finish: (quantity: number) => void;
  prices:
    | {
        quantity: number;
        price: number;
      }[]
    | null;
  hidePrices: boolean;
}

const WatermarkSensor: React.FC<WatermarkSensorProps> = (props) => {
  return (
    <>
      <TwoColumn
        title="Lead length"
        subtitle="Select the length of wires you’d like on your sensors"
        content={
          <>
            <div
              style={{ color: colors.textLight }}
              className={FontClasses.caption}
            >
              Length
            </div>
            <Select
              value={props.length || ''}
              onChange={(e) => props.setLength(Number(e.target.value))}
              style={{ width: 200 }}
            >
              <MenuItem value="5">5 ft</MenuItem>
              <MenuItem value="15">15 ft</MenuItem>
            </Select>
          </>
        }
      />
      <TwoColumn
        title="Spigot"
        subtitle="A spigot is an attachment on the bottom of the sensor that allows you to attach one or many sensors to a single piece of Schedule 315 PVC pipe"
        content={
          <>
            <FormControlLabel
              control={
                <Checkbox
                  checked={!!props.hasSpigot}
                  onChange={(e) => props.setHasSpigot(e.target.checked)}
                />
              }
              label="Include a spigot"
            />
          </>
        }
      />
      <div style={{ height: 24 }} />
      <VolumePricing
        finish={props.finish}
        disabled={props.length === undefined}
        prices={props.prices}
        hidePrices={props.hidePrices}
      />
    </>
  );
};

export default WatermarkSensor;
