import * as React from 'react';
import Card from './Card';
import { HardwareTypeEnum } from 'types/graphql';

interface ReplacementCircuitBoardProps {
  onClick: (type: HardwareTypeEnum) => void;
}

class ReplacementCircuitBoard extends React.Component<
  ReplacementCircuitBoardProps
> {
  cards = [
    {
      title: 'Wireless Sensor Station',
      description: 'Includes a new circuit board, batteries, & radio.',
      onClick: () =>
        this.props.onClick(HardwareTypeEnum.replacementSensorBoard),
    },
    {
      title: 'Central Base Station',
      description:
        'Includes a new circuit board, rechargeable battery, solar panel, & radio.',
      onClick: () =>
        this.props.onClick(HardwareTypeEnum.replacementGatewayBoard),
    },
    {
      title: 'Mercury Module',
      description:
        'Includes a new Mercury Module to be attached to a base station circuit board.',
      onClick: () =>
        this.props.onClick(HardwareTypeEnum.replacementMercuryModule),
    },
  ];
  render() {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'space-around',
        }}
      >
        {this.cards.map((p) => (
          <Card {...p} key={p.title} />
        ))}
      </div>
    );
  }
}

export default ReplacementCircuitBoard;
