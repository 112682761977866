import * as React from 'react';
import sortBy from 'lodash/sortBy';

import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import { FontClasses } from 'services/theme';

interface FieldFormProps {
  onChange: (value: { [name: string]: string }) => void;
  crops: { id: string; name: string }[];
  irrigationTypes: { id: string; name: string }[];
  fieldValues: {
    fieldName: string;
    crop: string;
    cropVariety: string;
    irrigation: string;
  };
}

class FieldForm extends React.Component<FieldFormProps> {
  handleChange = (name: string) => (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    this.props.onChange({ [name]: event.target.value as string });
  };

  render() {
    const { crops, irrigationTypes } = this.props;
    return (
      <div>
        <div style={{ marginBottom: 10, flexDirection: 'column' }}>
          <div>
            <TextField
              label="Field Name"
              placeholder="Field Name"
              value={this.props.fieldValues.fieldName}
              onChange={this.handleChange('fieldName')}
              id="fieldName"
              style={{ width: '100%' }}
            />
          </div>
          <div>
            <FormControl style={{ width: '100%' }}>
              <InputLabel htmlFor="crop-helper">Crop</InputLabel>
              <Select
                value={this.props.fieldValues.crop}
                onChange={this.handleChange('crop')}
                id="cropType"
              >
                {sortBy(crops, ['name']).map(({ id, name }) => (
                  <MenuItem key={id} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div>
            <TextField
              label="Crop Variety"
              placeholder="Variety Name"
              value={this.props.fieldValues.cropVariety}
              onChange={this.handleChange('cropVariety')}
              id="cropVariety"
              style={{ width: '100%' }}
            />
          </div>
          <div>
            <FormControl style={{ width: '100%' }}>
              <InputLabel htmlFor="irr-helper">Irrigation Type</InputLabel>
              <Select
                value={this.props.fieldValues.irrigation}
                onChange={this.handleChange('irrigation')}
                id="irrigationType"
              >
                {irrigationTypes.map(option => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
        <br />
        <br />
        <div className={FontClasses.title}>
          The table below contains a list of all of the Customer’s devices.
        </div>
        <ul>
          <li>
            <div className={FontClasses.subtitle}>
              Select which devices to include in the field , making sure to
              choose one base station and at least one sensor station.
            </div>
          </li>
          <li>
            <div className={FontClasses.subtitle}>
              Select the soil texture for each sensor station.
            </div>
          </li>
        </ul>
      </div>
    );
  }
}

export default FieldForm;
