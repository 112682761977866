import * as React from 'react';
import Card from './Card';
import { HardwareTypeEnum } from 'types/graphql';

interface SensorOrGatewayProps {
  onClick: (type: HardwareTypeEnum) => void;
}

class SensorOrGateway extends React.Component<SensorOrGatewayProps> {
  cards = [
    {
      title: 'Wireless Sensor Station',
      description:
        'Includes the enclosure, a new circuit board, batteries, & radio.',
      onClick: () =>
        this.props.onClick(HardwareTypeEnum.replacementSensorEnclosure),
    },
    {
      title: 'Central Base Station',
      description:
        'Includes the enclosure, a new circuit board, rechargeable battery, solar panel, & radio.',
      onClick: () =>
        this.props.onClick(HardwareTypeEnum.replacementGatewayEnclosure),
    },
  ];

  render() {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'space-around',
        }}
      >
        {this.cards.map(p => (
          <Card {...p} key={p.title} />
        ))}
      </div>
    );
  }
}

export default SensorOrGateway;
