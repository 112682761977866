import * as React from 'react';
import { HardwareTypeEnum } from 'types/graphql';
import Card from './Card';

interface ReplacementTypeProps {
  onClick: (type: HardwareTypeEnum) => void;
}

class ReplacementType extends React.Component<ReplacementTypeProps> {
  cards = [
    {
      title: 'Sensor Probe',
      description:
        'Are your sensors worn out after a few seasons? Pick up a new sensor probe without having to replace the entire sensor station.',
      onClick: () =>
        this.props.onClick(HardwareTypeEnum.replacementSensorProbe),
    },
    {
      title: 'Watermark Sensor',
      description:
        'Do you use Trellis telemetry stations? Purchase standalone Watermark sensors with or without a spigot attachment.',
      onClick: () => this.props.onClick(HardwareTypeEnum.watermarkSensor),
    },
    {
      title: 'Electronics Enclosure',
      description:
        'Accidentally leave your electronics enclosure open in the rain? Get a replacement enclosure, without having to replace the entire unit.',
      onClick: () => this.props.onClick(HardwareTypeEnum.replacementEnclosure),
    },
    {
      title: 'Antenna',
      description:
        'Interested in installing your sensor stations in multiple crops throughout the year? A replacement antenna allows you to swap out a shorter antenna for a taller one (or vice versa).',
      onClick: () => this.props.onClick(HardwareTypeEnum.replacementAntenna),
    },
    {
      title: 'Rain Gauge',
      description:
        'Replacement Davis Instruments AeroCone Rain Gauge. Includes rain gauge, conduit that houses wiring, & cable gland to connect to sensor station enclosure.',
      onClick: () => this.props.onClick(HardwareTypeEnum.replacementRainGauge),
    },
    {
      title: 'Weather Station',
      description:
        'Includes anemometer, rain collector, & temperature, humidity & solar irradiance sensors.',
      onClick: () =>
        this.props.onClick(HardwareTypeEnum.replacementWeatherStation),
    },
    {
      title: 'Circuit Boards',
      description:
        'Order replacement circuit boards for your base stations or sensor probes.',
      onClick: () =>
        this.props.onClick(HardwareTypeEnum.replacementCircuitBoard),
    },
  ];

  render() {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'space-around',
        }}
      >
        {this.cards.map((p) => (
          <Card {...p} key={p.title} />
        ))}
      </div>
    );
  }
}

export default ReplacementType;
