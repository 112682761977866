import React from 'react';
import gql from 'graphql-tag';

import DeleteIcon from '@material-ui/icons/Delete';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';

import { colors, FontClasses } from 'services/theme';
import { navigate } from '@reach/router';

import {
  RemoveCustomerComponent,
  CustomerRepInfoComponent,
} from 'types/graphql';
import Loading from 'components/Loading/Loading';
import GraphQLError from 'components/Errors/GraphQLError';

gql`
  query CustomerRepInfo($repId: String!) {
    rep(repId: $repId) {
      id
      name {
        first
        last
      }
    }
  }
`;

gql`
  mutation RemoveCustomer($input: RemoveCustomerInput!) {
    removeCustomer(input: $input) {
      id
    }
  }
`;

const deleteCustomerButtonId = 'deleteCustomerButton';

interface CustomerInfoCardProps {
  cardStyle: React.CSSProperties;
  customer: {
    id: string;
    repId: string | null;
    name: {
      first: string | null;
      last: string | null;
    } | null;
    email: string | null;
    phone: string | null;
    addresses: {
      name: {
        first: string | null;
        last: string | null;
      } | null;
      street: string | null;
      city: string | null;
      state: string | null;
      zip: string | null;
    }[];
  };
  currentDistributorId: string;
}

interface CustomerInfoCardState {
  deletePopoverOpen: boolean;
}

class CustomerInfoCard extends React.Component<
  CustomerInfoCardProps,
  CustomerInfoCardState
> {
  state = { deletePopoverOpen: false };

  handleDeletePromptClose = () => this.setState({ deletePopoverOpen: false });

  render() {
    const { cardStyle, customer, currentDistributorId } = this.props;
    const address = customer.addresses ? customer.addresses[0] : null;
    return (
      <>
        <Card style={cardStyle}>
          <CardHeader
            title="Customer Information"
            style={{ backgroundColor: 'rgb(242,242,242)' }}
            action={
              <IconButton
                style={{ color: colors.error }}
                onClick={() => this.setState({ deletePopoverOpen: true })}
                id={deleteCustomerButtonId}
              >
                <DeleteIcon />
              </IconButton>
            }
          />
          <CardContent>
            <div style={{ display: 'flex', width: '100%' }}>
              <div style={{ flexBasis: '25%', flex: '1 1 auto' }}>
                <div className={FontClasses.subtitle}>Name:</div>
                <div className={FontClasses.subtitle}>Email:</div>
                <div className={FontClasses.subtitle}>Phone:</div>
                <div className={FontClasses.subtitle}>Address:</div>
              </div>
              <div style={{ flexBasis: '75%', flex: '1 1 auto' }}>
                <div className={FontClasses.subtitle}>
                  {customer.name &&
                    `${customer.name.first} ${customer.name.last}`}
                </div>
                <div className={FontClasses.subtitle}>{customer.email}</div>
                <div className={FontClasses.subtitle}>{customer.phone}</div>
                {address && (
                  <>
                    <div className={FontClasses.subtitle}>
                      {address.name &&
                        `${address.name.first} ${address.name.last}`}
                    </div>
                    <div className={FontClasses.subtitle}>{address.street}</div>
                    <div className={FontClasses.subtitle}>
                      {address.city}, {address.state} {address.zip}
                    </div>
                  </>
                )}
              </div>
            </div>
          </CardContent>
        </Card>
        {customer.repId && currentDistributorId !== customer.repId ? (
          <CustomerRepInfoComponent variables={{ repId: customer.repId }}>
            {({ data, loading, error }) => {
              if (loading) return <Loading />;
              if (error || !data) return <GraphQLError error={error} />;
              const { rep } = data;
              const repName =
                rep && rep.name
                  ? `${rep.name.first} ${rep.name.last}`
                  : 'a rep';
              return (
                <DeleteCustomerPopup
                  open={this.state.deletePopoverOpen}
                  onClose={this.handleDeletePromptClose}
                  customerId={customer.id}
                  repName={repName}
                />
              );
            }}
          </CustomerRepInfoComponent>
        ) : (
          <DeleteCustomerPopup
            open={this.state.deletePopoverOpen}
            onClose={this.handleDeletePromptClose}
            customerId={customer.id}
          />
        )}
      </>
    );
  }
}

interface PopupProps {
  open: boolean;
  onClose: () => void;
  customerId: string;
  // If set, a different rep owns the customer so you cant delete
  repName?: string;
}

const DeleteCustomerPopup: React.FC<PopupProps> = ({
  open,
  onClose,
  customerId,
  repName,
}) => {
  return (
    <RemoveCustomerComponent>
      {(deleteCustomerMutation, { loading, error }) => {
        const deleteCustomer = () => {
          deleteCustomerMutation({
            variables: {
              input: {
                customerId,
              },
            },
          }).then(() => navigate('/manage'));
        };

        const content = repName ? (
          <>
            <div style={{ textAlign: 'center' }}>
              This customer was created by {repName}, so only they can delete
              them.
            </div>
          </>
        ) : (
          <>
            <div style={{ textAlign: 'center' }}>
              Are you sure you want to delete this customer?
            </div>
            <div style={{ height: 8 }} />
            {error && <span style={{ color: 'red' }}>{error.message}</span>}
            {loading && <CircularProgress color="primary" />}
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={deleteCustomer}
              >
                Yes
              </Button>
              <div style={{ width: 8 }} />
              <Button variant="contained" size="small" onClick={onClose}>
                No
              </Button>
            </div>
          </>
        );

        return (
          <Popover
            open={open}
            onClose={onClose}
            anchorEl={() =>
              document.getElementById(deleteCustomerButtonId) as HTMLElement
            }
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: 8,
                maxWidth: 200,
              }}
            >
              {content}
            </div>
          </Popover>
        );
      }}
    </RemoveCustomerComponent>
  );
};

export default CustomerInfoCard;
