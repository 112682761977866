import React from 'react';

import { navigate } from '@reach/router';
import { FontClasses } from 'services/theme';

import { IsMobileConsumer } from 'providers/Responsive';

import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';

import Loading from 'components/Loading/Loading';

import { AddCustomerComponent } from 'types/graphql';
import GraphQLError from 'components/Errors/GraphQLError';

interface NewCustomerProps {}
interface NewCustomerState {
  fname: string;
  lname: string;
  email: string;
  phone: string;
  street: string;
  city: string;
  state: string;
  zip: string;
}

class NewCustomer extends React.Component<NewCustomerProps, NewCustomerState> {
  constructor(props: NewCustomerProps) {
    super(props);
    this.state = {
      fname: '',
      lname: '',
      email: '',
      phone: '',
      street: '',
      city: '',
      state: '',
      zip: '',
    };
  }

  render() {
    const containerStyle: React.CSSProperties = {
      display: 'flex',
      flexWrap: 'wrap',
    };
    const textFieldStyle: React.CSSProperties = {
      flexBasis: '100%',
      maxWidth: 360,
    };
    return (
      <IsMobileConsumer>
        {({ isMobile }) => {
          const buttonStyle = isMobile ? { width: '50%' } : {};
          return (
            <AddCustomerComponent>
              {(addCustomer, { error, loading }) => {
                const save = () => {
                  addCustomer({
                    variables: {
                      input: {
                        address: {
                          city: this.state.city,
                          name: {
                            first: this.state.fname,
                            last: this.state.lname,
                          },
                          state: this.state.state,
                          street: this.state.street,
                          zip: this.state.zip,
                        },
                        // remove any spaces before or after email
                        email: this.state.email.replace(/\s+/g, ''),
                        name: {
                          first: this.state.fname,
                          last: this.state.lname,
                        },
                        // remove all nonnumeric chars from string
                        phone: this.state.phone.replace(/\D/g, ''),
                      },
                    },
                  }).then(() => {
                    navigate('/manage');
                  });
                };
                const notAllFilledOut = Object.values(this.state).some(
                  (s) => s === ''
                );
                return (
                  <div>
                    <Paper style={{ padding: 20 }}>
                      <div
                        className={FontClasses.title}
                        style={{ marginBottom: 10 }}
                      >
                        Enter Customer Details
                      </div>
                      <div style={containerStyle}>
                        <TextField
                          label="First Name"
                          value={this.state.fname}
                          style={textFieldStyle}
                          onChange={(e) =>
                            this.setState({ fname: e.target.value })
                          }
                          id="customerFirstName"
                        />
                        <div style={{ flexBasis: '6%' }} />
                        <TextField
                          label="Last Name"
                          value={this.state.lname}
                          style={textFieldStyle}
                          onChange={(e) =>
                            this.setState({ lname: e.target.value })
                          }
                          id="customerLastName"
                        />
                      </div>
                      <div style={containerStyle}>
                        <TextField
                          label="Email Address"
                          value={this.state.email}
                          style={textFieldStyle}
                          onChange={(e) =>
                            this.setState({ email: e.target.value })
                          }
                          id="customerEmail"
                        />
                        <div style={{ flexBasis: '6%' }} />
                        <TextField
                          label="Phone Number"
                          value={this.state.phone}
                          style={textFieldStyle}
                          onChange={(e) =>
                            this.setState({ phone: e.target.value })
                          }
                          id="customerPhone"
                        />
                      </div>
                      <div style={containerStyle}>
                        <TextField
                          label="Address"
                          value={this.state.street}
                          style={{ flexBasis: '100%' }}
                          onChange={(e) =>
                            this.setState({ street: e.target.value })
                          }
                          id="customerAddress"
                        />
                      </div>
                      <div style={containerStyle}>
                        <TextField
                          label="City"
                          value={this.state.city}
                          style={{ flexBasis: '40%' }}
                          onChange={(e) =>
                            this.setState({ city: e.target.value })
                          }
                          id="customerCity"
                        />
                        <div style={{ flexBasis: '5%' }} />
                        <TextField
                          label="State"
                          value={this.state.state}
                          style={{ flexBasis: '15%' }}
                          onChange={(e) =>
                            this.setState({ state: e.target.value })
                          }
                          id="customerState"
                        />
                        <div style={{ flexBasis: '5%' }} />
                        <TextField
                          label="Zip Code"
                          value={this.state.zip}
                          style={{ flexBasis: '35%' }}
                          onChange={(e) =>
                            this.setState({ zip: e.target.value })
                          }
                          id="customerZip"
                        />
                      </div>
                    </Paper>
                    <div
                      style={{
                        width: '100%',
                        alignItems: 'right',
                        marginRight: 20,
                        marginTop: 20,
                      }}
                    >
                      <GraphQLError error={error} />
                      <Button
                        onClick={() => navigate('/manage')}
                        style={buttonStyle}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={save}
                        id="submitAddCustomer"
                        style={buttonStyle}
                        disabled={notAllFilledOut || loading}
                      >
                        Add Customer
                      </Button>
                    </div>
                    <Dialog open={loading}>
                      <DialogTitle id="simple-dialog-title">
                        Adding Customer...
                      </DialogTitle>
                      <div>
                        <Loading style={{ margin: 0 }} />
                      </div>
                    </Dialog>
                  </div>
                );
              }}
            </AddCustomerComponent>
          );
        }}
      </IsMobileConsumer>
    );
  }
}

export default NewCustomer;
