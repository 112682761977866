const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const isValidEmail = (email: string) => emailRegex.test(email);

export const fixSensorWording = (str: string): string => {
  switch (str) {
    case 'ack_timeout':
      return 'Ack Timeout';
    case 'boot':
      return 'Boot';
    case 'freq':
      return 'Frequency';
    case 'historical_enable':
      return 'Historical Enable';
    case 'hw_ver':
      return 'Hardware Version';
    case 'missed_message_sleep':
      return 'Missed Message Sleep';
    case 'rpt_int':
      return 'Repeat Interval';
    case 'sensor_var':
      return 'Sensor Variant';
    case 'sw_ver':
      return 'Software Version';
    case 't_sleep':
      return 'Sleep Time (ms)';
    case 'tx_high_threshold':
      return 'Tx High Threshold';
    case 'tx_low_threshold':
      return 'Tx Low Threshold';
    case 'tx_power':
      return 'Transmit Power (3-23)';
    case 'meas_no':
      return 'Measure Number';
    case 'rssi':
      return 'Radio Strength';
    case 'vin':
      return 'Battery';
    case 'vpp':
      return 'Power Pack Voltage [V]';
    case 'nonsens':
      return 'Telemetry Only';
    case 'bootCounter':
      return 'Boot Counter';
    case 'bootLimitCounter':
      return 'Boot Limit Counter';
    case 'pwrOnRstCounter':
      return 'Power On Reset Counter';
    case 'brnOutRstCounter':
      return 'Brown Out Reset Counter';
    case 'extRstCounter':
      return 'External Reset Counter';
    case 'wdtRstCounter':
      return 'Watchdog Reset Counter';
    case 'sessionRetry':
      return 'Session Retry';
    case 'sessionFail':
      return 'Session Fail';
    case 'historicalMessages':
      return 'Historical Messages';
    case 'adc':
      return 'Raw Probe Readings';
    case 'vic':
      return 'Volumetric Ion Content';
    case 'moistures':
      return 'Sentek VWC';
    case 'tensions':
      return 'Sensor kPa';
    case 'tensionsWithTemp':
      return 'Sensor + Temp kPa';
    case 'temp':
      return 'Temperature C°';
    default:
      return str;
  }
};

export const fixStreamWording = (str: string, idx: number): string => {
  switch (str) {
    case 'adc':
      return `Sensor ${idx} Raw`;
    case 'tensions':
      return `Sensor ${idx} kPa`;
    case 'tensionsWithTemp':
      return `Sensor + Temp ${idx} kPa`;
    case 'temp':
      return `Temperature ${idx}`;
    case 'vic':
      return `VIC ${idx}`;
    case 'moistures':
      return `Moisture ${idx}`;
    default:
      return str;
  }
};

export const fixGatewayWording = (str: string): string => {
  switch (str) {
    case 'boot':
      return 'First Config Since Boot';
    case 'crit_th':
      return 'Critical Voltage Threshold (mV)';
    case 'hw_ver':
      return 'Hardware Version';
    case 'led_th':
      return 'LED Voltage Threshold (mv)';
    case 'sw_ver':
      return 'Software Version';
    case 'voltage_th':
      return 'Voltage Threshold (mV)';
    case 'ws_fw_ver':
      return 'Weather Station Firmware Version';
    case 'cell_signal':
      return 'Cell Signal';
    case 'vbat':
      return 'Battery (mv)';
    case 'rbat':
      return 'Non-Session Battery (mv)';
    case 'vsol':
      return 'Solar Panel Voltage (mv)';
    case 'msg':
      return 'Message Number';
    case 'netOpenRetry':
      return 'Net Open Retries';
    case 'tcpOpenRetry':
      return 'TCP Sessions Opened';
    case 'tcpSendRetry':
      return 'TCP Send Fails';
    case 'resetCounter':
      return 'Reset Counter';
    case 'failCounter':
      return 'TCP Session Fail Counter';
    case 'rebootCounter':
      return 'Reboot Counter';
    case 'rebootLimitCounter':
      return 'Reboot Limit Counter';
    case 'localCounter':
      return 'Local Sessions';
    case 'rxBadCounter':
      return 'RX Bad Counts';
    case 'isCharging':
      return 'Solar Charging Status';
    case 'pwrOnRstCounter':
      return 'Power On Reset Counter';
    case 'brnOutRstCounter':
      return 'Brown Out Reset Counter';
    case 'extRstCounter':
      return 'External Reset Counter';
    case 'wdtRstCounter':
      return 'Watchdog Reset Counter';
    case 'tcpFailID':
      return 'TCP Session Failure Stage';
    case 'pressure':
      return 'Pressures';
    case 'voltage':
      return 'Pressure Voltages';
    case 'runtime':
      return 'Total Runtime';
    case 'hum':
      return 'Humidity';
    case 'mWindSpd':
      return 'Max Wind Speed';
    case 'rnCnt':
      return 'Rain Count';
    case 'rnRate':
      return 'Rain Rate';
    case 'rnTotal':
      return 'Rain Total';
    case 'solarRad':
      return 'Solar Radiation';
    case 'temp':
      return 'Temperature';
    case 'windSpd':
      return 'Wind Speed';
    case 'windErr':
      return 'Wind Data Error';
    case 'rnRateErr':
      return 'Rain Rate Data Error';
    case 'solarRadErr':
      return 'Solar Radiation Data Error';
    case 'tempErr':
      return 'Temperature Data Error';
    case 'mWindErr':
      return 'Max Wind Speed Data Error';
    case 'humErr':
      return 'Humidity Data Error';
    case 'rnCntErr':
      return 'Rain Count Data Error';
    default:
      return str;
  }
};
