import * as React from 'react';
import AntennaLength from './AntennaLength';
import SensorProbe from './SensorProbe';
import SensorAddons from './SensorAddons';
import { SensorTypeOption } from '../AddLineItem';
import Spacer from './Spacer';
import VolumePricing from './VolumePricing';

interface SensorDetailsProps {
  // Variants
  enclosure?: boolean;
  probe?: boolean;

  antennaLength?: number | undefined;
  antennaLengthChange?: (length: number) => void;
  moistureDepths: number[] | null | undefined;
  moistureDepthsChange: (depths: number[] | null) => void;
  temperatureDepthsChange: (depths: number[] | null) => void;
  probeless: boolean | undefined;
  probelessChange: (probeless: boolean) => void;
  custom: boolean | undefined;
  customChange: (custom: boolean) => void;
  sensorType: SensorTypeOption;
  hasRainGauge?: boolean | undefined;
  hasRainGaugeChange?: (hasRainGauge: boolean) => void;
  finish: (quantity: number) => void;
  prices:
    | {
        quantity: number;
        price: number;
      }[]
    | null;
  rgPrices?:
    | {
        quantity: number;
        price: number;
      }[]
    | null;
  customPrice: number;
  hidePrices: boolean;
}

class SensorDetails extends React.Component<SensorDetailsProps> {
  antennaLengthChange = (
    ev: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const val = ev.target.value;
    if (val && this.props.antennaLengthChange)
      this.props.antennaLengthChange(Number(val));
  };

  probelessChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    this.props.probelessChange(ev.target.checked);
  };

  rgChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    if (this.props.hasRainGaugeChange)
      this.props.hasRainGaugeChange(ev.target.checked);
  };

  render() {
    let disabled = !this.props.moistureDepths || !this.props.antennaLength;
    if (this.props.enclosure) disabled = !this.props.moistureDepths;
    if (this.props.probe) disabled = !this.props.moistureDepths;

    const showAntenna = !this.props.enclosure && !this.props.probe;
    const showAddons =
      !this.props.probe && this.props.sensorType === 'watermark';

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      >
        {showAntenna && (
          <React.Fragment>
            <AntennaLength
              value={this.props.antennaLength}
              onChange={this.antennaLengthChange}
            />
            <Spacer border />
          </React.Fragment>
        )}
        <SensorProbe
          sensorType={this.props.sensorType}
          sensorDepths={this.props.moistureDepths}
          onDepthsChange={this.props.moistureDepthsChange}
          probeless={this.props.probeless}
          onProbelessChange={this.probelessChange}
          onCustomChange={this.props.customChange}
          custom={this.props.custom}
          temperatureDepthsChange={this.props.temperatureDepthsChange}
          replacement={this.props.probe}
        />
        {showAddons && (
          <React.Fragment>
            <Spacer border />
            <SensorAddons
              hasRainGauge={!!this.props.hasRainGauge}
              onRainGaugeChange={this.rgChange}
            />
          </React.Fragment>
        )}
        <Spacer border />
        <VolumePricing
          disabled={disabled}
          prices={this.props.prices}
          finish={this.props.finish}
          rgPrices={this.props.rgPrices}
          rg={this.props.hasRainGauge}
          customPrice={this.props.customPrice}
          hidePrices={this.props.hidePrices}
        />
      </div>
    );
  }
}

export default SensorDetails;
