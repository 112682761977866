import * as React from 'react';

import HeaderButton from 'components/HeaderButton/HeaderButton';

const BrochureIcon = require('./assets/brochure.svg');

class Marketing extends React.Component {
  render() {
    const helpBoxPadding = 8;
    const helpBoxHeight = 162 + helpBoxPadding * 2;
    const helpBoxWidth = helpBoxHeight * 1.61803399;
    return (
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          margin: 'auto',
          justifyContent: 'center',
          maxWidth: helpBoxWidth * 4,
        }}
      >
        <HeaderButton
          title="Sensor Station Data Sheet"
          icon={BrochureIcon}
          to="https://firebasestorage.googleapis.com/v0/b/project-2290036988929696476.appspot.com/o/DistributorPortal%2F2020%20Trellis%20Data%20Sheet%20-%20Sensor%20Station.pdf?alt=media&token=dacd6259-eda8-4df7-809c-2f51802aaa7b"
        />
        <HeaderButton
          title="Base Station Data Sheet"
          icon={BrochureIcon}
          to="https://firebasestorage.googleapis.com/v0/b/project-2290036988929696476.appspot.com/o/DistributorPortal%2F2020%20Trellis%20Data%20Sheet%20-%20Base%20Station.pdf?alt=media&token=08c16cfc-f7c7-46f5-992a-6de29d9bcf9c"
        />
        <HeaderButton
          title="Mobile App Brochure"
          icon={BrochureIcon}
          to="https://firebasestorage.googleapis.com/v0/b/project-2290036988929696476.appspot.com/o/DistributorPortal%2FMobile%20App%20Brochure.pdf?alt=media&token=c92698e6-4e1f-4b47-ad5d-51ed2331f46a"
        />
        <HeaderButton
          title="One Page Brochure"
          icon={BrochureIcon}
          to="https://firebasestorage.googleapis.com/v0/b/project-2290036988929696476.appspot.com/o/DistributorPortal%2FTrellis%20Brochure%20-%20Non%20Trifold.pdf?alt=media&token=1dffac72-fd21-483f-b3c2-735c00fff5bf"
        />
      </div>
    );
  }
}

export default Marketing;
