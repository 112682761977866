import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { RouteComponentProps, redirectTo } from '@reach/router';
import gql from 'graphql-tag';
import {
  useRegisterStripeAccountMutation,
  useBillingQuery,
  BillingDocument,
  BillingQuery,
} from 'types/graphql';

import { useApolloClient } from '@apollo/react-hooks';
import { ApolloError } from 'apollo-client';

import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import { STRIPE_CLIENT_ID, isProduction } from 'services/env';

import Loading from 'components/Loading/Loading';
import GraphQLError from 'components/Errors/GraphQLError';
import * as Sentry from '@sentry/browser';

gql`
  mutation RegisterStripeAccount($input: DistributorStripeRegisterInput!) {
    distributorStripeRegister(input: $input) {
      success
    }
  }
`;

gql`
  query Billing {
    currentDistributor {
      id
      email
      name {
        first
        last
      }
      stripeInfo {
        stripeURL
      }
    }
  }
`;

const BASE = `https://connect.stripe.com/express/oauth/authorize`;
const REDIRECT_URI = isProduction
  ? // ? 'https://distributor.mytrellis.com/billing'
    'https://distributor.mytrellis.com/billing'
  : // TODO: Change this to dev site
    'https://distributorportaldev-21cdd.web.app';
const createStripeLink = (params: Record<string, string>) => (
  type: 'company' | 'individual'
) => {
  let url = BASE;
  url += `?redirect_uri=${REDIRECT_URI}`;
  url += `&client_id=${STRIPE_CLIENT_ID}`;
  url += `&stripe_user[business_type]=${type}`;
  const extra = Object.entries(params)
    .map(([k, v]) => `&${k}=${v}`)
    .join('');
  url += extra;
  return url;
};

interface SearchParams {
  code?: string;
  state?: string;
}

type StripeURLResult =
  | { state: 'DONE' }
  | { state: 'ERROR'; error: ApolloError }
  | { state: 'LOADING' };

type BillingProps = RouteComponentProps;

const Billing: React.FC<BillingProps> = ({ location }) => {
  const { data, loading, error } = useBillingQuery();
  const [
    registerAccount,
    registerAccountStatus,
  ] = useRegisterStripeAccountMutation({
    onCompleted: () => {
      // if (location) location.reload();
      redirectTo('/orders');
    },
  });

  const client = useApolloClient();
  const [urlResult, setUrlResult] = useState<StripeURLResult>({
    state: 'DONE',
  });

  function redirectToStripe() {
    // We need to fetch the URL when they click the button because the link
    // expires after a time
    setUrlResult({ state: 'LOADING' });
    client
      .query<BillingQuery>({
        query: BillingDocument,
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        if (!res.data) throw Error('Did not get response');
        const { stripeInfo } = res.data.currentDistributor;
        if (!stripeInfo)
          throw Error('Distributor does not have stripe account');
        const { stripeURL } = stripeInfo;
        window.open(stripeURL);
        setUrlResult({ state: 'DONE' });
      })
      .catch((err) => {
        setUrlResult({ state: 'ERROR', error: err });
        Sentry.captureException(err);
      });
  }

  let code: string | undefined;
  if (location) {
    const search = location.search
      .substring(1)
      .split('&')
      .map((e) => e.split('='))
      .reduce<SearchParams>((p, [k, v]) => ({ ...p, [k]: v }), {});
    code = search.code;
  }

  useEffect(() => {
    if (!code) return;
    registerAccount({ variables: { input: { authCode: code } } });
  }, [code]);

  if (loading || registerAccountStatus.loading) {
    return (
      <Container>
        <Loading />
      </Container>
    );
  }

  if (error || registerAccountStatus.error || !data) {
    return (
      <Container>
        <GraphQLError error={error || registerAccountStatus.error} />
      </Container>
    );
  }

  const {
    currentDistributor: { id, email, stripeInfo },
  } = data;

  if (stripeInfo) {
    // User has already created account

    return (
      <Container>
        <Header>Billing</Header>
        <Info>
          You can view your billing information on Stripe. The button below will
          link you to your Stripe dashboard.
        </Info>
        {urlResult.state === 'ERROR' && (
          <div>
            <GraphQLError error={urlResult.error} />
          </div>
        )}
        <Button
          variant="outlined"
          color="default"
          onClick={redirectToStripe}
          disabled={urlResult.state === 'LOADING'}
          style={{ maxWidth: 450, margin: 'auto' }}
        >
          View Payouts / Change Billing Details
        </Button>
      </Container>
    );
  } else {
    // User has not created account yet
    const createStripeAccountLink = createStripeLink({
      state: id,
      'stripe_user[email]': email || '',
      'suggested_capabilities[]': 'transfers',
    });

    return (
      <Container>
        <Header>Billing</Header>
        <Subtitle>
          Please set up a Stripe account so that you can receive payments from
          Trellis. Stripe is a payment provider that connects to your bank
          account and allows us to transfer funds to you.
        </Subtitle>
        <Row>
          <Section>
            <Button
              variant="outlined"
              color="default"
              href={createStripeAccountLink('company')}
            >
              Create Company Stripe Account
            </Button>
            <Explanation>
              Select if you <b>have</b> a registered company (LLC., S-Corp,
              Partnership, etc.)
            </Explanation>
          </Section>
          <Section>
            <Button
              variant="outlined"
              color="default"
              href={createStripeAccountLink('individual')}
            >
              Create Individual Stripe Account
            </Button>
            <Explanation>
              Select this option if you <b>do not</b> have a registered company.
            </Explanation>
          </Section>
        </Row>
      </Container>
    );
  }
};

const Header = styled.div(
  (p) => `
  font-size: ${p.theme.fontSizes.title};
  margin-bottom: 16px;
`
);

const Container = styled(Paper)`
  margin: 16px auto;
  padding: 16px;
  max-width: 800px;
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 24px;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 32px;
`;

const Explanation = styled.div(
  (p) => `
  color: ${p.theme.colors.textLight};
  text-align: center;
`
);

const Subtitle = styled.div``;

const Info = styled.div`
  margin-bottom: 24px;
`;

export default Billing;
