import * as React from 'react';

import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';

import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';

import { colors, FontClasses } from 'services/theme';

interface SearchBarProps {
  maxWidth?: number;
  content: string;
  onClear: () => void;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const SearchBar: React.SFC<SearchBarProps> = ({
  maxWidth,
  content,
  onChange,
  onClear,
}) => {
  const height = 40;
  const padding = 8;

  const iconStyle = {
    width: 35,
    height: 35,
    color: colors.textLight,
  };

  const SearchBarStyle: React.CSSProperties = {
    maxWidth: maxWidth ? maxWidth - padding * 2 : undefined,
    height,
    zIndex: 2,
    padding,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  };

  const searchInputStyle = {
    background: 'none',
    border: 'none',
    outline: 'none',
    flex: 'auto',
    marginLeft: 5,
    color: colors.text,
    minWidth: 0,
  };

  return (
    <Paper className={FontClasses.title} style={SearchBarStyle}>
      <SearchIcon style={iconStyle} />
      <input
        name="search"
        placeholder="Search"
        style={searchInputStyle}
        value={content}
        onChange={onChange}
        className={FontClasses.subtitle}
      />
      <IconButton onClick={onClear}>
        <CloseIcon style={iconStyle} />
      </IconButton>
    </Paper>
  );
};

export default SearchBar;
