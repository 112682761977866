import * as React from 'react';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Tooltip from '@material-ui/core/Tooltip';

import { colors, FontClasses } from 'services/theme';

interface SelectAddressProps {
  selectedId: string;
  addresses: { id: string; street: string | null; city: string | null }[];
  callback: (val: string) => void;
  button: (id: string) => void;
  disabled: boolean;
}

class SelectAddress extends React.Component<SelectAddressProps> {
  render() {
    const { addresses, selectedId, disabled } = this.props;
    return (
      <div>
        <div className={FontClasses.subtitle}>Shipping Address</div>
        <div style={{ color: colors.textLight }}>
          This where we will ship your order.
        </div>
        <Tooltip
          title="Please choose a sales rep first"
          disableFocusListener={!disabled}
          disableHoverListener={!disabled}
          disableTouchListener={!disabled}
        >
          <Select
            style={{ width: 200, marginRight: 20, marginTop: 0 }}
            value={selectedId}
            onChange={(e) => {
              const val = e.target.value as string;
              if (val === 'addNewAddress') {
                this.props.button('address');
                this.props.callback('');
              } else {
                this.props.callback(val);
              }
            }}
            disabled={disabled}
            data-test="selectAddress"
          >
            {addresses.map(({ id, street, city }) => (
              <MenuItem value={id} key={id}>
                {street}, {city}
              </MenuItem>
            ))}
            <MenuItem
              value="addNewAddress"
              style={{ background: colors.primary, color: 'white' }}
              data-test="addNewAddress"
            >
              Add New Address
            </MenuItem>
          </Select>
        </Tooltip>
      </div>
    );
  }
}

export default SelectAddress;
