import * as React from 'react';

import { colors, FontClasses } from 'services/theme';

interface NoDevicesProps {
  plural?: boolean;
}

const NoDevices = (props: NoDevicesProps) => {
  const message = props.plural
    ? 'None of these devices have updated their location (can take up to 24 hours after installation).  When they do, a map of the location will be displayed here.'
    : 'This device has not yet updated its location (can take up to 24 hours after installation).  When it does, a map of the location will be displayed here.';
  return (
    <div style={{ height: '100%', padding: '10%' }}>
      <span
        className={FontClasses.subtitle}
        style={{ color: colors.textLight, textAlign: 'center' }}
      >
        {message}
      </span>
    </div>
  );
};

export default NoDevices;
