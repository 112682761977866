import * as React from 'react';
import { ApolloProvider } from '@apollo/react-common';
import * as Sentry from '@sentry/browser';
import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { InMemoryCache, NormalizedCacheObject } from 'apollo-cache-inmemory';
import { onError } from 'apollo-link-error';
import { User } from '@firebase/auth-types';

import { firebaseAuth } from 'services/auth';
import { AuthProvider } from 'providers/Auth';

const { NODE_ENV, REACT_APP_AQUILA, REACT_APP_STAGING } = process.env;

export const getGraphQLUrl = () => {
  let aquilaUri = '';
  let dashboardUrl = '';
  let distPortalUrl = '';
  if (NODE_ENV === 'development') {
    // Local dev
    console.log('using local DB');
    aquilaUri = `http://localhost:4000/graphql/external`;
    dashboardUrl = 'http://localhost:3000';
    distPortalUrl = 'http://localhost:3100';
  } else if (REACT_APP_AQUILA === 'development') {
    // Online test
    console.log('using dev DB');
    aquilaUri = 'https://aquila-test.mytrellis.com/graphql/external/';
    dashboardUrl = 'https://trellis-test.firebaseapp.com';
    distPortalUrl = 'https://distributorportaldev-21cdd.firebaseapp.com';
  } else {
    // Online production
    console.log('using production DB');
    aquilaUri = 'https://aquila.mytrellis.com/graphql/external/';
    if (REACT_APP_STAGING) {
      dashboardUrl = 'https://dashboard-staging-5998b.firebaseapp.com';
      distPortalUrl = 'https://distributor-portal-staging.firebaseapp.com';
    } else {
      dashboardUrl = 'https://dashboard.mytrellis.com';
      distPortalUrl = 'https://distributor.mytrellis.com';
    }
  }
  return {
    uri: aquilaUri,
    dashboardUrl,
    distPortalUrl,
    validIframeOrigins: [distPortalUrl, dashboardUrl],
  };
};
const { uri, validIframeOrigins } = getGraphQLUrl();

const httpLink = createHttpLink({ uri });
const cache = new InMemoryCache();

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  Sentry.withScope((scope) => {
    scope.setExtra('operationName', operation.operationName);
    scope.setExtra('operationVariables', operation.variables);
    if (graphQLErrors) {
      scope.setExtra(
        'graphQLErrors',
        graphQLErrors.map(({ message }) => message)
      );
    }
    if (networkError) {
      scope.setExtra('networkError', networkError.message);
    }
    Sentry.captureMessage(`GraphQL Error - ${operation.operationName}`);
  });
});

function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

const withApollo = (WrappedComponent: typeof React.Component) => {
  interface ApolloHOCState {
    token: string | null;
    loading: boolean;
    peekUid: string | null;
  }

  class ApolloHOC extends React.Component<{}, ApolloHOCState> {
    lastRefresh = 0;
    client: ApolloClient<NormalizedCacheObject>;
    user: User | null = null;
    fbUnsub: (() => void) | null = null;

    state: ApolloHOCState = {
      token: null,
      loading: true,
      peekUid: null,
    };

    inFlightTokenFromParent: Promise<string> | null = null;
    getTokenFromParent = () => {
      if (this.inFlightTokenFromParent) return this.inFlightTokenFromParent;
      const p = new Promise<string>((resolve, reject) => {
        const watchdog = setTimeout(() => {
          reject(new Error('Did not get token from parent in time'));
        }, 20000);
        const handler = (e: MessageEvent) => {
          const { data } = e;
          if (typeof data !== 'string' || !data.startsWith('TOKEN:')) return;
          const token = data.split(':')[1];
          window.removeEventListener('message', handler);
          clearTimeout(watchdog);
          this.inFlightTokenFromParent = null;
          resolve(token);
        };
        window.addEventListener('message', handler);
        parent.postMessage('REQUEST_TOKEN', '*');
      });
      this.inFlightTokenFromParent = p;
      return p;
    };

    getTokenFromFirebase = () => {
      if (!this.user) return null;
      return this.user.getIdToken(true);
    };

    refreshToken = async () => {
      const token = inIframe()
        ? await this.getTokenFromParent()
        : await this.getTokenFromFirebase();

      // Only actually setState the first time (loading = true)
      // or if it's a new token
      if (this.state.loading || token !== this.state.token) {
        this.setState({ token, loading: false });
        if (token) this.lastRefresh = Date.now();
      }

      return token;
    };

    constructor(props: {}) {
      super(props);
      const THIRTY_MINUTES = 1000 * 60 * 30;
      const authLink = setContext(async (_, { headers }) => {
        let { token } = this.state;
        const { peekUid } = this.state;
        if (token) {
          if (Date.now() > this.lastRefresh + THIRTY_MINUTES) {
            token = await this.refreshToken();
          }
          const firebasetoken = peekUid ? `peek-${token}::${peekUid}` : token;
          return {
            headers: {
              ...headers,
              firebasetoken,
            },
          };
        } else {
          return {
            headers: {
              ...headers,
            },
          };
        }
      });

      this.client = new ApolloClient({
        link: errorLink.concat(authLink.concat(httpLink)),
        cache,
        defaultOptions: {
          watchQuery: {
            errorPolicy: 'all',
            fetchPolicy: 'cache-first',
          },
        },
      });
    }

    onMessage = (event: MessageEvent) => {
      if (!validIframeOrigins.includes(event.origin)) return;
      if (typeof event.data !== 'string') return;
      else if (event.data === 'GOT_PEEK_MSG') return;
      else if (event.data.startsWith('TOKEN:')) return;
      else if (event.data === 'REQUEST_TOKEN')
        return this.onTokenRequest(event);
      else if (event.data[0] === '{') return this.onPeek(event);
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onTokenRequest = async (event: any) => {
      const token = await this.refreshToken();
      if (event.source && event.source.postMessage) {
        event.source.postMessage(`TOKEN:${token}`, '*');
      }
    };

    // Peek from distributor portal
    onPeek = (event: MessageEvent) => {
      try {
        // Tell it that we got the message
        const { data } = event;
        const {
          token,
          targetUid,
        }: { token: string; targetUid: string } = JSON.parse(data);
        if (!token || !targetUid) return;
        window.parent.postMessage('GOT_PEEK_MSG', '*');
        Sentry.configureScope((scope) => scope.setUser({ username: 'Peeker' }));
        this.setState({ loading: true, peekUid: targetUid }, async () => {
          await this.client.resetStore();
          this.setState({
            token,
            loading: false,
          });
        });
      } catch (err) {
        // It wasn't a peek message
      }
    };

    componentDidMount() {
      if (!inIframe()) {
        // If we are in an iframe, we are peeking
        this.fbUnsub = firebaseAuth().onAuthStateChanged((user) => {
          const { token } = this.state;
          if (token && token.startsWith('peek-')) return;
          this.user = user;

          // Reset graphql cache
          cache.reset();

          this.refreshToken();
        });
      } else {
        // If we are peeking, creds will come from parent, so we don't need
        // to use firebase
        // Refreshing the token will request it from the parent
        this.refreshToken();
      }

      window.addEventListener('message', this.onMessage);
    }

    render() {
      if (this.state.loading) {
        // Wait on firebase auth to resolve before showing anything
        return null;
      }

      return (
        <ApolloProvider client={this.client}>
          <AuthProvider
            value={{
              authed: !!this.state.token,
              peeking: !!this.state.peekUid,
              token: this.state.token,
            }}
          >
            <WrappedComponent {...this.props} />
          </AuthProvider>
        </ApolloProvider>
      );
    }

    componentWillUnmount() {
      if (this.fbUnsub) this.fbUnsub();
      window.removeEventListener('message', this.onMessage);
    }
  }

  return ApolloHOC;
};

export default withApollo;
