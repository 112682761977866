import React from 'react';
import gql from 'graphql-tag';
import { Router, Redirect, RouteComponentProps } from '@reach/router';

import Code404 from 'components/Errors/Code404';
import AnalyticsContext from 'components/AnalyticsContext';

import { withAuth, InjectedAuthProps } from 'providers/Auth';

import Login from 'pages/Entry/Login/Login';
import Register from 'pages/Entry/Register/Register';
import ForceLogout from 'components/ForceLogout';

import Overlay from 'pages/Overlay/Overlay';

import OrderList from 'pages/Order/OrderList/OrderList';
import Order from 'pages/Order/OrderForm/Order';
import Status from 'pages/Status/Status';
import ManageCustomers from 'pages/ManageCustomers/ManageCustomers';
import NewCustomer from 'pages/NewCustomer/NewCustomer';
import ManageCustomer from 'pages/ManageCustomer/ManageCustomer';
import EditHardware from 'pages/EditHardware/EditHardware';
import AddFields from 'pages/AddFields/AddFields';
import Products from 'pages/Products/Products';
import Marketing from 'pages/Marketing/Marketing';
import Admin from 'pages/Admin/Admin';
import AdminShipping from 'pages/AdminShipping/LoadableAdminShipping';
import AdminOrders from 'pages/AdminOrders/LoadableAdminOrders';
import AdminStatus from 'pages/AdminStatus/LoadableAdminStatus';
import AdminStatusSensor from 'pages/AdminStatusSensor/LoadableAdminStatusSensor';
import AdminStatusGateway from 'pages/AdminStatusGateway/LoadableAdminStatusGateway';
import AdminStatusMap from 'pages/AdminStatusMap/LoadableAdminStatusMap';
import BindOrder from 'pages/BindOrder/LoadableBindOrder';
import AdminCreateDistributor from 'pages/AdminCreateDistributor/LoadableAdminCreateDistributor';
import AdminCreateRep from 'pages/AdminCreateRep/LoadableAdminCreateRep';
import AdminPrepay from 'pages/AdminPrepay/LoadableAdminPrepay';
import AdminPeek from 'pages/AdminPeek/LoadableAdminPeek';
import AdminCustomerList from 'pages/AdminCustomerList/LoadableAdminCustomerList';
import AdminDistributorList from 'pages/AdminDistributorList/LoadableAdminDistributorList';
import AdminAllSensors from 'pages/AdminAllSensors/AdminAllSensors';
import AdminServiceLogs from 'pages/AdminServiceLogs/LoadableAdminServiceLogs';
import Help from 'pages/Help/Help';
import Billing from 'pages/Billing/Billing';

import Orders from 'pages/Orders/Orders';

import { useIdentityQuery } from 'types/graphql';
import { ApolloConsumer } from '@apollo/react-common';

gql`
  query Identity {
    currentDistributor {
      id
      isDistributor
      isAdmin
    }
  }
`;

interface RouteProps {
  path: string;
  Component: React.ComponentType<RouteComponentProps<{}>>;
  forceLogout?: boolean;
}

type AppRoute = (props: {
  path: string;
  Component: React.ComponentType<RouteComponentProps<{}>>;
  exact?: boolean;
  forceLogout?: boolean;
}) => JSX.Element;

type AppProps = InjectedAuthProps;

const App: React.FC<AppProps> = ({ authed }) => {
  const { data, loading } = useIdentityQuery();

  if (loading) {
    // Wait for identity to resolve before deciding what to show
    return null;
  }

  const isAdmin = data ? data.currentDistributor.isAdmin : false;

  const loggedInRoutes: RouteProps[] = [
    {
      path: '/',
      Component: () => <Redirect from="/" to="orders" noThrow />,
    },
    {
      path: '/oldOrders',
      Component: OrderList,
    },
    {
      path: '/orders/order',
      Component: Order,
    },
    {
      path: '/products',
      Component: Products,
    },
    {
      path: '/help/marketing',
      Component: Marketing,
    },
    {
      path: '/manage',
      Component: ManageCustomers,
    },
    {
      path: '/manage/newCustomer',
      Component: NewCustomer,
    },
    {
      path: '/manage/:customerId/editHardware',
      Component: EditHardware,
    },
    {
      path: '/manage/:customerId/editFields',
      Component: AddFields,
    },
    {
      path: '/manage/:customerId',
      Component: ManageCustomer,
    },
    {
      path: '/status',
      Component: Status,
    },
    {
      path: '/help',
      Component: Help,
    },
    {
      path: '/billing',
      Component: Billing,
    },
    {
      path: '/orders',
      Component: Orders,
    },
  ];

  const adminRoutes: RouteProps[] = isAdmin
    ? [
        {
          path: '/admin',
          Component: Admin,
        },
        {
          path: '/admin/shipping',
          Component: AdminShipping,
        },
        {
          path: '/admin/orders',
          Component: AdminOrders,
        },
        {
          path: '/admin/shipping/:orderId',
          Component: BindOrder,
        },
        {
          path: '/admin/status',
          Component: AdminStatus,
        },
        {
          path: '/admin/map',
          Component: AdminStatusMap,
        },
        {
          path: '/admin/status/sensor',
          Component: () => (
            <Redirect from="/admin/status/sensor" to="/admin/status" noThrow />
          ),
        },
        {
          path: '/admin/status/sensor/:sensorId',
          Component: AdminStatusSensor,
        },
        {
          path: '/admin/status/gateway',
          Component: () => (
            <Redirect from="/admin/status/gateway" to="/admin/status" noThrow />
          ),
        },
        {
          path: '/admin/status/gateway/:gatewayId',
          Component: AdminStatusGateway,
        },
        {
          path: '/admin/createDistributor',
          Component: AdminCreateDistributor,
        },
        {
          path: '/admin/createRep',
          Component: AdminCreateRep,
        },
        {
          path: '/admin/prepay',
          Component: AdminPrepay,
        },
        {
          path: '/admin/peek',
          Component: AdminPeek,
        },
        {
          path: '/admin/customers',
          Component: AdminCustomerList,
        },
        {
          path: '/admin/distributors',
          Component: AdminDistributorList,
        },
        {
          path: '/admin/sensors',
          Component: AdminAllSensors,
        },
        {
          path: '/admin/logs',
          Component: AdminServiceLogs,
        },
      ]
    : [];

  const loggedOutRoutes: RouteProps[] = [
    {
      path: 'login',
      Component: () => (
        <ApolloConsumer>{(client) => <Login client={client} />}</ApolloConsumer>
      ),
    },
    {
      path: 'register',
      Component: Register,
      forceLogout: true,
    },
  ];

  if (!authed) {
    return (
      <Router>
        {/* Public */}
        {loggedOutRoutes.map(({ Component: Component, path }) => {
          return <Component path={path} key={path} />;
        })}
        <Redirect from="/" to="/login" default noThrow />
      </Router>
    );
  } else {
    return (
      <Overlay>
        <AnalyticsContext />

        <Router>
          {/* Private */}
          {loggedInRoutes.map(({ Component, path }) => (
            <Component path={path} key={path} />
          ))}

          {/* Admin */}
          {adminRoutes.map(({ Component, path }) => (
            <Component path={path} key={path} />
          ))}

          {/* If someone goes to register while logged in, log them out */}
          {loggedOutRoutes.map(({ path, forceLogout }) =>
            forceLogout ? (
              <ForceLogout path={path} key={path} />
            ) : (
              <RedirectComponent path={path} to="/" key={path} />
            )
          )}

          {/* 404 */}
          <Code404 default />
        </Router>
      </Overlay>
    );
  }
};

const RedirectComponent: React.FC<RouteComponentProps<{
  to: string;
}>> = (props) => <Redirect to={props.to} noThrow />;

export default withAuth(App);
