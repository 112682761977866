import * as React from 'react';
import { MoistureMethod } from 'types/graphql';
import Card from './Card';

interface InstallationToolsProps {
  setAugerType: (moistureType: MoistureMethod) => void;
}

class InstallationTools extends React.Component<InstallationToolsProps> {
  cards = [
    {
      title: 'Watermark Auger',
      description:
        "We've used these auger bits to install hundreds of soil moisture sensors. It works well with any cordless drill.",
      onClick: () => this.props.setAugerType(MoistureMethod.tension),
    },
    {
      title: 'Sentek Auger',
      description:
        'Use a tapered auger drill bit to install Sentek sensors without a slurry.',
      onClick: () => this.props.setAugerType(MoistureMethod.capacitance),
    },
  ];

  render() {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'space-around',
        }}
      >
        {this.cards.map(p => (
          <Card {...p} key={p.title} />
        ))}
      </div>
    );
  }
}

export default InstallationTools;
