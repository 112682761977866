import * as React from 'react';
import gql from 'graphql-tag';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Checkbox from '@material-ui/core/Checkbox';

import { SignTCComponent } from 'types/graphql';

import TandCContent from './TandCContent';

gql`
  mutation SignTC($input: DistributorSignTCInput!) {
    distributorSignTC(input: $input) {
      id
    }
  }
`;

interface TandCProps {
  token: string;
  uid: string;
  next: () => void;
}

interface TandCState {
  name: string;
  loc: string;
  accepted: boolean;
}

class TandC extends React.Component<TandCProps, TandCState> {
  state = { name: '', loc: '', accepted: false };

  termsRef = React.createRef<HTMLDivElement>();

  render() {
    const completed =
      this.state.name !== '' && this.state.loc !== '' && this.state.accepted;
    return (
      <SignTCComponent>
        {(sign, { loading, error }) => {
          const submit = () => {
            const tcRef = this.termsRef.current;
            if (!tcRef) throw Error('Could not get terms and conditions text!');
            const tcText = tcRef.textContent;
            if (!tcText)
              throw Error('Could not get terms and conditions text!');
            sign({
              variables: {
                input: {
                  tcText,
                  token: this.props.token,
                  userId: this.props.uid,
                },
              },
            }).then(() => {
              this.props.next();
            });
          };
          return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <TextField
                label="Name"
                value={this.state.name}
                onChange={(e) => this.setState({ name: e.target.value })}
                style={{ maxWidth: 240 }}
              />
              <TextField
                label="Location"
                value={this.state.loc}
                onChange={(e) => this.setState({ loc: e.target.value })}
                style={{ maxWidth: 480 }}
              />
              <div style={{ height: 16 }} />
              <TandCContent
                style={{ overflowY: 'scroll', height: 400, padding: 8 }}
                name={this.state.name}
                loc={this.state.loc}
                ref={this.termsRef}
              />
              <span style={{ color: 'red' }}>{error}</span>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Checkbox
                  checked={this.state.accepted}
                  onChange={() =>
                    this.setState({ accepted: !this.state.accepted })
                  }
                  disabled={loading}
                  color="primary"
                />
                <div>I have read and agree to the Terms and Conditions</div>
              </div>
              <Button
                variant="contained"
                color="primary"
                onClick={submit}
                style={{ maxWidth: 240, height: 50, marginTop: 16 }}
                disabled={!completed}
              >
                Continue
                {loading && (
                  <CircularProgress size={30} style={{ color: 'white' }} />
                )}
              </Button>
            </div>
          );
        }}
      </SignTCComponent>
    );
  }
}

export default TandC;
