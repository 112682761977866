import * as React from 'react';
import AntennaLength from './AntennaLength';
import VolumePricing from './VolumePricing';

interface ReplacementAntennaProps {
  antennaLength: number | undefined;
  antennaLengthChange: (length: number) => void;
  finish: (quantity: number) => void;
  prices:
    | {
        quantity: number;
        price: number;
      }[]
    | null;
  hidePrices: boolean;
}

class ReplacementAntenna extends React.Component<ReplacementAntennaProps> {
  antennaLengthChange = (
    ev: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const val = ev.target.value;
    if (val) this.props.antennaLengthChange(Number(val));
  };

  render() {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      >
        <AntennaLength
          value={this.props.antennaLength}
          onChange={this.antennaLengthChange}
        />
        <div style={{ height: 24 }} />
        <VolumePricing
          finish={this.props.finish}
          disabled={this.props.antennaLength === undefined}
          prices={this.props.prices}
          hidePrices={this.props.hidePrices}
        />
      </div>
    );
  }
}

export default ReplacementAntenna;
