import React, { Suspense, lazy } from 'react';
import Loading from 'components/Loading/Loading';

const LoadedAdmin = lazy(() =>
  import(/* webpackChunkName: "LoadedAdmin" */ './LoadedAdmin')
);

const LoadableAdmin: React.FC = props => (
  <Suspense fallback={<Loading />}>
    <LoadedAdmin {...props} />
  </Suspense>
);

export default LoadableAdmin;
