const BaseStationImage = require('./assets/CBS.jpg');
const SensorStationImage = require('./assets/SMS.jpg');
const ReplacementAntennaImage = require('./assets/Replacement_Antenna.jpg');
const ReplacementEnclosure = require('./assets/Replacement_Electronics_Enclosure.jpg');
const ReplacementProbe = require('./assets/Replacement_Sensor_Probe.jpg');

export const hardwareTypeToPrettyName = (type: string, mobile?: boolean) => {
  switch (type.toLowerCase()) {
    case 'sensor':
      return mobile ? 'Sensor' : 'Sensor Station';
    case 'gateway':
      return mobile ? 'Base' : 'Base Station';
    case 'replacementSensorProbe':
      return 'Replacement Sensor Probe';
    case 'replacementEnclosure':
      return 'Replacement Enclosure';
    case 'replacementAntenna':
      return 'Replacement Antenna';
    default:
      return '';
  }
};

export const hardwareTypeToPrettyNamePlural = (type: string) => {
  return `${hardwareTypeToPrettyName(type)}s`;
};

export const typesInfo = [
  {
    type: 'sensor',
    title: 'Wireless Sensor Station',
    image: SensorStationImage,
    body: [
      {
        type: 'text',
        text:
          'Packaged in a rugged waterproof case, wireless sensor stations regularly record data & automatically upload it to the cloud.',
      },
      {
        type: 'header',
        text: 'Easy Installation',
      },
      {
        type: 'text',
        text: 'Unit arrives pre-assembled.',
      },
      {
        type: 'header',
        text: 'Maintenance-free',
      },
      {
        type: 'text',
        text:
          "Install it and forget it. Battery power lasts all season and a fiberglass antenna allows equipment to drive over unit throughout the season (antennas available in 4', 8', and 12' lengths).",
      },
      {
        type: 'header',
        text: 'Valuable Data',
      },
      {
        type: 'text',
        text:
          "Two Watermark sensors collect moisture data at two depths, customized for each crop's typical root profile. Data collected by base station.",
      },
      {
        type: 'header',
        text: 'Actionable Intelligence',
      },
      {
        type: 'text',
        text:
          'Access your data and make better irrigation decisions using our web/mobile application.',
      },
    ],
  },
  {
    type: 'gateway',
    title: 'Base Station',
    image: BaseStationImage,
    body: [
      {
        type: 'text',
        text:
          'The base station regularly collects data from sensor stations in the field & automatically uploads data to our web/mobile application.',
      },
      {
        type: 'header',
        text: 'Easy Installation',
      },
      {
        type: 'text',
        text: 'Unit delivered pre-assembled.',
      },
      {
        type: 'header',
        text: 'Flexible',
      },
      {
        type: 'text',
        text:
          "Base stations can be used in any crop and with an unlimited quantity of Trellis sensor stations.  Solar panel and battery provide power that lasts all season and an 8' flexible fiberglass antenna ensures sensors are within line-of-sight, regardless of terrain.",
      },
      {
        type: 'header',
        text: 'Actionable Intelligence',
      },
      {
        type: 'text',
        text:
          'View moisture and temperature data collected by base station on our web/mobile application.',
      },
      {
        type: 'header',
        text: 'Not-So-Fine-Print',
      },
      {
        type: 'text',
        text:
          'Currently, Trellis base stations work in areas supported by AT&T cellular coverage.  We recommend one base station per field.',
      },
    ],
  },
  {
    type: 'replacementSensorProbe',
    title: 'Replacement Sensor Probe',
    image: ReplacementProbe,
    body: [
      {
        type: 'text',
        text:
          'Are your sensors worn out after a few seasons?  Pick up a new sensor probe without having to replace the entire sensor station.',
      },
      {
        type: 'text',
        text:
          'Includes temperature sensor and two Watermark soil moisture sensors.  Conduit also included.  Electronics enclosure and antenna are sold separately.',
      },
    ],
  },
  {
    type: 'replacementEnclosure',
    title: 'Replacement Electronics Enclosure',
    image: ReplacementEnclosure,
    body: [
      {
        type: 'text',
        text:
          'Accidentally leave your electronics enclosure open in the rain?  Get a replacement enclosure, without having to replace the entire sensor station.',
      },
      {
        type: 'text',
        text:
          'Sensor station electronics enclosure includes the enclosure itself, a new circuit board, batteries, and radio.  The base station electronics enclosure includes the enclosure itself, a new circuit board, battery, solar panel, and radio.  Sensor probe and antenna not included.',
      },
    ],
  },
  {
    type: 'replacementAntenna',
    title: 'Replacement Antenna',
    image: ReplacementAntennaImage,
    body: [
      {
        type: 'text',
        text:
          'Interested in installing your sensor stations in multiple crops throughout the year?  A replacement antenna allows you to swap out a shorter antenna for a taller one (or vice versa).',
      },
      {
        type: 'text',
        text:
          'Includes antenna assembly only.  Sensor probe and electronics enclosure(s) are not included.  Antenna compatibility shown in the table below.',
      },
    ],
  },
];
