import * as React from 'react';
import uniq from 'lodash/uniq';

import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';

import { MobileOnly, DesktopOnly } from 'providers/Responsive';

import { hardwareTypeToPrettyName } from 'services/hardware';
import { hardwareWarningToIcon } from 'services/HardwareWarnings';
import StatusTableModal from './StatusTableModal/StatusTableModal';

import { HardwareWarningEnum, ConnectionStatus } from 'types/graphql';

import { signalToIcon, batteryToIcon } from 'services/iconPicker/IconPicker';
import { FontClasses } from 'services/theme';

interface Location {
  lat: number | null;
  lng: number | null;
}

interface Owner {
  name: { first: string | null; last: string | null } | null;
}

interface Hardware {
  id: string;
  location: Location | null;
  owner: Owner | null;
  connectionStatus: ConnectionStatus;
  statusWarnings: HardwareWarningEnum[];
}

interface SensorHardware extends Hardware {
  __typename: 'Sensor';
  name: string | null;
  rssi: number | null;
  battery: number | null;
  sensor_time: number | null;
  gatewayId: string | null;
}

interface GatewayHardware extends Hardware {
  __typename: 'Gateway';
  cell_signal: number | null;
  gateway_battery: number | null;
  gateway_time: number | null;
  sensorIds: string[];
}

interface StatusTableProps {
  hardware: (GatewayHardware | SensorHardware)[];
}

class StatusTable extends React.Component<StatusTableProps> {
  shortenLength = (string: string | null, maxLength: number) => {
    if (!string) return '';
    if (string.length > maxLength) {
      return `${string.slice(0, maxLength - 3)}...`;
    }
    return string;
  };

  cellStyle = { padding: 6 };

  isOnline = (
    status: string,
    time: number | null,
    statusWarnings: HardwareWarningEnum[]
  ) => {
    let text;
    let backgroundColor;
    if (status === 'online' && statusWarnings.length === 0) {
      text = 'Online';
      backgroundColor = '#226f54';
    } else if (status === 'offline') {
      text = 'Offline';
      backgroundColor = '#F44336';
    } else {
      text = 'Warning';
      backgroundColor = '#bcb41a';
    }
    return (
      <TableCell
        className={FontClasses.body}
        style={{
          ...this.cellStyle,
          textAlign: 'center',
          backgroundColor,
          color: 'white',
        }}
      >
        {text}
        <div>{time ? new Date(time).toDateString() : 'No data'}</div>
        {statusWarnings.map(warning => {
          const Icon = hardwareWarningToIcon(warning);
          if (!Icon) return null;
          return <Icon key={warning} />;
        })}
      </TableCell>
    );
  };

  render() {
    const iconSize = 20;

    const { hardware } = this.props;
    const cellStyle = this.cellStyle;

    return (
      <Table>
        <TableHead className={FontClasses.title}>
          <TableRow>
            <TableCell className={FontClasses.body} style={cellStyle}>
              Customer
            </TableCell>
            <DesktopOnly>
              <TableCell className={FontClasses.body} style={cellStyle}>
                Device Type
              </TableCell>
              <TableCell className={FontClasses.body} style={cellStyle}>
                Name
              </TableCell>
              <TableCell className={FontClasses.body} style={cellStyle}>
                ID
              </TableCell>
              <TableCell className={FontClasses.body} style={cellStyle}>
                Assoc. ID
              </TableCell>
              <TableCell className={FontClasses.body} style={cellStyle}>
                GPS Location
              </TableCell>
            </DesktopOnly>
            <MobileOnly>
              <TableCell className={FontClasses.body} style={cellStyle}>
                Info
              </TableCell>
            </MobileOnly>
            <TableCell className={FontClasses.body} style={cellStyle}>
              Status
            </TableCell>
            <TableCell className={FontClasses.body} style={cellStyle}>
              Indicator
              <StatusTableModal />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {hardware.map(hw => {
            const signal =
              hw.__typename === 'Sensor' ? hw.rssi : hw.cell_signal;
            const battery =
              hw.__typename === 'Sensor' ? hw.battery : hw.gateway_battery;
            const location = hw.location
              ? `${hw.location.lat}, ${hw.location.lng}`
              : '';
            const time =
              hw.__typename === 'Sensor' ? hw.sensor_time : hw.gateway_time;
            const typeName = hardwareTypeToPrettyName(hw.__typename);
            const { owner } = hw;
            const assocId =
              hw.__typename === 'Sensor'
                ? hw.gatewayId
                : uniq(hw.sensorIds).join(', ');
            const name = hw.__typename === 'Sensor' ? hw.name : '';
            return (
              <TableRow key={`${hw.id}${hw.__typename}`}>
                <TableCell className={FontClasses.body} style={cellStyle}>
                  {owner && owner.name && (
                    <span>
                      {owner.name.first} {owner.name.last}
                    </span>
                  )}
                </TableCell>
                <DesktopOnly>
                  <TableCell className={FontClasses.body} style={cellStyle}>
                    {typeName}
                  </TableCell>
                  <TableCell className={FontClasses.body} style={cellStyle}>
                    {name}
                  </TableCell>
                  <TableCell className={FontClasses.body} style={cellStyle}>
                    {hw.id}
                  </TableCell>
                  <TableCell
                    className={FontClasses.body}
                    style={{ padding: 6, maxWidth: '100%' }}
                  >
                    {assocId}
                  </TableCell>
                  <TableCell
                    className={FontClasses.body}
                    style={{ padding: 6, maxWidth: '100%' }}
                  >
                    {location}
                  </TableCell>
                </DesktopOnly>
                <MobileOnly>
                  <TableCell className={FontClasses.body} style={cellStyle}>
                    <div>
                      <b>{this.shortenLength(name, 16)}</b>
                    </div>
                    <div className={FontClasses.caption}>
                      Type: {hardwareTypeToPrettyName(hw.__typename, true)}
                    </div>
                    <div className={FontClasses.caption}>ID: {hw.id}</div>
                    <div className={FontClasses.caption}>
                      Assoc. ID: {assocId}
                    </div>
                    <div className={FontClasses.caption}>GPS: {location} </div>
                  </TableCell>
                </MobileOnly>
                <TableCell
                  className={FontClasses.body}
                  style={{ ...cellStyle, paddingLeft: 18 }}
                >
                  <img
                    src={signalToIcon(hw.__typename, signal)}
                    style={{ width: iconSize, height: iconSize }}
                  />
                  <div style={{ width: iconSize }} />
                  <img
                    src={batteryToIcon(hw.__typename, battery)}
                    style={{ width: iconSize, height: iconSize }}
                  />
                </TableCell>
                {this.isOnline(hw.connectionStatus, time, hw.statusWarnings)}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  }
}

export default StatusTable;
