import * as React from 'react';

import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { Link } from '@reach/router';

import { colors, FontClasses } from 'services/theme';

import { useIsMobile } from 'providers/Responsive';

const email = 'info@mytrellis.com';
const phone = '1-678-551-2871';

const OrderThanks = () => {
  const isMobile = useIsMobile();
  const padding = isMobile ? '0%' : '20%';
  return (
    <Paper
      style={{
        paddingLeft: 20,
        paddingTop: 20,
        paddingBottom: 30,
        paddingRight: 20,
      }}
    >
      <h1 style={{ color: colors.primary, textAlign: 'center' }}>
        Your order has been submitted!
      </h1>
      <div
        className={FontClasses.title}
        style={{
          paddingLeft: padding,
          paddingRight: padding,
        }}
      >
        <p>Thank you so much for your order!</p>
        <p>
          You (and the sales rep) will receive an Order Confirmation email and
          text once the order has been approved. You both will also receive an
          email with tracking information once the order ships.
        </p>
        <p>
          If you have any questions, please reach out to us via chat, send us an
          email at{' '}
          <a href={`mailto:${email}`} target="_top">
            {email}
          </a>
          , or give us a call at <a href={`tel:${phone}`}>{phone}</a>.
        </p>
      </div>
      <div style={{ textAlign: 'center' }}>
        <Link to="/orders" style={{ textDecoration: 'none' }}>
          <Button variant="contained" color="primary" data-test="backToOrders">
            Back to Order List
          </Button>
        </Link>
      </div>
    </Paper>
  );
};

export default OrderThanks;
