import React, { useState } from 'react';
import gql from 'graphql-tag';
import find from 'lodash/find';
import moment from 'moment';

import { useRepsQuery } from 'types/graphql';

import { useIsMobile } from 'providers/Responsive';

import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import Loading from 'components/Loading/Loading';

import SelectRep from './components/SelectRep';
import SelectAddress from './components/SelectAddress';
import AccountManagement from './components/AccountManagement';
import PopupAddress from './components/PopupAddress';
import PopupRep from './components/PopupRep';
import PopupCustomer from './components/PopupCustomer';

import GenericError from 'components/Errors/GenericError';

import { FontClasses } from 'services/theme';
import { isValidEmail } from 'services/util';

gql`
  query Reps {
    currentDistributor {
      id
      reps {
        id
        email
        phone
        name {
          first
          last
        }
        addresses {
          id
          street
          city
          state
          zip
          name {
            first
            last
          }
        }
      }
      customers {
        id
        repId
        name {
          first
          last
        }
        email
        phone
      }
    }
  }
`;

interface OrderRepProps {
  nextStep: () => void;
  previousStep: () => void;
  initialRepId: string;
  initialAddressId: string;
  initialCustomerId: string;
  save: (input: {
    addressId: string;
    repId: string;
    customerId: string;
    prepaid: boolean;
    prepaidMonths: number;
    prepaidStart: number | null;
    sharingEmails: string[];
    managedPrice: number | null;
    managedPrepaid: boolean;
    managedMonths: number | null;
  }) => void;
  isManaged: boolean;
  initialManagedPrice: number | null;
  initialManagedPrepaid: boolean;
  initialManagedMonths: number | null;
}

const OrderRep: React.FC<OrderRepProps> = (props) => {
  const { data, loading, error, refetch } = useRepsQuery();

  const [selectedRep, setSelectedRep] = useState(props.initialRepId);
  const [selectedAddress, setSelectedAddress] = useState(
    props.initialAddressId
  );
  const [selectedCustomer, setSelectedCustomer] = useState(
    props.initialCustomerId
  );
  const [showPopupAdd, setShowPopupAdd] = useState(false);
  const [showPopupRep, setShowPopupRep] = useState(false);
  const [showPopupCustomer, setShowPopupCustomer] = useState(false);
  const [prepaid, setPrepaid] = useState(false);
  const [prepaidMonths, setPrepaidMonths] = useState(1);
  const [emailErrors, setEmailErrors] = useState([false]);
  const [sharingEmails, setSharingEmails] = useState(['']);
  const [prepaidStart, setPrepaidStart] = useState<moment.Moment | null>(
    moment()
  );
  const [managedPrice, setManagedPrice] = useState(
    props.initialManagedPrice === null ? '' : String(props.initialManagedPrice)
  );
  const [managedPrepaid, setManagedPrepaid] = useState(
    props.initialManagedPrepaid
  );
  const [managedMonths, setManagedMonths] = useState(
    props.initialManagedMonths === null
      ? ''
      : String(props.initialManagedMonths)
  );

  const isMobile = useIsMobile();

  const handleRequestClose = () => {
    setShowPopupRep(false);
    setShowPopupAdd(false);
    setShowPopupCustomer(false);
  };

  const save = () => {
    props.save({
      addressId: selectedAddress,
      repId: selectedRep,
      customerId: selectedCustomer,
      prepaid: prepaid,
      prepaidMonths: prepaidMonths,
      prepaidStart: prepaidStart ? prepaidStart.valueOf() : null,
      sharingEmails: sharingEmails,
      managedPrice: managedPrice !== '' ? Number(managedPrice) : null,
      managedPrepaid,
      managedMonths: managedMonths !== '' ? Number(managedMonths) : null,
    });
  };

  const previousStep = () => {
    save();
    props.previousStep();
  };

  const tryNextStep = () => {
    save();
    // Check that emails are valid
    const newEmailErrors = sharingEmails.map(
      (email) => email !== '' && !isValidEmail(email)
    );
    setEmailErrors(newEmailErrors);
    const noErrors = newEmailErrors.every((e) => !e);
    if (noErrors) props.nextStep();
  };

  const togglePopup = (type: string) => {
    if (type === 'rep') {
      setShowPopupRep(true);
    } else if (type === 'customer') {
      setShowPopupCustomer(true);
    } else {
      setShowPopupAdd(true);
    }
  };

  const onSharingEmailChange = (row: number, value: string) => {
    const newSharingEmails = sharingEmails.map((email, i) => {
      if (i === row) return value;
      return email;
    });
    setSharingEmails(newSharingEmails);
  };

  const onSharingEmailAdd = () => {
    const newSharingEmails = [...sharingEmails];
    newSharingEmails.push('');
    setSharingEmails(newSharingEmails);
  };

  const onSharingEmailRemove = (row: number) => {
    const newSharingEmails = sharingEmails.filter((_, i) => {
      if (i === row) return false;
      return true;
    });
    setSharingEmails(newSharingEmails);
  };

  if (loading) return <Loading />;
  if (error || !data) return <GenericError />;

  const { currentDistributor } = data;
  const { reps, customers } = currentDistributor;
  const rep = find(reps, { id: selectedRep });
  const repFirstName = rep?.name?.first || '';
  const repLastName = rep?.name?.last || '';
  const repEmail = rep?.email || '';
  const repPhone = rep?.phone || '';

  const repsCustomers = customers.filter((e) => e.repId === selectedRep);

  const customer = find(repsCustomers, {
    id: selectedCustomer,
  });
  const customerFirstName = customer?.name?.first || '';
  const customerLastName = customer?.name?.last || '';
  const customerEmail = customer?.email || '';
  const customerPhone = customer?.phone || '';

  const addresses = rep ? rep.addresses : [];
  const address = find(addresses, {
    id: selectedAddress,
  });
  const addressCity = address?.city || '';
  const addressState = address?.state || '';
  const addressZip = address?.zip || '';
  const addressStreet = address?.street || '';
  const addressFirstName = address?.name?.first || '';
  const addressLastName = address?.name?.last || '';

  const buttonStyle = isMobile ? { width: '50%' } : {};

  const managedMonthsNumber =
    managedMonths === '' ? null : Number(managedMonths);
  const managedMonthsIsValid = props.isManaged
    ? managedMonthsNumber !== null && managedMonthsNumber > 0
    : true;

  const managedPriceNumber = managedPrice === '' ? null : Number(managedPrice);
  const managedPriceIsValid = props.isManaged
    ? managedPriceNumber !== null && managedPriceNumber >= 0
    : true;

  const customerInfoIsOK = props.isManaged
    ? selectedCustomer !== ''
    : !prepaid || (prepaidMonths > 0 && selectedCustomer !== '');

  const canContinue =
    selectedRep !== '' &&
    selectedAddress !== '' &&
    customerInfoIsOK &&
    managedMonthsIsValid &&
    managedPriceIsValid;

  const paperStyle = {
    padding: 16,
    marginBottom: 20,
  };

  return (
    <>
      <PopupRep
        open={showPopupRep}
        close={handleRequestClose}
        refresh={refetch}
        selectRep={setSelectedRep}
      />
      <PopupAddress
        open={showPopupAdd}
        close={handleRequestClose}
        refresh={refetch}
        repId={selectedRep}
        repName={{ first: repFirstName, last: repLastName }}
        selectAddress={setSelectedAddress}
      />
      <PopupCustomer
        open={showPopupCustomer}
        close={handleRequestClose}
        refresh={refetch}
        repId={selectedRep}
        selectCustomer={setSelectedCustomer}
      />

      {/* Sales Rep */}
      <Paper style={paperStyle}>
        <SelectRep
          selectedId={selectedRep}
          reps={reps}
          callback={setSelectedRep}
          button={togglePopup}
        />
        <div style={{ height: 24 }} />
        {selectedRep && (
          <div>
            <div className={FontClasses.title}>
              {repFirstName}&nbsp;{repLastName}
            </div>
            <div>{repEmail}</div>
            <div>{repPhone}</div>
          </div>
        )}
      </Paper>

      {/* Address */}
      <Paper style={paperStyle}>
        <SelectAddress
          selectedId={selectedAddress}
          addresses={addresses}
          callback={setSelectedAddress}
          button={togglePopup}
          disabled={selectedRep === ''}
        />
        <div style={{ height: 24 }} />
        {selectedAddress && (
          <div>
            <div className={FontClasses.title}>
              {addressFirstName} {addressLastName}
            </div>
            <div>{addressStreet}</div>
            <div>
              {addressCity}, {addressState} {addressZip}
            </div>
          </div>
        )}
      </Paper>

      {/* Customer */}
      <Paper style={paperStyle}>
        <AccountManagement
          selectedCustomerId={selectedCustomer}
          customers={repsCustomers}
          handleCustomerChange={setSelectedCustomer}
          button={togglePopup}
          disabled={selectedRep === ''}
          selectedCustomer={selectedCustomer}
          prepaid={prepaid}
          onPrepaidChange={() => setPrepaid(!prepaid)}
          prepaidMonths={prepaidMonths}
          onPrepaidMonthsChange={setPrepaidMonths}
          emailErrors={emailErrors}
          sharingEmails={sharingEmails}
          onSharingEmailChange={onSharingEmailChange}
          onSharingEmailAdd={onSharingEmailAdd}
          onSharingEmailRemove={onSharingEmailRemove}
          prepaidStart={prepaidStart}
          onPrepaidStartChange={setPrepaidStart}
          repId={selectedRep}
          customerFirstName={customerFirstName}
          customerLastName={customerLastName}
          customerEmail={customerEmail}
          customerPhone={customerPhone}
          isManaged={props.isManaged}
          managedPrice={managedPrice}
          setManagedPrice={setManagedPrice}
          managedPrepaid={managedPrepaid}
          setManagedPrepaid={setManagedPrepaid}
          managedMonths={managedMonths}
          setManagedMonths={setManagedMonths}
          managedMonthsIsValid={managedMonthsIsValid}
          managedPriceIsValid={managedPriceIsValid}
        />
      </Paper>

      <div
        style={{
          width: '100%',
          margin: 'auto',
          marginTop: 10,
          maxWidth: 800,
        }}
      >
        <div style={{ float: 'right' }}>
          <Button onClick={previousStep} style={buttonStyle}>
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={tryNextStep}
            data-test="selectRepNext"
            style={buttonStyle}
            disabled={!canContinue}
          >
            Continue
          </Button>
        </div>
      </div>
    </>
  );
};

export default OrderRep;
