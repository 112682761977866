import { ConnectionStatus } from 'types/graphql';

export interface Device {
  id: string;
  name?: string | null;
  type: string;
  location: {
    lat: number;
    lng: number;
  } | null;
  state?: number | null;
  hasRainGauge: boolean;
  connectionStatus?: ConnectionStatus;
  customer?: string;
  opacity?: number;
}

export interface DeviceWithLocation extends Device {
  location: {
    lat: number;
    lng: number;
  };
}

export const isValidLocation = (e: Device): e is DeviceWithLocation => {
  return !!e.location && !!e.location.lat && !!e.location.lng;
};

export const gatewayIconUrl = 'https://bit.ly/2KdtZkl';

export const MAX_ZOOM = 16;
