import 'react-app-polyfill/ie11';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import { isProduction, version } from 'services/env';

if (isProduction) {
  Sentry.init({
    dsn: 'https://781dbca34c25498f84f55a79fb5cb792@sentry.io/1327036',
    release: version,
  });
}

import Wrapped from './wrapper';

ReactDOM.render(<Wrapped />, document.getElementById('root'));
