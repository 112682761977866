import * as React from 'react';
import gql from 'graphql-tag';
import moment from 'moment';
import OrderSummary from 'components/OrderSummary/OrderSummary';
import GraphQLError from 'components/Errors/GraphQLError';

import { OrderComponent } from 'types/graphql';
import { MODEL_FIELDS } from 'services/model';
import BasicModal from './BasicModal';

gql`
  query Order($orderId: Int!) {
    order(orderId: $orderId) {
      id
      placedTimestamp
      notes
      sharingEmails
      zohoSalesOrder {
        invoice {
          due
          paymentUrl
          status
        }
      }
      prepaid {
        plan
        months
        start
      }
      details {
        customer {
          id
          email
          name {
            first
            last
          }
        }
        hardware {
          id
          model {
            ...ModelFields
          }
          pricePerUnit
          quantity
          type
        }
        address {
          id
          name {
            first
            last
          }
          street
          city
          state
          zip
        }
        rep {
          id
          email
          phone
          name {
            first
            last
          }
        }
      }
      managedServiceOptions {
        months
        prepaid
        price
      }
    }
  }
  ${MODEL_FIELDS}
`;

interface OrderPopupProps {
  orderId: number | null;
  closeFunction: () => void;
  open: boolean;
}

class OrderPopup extends React.Component<OrderPopupProps> {
  render() {
    if (!this.props.open) return null;
    return (
      <OrderComponent variables={{ orderId: Number(this.props.orderId) }}>
        {({ data, loading, error }) => {
          if (loading) return null;
          if (error || !data || !data.order)
            return <GraphQLError error={error} />;
          const { order } = data;

          const {
            placedTimestamp,
            details,
            prepaid,
            notes,
            sharingEmails,
            zohoSalesOrder,
            managedServiceOptions,
          } = order;
          const { hardware, rep, address, customer } = details;

          const invoice = zohoSalesOrder && zohoSalesOrder.invoice;

          return (
            <BasicModal
              open={this.props.open}
              onClose={this.props.closeFunction}
              title={`Order ${order.id}`}
              subtitle={moment(placedTimestamp).format('MM-DD-YYYY')}
              addCloseButton
            >
              <OrderSummary
                hardware={hardware}
                rep={rep}
                address={address}
                shipping={null}
                prepaid={prepaid}
                customer={customer}
                notes={notes}
                sharingEmails={sharingEmails}
                invoice={invoice}
                managedServiceOptions={managedServiceOptions}
              />
            </BasicModal>
          );
        }}
      </OrderComponent>
    );
  }
}

export default OrderPopup;
