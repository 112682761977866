import React, { Suspense, lazy } from 'react';
import Loading from 'components/Loading/Loading';

const AdminCreateRep = lazy(() =>
  import(/* webpackChunkName: "AdminCreateRep" */ './AdminCreateRep')
);

const LoadableAdminCreateRep: React.FC = props => (
  <Suspense fallback={<Loading />}>
    <AdminCreateRep {...props} />
  </Suspense>
);

export default LoadableAdminCreateRep;
