import React, { useState } from 'react';
import { Link } from '@reach/router';
import gql from 'graphql-tag';

import { useIsMobile } from 'providers/Responsive';

import Loading from 'components/Loading/Loading';
import OrderTable from 'components/OrderTable';

import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import OrderPopup from 'components/OrderPopup';
import BasicModal from 'components/BasicModal';
import List from '@material-ui/core/List';

import { GetBindOrderComponent, OrdersComponent } from 'types/graphql';
import GenericError from 'components/Errors/GenericError';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

gql`
  query Orders {
    currentDistributor {
      id
      isDistributor
      orders {
        id
        approved
        placedTimestamp
        status
        details {
          hardware {
            id
            quantity
          }
          customer {
            id
            name {
              first
              last
            }
          }
          rep {
            id
            name {
              first
              last
            }
          }
        }
      }
    }
  }
`;

interface OrderListProps {}

const OrderList: React.FC<OrderListProps> = () => {
  const isMobile = useIsMobile();
  const [selectedOrder, setSelectedOrder] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const [showIdPopup, setShowIdPopup] = useState(false);

  let toOpenIdPopup = false;
  let toOpenPopup = false;

  return (
    <OrdersComponent fetchPolicy="network-only">
      {({ data, loading, error }) => {
        if (loading) return <Loading />;
        if (error || !data) return <GenericError />;
        const { currentDistributor } = data;
        const { orders, isDistributor } = currentDistributor;
        const marginAmount = isMobile ? 0 : 24;
        const buttonWidth = isMobile ? '100%' : undefined;
        return (
          <div
            style={{
              flexGrow: 1,
              margin: marginAmount,
              paddingTop: marginAmount,
            }}
          >
            {/* Popup */}
            <OrderPopup
              orderId={selectedOrder}
              closeFunction={() => setShowPopup(false)}
              open={showPopup}
            />
            <BasicModal
              open={showIdPopup}
              onClose={() => setShowIdPopup(false)}
              addCloseButton
              title={`Bound Ids: Order #${selectedOrder}`}
            >
              <GetBindOrderComponent
                variables={{ orderId: Number(selectedOrder) }}
              >
                {({ data, loading, error }) => {
                  if (loading) return <Loading />;
                  if (error || !data || !data.order) {
                    return <GenericError />;
                  }
                  const boundIds = data.order?.boundIds;
                  if (!boundIds) {
                    return <GenericError />;
                  }
                  if (boundIds === null || boundIds.length === 0) {
                    return <p>This order has not been bound.</p>;
                  }
                  return (
                    <List>
                      {boundIds.map((item) => (
                        <ListItem key={`${item}`} divider={true}>
                          <ListItemText primary={`${item}`} />
                        </ListItem>
                      ))}
                    </List>
                  );
                }}
              </GetBindOrderComponent>
            </BasicModal>
            {/* Place Order Button */}
            {isDistributor && (
              <Link to="/orders/order" style={{ textDecoration: 'none' }}>
                <Button
                  variant="contained"
                  color="primary"
                  id="placeOrder"
                  style={{ width: buttonWidth, height: 48 }}
                >
                  Place Order
                </Button>
              </Link>
            )}
            {/* Table */}
            <Paper
              style={{
                marginTop: 20,
                alignSelf: 'stretch',
                overflow: 'auto',
              }}
            >
              <OrderTable
                orders={orders}
                onRowClick={(_, id) => {
                  setSelectedOrder(id);
                  toOpenPopup = true;
                  setShowPopup(() => {
                    if (toOpenIdPopup) {
                      return false;
                    } else if (toOpenPopup) {
                      return true;
                    }
                    return false;
                  });
                  setShowIdPopup(() => {
                    if (toOpenIdPopup) {
                      return true;
                    } else return false;
                  });
                }}
                onIdClick={(id) => {
                  setSelectedOrder(id);
                  toOpenIdPopup = true;
                }}
              />
            </Paper>
            <div style={{ height: 24 }} />
          </div>
        );
      }}
    </OrdersComponent>
  );
};

export default OrderList;
