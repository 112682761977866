import React, { Suspense, lazy } from 'react';
import Loading from '../../components/Loading/Loading';

const BindOrder = lazy(() =>
  import(/* webpackChunkName: "BindOrder" */ './BindOrder')
);

const LoadableBindOrders: React.FC = props => (
  <Suspense fallback={<Loading />}>
    <BindOrder {...props} />
  </Suspense>
);

export default LoadableBindOrders;
