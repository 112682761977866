const { NODE_ENV, STORYBOOK_AQUILA } = process.env;
const { version: pjsonVersion } = require('../../package.json');

export const isProduction = NODE_ENV === 'production';
export const isDevelopment = !isProduction;

export const isStorybook = STORYBOOK_AQUILA === 'development';
export const isJest = NODE_ENV === 'test';

export const isTesting = isStorybook || isJest;
export const version = pjsonVersion;

export const STRIPE_CLIENT_ID = isProduction
  ? 'ca_AUymFURdeZuAJSUBL0Cx11BoVsWaRaW2'
  : 'ca_AUymBCwqKk1OHHB3Z1Kd6YfthtKYeX51';
