import * as React from 'react';
import Button from '@material-ui/core/Button';

/* eslint-disable react/no-unescaped-entities */

interface TandCPrefaceProps {
  next: () => void;
}

class TandCPreface extends React.Component<TandCPrefaceProps> {
  render() {
    return (
      <div>
        <div>
          By signing this agreement, you are agreeing to be a non-exclusive
          dealer of Trellis products.
        </div>

        <h5>Territory</h5>
        <div>You can sell Trellis products anywhere in the US.</div>

        <h5>Dealer Portal</h5>
        <div>
          Place orders, set up your customers' accounts, & monitor their
          equipment from this website (http://distributor.mytrellis.com)
        </div>

        <h5>Prices</h5>
        <div>
          As a Trellis dealer, you'll receive our products at a volume-based
          discount (see our{' '}
          <a
            href="https://docs.zoho.com/file/bcoqzf24e3d7f18ac40a6a00abe48e38886f4"
            target="_blank"
            rel="noopener noreferrer"
          >
            2019 Dealer Pricing Guide
          </a>{' '}
          for details). The more products you purchase, the greater the discount
          we're able to offer.
        </div>
        <div>
          In terms of reselling our products, you can set your own prices. Some
          dealers like to bundle the hardware with other equipment-related
          services like install, removal, etc. Other dealers include the
          equipment as part of their existing crop consulting business.
        </div>
        <h5>Training</h5>
        <div>
          Your sales & support representative will set up a time to walk through
          the dealer portal & answer any questions you might have. We also want
          you to feel comfortable with the equipment! If there's anything we can
          do, let us know; we'd be happy to help.
        </div>
        <Button
          variant="contained"
          color="primary"
          onClick={this.props.next}
          style={{ maxWidth: 240, height: 50, marginTop: 8 }}
        >
          Continue
        </Button>
      </div>
    );
  }
}

export default TandCPreface;
