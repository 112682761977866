import React, { Suspense, lazy } from 'react';
import Loading from 'components/Loading/Loading';

const AdminOrders = lazy(() =>
  import(/* webpackChunkName: "AdminOrders" */ './AdminOrders')
);

const LoadableAdminOrders: React.FC = props => (
  <Suspense fallback={<Loading />}>
    <AdminOrders {...props} />
  </Suspense>
);

export default LoadableAdminOrders;
