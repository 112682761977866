import React, { Suspense, lazy } from 'react';
import Loading from 'components/Loading/Loading';

const AdminStatus = lazy(() =>
  import(/* webpackChunkName: "AdminStatus" */ './AdminStatus')
);

const LoadableAdminStatus: React.FC = props => (
  <Suspense fallback={<Loading />}>
    <AdminStatus {...props} />
  </Suspense>
);

export default LoadableAdminStatus;
