import * as React from 'react';

import './Loading.css';
const AnimatedLogo = require('./spinningDots.svg');
const StaticLogo = require('./trellis_logo_full_color_dots_only.png');

const isIE = () => {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf('MSIE ');
  if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv:11\./)) {
    return true;
  }
  return false;
};

interface LoadingProps {
  error?: boolean | {};
  pastDelay?: boolean;
  outerStyle?: {};
  style?: {};
}

class Loading extends React.PureComponent<LoadingProps> {
  render() {
    let { error, pastDelay, style, outerStyle } = this.props;
    if (error === undefined) error = false;
    if (pastDelay === undefined) pastDelay = true;
    if (style === undefined) style = {};
    if (outerStyle === undefined) outerStyle = {};

    if (error) throw Error('Error loading loadable component');
    if (!pastDelay) return null;
    const src = isIE() ? StaticLogo : AnimatedLogo;
    return (
      <div style={{ display: 'flex', justifyContent: 'center', ...outerStyle }}>
        <img
          src={src}
          style={{
            margin: 50,
            maxWidth: 200,
            maxHeight: 200,
            width: '100%',
            height: '100%',
            ...style,
          }}
          className="LoadingSpinner"
        />
      </div>
    );
  }
}

export default Loading;
