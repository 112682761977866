import * as React from 'react';
import TwoColumn from './TwoColumn';
import Spacer from './Spacer';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import VolumePricing from './VolumePricing';

interface GatewayAddonsProps {
  hasRainGauge: boolean | undefined;
  hasRainGaugeChange: (hasRainGauge: boolean) => void;
  hasWeatherStation: boolean | undefined;
  hasWeatherStationChange: (hasWeatherStation: boolean) => void;
  hasPressureSensor: boolean | undefined;
  hasPressureSensorChange: (hasPressureSensor: boolean) => void;
  antennaLengthChange: (length: number) => void;
  finish: (quantity: number) => void;
  prices:
    | {
        quantity: number;
        price: number;
      }[]
    | null;
  rgPrices:
    | {
        quantity: number;
        price: number;
      }[]
    | null;
  hidePrices: boolean;
}

class GatewayAddons extends React.Component<GatewayAddonsProps> {
  componentDidMount() {
    this.props.antennaLengthChange(8);
  }

  handleChange = (value: string) => () => {
    if (value === 'basic') {
      this.props.hasRainGaugeChange(false);
      this.props.hasWeatherStationChange(false);
      this.props.hasPressureSensorChange(false);
    }
    if (value === 'rg') {
      this.props.hasRainGaugeChange(true);
      this.props.hasWeatherStationChange(false);
      this.props.hasPressureSensorChange(false);
    }
    if (value === 'ws') {
      this.props.hasRainGaugeChange(false);
      this.props.hasWeatherStationChange(true);
      this.props.hasPressureSensorChange(false);
    }
    if (value === 'ps') {
      this.props.hasRainGaugeChange(false);
      this.props.hasWeatherStationChange(false);
      this.props.hasPressureSensorChange(true);
    }
  };

  render() {
    let value = 'basic';
    if (this.props.hasRainGauge) value = 'rg';
    if (this.props.hasWeatherStation) value = 'ws';
    if (this.props.hasPressureSensor) value = 'ps';
    return (
      <React.Fragment>
        <TwoColumn
          title="Add-ons"
          subtitle="Include a rain gauge with your base station to see rain data on your dashboard or a weather station to see more detailed weather data."
          content={
            <React.Fragment>
              <FormControlLabel
                control={
                  <Radio
                    onChange={this.handleChange('basic')}
                    checked={value === 'basic'}
                  />
                }
                label="Standard base station, no add-ons"
              />
              <FormControlLabel
                control={
                  <Radio
                    onChange={this.handleChange('rg')}
                    checked={value === 'rg'}
                  />
                }
                label="With Davis Instruments AeroCone Rain Gauge"
              />
              <FormControlLabel
                control={
                  <Radio
                    onChange={this.handleChange('ws')}
                    checked={value === 'ws'}
                  />
                }
                label="With Davis Vantage Pro2™ GroWeather Cabled Weather Station"
              />
              <FormControlLabel
                control={
                  <Radio
                    onChange={this.handleChange('ps')}
                    checked={value === 'ps'}
                  />
                }
                label="Pressure Sensor"
              />
            </React.Fragment>
          }
        />
        <Spacer />
        <VolumePricing
          finish={this.props.finish}
          disabled={false}
          prices={this.props.prices}
          rgPrices={this.props.rgPrices}
          rg={value === 'rg'}
          hidePrices={this.props.hidePrices}
        />
      </React.Fragment>
    );
  }
}

export default GatewayAddons;
