import * as React from 'react';

import BasicModal from 'components/BasicModal';
import { HardwareTypeEnum, MoistureMethod } from 'types/graphql';
import { computeModelId } from 'services/model';
import { Model } from 'services/model';

import LineItemType from './steps/LineItemType';
import ReplacementType from './steps/ReplacementType';
import ReplacementAntenna from './steps/ReplacementAntenna';
import SensorType from './steps/SensorType';
import SensorDetails from './steps/SensorDetails';
import GatewayAddons from './steps/GatewayAddons';
import SensorOrGateway from './steps/SensorOrGateway';
import ReplacementRainGauge from './steps/ReplacementRainGauge';
import ReplacementWeatherStation from './steps/ReplacementWeatherStation';
import InstallationTools from './steps/InstallationTools';
import Auger from './steps/Auger';
import WatermarkSensor from './steps/WatermarkSensor';
import ReplacementCircuitBoard from './steps/ReplacementCircuitBoard';
import ReplacementBoardDetails from './steps/ReplacementBoardDetails';

interface AddLineItemProps {
  onClose: () => void;
  open: boolean;
  addLineItem: (model: Model, quantity: number) => void;
  priceSheets: {
    modelId: string;
    prices: {
      quantity: number;
      price: number;
    }[];
  }[];
  getRgPrice: (amount: number) => number;
  hidePrices: boolean;
}

export type LineItemTypeBranch =
  | 'sensor'
  | 'gateway'
  | 'replacement'
  | 'installation';

export type SensorTypeOption = 'watermark' | 'sentek' | 'sentek-tri';

interface AddLineItemState {
  // Just used for viewing
  typeBranch?: LineItemTypeBranch;
  sensorType?: SensorTypeOption;

  // Used to build model
  type?: HardwareTypeEnum;
  moistureDepths?: number[] | null;
  temperatureDepths?: number[] | null;
  ecDepths?: number[] | null;
  antennaLength?: number;
  moistureMethod?: MoistureMethod;
  hasRainGauge?: boolean;
  hasWeatherStation?: boolean;
  hasPressureSensor?: boolean;
  probeless?: boolean;
  custom?: boolean;
  length?: number;
  hasSpigot?: boolean;
}

const emptyState: AddLineItemState = {
  typeBranch: undefined,
  sensorType: undefined,
  type: undefined,
  moistureDepths: undefined,
  temperatureDepths: undefined,
  ecDepths: undefined,
  antennaLength: undefined,
  moistureMethod: undefined,
  hasRainGauge: undefined,
  hasWeatherStation: undefined,
  hasPressureSensor: undefined,
  probeless: undefined,
  custom: undefined,
  length: undefined,
  hasSpigot: undefined,
};

class AddLineItem extends React.Component<AddLineItemProps, AddLineItemState> {
  state: AddLineItemState = emptyState;

  onClose = () => {
    this.setState(emptyState);
    this.props.onClose();
  };

  lineItemTypeChange = (typeBranch: LineItemTypeBranch) => {
    if (typeBranch === 'replacement') {
      // Don't know what type of replacement part yet
      this.setState({ typeBranch });
    }
    if (typeBranch === 'installation') {
      this.setState({ typeBranch });
    }
    if (typeBranch === 'sensor') {
      this.setState({ typeBranch, type: HardwareTypeEnum.sensor });
    }
    if (typeBranch === 'gateway') {
      this.setState({ typeBranch, type: HardwareTypeEnum.gateway });
    }
  };

  typeChange = (type: HardwareTypeEnum) => {
    this.setState({ type });
  };

  antennaLengthChange = (antennaLength: number) => {
    this.setState({ antennaLength });
  };

  sensorTypeChange = (sensorType: SensorTypeOption) => {
    if (sensorType === 'watermark') {
      this.setState({ sensorType, moistureMethod: MoistureMethod.tension });
    } else {
      this.setState({ sensorType, moistureMethod: MoistureMethod.capacitance });
    }
  };

  probelessChange = (probeless: boolean) => {
    this.setState({ probeless, custom: false });
  };

  customChange = (custom: boolean) => {
    this.setState({ custom });
  };

  moistureDepthsChange = (moistureDepths: number[] | null) => {
    const { sensorType, custom } = this.state;

    if (custom) {
      this.setState({ moistureDepths });
    } else {
      // Sentek is same as moisture
      let temperatureDepths = moistureDepths;
      // Watermark depends on # of moisture sensors
      if (sensorType === 'watermark') {
        if (moistureDepths && moistureDepths.length <= 3) {
          temperatureDepths = [2];
        } else {
          temperatureDepths = null;
        }
      }
      this.setState({
        moistureDepths,
        temperatureDepths,
        ecDepths: sensorType === 'sentek-tri' ? moistureDepths : null,
      });
    }
  };

  temperatureDepthsChange = (temperatureDepths: number[] | null) => {
    this.setState({ temperatureDepths });
  };

  hasRainGaugeChange = (hasRainGauge: boolean) => {
    this.setState({ hasRainGauge });
  };

  hasWeatherStationChange = (hasWeatherStation: boolean) => {
    this.setState({ hasWeatherStation });
  };

  hasPressureSensorChange = (hasPressureSensor: boolean) => {
    this.setState({ hasPressureSensor });
  };

  setAugerType = (moistureMethod: MoistureMethod) => {
    this.setState({ moistureMethod, type: HardwareTypeEnum.auger });
  };

  setLength = (length: number) => {
    this.setState({ length });
  };

  setHasSpigot = (hasSpigot: boolean) => {
    this.setState({ hasSpigot });
  };

  getCurrentModel = () => {
    const s = this.state;
    if (!s.type) throw Error('Type not selected');
    const model: Model = {
      antennaLength: s.antennaLength,
      ecDepths: s.ecDepths,
      hasRainGauge: s.hasRainGauge,
      hasWeatherStation: s.hasWeatherStation,
      hasPressureSensor: s.hasPressureSensor,
      moistureDepths: s.moistureDepths,
      moistureMethod: s.moistureMethod,
      probeless: s.probeless,
      temperatureDepths: s.temperatureDepths,
      type: s.type,
      custom: s.custom,
      hasSpigot: s.hasSpigot,
      length: s.length,
    };
    return model;
  };

  getCurrentPriceSheet = () => {
    const model = this.getCurrentModel();
    const priceSheet = this.props.priceSheets.find(
      (e) => e.modelId === computeModelId(model)
    );
    if (!priceSheet) return null;
    return priceSheet.prices;
  };

  getRGPriceSheet = () => {
    const priceSheet = this.props.priceSheets.find((e) => e.modelId === 'rg');
    if (!priceSheet) return null;
    return priceSheet.prices;
  };

  getCustomPrice = () => {
    const { custom: customDepths, moistureDepths } = this.state;
    if (!customDepths) return 0;
    const priceSheet = this.props.priceSheets.find(
      (e) =>
        e.modelId === `custom-${moistureDepths ? moistureDepths.length : 1}`
    );
    if (!priceSheet) return 0;
    return priceSheet.prices[0].price;
  };

  finish = (quantity: number) => {
    const model = this.getCurrentModel();
    this.props.addLineItem(model, quantity);
    this.onClose();
  };

  getCurrentView = () => {
    const {
      typeBranch,
      type,
      sensorType,
      moistureDepths,
      antennaLength,
      hasRainGauge,
      probeless,
      hasWeatherStation,
      hasPressureSensor,
      custom,
      moistureMethod,
      length,
      hasSpigot,
    } = this.state;

    if (!typeBranch) {
      // Pick sensor, bs, replacement
      return {
        title: 'Select a product category',
        content: <LineItemType onClick={this.lineItemTypeChange} />,
      };
    }
    if (typeBranch === 'sensor') {
      if (!sensorType) {
        // Pick sensor type
        return {
          title: 'Select a sensor type',
          content: (
            <SensorType
              onClick={this.sensorTypeChange}
              priceSheets={this.props.priceSheets}
              hidePrices={this.props.hidePrices}
            />
          ),
        };
      }
      // Pick sensor details
      return {
        title: 'Select a sensor',
        content: (
          <SensorDetails
            antennaLength={antennaLength}
            antennaLengthChange={this.antennaLengthChange}
            moistureDepths={moistureDepths}
            moistureDepthsChange={this.moistureDepthsChange}
            probeless={probeless}
            probelessChange={this.probelessChange}
            sensorType={sensorType}
            hasRainGauge={hasRainGauge}
            hasRainGaugeChange={this.hasRainGaugeChange}
            finish={this.finish}
            prices={this.getCurrentPriceSheet()}
            rgPrices={this.getRGPriceSheet()}
            custom={custom}
            customChange={this.customChange}
            temperatureDepthsChange={this.temperatureDepthsChange}
            customPrice={this.getCustomPrice()}
            hidePrices={this.props.hidePrices}
          />
        ),
      };
    }
    if (typeBranch === 'gateway') {
      // Pick addons
      return {
        title: 'Select Gateway Model',
        content: (
          <GatewayAddons
            hasRainGauge={hasRainGauge}
            hasRainGaugeChange={this.hasRainGaugeChange}
            hasWeatherStation={hasWeatherStation}
            hasWeatherStationChange={this.hasWeatherStationChange}
            hasPressureSensor={hasPressureSensor}
            hasPressureSensorChange={this.hasPressureSensorChange}
            finish={this.finish}
            antennaLengthChange={this.antennaLengthChange}
            prices={this.getCurrentPriceSheet()}
            rgPrices={this.getRGPriceSheet()}
            hidePrices={this.props.hidePrices}
          />
        ),
      };
    }
    if (typeBranch === 'replacement') {
      if (!type) {
        // Pick antenna, probe, electronics
        return {
          title: 'Select a replacement part',
          content: <ReplacementType onClick={this.typeChange} />,
        };
      }
      if (type === HardwareTypeEnum.replacementAntenna) {
        // Pick length
        return {
          title: 'Select antenna length',
          content: (
            <ReplacementAntenna
              antennaLength={antennaLength}
              antennaLengthChange={this.antennaLengthChange}
              finish={this.finish}
              prices={this.getCurrentPriceSheet()}
              hidePrices={this.props.hidePrices}
            />
          ),
        };
      }
      if (type === HardwareTypeEnum.replacementEnclosure) {
        // Sensor or gateway
        return {
          title: 'Select Enclosure Type',
          content: <SensorOrGateway onClick={this.typeChange} />,
        };
      }
      if (type === HardwareTypeEnum.replacementGatewayEnclosure) {
        // Pick addons
        return {
          title: 'Select Gateway Model',
          content: (
            <GatewayAddons
              hasRainGauge={hasRainGauge}
              hasRainGaugeChange={this.hasRainGaugeChange}
              hasWeatherStation={hasWeatherStation}
              hasWeatherStationChange={this.hasWeatherStationChange}
              hasPressureSensor={hasPressureSensor}
              hasPressureSensorChange={this.hasPressureSensorChange}
              finish={this.finish}
              antennaLengthChange={this.antennaLengthChange}
              prices={this.getCurrentPriceSheet()}
              rgPrices={this.getRGPriceSheet()}
              hidePrices={this.props.hidePrices}
            />
          ),
        };
      }

      if (type === HardwareTypeEnum.watermarkSensor) {
        // Pick watermark options
        return {
          title: 'Watermark Sensor',
          content: (
            <WatermarkSensor
              setLength={this.setLength}
              length={length}
              setHasSpigot={this.setHasSpigot}
              hasSpigot={hasSpigot}
              finish={this.finish}
              prices={this.getCurrentPriceSheet()}
              hidePrices={this.props.hidePrices}
            />
          ),
        };
      }

      if (type === HardwareTypeEnum.replacementSensorEnclosure) {
        if (!sensorType) {
          // Pick sensor type
          return {
            title:
              'What kind of sensor probes do you need replacement electronics enclosures for?',
            content: (
              <SensorType
                onClick={this.sensorTypeChange}
                priceSheets={this.props.priceSheets}
                replacement
                hidePrices={this.props.hidePrices}
              />
            ),
          };
        }
        // SensorDetails sans antenna
        return {
          title: 'Select sensor probe details',
          content: (
            <SensorDetails
              enclosure
              moistureDepths={moistureDepths}
              moistureDepthsChange={this.moistureDepthsChange}
              probeless={probeless}
              probelessChange={this.probelessChange}
              sensorType={sensorType}
              hasRainGauge={hasRainGauge}
              hasRainGaugeChange={this.hasRainGaugeChange}
              finish={this.finish}
              prices={this.getCurrentPriceSheet()}
              custom={custom}
              customChange={this.customChange}
              temperatureDepthsChange={this.temperatureDepthsChange}
              customPrice={this.getCustomPrice()}
              hidePrices={this.props.hidePrices}
            />
          ),
        };
      }
      if (type === HardwareTypeEnum.replacementSensorProbe) {
        if (!sensorType) {
          // Pick sensor type
          return {
            title: 'Select a sensor type',
            content: (
              <SensorType
                onClick={this.sensorTypeChange}
                priceSheets={this.props.priceSheets}
                replacement
                hidePrices={this.props.hidePrices}
              />
            ),
          };
        }
        // SensorDetails sans addons and antenna (just probe)
        return {
          title: 'Select sensor probe details',
          content: (
            <SensorDetails
              probe
              moistureDepths={moistureDepths}
              moistureDepthsChange={this.moistureDepthsChange}
              probeless={probeless}
              probelessChange={this.probelessChange}
              sensorType={sensorType}
              finish={this.finish}
              prices={this.getCurrentPriceSheet()}
              custom={custom}
              customChange={this.customChange}
              temperatureDepthsChange={this.temperatureDepthsChange}
              customPrice={this.getCustomPrice()}
              hidePrices={this.props.hidePrices}
            />
          ),
        };
      }
      if (type === HardwareTypeEnum.replacementRainGauge) {
        return {
          title: 'Replacement Rain Gauge',
          content: (
            <ReplacementRainGauge
              finish={this.finish}
              prices={this.getCurrentPriceSheet()}
              hidePrices={this.props.hidePrices}
            />
          ),
        };
      }
      if (type === HardwareTypeEnum.replacementWeatherStation) {
        return {
          title: 'Replacement Weather Station',
          content: (
            <ReplacementWeatherStation
              finish={this.finish}
              prices={this.getCurrentPriceSheet()}
              hidePrices={this.props.hidePrices}
            />
          ),
        };
      }
      if (type === HardwareTypeEnum.replacementCircuitBoard) {
        return {
          title: 'Select Circuit Board Type',
          content: <ReplacementCircuitBoard onClick={this.typeChange} />,
        };
      }
      if (type === HardwareTypeEnum.replacementSensorBoard) {
        return {
          title: 'Select Replacement Sensor Board Model',
          content: (
            <ReplacementBoardDetails
              type={type}
              sensorType={sensorType}
              onChange={this.sensorTypeChange}
              hasRainGauge={hasRainGauge}
              hasRainGaugeChange={this.hasRainGaugeChange}
              hasWeatherStation={hasWeatherStation}
              hasWeatherStationChange={this.hasWeatherStationChange}
              hasPressureSensor={hasPressureSensor}
              hasPressureSensorChange={this.hasPressureSensorChange}
              finish={this.finish}
              prices={this.getCurrentPriceSheet()}
              hidePrices={this.props.hidePrices}
              moistureDepths={moistureDepths}
              moistureDepthsChange={this.moistureDepthsChange}
            />
          ),
        };
      }
      if (type === HardwareTypeEnum.replacementGatewayBoard) {
        return {
          title: 'Select Replacement Gateway Board Model',
          content: (
            <ReplacementBoardDetails
              type={type}
              sensorType={sensorType}
              onChange={this.sensorTypeChange}
              hasRainGauge={hasRainGauge}
              hasRainGaugeChange={this.hasRainGaugeChange}
              hasWeatherStation={hasWeatherStation}
              hasWeatherStationChange={this.hasWeatherStationChange}
              hasPressureSensor={hasPressureSensor}
              hasPressureSensorChange={this.hasPressureSensorChange}
              finish={this.finish}
              prices={this.getCurrentPriceSheet()}
              hidePrices={this.props.hidePrices}
              moistureDepths={moistureDepths}
              moistureDepthsChange={this.moistureDepthsChange}
            />
          ),
        };
      }
      if (type === HardwareTypeEnum.replacementMercuryModule) {
        return {
          title: 'Add Replacement Mercury Module',
          content: (
            <ReplacementBoardDetails
              type={type}
              sensorType={sensorType}
              onChange={this.sensorTypeChange}
              hasRainGauge={hasRainGauge}
              hasRainGaugeChange={this.hasRainGaugeChange}
              hasWeatherStation={hasWeatherStation}
              hasWeatherStationChange={this.hasWeatherStationChange}
              hasPressureSensor={hasPressureSensor}
              hasPressureSensorChange={this.hasPressureSensorChange}
              finish={this.finish}
              prices={this.getCurrentPriceSheet()}
              hidePrices={this.props.hidePrices}
              moistureDepths={moistureDepths}
              moistureDepthsChange={this.moistureDepthsChange}
            />
          ),
        };
      }
    }

    if (typeBranch === 'installation') {
      if (!type) {
        // Pick installation item
        return {
          title: 'Installation Tools',
          content: <InstallationTools setAugerType={this.setAugerType} />,
        };
      }

      if (type === HardwareTypeEnum.auger && moistureMethod) {
        // Pick auger details
        const title =
          moistureMethod === MoistureMethod.tension
            ? 'Watermark Auger'
            : 'Sentek Auger';
        return {
          title,
          content: (
            <Auger
              moistureMethod={moistureMethod}
              setLength={this.setLength}
              length={length}
              finish={this.finish}
              prices={this.getCurrentPriceSheet()}
              hidePrices={this.props.hidePrices}
            />
          ),
        };
      }
    }

    // Should never reach here
    throw Error('Invalid state');
  };

  render() {
    const { title, content } = this.getCurrentView();
    return (
      <BasicModal
        open={this.props.open}
        onClose={this.onClose}
        title={title}
        addCloseButton
      >
        <div style={{ maxWidth: 800, width: '100vw' }}>{content}</div>
      </BasicModal>
    );
  }
}

export default AddLineItem;
