import * as React from 'react';
import gql from 'graphql-tag';
import moment from 'moment';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import GraphQLError from 'components/Errors/GraphQLError';

import { colors, FontClasses } from 'services/theme';

import {
  ActivateCustomerComponent,
  ResendActivationEmailComponent,
} from 'types/graphql';

gql`
  mutation ActivateCustomer($input: ActivateCustomerInput!) {
    activateCustomer(input: $input) {
      userId
    }
  }
`;

gql`
  mutation ResendActivationEmail($customerId: String!) {
    activationResendEmail(customerId: $customerId)
  }
`;

interface DashboardStatusProps {
  cardStyle: React.CSSProperties;
  hasActivePlan: boolean;
  prepaid: boolean;
  prepaidStart: number | null;
  prepaidUntil: number;
  activated: boolean;
  activationCompleted: boolean;
  isMobile: boolean;
  customerId: string;
  managedService: boolean;
  refetch: () => void;
}

interface DashboardStatusState {
  activateDialogOpen: boolean;
  resendDialogOpen: boolean;
}

class DashboardStatus extends React.Component<
  DashboardStatusProps,
  DashboardStatusState
> {
  state = { activateDialogOpen: false, resendDialogOpen: false };

  onActivateClose = () => {
    this.setState({ activateDialogOpen: false });
  };

  onResendClose = () => {
    this.setState({ resendDialogOpen: false });
  };

  render() {
    const {
      cardStyle,
      hasActivePlan,
      isMobile,
      prepaid,
      prepaidStart,
      prepaidUntil,
      activated,
      activationCompleted,
      customerId,
      refetch,
      managedService,
    } = this.props;

    const buttonStyle = isMobile ? { width: '100%' } : {};

    let dataPlanText = hasActivePlan ? 'active' : 'not active';
    if (prepaid) {
      const dateFmt = 'M/D/Y';
      if (prepaidStart && prepaidStart > Date.now()) {
        dataPlanText += ` (prepaid begins ${moment(prepaidStart).format(
          dateFmt
        )})`;
      } else {
        dataPlanText += ` (prepaid until ${moment(prepaidUntil).format(
          dateFmt
        )})`;
      }
    }

    const notActivatedContent = (
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          this.setState({
            activateDialogOpen: true,
          });
        }}
        style={buttonStyle}
      >
        Activate Customer
      </Button>
    );

    const activatingPendingContent = (
      <>
        <div style={{ marginBottom: 8 }}>
          User has been sent activation email, but has not activated yet
        </div>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            this.setState({
              resendDialogOpen: true,
            });
          }}
          style={buttonStyle}
        >
          Resend Customer Activation
        </Button>
      </>
    );

    const activatedContent = (
      <>
        <div style={{ color: colors.primary }}>
          <b>User has been activated</b>
        </div>
      </>
    );

    let activationContent = notActivatedContent;
    if (activated) activationContent = activatingPendingContent;
    if (activationCompleted) activationContent = activatedContent;

    return (
      <React.Fragment>
        <Card style={cardStyle}>
          <CardHeader
            title="Dashboard Status"
            style={{ backgroundColor: 'rgb(242,242,242)' }}
          />
          <CardContent>
            <div className={FontClasses.subtitle}>
              Software Subscription is {dataPlanText}
            </div>
            {!hasActivePlan && !managedService && (
              <div style={{ marginTop: 16, color: colors.textLight }}>
                The customer can enter credit card information to pay for their
                subscription. If you would like to pre-pay for this customer
                {"'"}s subscription, please contact Trellis.
              </div>
            )}
            <div style={{ marginTop: 16 }}>{activationContent}</div>
          </CardContent>
        </Card>

        <ActivateCustomerComponent>
          {(activateCustomerMutate, { error, loading }) => {
            const activate = () => {
              activateCustomerMutate({
                variables: { input: { customerId } },
              }).then(() => {
                refetch();
                this.onActivateClose();
              });
            };

            return (
              <Dialog
                open={this.state.activateDialogOpen}
                onClose={this.onActivateClose}
              >
                <DialogTitle>
                  Are you sure you want to activate this user?
                </DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Activating a user will send them an email with instructions
                    on how to log into their account.
                  </DialogContentText>
                  <GraphQLError error={error} />
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.onActivateClose}>Cancel</Button>
                  <Button onClick={activate} color="primary" disabled={loading}>
                    Confirm
                  </Button>
                </DialogActions>
              </Dialog>
            );
          }}
        </ActivateCustomerComponent>

        <ResendActivationEmailComponent>
          {(resendActivationEmail, { error, loading }) => {
            const resend = () => {
              resendActivationEmail({ variables: { customerId } }).then(() => {
                refetch();
                this.onResendClose();
              });
            };

            return (
              <Dialog
                open={this.state.resendDialogOpen}
                onClose={this.onResendClose}
              >
                <DialogTitle>
                  Are you sure you want to resend this user{"'"}s activation
                  email?
                </DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    This will send the user an email with instructions on how to
                    log into their account.
                  </DialogContentText>
                  <GraphQLError error={error} />
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.onResendClose}>Cancel</Button>
                  <Button onClick={resend} color="primary" disabled={loading}>
                    Confirm
                  </Button>
                </DialogActions>
              </Dialog>
            );
          }}
        </ResendActivationEmailComponent>
      </React.Fragment>
    );
  }
}

export default DashboardStatus;
