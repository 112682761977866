import * as React from 'react';

import { LineItemTypeBranch } from '../AddLineItem';
import Card from './Card';

interface LineItemTypeProps {
  onClick: (type: LineItemTypeBranch) => void;
}

class LineItemType extends React.Component<LineItemTypeProps> {
  cards = [
    {
      title: 'Wireless Sensor Station',
      description:
        'Sensor stations record soil moisture, electroconductivity, temperature, & rainfall data.',
      onClick: () => this.props.onClick('sensor'),
    },
    {
      title: 'Central Base Station',
      description:
        'The base station collects data from sensor stations & automatically uploads it to our web/mobile app.',
      onClick: () => this.props.onClick('gateway'),
    },
    {
      title: 'Replacement Parts',
      description:
        'Repair hardware damaged by field work without having to replace the entire unit.',
      onClick: () => this.props.onClick('replacement'),
    },
    {
      title: 'Installation Tools',
      description:
        'Auger drill bits make installing soil moisture sensors a breeze.',
      onClick: () => this.props.onClick('installation'),
    },
  ];

  render() {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'space-around',
        }}
      >
        {this.cards.map(p => (
          <Card {...p} key={p.title} />
        ))}
      </div>
    );
  }
}

export default LineItemType;
