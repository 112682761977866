import * as React from 'react';
import { SensorTypeOption } from '../AddLineItem';
import Card from './Card';
import { formatCurrency } from 'services/currency';

interface SensorTypeProps {
  onClick: (sensorType: SensorTypeOption) => void;
  priceSheets: {
    modelId: string;
    prices: {
      quantity: number;
      price: number;
    }[];
  }[];
  replacement?: boolean;
  hidePrices: boolean;
}

class SensorType extends React.Component<SensorTypeProps> {
  render() {
    let wmText = '';
    let sentekText = '';
    let sentekTriText = '';

    let wmPrice = 10000;
    let sentekPrice = 10000;
    let sentekTriPrice = 10000;
    this.props.priceSheets.forEach(({ modelId, prices }) => {
      const { price } = prices[0];
      if (modelId.startsWith('ss-w') && price < wmPrice) wmPrice = price;
      if (modelId.startsWith('ss-e') && price < sentekPrice)
        sentekPrice = price;
      if (modelId.startsWith('ss-t') && price < sentekTriPrice)
        sentekTriPrice = price;
    });

    if (!this.props.replacement && !this.props.hidePrices) {
      wmText = `Starting at ${formatCurrency(wmPrice)}`;
      sentekText = `Starting at ${formatCurrency(sentekPrice)}`;
      sentekTriText = `Starting at ${formatCurrency(sentekTriPrice)}`;
    }

    const cards = [
      {
        title: 'Watermark',
        description: [
          'These sensors measure soil moisture tension (how hard the plant has to work to extract water from the soil).',
          wmText,
        ],
        onClick: () => this.props.onClick('watermark'),
      },
      {
        title: 'Sentek Drill & Drop',
        description: [
          'These are volumetric sensors that directly measure the amount of water in the soil. They also measure soil temperature.',
          sentekText,
        ],
        onClick: () => this.props.onClick('sentek'),
      },
      {
        title: 'Sentek Drill & Drop Triscan',
        description: [
          'In addition to soil moisture and soil temperature, these sensors also measure electroconductivity.',
          sentekTriText,
        ],
        onClick: () => this.props.onClick('sentek-tri'),
      },
    ];

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'space-around',
        }}
      >
        {cards.map((p) => (
          <Card {...p} key={p.title} />
        ))}
      </div>
    );
  }
}

export default SensorType;
