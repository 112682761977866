import * as React from 'react';
import gql from 'graphql-tag';
import find from 'lodash/find';
import sortBy from 'lodash/sortBy';
import { RouteComponentProps, navigate } from '@reach/router';

import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TableCell from '@material-ui/core/TableCell';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { IsMobileConsumer } from 'providers/Responsive';

import Loading from 'components/Loading/Loading';
import HardwareTable from 'components/HardwareTable/HardwareTable';
import DevicesMap from 'components/DevicesMap/DevicesMap';
import { Device } from 'components/DevicesMap/Device';

import FieldForm from './components/FieldForm';

import {
  AddFieldsDataComponent,
  AddFieldComponent,
  UpdateSensorAddFieldComponent,
} from 'types/graphql';
import GenericError from 'components/Errors/GenericError';
import GraphQLError from 'components/Errors/GraphQLError';

gql`
  query AddFieldsData($customerId: String!) {
    customer(customerId: $customerId) {
      id
      user {
        id
        sensors {
          id
          name
          model {
            type
            antennaLength
            moistureDepths
            hasRainGauge
          }
          field {
            id
          }
          location {
            lat
            lng
          }
        }
        gateways {
          id
          model {
            type
            antennaLength
            hasRainGauge
          }
          fields {
            id
          }
          location {
            lat
            lng
          }
        }
      }
    }
    soils {
      id
      name
    }
    crops {
      id
      name
    }
    irrigationTypes {
      id
      name
    }
  }
`;

gql`
  mutation AddField($input: AddFieldInput!) {
    addField(input: $input) {
      success
    }
  }
`;

gql`
  mutation UpdateSensorAddField(
    $sensorId: String!
    $input: UpdateSensorInput!
  ) {
    updateSensor(sensorId: $sensorId, input: $input) {
      sensorId
    }
  }
`;

interface FieldInfo {
  fieldName: string;
  crop: string;
  cropVariety: string;
  irrigation: string;
}

interface AddFieldsState {
  hardware: string[];
  fieldInfo: FieldInfo;
  soilTextures: Record<string, string>;
}

type AddFieldsProp = RouteComponentProps<{ customerId: string }>;

class AddFields extends React.Component<AddFieldsProp, AddFieldsState> {
  customerId: string;
  emptyFieldInfo: FieldInfo;
  onSelectedChange: (selected: string[]) => void;
  changeFieldInfo: (data: { [key: string]: string }) => void;

  constructor(props: AddFieldsProp) {
    super(props);

    this.customerId = props.customerId || '';

    this.emptyFieldInfo = {
      fieldName: '',
      crop: '',
      cropVariety: '',
      irrigation: '',
    };

    this.state = {
      // Field we are bulding
      hardware: [],
      fieldInfo: this.emptyFieldInfo,

      // Soil texture info
      soilTextures: {},
    };

    this.onSelectedChange = selected => {
      this.setState({ hardware: selected });
    };

    this.changeFieldInfo = data => {
      const newData = Object.assign({}, this.state.fieldInfo, data);
      this.setState({
        fieldInfo: newData,
      });
    };
  }

  render() {
    return (
      <IsMobileConsumer>
        {({ isMobile }) => (
          <AddFieldComponent>
            {(
              addFieldMutate,
              { error: addFieldError, loading: addFieldLoading }
            ) => (
              <UpdateSensorAddFieldComponent>
                {(
                  updateSensorMutate,
                  { error: updateSensorError, loading: updateSensorLoading }
                ) => (
                  <AddFieldsDataComponent
                    variables={{ customerId: this.customerId }}
                  >
                    {({ data, error, loading, refetch }) => {
                      if (loading) return <Loading />;
                      if (error || !data || !data.customer)
                        return <GenericError />;

                      const { customer, soils, crops, irrigationTypes } = data;

                      const mutationError = addFieldError || updateSensorError;
                      const mutationLoading =
                        addFieldLoading || updateSensorLoading;

                      const userHardware = [
                        ...customer.user.sensors,
                        ...customer.user.gateways,
                      ];

                      const availableHardware = userHardware.filter(hw => {
                        // Keep it if it's a gateway
                        if (hw.__typename === 'Gateway') return true;

                        // Keep it if it doesn't have a field
                        return !hw.field;
                      });

                      const availableHardwareWithType: Device[] = availableHardware.map(
                        e => {
                          return {
                            id: e.id,
                            type: e.__typename,
                            location: e.location,
                            hasRainGauge:
                              e && e.model ? e.model.hasRainGauge : false,
                          };
                        }
                      );

                      const padding = isMobile ? 0 : 16;

                      const fullHardware = this.state.hardware.map(id => {
                        const [hw] = userHardware.filter(e => e.id === id);
                        return hw;
                      });

                      const fieldIsFilledOut =
                        this.state.fieldInfo.fieldName !== '' &&
                        this.state.fieldInfo.crop !== '' &&
                        this.state.fieldInfo.irrigation !== '' &&
                        fullHardware.some(e => e.__typename === 'Sensor') &&
                        fullHardware.some(e => e.__typename === 'Gateway');

                      const save = () => {
                        const createFields = () =>
                          addFieldMutate({
                            variables: {
                              input: {
                                name: this.state.fieldInfo.fieldName,
                                gateways: fullHardware
                                  .filter(e => e.__typename === 'Gateway')
                                  .map(e => e.id),
                                sensors: fullHardware
                                  .filter(e => e.__typename === 'Sensor')
                                  .map(e => e.id),
                                customerId: this.customerId,
                                irrigationMetadata: {
                                  irrigationType: this.state.fieldInfo
                                    .irrigation,
                                },
                              },
                            },
                          });

                        const updateSensors = () =>
                          Promise.all(
                            fullHardware
                              .filter(e => e.__typename === 'Sensor')
                              .map(sensor => {
                                const crop = find(crops, {
                                  name: this.state.fieldInfo.crop,
                                });
                                return updateSensorMutate({
                                  variables: {
                                    sensorId: sensor.id,
                                    input: {
                                      soil_type: this.state.soilTextures[
                                        sensor.id
                                      ],
                                      customerId: this.customerId,
                                      crop_type: crop ? crop.id : null,
                                      crop_variety: this.state.fieldInfo
                                        .cropVariety,
                                    },
                                  },
                                });
                              })
                          );

                        Promise.all([createFields(), updateSensors()]).then(
                          () => {
                            refetch();
                            navigate(`/manage/${this.customerId}`);
                          }
                        );
                      };

                      return (
                        <div style={{ padding }}>
                          <Paper style={{ padding, marginTop: 20 }}>
                            <h2>Add a Field</h2>
                            <div
                              style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                width: '100%',
                                marginBottom: 20,
                              }}
                            >
                              <div
                                style={{
                                  minWidth: 300,
                                  minHeight: 300,
                                  flex: '1 1 0',
                                }}
                              >
                                <DevicesMap
                                  devices={availableHardwareWithType}
                                />
                              </div>
                              <div style={{ flex: '1 1 0' }}>
                                <FieldForm
                                  irrigationTypes={irrigationTypes}
                                  fieldValues={this.state.fieldInfo}
                                  onChange={this.changeFieldInfo}
                                  crops={crops}
                                />
                              </div>
                            </div>
                            <HardwareTable
                              columns={{
                                selected: true,
                                type: !isMobile,
                                id: true,
                                depths: !isMobile,
                                antenna: !isMobile,
                              }}
                              customColumns={[
                                {
                                  header: {
                                    id: 'soilTextureInput',
                                    label: 'Soil Texture',
                                    centered: false,
                                  },
                                  cell: (row, i) => (
                                    <TableCell
                                      key={i}
                                      style={{ textAlign: 'center' }}
                                      padding="none"
                                    >
                                      {row.model &&
                                        row.model.type === 'sensor' && (
                                          <Select
                                            value={
                                              this.state.soilTextures[row.id] ||
                                              ''
                                            }
                                            onChange={e =>
                                              this.setState({
                                                soilTextures: {
                                                  ...this.state.soilTextures,
                                                  [row.id]: e.target
                                                    .value as string,
                                                },
                                              })
                                            }
                                            style={{ width: '100%' }}
                                          >
                                            {sortBy(soils, ['name']).map(
                                              ({ id, name }) => {
                                                return (
                                                  <MenuItem key={id} value={id}>
                                                    {name}
                                                  </MenuItem>
                                                );
                                              }
                                            )}
                                          </Select>
                                        )}
                                    </TableCell>
                                  ),
                                },
                              ]}
                              data={availableHardware}
                              selected={this.state.hardware}
                              onSelectedChange={this.onSelectedChange}
                            />
                          </Paper>
                          <GraphQLError error={mutationError} />
                          <div
                            style={{
                              width: '100%',
                              alignItems: 'right',
                              marginRight: 20,
                              direction: 'rtl',
                              marginTop: 20,
                            }}
                          >
                            <Button
                              disabled={mutationLoading || !fieldIsFilledOut}
                              variant="contained"
                              color="primary"
                              onClick={save}
                              id="saveFields"
                            >
                              Save Fields
                            </Button>
                            <Button
                              onClick={() =>
                                navigate(`/manage/${this.customerId}`)
                              }
                            >
                              Cancel
                            </Button>
                          </div>
                        </div>
                      );
                    }}
                  </AddFieldsDataComponent>
                )}
              </UpdateSensorAddFieldComponent>
            )}
          </AddFieldComponent>
        )}
      </IsMobileConsumer>
    );
  }
}

export default AddFields;
