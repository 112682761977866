import * as React from 'react';

import NoDevices from './NoDevices';
import { Device, isValidLocation } from './Device';
import DevicesMapView from './DevicesMapView';

interface DevicesMapProps {
  devices: Device[];
  hovered?: string;
  onSensorClick?: (id: string) => void;
}

class DevicesMap extends React.Component<DevicesMapProps> {
  googleMapURL =
    'https://maps.googleapis.com/maps/api/js?key=AIzaSyANzGyW4ezzl-UCBZMXIhBfa2tpbqXYCog';

  render() {
    const plural = this.props.devices.length > 1;
    const devices = this.props.devices.filter(isValidLocation);

    const fullDiv = (
      <div
        style={{
          height: '100%',
          width: '100%',
        }}
      />
    );

    if (devices.length === 0) return <NoDevices plural={plural} />;
    return (
      <DevicesMapView
        devices={devices}
        onMarkerClicked={this.props.onSensorClick || (() => null)}
        hovered={this.props.hovered}
        googleMapURL={this.googleMapURL}
        containerElement={fullDiv}
        mapElement={fullDiv}
        loadingElement={fullDiv}
      />
    );
  }
}

export default DevicesMap;
