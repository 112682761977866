import * as React from 'react';
import { Link, navigate } from '@reach/router';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import Loading from 'components/Loading/Loading';
import GenericError from 'components/Errors/GenericError';
import {
  MobileOnly,
  DesktopOnly,
  IsMobileConsumer,
} from 'providers/Responsive';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import ArrowIcon from '@material-ui/icons/KeyboardArrowRight';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import { FontClasses, theme } from 'services/theme';
import SearchBar from 'components/SearchBar';

const FAST_CUSTOMERS_QUERY = gql`
  query FastCustomers($first: Int!, $after: String, $search: String) {
    currentDistributor {
      id
      fastCustomers(first: $first, after: $after, search: $search) {
        edges {
          cursor
          node {
            email
            id
            phone
            name {
              first
              last
            }
            user {
              id
              gateways {
                id
              }
              sensors {
                id
              }
            }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
`;

const PAGE_SIZE = 20;

const ManageCustomer = () => {
  const [initialLoad, setInitialLoad] = React.useState(true);
  const [isLoadingMore, setIsLoadingMore] = React.useState(false);
  const [search, setSearch] = React.useState('');

  const { data, loading, error, fetchMore, refetch } = useQuery(
    FAST_CUSTOMERS_QUERY,
    {
      variables: { first: PAGE_SIZE, after: null, search },
      fetchPolicy: 'cache-and-network',
      onCompleted: () => setInitialLoad(false),
    }
  );

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    refetch({ first: PAGE_SIZE, after: null, search: e.target.value });
  };

  const loadMoreCustomers = () => {
    if (!data || !data.currentDistributor?.fastCustomers.pageInfo.hasNextPage)
      return;

    setIsLoadingMore(true);
    fetchMore({
      variables: {
        first: PAGE_SIZE,
        after: data.currentDistributor.fastCustomers.pageInfo.endCursor,
        search,
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      updateQuery: (previousResult: any, { fetchMoreResult }) => {
        setIsLoadingMore(false);
        if (!fetchMoreResult || !previousResult.currentDistributor)
          return previousResult;

        return {
          currentDistributor: {
            ...previousResult.currentDistributor,
            fastCustomers: {
              ...previousResult.currentDistributor.fastCustomers,
              edges: [
                ...previousResult.currentDistributor.fastCustomers.edges,
                ...fetchMoreResult.currentDistributor.fastCustomers.edges,
              ],
              pageInfo:
                fetchMoreResult.currentDistributor.fastCustomers.pageInfo,
            },
          },
        };
      },
    }).catch(() => setIsLoadingMore(false));
  };

  if (loading && initialLoad) return <Loading />;
  if (error || !data) return <GenericError />;

  const { currentDistributor } = data;
  const { edges: customers, pageInfo } = currentDistributor.fastCustomers;

  const cellStyle: React.CSSProperties = {
    textAlign: 'center',
    padding: 8,
  };

  const buttonStyle = {
    width: '100%',
    height: 48,
  };

  return (
    <IsMobileConsumer>
      {({ isMobile }) => (
        <div
          style={{
            flexGrow: 1,
            margin: isMobile ? 4 : 50,
            paddingTop: isMobile ? 24 : 50,
            backgroundColor: theme.colors.grey,
          }}
        >
          {/* Add Customer Button */}
          <div
            style={{
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{
                width: '250px',
                paddingBottom: isMobile ? '24px' : '0px',
              }}
            >
              <Link to="/manage/newCustomer" style={{ textDecoration: 'none' }}>
                <Button
                  variant="contained"
                  color="primary"
                  id="addCustomer"
                  style={buttonStyle}
                >
                  Add Customer
                </Button>
              </Link>
            </div>
            {/* Search Input */}
            <SearchBar
              content={search}
              onChange={handleSearchChange}
              onClear={() => setSearch('')}
            />
          </div>
          {/* Table */}
          <Paper
            style={{
              alignSelf: 'stretch',
              marginBottom: 50,
              marginTop: 20,
            }}
          >
            <Table>
              <TableHead className={FontClasses.title}>
                <TableRow>
                  <TableCell style={{ ...cellStyle, textAlign: 'left' }}>
                    Name
                  </TableCell>
                  <TableCell style={cellStyle}>Email</TableCell>
                  <DesktopOnly>
                    <TableCell style={cellStyle}>Phone</TableCell>
                  </DesktopOnly>
                  <DesktopOnly>
                    <TableCell style={cellStyle}># Sensors</TableCell>
                  </DesktopOnly>
                  <DesktopOnly>
                    <TableCell style={cellStyle}># Base Stations</TableCell>
                  </DesktopOnly>
                  <MobileOnly>
                    <TableCell style={cellStyle}># of Units</TableCell>
                  </MobileOnly>
                  <TableCell style={{ width: 25 }} />
                </TableRow>
              </TableHead>
              <TableBody>
                {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                {customers.map(({ node: customer }: { node: any }) => {
                  const customerName = customer.name
                    ? `${customer.name.first} ${customer.name.last}`
                    : '';
                  return (
                    <TableRow
                      key={customer.id}
                      onClick={() => navigate(`manage/${customer.id}/`)}
                    >
                      <TableCell style={{ ...cellStyle, textAlign: 'left' }}>
                        {customerName}
                      </TableCell>
                      <TableCell style={cellStyle}>{customer.email}</TableCell>
                      <DesktopOnly>
                        <TableCell style={cellStyle}>
                          {customer.phone}
                        </TableCell>
                      </DesktopOnly>
                      <DesktopOnly>
                        <TableCell style={cellStyle}>
                          {customer.user.sensors.length}
                        </TableCell>
                      </DesktopOnly>
                      <DesktopOnly>
                        <TableCell style={cellStyle}>
                          {customer.user.gateways.length}
                        </TableCell>
                      </DesktopOnly>
                      <MobileOnly>
                        <TableCell style={cellStyle}>
                          {/* eslint-disable-next-line @typescript-eslint/restrict-plus-operands */}
                          {customer.user.sensors.length +
                            customer.user.gateways.length}
                        </TableCell>
                      </MobileOnly>
                      <TableCell align="right">
                        <ArrowIcon />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Paper>
          {pageInfo.hasNextPage && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                paddingBottom: '64px',
              }}
            >
              <Button
                onClick={loadMoreCustomers}
                variant="contained"
                color="primary"
              >
                {isLoadingMore ? 'Loading...' : 'Load More'}
              </Button>
            </div>
          )}
        </div>
      )}
    </IsMobileConsumer>
  );
};

export default ManageCustomer;
