import React, { useState } from 'react';
import gql from 'graphql-tag';
import find from 'lodash/find';

import {
  useOrderDetailsQuery,
  useAddOrderMutation,
  SensorDataPlan,
  PermissionLevelInput,
} from 'types/graphql';
import { makeGetPricing } from 'services/model';

import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import Loading from 'components/Loading/Loading';
import GraphQLError from 'components/Errors/GraphQLError';
import OrderSummary from 'components/OrderSummary/OrderSummary';
import { LineItem } from '../OrderTable/OrderTable';
import { useIsMobile } from 'providers/Responsive';
import { FontClasses } from 'services/theme';

gql`
  query OrderDetails($customerId: String, $customer: Boolean!) {
    customer(customerId: $customerId) @include(if: $customer) {
      id
      email
      name {
        first
        last
      }
    }
    currentDistributor {
      id
      pricing {
        id
        hardware {
          modelId
          prices {
            quantity
            price
          }
        }
      }
      reps {
        id
        email
        phone
        name {
          first
          last
        }
        addresses {
          id
          street
          city
          state
          zip
          name {
            first
            last
          }
        }
      }
    }
  }
`;

gql`
  mutation AddOrder($input: AddOrderInput!) {
    addOrder(input: $input) {
      id
    }
  }
`;

interface OrderConfirmProps {
  items: LineItem[];
  repId: string;
  addressId: string;
  customerId: string | null;
  prepaid: {
    months: number;
    plan: SensorDataPlan;
    start: number | null;
  } | null;
  managedInfo: {
    months: number;
    price: number;
    prepaid: boolean;
  } | null;
  sharingEmails: string[];
  sharingLevel: PermissionLevelInput | null;
  previousStep: () => void;
  completed: () => void;
}

const OrderConfirm: React.FC<OrderConfirmProps> = ({
  repId,
  addressId,
  prepaid,
  items,
  customerId,
  completed,
  sharingEmails,
  sharingLevel,
  previousStep,
  managedInfo,
}) => {
  const isMobile = useIsMobile();
  const [notes, setNotes] = useState('');

  const customerIsRep = customerId === repId;

  const { loading, error, data } = useOrderDetailsQuery({
    variables: {
      customerId: customerId,
      customer: !!customerId && !customerIsRep,
    },
  });
  const [addOrder, addOrderStatus] = useAddOrderMutation();

  const submitOrder = () => {
    addOrder({
      variables: {
        input: {
          address: {
            city: address?.city || '',
            state: address?.state || '',
            street: address?.street || '',
            zip: address?.zip || '',
            name: {
              first: address?.name?.first || '',
              last: address?.name?.last || '',
            },
          },
          repId,
          hardware: items.map(({ model, amount }) => {
            return { model, quantity: amount || 0 };
          }),
          customerId,
          prepaid,
          notes,
          sharingEmails: sharingEmails.filter((e) => e !== ''),
          sharingLevel,
          managedServiceOptions: managedInfo,
        },
      },
    }).then(() => {
      completed();
    });
  };

  if (loading) return <Loading />;
  if (error || !data) return <GraphQLError error={error} />;

  const { currentDistributor, customer } = data;

  const { reps, pricing } = currentDistributor;
  const rep = find(reps, { id: repId });
  if (!rep) throw Error('Cannot find rep');
  const address = find((rep || { addresses: [] }).addresses, {
    id: addressId,
  });

  let rgCount = 0;
  items.forEach(({ amount, model }) => {
    if (model.hasRainGauge) rgCount += amount || 0;
  });

  const getPricing = makeGetPricing(pricing, rgCount);
  const hardware = items.map(({ amount, model }) => {
    const pricePerUnit = getPricing(model, amount);
    return { pricePerUnit, quantity: amount || 0, model };
  });

  const buttonStyle = isMobile ? { width: '50%' } : {};

  return (
    <Paper style={{ maxWidth: 800, margin: 'auto' }}>
      <OrderSummary
        rep={rep}
        address={address}
        hardware={hardware}
        prepaid={prepaid}
        customer={customerIsRep ? rep : customer}
        sharingEmails={sharingEmails}
        managedServiceOptions={managedInfo}
      />
      <div
        style={{
          maxWidth: 500,
          margin: 'auto',
          padding: 16,
        }}
      >
        <div
          style={{
            textAlign: 'center',
            width: '100%',
            marginBottom: 8,
          }}
          className={FontClasses.subtitle}
        >
          Notes
        </div>
        <textarea
          value={notes}
          onChange={(event) => setNotes(event.target.value)}
          rows={5}
          style={{
            width: '100%',
            padding: 4,
            borderRadius: 4,
            outline: 'none',
          }}
        />
      </div>
      <div
        style={{
          display: 'flex',
          marginBottom: 12,
          flexDirection: 'row',
          justifyContent: 'flex-end',
          padding: 16,
        }}
      >
        <GraphQLError error={addOrderStatus.error} />
        <Button onClick={previousStep} style={buttonStyle}>
          Back
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={submitOrder}
          style={buttonStyle}
          disabled={addOrderStatus.loading}
        >
          Submit Order
        </Button>
      </div>
    </Paper>
  );
};

export default OrderConfirm;
