import * as React from 'react';
import { colors } from 'services/theme';

const size = 48;

const Spacer: React.SFC<{ border?: boolean }> = ({ border }) => {
  const height = size / 2;
  const borderStyle: React.CSSProperties = border
    ? {
        borderTopWidth: 1,
        borderTopStyle: 'solid',
        borderTopColor: colors.grey,
      }
    : {};
  return (
    <React.Fragment>
      <div style={{ height, width: '100%' }} />
      <div style={{ height, width: '100%', ...borderStyle }} />
    </React.Fragment>
  );
};

export default Spacer;
