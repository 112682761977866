import * as React from 'react';
import { Link } from '@reach/router';

import { colors, FontClasses } from 'services/theme';
import { goUpOne } from 'services/history';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import ButtonBase from '@material-ui/core/ButtonBase';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Popover from '@material-ui/core/Popover';

import MenuIcon from '@material-ui/icons/Menu';
import LeftArrowIcon from '@material-ui/icons/KeyboardArrowLeft';
import DropdownIcon from '@material-ui/icons/ArrowDropDown';

const emptyMan = require('../assets/profile.png');

import { logout } from '../../../../services/auth';

const menuButtonId = 'menuButtonId';

type AppbarProps = {
  isMobile: boolean;
  toggleDrawer: () => void;
  appBarHeight: number;
  sideBarWidth: number;
  name: { first: string; last: string };
};

interface AppbarState {
  menuOpen: boolean;
}

class Appbar extends React.PureComponent<AppbarProps, AppbarState> {
  menuButton: HTMLElement | null = null;

  state = {
    menuOpen: false,
  };

  backPressed = () => {
    goUpOne();
  };

  componentDidMount() {
    this.menuButton = document.getElementById(menuButtonId) as HTMLElement;
  }

  render() {
    const { appBarHeight, sideBarWidth, name, isMobile } = this.props;
    const { pathname } = window.location;
    const menuItemStyle = { minWidth: 96 };
    const paths = pathname.split('/').filter(e => e.length > 0);
    const onRootPage = paths.length === 0;
    const onOrderPage = paths[0] === 'orders' || paths[1] === 'order';
    const shouldShowBack = !onRootPage && !onOrderPage;

    const onClose = () => this.setState({ menuOpen: false });

    return (
      <AppBar
        style={{
          zIndex: 1200,
          height: appBarHeight,
          paddingLeft: sideBarWidth,
          background: colors.primaryExtraLight,
        }}
        elevation={1}
        color="secondary"
      >
        <Toolbar id="AppBarToolbar" style={{ paddingLeft: 0, height: '100%' }}>
          {/* Minimize/maximize sidebar button */}
          {isMobile && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <IconButton onClick={this.props.toggleDrawer}>
                <MenuIcon style={{ width: 38, height: 38 }} />
              </IconButton>
            </div>
          )}
          {shouldShowBack && (
            <IconButton onClick={this.backPressed}>
              <LeftArrowIcon style={{ width: 38, height: 38 }} />
            </IconButton>
          )}

          {/* Current route info */}
          <div
            style={{
              display: 'flex',
              position: 'absolute',
              right: 0,
              top: 0,
              alignItems: 'center',
            }}
          >
            <img
              src={emptyMan}
              style={{
                height: 36,
                width: 36,
                borderRadius: '100%',
                borderColor: 'white',
                borderStyle: 'solid',
                background: 'lightgrey',
              }}
            />
            <div style={{ width: 8 }} />
            <ButtonBase
              color="contrast"
              onClick={() =>
                this.setState({
                  menuOpen: true,
                })
              }
              className={FontClasses.subtitle}
              style={{
                height: appBarHeight,
              }}
              id={menuButtonId}
            >
              {`${name.first} ${name.last}`}
              <DropdownIcon />
            </ButtonBase>
            <Popover
              open={this.state.menuOpen}
              onClose={onClose}
              anchorEl={this.menuButton}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              marginThreshold={0}
            >
              <MenuList>
                <Link to="/billing" style={{ textDecoration: 'none' }}>
                  <MenuItem onClick={onClose} style={menuItemStyle}>
                    Billing
                  </MenuItem>
                </Link>
                <a
                  href="https://dealersupport.mytrellis.com"
                  style={{ textDecoration: 'none' }}
                >
                  <MenuItem style={menuItemStyle}>Help</MenuItem>
                </a>
                <MenuItem onClick={logout} style={menuItemStyle}>
                  Logout
                </MenuItem>
              </MenuList>
            </Popover>
          </div>
        </Toolbar>
      </AppBar>
    );
  }
}

export default Appbar;
