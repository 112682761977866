import * as React from 'react';
import gql from 'graphql-tag';
import { navigate } from '@reach/router';
import { ChangePasswordComponent } from 'types/graphql';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import { FontClasses } from 'services/theme';

gql`
  mutation ChangePassword($input: DistributorChangePasswordInput!) {
    changeDistributorPassword(input: $input) {
      id
    }
  }
`;

interface ChangePasswordProps {
  userId: string;
  token: string;
  email: string;
}

interface ChangePasswordState {
  password: {
    text: string;
    hasError: boolean;
    errorMessage: string;
  };
  cpassword: {
    text: string;
    hasError: boolean;
    errorMessage: string;
  };
  errorMessage: string;
  processing: boolean;
}

class ChangePassword extends React.Component<
  ChangePasswordProps,
  ChangePasswordState
> {
  state = {
    password: {
      text: '',
      hasError: false,
      errorMessage: '',
    },
    cpassword: {
      text: '',
      hasError: false,
      errorMessage: '',
    },
    errorMessage: '',
    processing: false,
  };

  passwordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      password: {
        text: event.target.value,
        hasError: false,
        errorMessage: '',
      },
      errorMessage: '',
    });
  };

  cpasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      cpassword: {
        text: event.target.value,
        hasError: false,
        errorMessage: '',
      },
      errorMessage: '',
    });
  };

  render() {
    return (
      <ChangePasswordComponent>
        {(changePassword) => {
          const submit = () => {
            if (this.state.processing) return;
            const { userId, token } = this.props;
            const pw = this.state.password.text;
            const cp = this.state.cpassword.text;
            if (cp !== pw) {
              this.setState({
                cpassword: {
                  text: cp,
                  hasError: true,
                  errorMessage: 'Passwords do not match',
                },
              });
              return;
            }

            this.setState({
              processing: true,
              errorMessage: '',
            });

            changePassword({
              variables: {
                input: {
                  password: pw,
                  token,
                  userId,
                },
              },
            })
              .then(() => {
                navigate('/');
              })
              .catch((err) => {
                if (err.graphQLErrors.length > 0) {
                  let gqlError = err.graphQLErrors[0];
                  if (gqlError.name === 'FirebaseAuthError') {
                    gqlError = gqlError.data;
                  }
                  this.setState({
                    errorMessage: gqlError.message,
                    processing: false,
                  });
                } else {
                  this.setState({
                    errorMessage: 'An error occured',
                    processing: false,
                  });
                }
              });
          };
          const { email } = this.props;
          const textFieldStyle = { flex: '0 0 auto', maxWidth: 240 };
          return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div>
                <div>
                  You will use the email{' '}
                  <i>
                    <b>{email}</b>
                  </i>{' '}
                  to login to the Trellis dashboard.
                </div>
                <div style={{ height: 24 }} />
                <div
                  style={{ fontWeight: 'bold' }}
                  className={FontClasses.title}
                >
                  Choose a new password for your account.
                </div>
                <div style={{ height: 8 }} />
                <div>Passwords must contain at least 6 characters.</div>
              </div>
              <TextField
                error={this.state.password.hasError}
                helperText={this.state.password.errorMessage}
                style={textFieldStyle}
                label="Password"
                margin="normal"
                type="password"
                onChange={this.passwordChange}
                id="password"
              />
              <TextField
                error={this.state.cpassword.hasError}
                helperText={this.state.cpassword.errorMessage}
                style={textFieldStyle}
                label="Confirm Password"
                margin="normal"
                type="password"
                onChange={this.cpasswordChange}
                onKeyDown={(e) => (e.keyCode === 13 ? submit() : null)}
                id="confirm"
              />
              <span style={{ color: 'red' }}>{this.state.errorMessage}</span>
              <Button
                variant="contained"
                color="primary"
                onClick={submit}
                style={{ maxWidth: 240, height: 50, marginTop: 8 }}
                id="setPassword"
              >
                Set Password
                {this.state.processing && (
                  <CircularProgress size={30} style={{ color: 'white' }} />
                )}
              </Button>
            </div>
          );
        }}
      </ChangePasswordComponent>
    );
  }
}

export default ChangePassword;
