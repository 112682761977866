/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import gql from 'graphql-tag';
import uniq from 'lodash/uniq';

import { DeleteFieldComponent } from 'types/graphql';

import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import DeleteIcon from '@material-ui/icons/Delete';
import CircularProgress from '@material-ui/core/CircularProgress';

import { IsMobileConsumer } from 'providers/Responsive';

import EnhancedTableHead from '../EnhancedTableHead';

import { colors } from 'services/theme';

gql`
  mutation DeleteField($input: DeleteFieldInput!) {
    deleteField(input: $input) {
      success
    }
  }
`;

type OrderDirection = 'asc' | 'desc';

interface FieldData {
  id: string;
  name: string;
  irrigationMetadata: {
    irrigationType: string | null;
  };
  sensors: {
    crop_type: {
      name: string;
    } | null;
    crop_variety: string | null;
  }[];
}

interface FieldsTableProps {
  data: FieldData[];
  customerId: string;
  refetch: () => void;
}

interface FieldsTableState {
  orderBy: string;
  orderDir: OrderDirection;
  deletePromptOpen: string;
}

class FieldsTable extends React.Component<FieldsTableProps, FieldsTableState> {
  handleRequestSort: (
    _event: React.MouseEvent<HTMLElement>,
    property: string
  ) => void;
  handleDeletePromptClose: () => void;

  constructor(props: FieldsTableProps) {
    super(props);
    this.state = {
      orderBy: 'name',
      orderDir: 'desc',
      deletePromptOpen: '',
    };

    // sorts columns depending on header selected
    this.handleRequestSort = (_event, property) => {
      const orderBy = property;
      let orderDir: OrderDirection = 'desc';

      if (this.state.orderBy === property && this.state.orderDir === 'desc') {
        orderDir = 'asc';
      }

      this.setState({ orderDir, orderBy });
    };

    this.handleDeletePromptClose = () => {
      this.setState({ deletePromptOpen: '' });
    };
  }

  render() {
    const { orderDir, orderBy } = this.state;

    const rowToSortField = (col: string, row: any) => {
      const cropTypes: string[] = [];
      const cropVariety: string[] = [];
      row.sensors.forEach((sensor: any) => {
        if (sensor.crop_type) {
          cropTypes.push(sensor.crop_type.name);
        }
        if (sensor.crop_variety) {
          cropVariety.push(sensor.crop_variety);
        }
      });

      switch (col) {
        case 'id':
          return row.id;
        case 'name':
          return row.name;
        case 'cropType':
          return uniq(cropTypes).join(', ');
        case 'cropVar':
          return uniq(cropVariety).join(', ');
        case 'irrType':
          return row.irrigationMetadata.irrigationType;
      }
    };
    const compareField = (fieldA: any, fieldB: any) => {
      if (fieldA === fieldB) return 0;
      if (fieldA === null || fieldA === undefined || fieldA === '') return 1;
      if (fieldB === null || fieldB === undefined || fieldB === '') return -1;
      if (!isNaN(fieldA) && !isNaN(fieldB))
        return parseInt(fieldA) < parseInt(fieldB) ? -1 : 1;
      return fieldA < fieldB ? -1 : 1;
    };

    const sortedData = this.props.data.sort(
      (a: any, b: any) =>
        compareField(rowToSortField(orderBy, a), rowToSortField(orderBy, b)) *
        (orderDir === 'desc' ? 1 : -1)
    );

    return (
      <IsMobileConsumer>
        {({ isMobile }) => {
          const { orderDir, orderBy } = this.state;
          const { customerId, refetch } = this.props;

          const columnHeaderData = [
            { id: 'name', centered: false, label: 'Name' },
            { id: 'cropType', centered: false, label: 'Crop' },
          ];
          if (!isMobile) {
            columnHeaderData.push({
              id: 'cropVar',
              centered: false,
              label: 'Crop Variety',
            });
          }
          columnHeaderData.push({
            id: 'irrType',
            centered: false,
            label: 'Irrigation Type',
          });
          columnHeaderData.push({
            id: 'delete',
            centered: false,
            label: '',
          });

          const cellStyle = { padding: 8 };
          return (
            <Table>
              <EnhancedTableHead
                columnData={columnHeaderData}
                order={orderDir}
                orderBy={orderBy}
                onRequestSort={this.handleRequestSort}
              />
              <TableBody>
                {sortedData.map(
                  ({ id, name, irrigationMetadata, sensors: fieldSensors }) => {
                    const cropTypes: string[] = [];
                    const cropVariety: string[] = [];
                    fieldSensors.forEach((sensor) => {
                      if (sensor.crop_type) {
                        cropTypes.push(sensor.crop_type.name);
                      }
                      if (sensor.crop_variety) {
                        cropVariety.push(sensor.crop_variety);
                      }
                    });

                    return (
                      <TableRow hover key={id}>
                        <TableCell style={cellStyle}>{name}</TableCell>
                        <TableCell style={cellStyle}>
                          {uniq(cropTypes).join(', ')}
                        </TableCell>

                        {!isMobile && (
                          <TableCell style={cellStyle}>
                            {uniq(cropVariety).join(', ')}
                          </TableCell>
                        )}

                        <TableCell style={cellStyle}>
                          {irrigationMetadata.irrigationType}
                        </TableCell>
                        <TableCell>
                          <IconButton
                            onClick={() =>
                              this.setState({ deletePromptOpen: id })
                            }
                            id={`icon-button-${id}`}
                          >
                            <DeleteIcon style={{ color: colors.error }} />
                          </IconButton>
                        </TableCell>
                        <DeleteFieldComponent>
                          {(deleteFieldMutation, { loading, error }) => {
                            const deleteField = () => {
                              deleteFieldMutation({
                                variables: {
                                  input: {
                                    fieldId: id,
                                    customerId,
                                  },
                                },
                              })
                                .then(this.handleDeletePromptClose)
                                .then(refetch);
                            };
                            return (
                              <Popover
                                open={this.state.deletePromptOpen === id}
                                anchorEl={() =>
                                  document.getElementById(
                                    `icon-button-${id}`
                                  ) as HTMLElement
                                }
                                onClose={this.handleDeletePromptClose}
                                anchorOrigin={{
                                  vertical: 'top',
                                  horizontal: 'center',
                                }}
                                transformOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'center',
                                }}
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    padding: 8,
                                    maxWidth: 200,
                                  }}
                                >
                                  <div style={{ textAlign: 'center' }}>
                                    Are you sure you want to delete the field{' '}
                                    {name}?
                                  </div>
                                  <div style={{ height: 8 }} />
                                  {error && (
                                    <span style={{ color: 'red' }}>
                                      {error.message}
                                    </span>
                                  )}
                                  {loading && (
                                    <CircularProgress color="primary" />
                                  )}
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                    }}
                                  >
                                    <Button
                                      variant="contained"
                                      size="small"
                                      color="primary"
                                      onClick={deleteField}
                                    >
                                      Yes
                                    </Button>
                                    <div style={{ width: 8 }} />
                                    <Button
                                      variant="contained"
                                      size="small"
                                      onClick={this.handleDeletePromptClose}
                                    >
                                      No
                                    </Button>
                                  </div>
                                </div>
                              </Popover>
                            );
                          }}
                        </DeleteFieldComponent>
                      </TableRow>
                    );
                  }
                )}
              </TableBody>
            </Table>
          );
        }}
      </IsMobileConsumer>
    );
  }
}

export default FieldsTable;
