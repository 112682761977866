export const COLOR_WET = '#32627A';
export const COLOR_OK = '#226f54';
export const COLOR_DRY = '#E0C884';
export const COLOR_UNKNOWN = '#C0C0C0';
export const COLOR_OFFLINE = '#FF4026';

export const stateToColor = (state?: number | null) => {
  if (state === null || state === undefined) return COLOR_UNKNOWN;
  if (state < 0) {
    return COLOR_OFFLINE;
  } else if (state < 1) {
    return COLOR_WET;
  } else if (state < 3) {
    return COLOR_OK;
  } else {
    return COLOR_DRY;
  }
};
