import * as React from 'react';

export const AuthContext = React.createContext<InjectedAuthProps>({
  authed: false,
  peeking: false,
  token: null,
});

export const { Provider: AuthProvider, Consumer: AuthConsumer } = AuthContext;

export type InjectedAuthProps = {
  authed: boolean;
  peeking: boolean;
  token: string | null;
};

export const withAuth = <T extends InjectedAuthProps>(
  Component: React.ComponentType<T>
) =>
  class WithAuth extends React.Component<T> {
    render() {
      return (
        <AuthConsumer>
          {auth => <Component {...this.props} {...auth} />}
        </AuthConsumer>
      );
    }
  };
