import React, { Suspense, lazy } from 'react';
import Loading from 'components/Loading/Loading';

const AdminDistributorList = lazy(() =>
  import(/* webpackChunkName: "AdminDistributorList" */ './AdminDistributorList')
);

const LoadableAdminDistributorList: React.FC = props => (
  <Suspense fallback={<Loading />}>
    <AdminDistributorList {...props} />
  </Suspense>
);

export default LoadableAdminDistributorList;
