import * as React from 'react';
import gql from 'graphql-tag';
import sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq';
import { RouteComponentProps, navigate } from '@reach/router';

import {
  EditHardwareComponent,
  EditCustomerHardwareComponent,
  UpdateSensorComponent,
} from 'types/graphql';

import { IsMobileConsumer } from 'providers/Responsive';

import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import Loading from 'components/Loading/Loading';
import OrderPopup from 'components/OrderPopup';
import HardwareTable from 'components/HardwareTable/HardwareTable';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';

import TableCell from '@material-ui/core/TableCell';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import GenericError from 'components/Errors/GenericError';
import GraphQLError from 'components/Errors/GraphQLError';

gql`
  query EditHardware($customerId: String!) {
    customer(customerId: $customerId) {
      id
      name {
        first
        last
      }
      user {
        id
        sensors(onlyOwned: true) {
          id
          soil_type {
            id
            name
          }
          crop_type {
            id
            name
          }
          model {
            type
            antennaLength
            moistureDepths
          }
          order {
            id
            placedTimestamp
          }
        }
        gateways(onlyOwned: true) {
          id
          model {
            type
            antennaLength
          }
          order {
            id
            placedTimestamp
          }
        }
      }
    }
    soils {
      id
      name
    }
    crops {
      id
      name
    }
    currentDistributor {
      id
      unassigned {
        sensors {
          id
          name
          model {
            type
            moistureDepths
            antennaLength
          }
          order {
            id
            placedTimestamp
          }
          soil_type {
            id
            name
          }
          crop_type {
            id
            name
          }
        }
        gateways {
          id
          name
          model {
            type
            moistureDepths
            antennaLength
          }
          order {
            id
            placedTimestamp
          }
        }
      }
    }
  }
`;

gql`
  mutation EditCustomerHardware($input: EditCustomerHardwareInputType!) {
    editCustomerHardware(input: $input) {
      id
    }
  }
`;
gql`
  mutation UpdateSensor($sensorId: String!, $input: UpdateSensorInput!) {
    updateSensor(sensorId: $sensorId, input: $input) {
      sensorId
    }
  }
`;

type EditHardwareProps = RouteComponentProps<{ customerId: string }>;

interface EditHardwareState {
  showPopup: boolean;
  popupOrderId: number;
  selectedSensors: string[];
  selectedGateways: string[];
  selectedForRemoval: string[];
  soilTextures: Record<string, string>;
  cropTypes: Record<string, string>;
}

class EditHardware extends React.Component<
  EditHardwareProps,
  EditHardwareState
> {
  customerId: string;
  constructor(props: EditHardwareProps) {
    super(props);
    this.state = {
      showPopup: false,
      popupOrderId: 0,
      selectedSensors: [],
      selectedGateways: [],
      selectedForRemoval: [],
      soilTextures: {},
      cropTypes: {},
    };

    this.customerId = this.props.customerId || '';
  }

  render() {
    return (
      <IsMobileConsumer>
        {({ isMobile }) => (
          <EditCustomerHardwareComponent>
            {(
              editHardware,
              { loading: editHardwareLoading, error: editHardwareError }
            ) => (
              <UpdateSensorComponent>
                {(
                  updateSensor,
                  { loading: updateSensorLoading, error: updateSensorError }
                ) => (
                  <EditHardwareComponent
                    fetchPolicy="network-only"
                    variables={{ customerId: this.customerId }}
                  >
                    {({ data, loading, error }) => {
                      if (loading) return <Loading />;
                      if (error || !data || !data.customer)
                        return <GenericError />;

                      const {
                        currentDistributor,
                        customer,
                        soils,
                        crops,
                      } = data;

                      const customerName = customer.name
                        ? `${customer.name.first} ${customer.name.last}`
                        : 'the customer';

                      const {
                        unassigned: { sensors, gateways },
                      } = currentDistributor;

                      const hardware = [
                        ...customer.user.sensors,
                        ...customer.user.gateways,
                      ];

                      const save = () => {
                        const getFullHardwareItem = (id: string) => {
                          const [sensor] = customer.user.sensors.filter(
                            s => s.id === id
                          );
                          const [gateway] = customer.user.gateways.filter(
                            g => g.id === id
                          );
                          return sensor || gateway;
                        };
                        const removedFull = this.state.selectedForRemoval.map(
                          getFullHardwareItem
                        );
                        const removeSensorIds = removedFull
                          .filter(
                            ({ model }) => model && model.type === 'sensor'
                          )
                          .map(s => s.id);
                        const removeGatewayIds = removedFull
                          .filter(
                            ({ model }) => model && model.type === 'gateway'
                          )
                          .map(g => g.id);
                        const allIds = uniq([
                          ...Object.keys(this.state.soilTextures),
                          ...Object.keys(this.state.cropTypes),
                        ]);
                        Promise.all([
                          editHardware({
                            variables: {
                              input: {
                                customerId: this.customerId,
                                add: {
                                  sensorIds: this.state.selectedSensors,
                                  gatewayIds: this.state.selectedGateways,
                                },
                                remove: {
                                  sensorIds: removeSensorIds,
                                  gatewayIds: removeGatewayIds,
                                },
                              },
                            },
                          }),
                          allIds.map(id => {
                            updateSensor({
                              variables: {
                                sensorId: id,
                                input: {
                                  customerId: this.customerId,
                                  soil_type: this.state.soilTextures[id],
                                  crop_type: this.state.cropTypes[id],
                                },
                              },
                            });
                          }),
                        ]).then(() => {
                          navigate(`/manage/${this.customerId}`);
                        });
                      };

                      const sensorColumns = {
                        selected: true,
                        sensorSummary: isMobile,
                        id: !isMobile,
                        depths: !isMobile,
                        antenna: !isMobile,
                        orderDate: !isMobile,
                        viewOrder: true,
                      };

                      const baseStationColumns = {
                        selected: true,
                        id: true,
                        depths: !isMobile,
                        antenna: !isMobile,
                        orderDate: !isMobile,
                        viewOrder: true,
                      };

                      const hardwareColumns = {
                        selectedRemove: true,
                        id: true,
                        depths: true,
                        antenna: true,
                        viewOrder: true,
                      };

                      const buttonStyle = isMobile
                        ? { width: '90%', marginLeft: '5%' }
                        : {
                            marginLeft: '12.5%',
                            marginRight: '12.5%',
                            width: '25%',
                            marginTop: 16,
                            marginBottom: 16,
                          };

                      const SaveButton = () => (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            const confirm = window.confirm(
                              `Are you sure? Saving any changes will be reflected on ${customerName}'s account.`
                            );
                            if (confirm) {
                              save();
                            }
                          }}
                          id="saveHardware"
                          style={buttonStyle}
                          disabled={editHardwareLoading || updateSensorLoading}
                        >
                          Save
                        </Button>
                      );

                      const CancelButton = () => (
                        <Button
                          onClick={() => navigate(`/manage/${this.customerId}`)}
                          style={buttonStyle}
                        >
                          Cancel
                        </Button>
                      );

                      return (
                        <div>
                          {isMobile && <SaveButton />}
                          <GraphQLError
                            error={updateSensorError || editHardwareError}
                          />
                          {/* Popup */}
                          <OrderPopup
                            orderId={this.state.popupOrderId}
                            closeFunction={() =>
                              this.setState({
                                showPopup: false,
                              })
                            }
                            open={this.state.showPopup}
                          />
                          <Paper style={{ padding: 20, marginBottom: 20 }}>
                            Available Sensors (Select to add)
                            <HardwareTable
                              columns={sensorColumns}
                              data={sensors.filter(s => s.id.length !== 20)}
                              selected={this.state.selectedSensors}
                              onSelectedChange={selectedSensors =>
                                this.setState({ selectedSensors })
                              }
                            />
                          </Paper>
                          <Paper style={{ padding: 20, marginBottom: 20 }}>
                            Available Base Stations (Select to add)
                            <HardwareTable
                              columns={baseStationColumns}
                              data={gateways.filter(g => g.id.length !== 20)}
                              selected={this.state.selectedGateways}
                              onSelectedChange={selectedGateways =>
                                this.setState({ selectedGateways })
                              }
                            />
                          </Paper>
                          <Paper style={{ padding: 20 }}>
                            Edit Hardware for {customerName}
                            <HardwareTable
                              columns={hardwareColumns}
                              data={hardware}
                              selected={this.state.selectedForRemoval}
                              onSelectedChange={selectedForRemoval =>
                                this.setState({ selectedForRemoval })
                              }
                              customColumns={[
                                {
                                  header: {
                                    id: 'soilTextureInput',
                                    label: 'Soil Texture',
                                    centered: false,
                                  },
                                  cell: (hw, i) => {
                                    return (
                                      <TableCell
                                        key={i}
                                        style={{ textAlign: 'left' }}
                                      >
                                        {hw.model &&
                                          hw.model.type === 'sensor' && (
                                            <Select
                                              value={
                                                this.state.soilTextures[
                                                  hw.id
                                                ] ||
                                                (hw.soil_type
                                                  ? hw.soil_type.id
                                                  : '')
                                              }
                                              onChange={e =>
                                                this.setState({
                                                  soilTextures: {
                                                    ...this.state.soilTextures,
                                                    [hw.id]: e.target
                                                      .value as string,
                                                  },
                                                })
                                              }
                                              style={{ width: '100%' }}
                                            >
                                              {sortBy(soils, ['name']).map(
                                                ({ id, name }) => {
                                                  return (
                                                    <MenuItem
                                                      key={id}
                                                      value={id}
                                                    >
                                                      {name}
                                                    </MenuItem>
                                                  );
                                                }
                                              )}
                                            </Select>
                                          )}
                                      </TableCell>
                                    );
                                  },
                                },
                                {
                                  header: {
                                    id: 'cropTypeInput',
                                    label: 'Crop Type',
                                    centered: false,
                                  },
                                  cell: (hw, i) => {
                                    return (
                                      <TableCell
                                        key={i}
                                        style={{ textAlign: 'left' }}
                                      >
                                        {hw.model &&
                                          hw.model.type === 'sensor' && (
                                            <Select
                                              value={
                                                this.state.cropTypes[hw.id] ||
                                                (hw.crop_type
                                                  ? hw.crop_type.id
                                                  : '')
                                              }
                                              onChange={e =>
                                                this.setState({
                                                  cropTypes: {
                                                    ...this.state.cropTypes,
                                                    [hw.id]: e.target
                                                      .value as string,
                                                  },
                                                })
                                              }
                                              style={{ width: '100%' }}
                                            >
                                              {sortBy(crops, ['name']).map(
                                                ({ id, name }) => {
                                                  return (
                                                    <MenuItem
                                                      key={id}
                                                      value={id}
                                                    >
                                                      {name}
                                                    </MenuItem>
                                                  );
                                                }
                                              )}
                                            </Select>
                                          )}
                                      </TableCell>
                                    );
                                  },
                                },
                              ]}
                            />
                          </Paper>
                          {isMobile && <CancelButton />}
                          {!isMobile && (
                            <div>
                              <CancelButton />
                              <SaveButton />
                            </div>
                          )}
                          <Dialog
                            open={editHardwareLoading || updateSensorLoading}
                          >
                            <DialogTitle id="simple-dialog-title">
                              Saving...
                            </DialogTitle>
                            <div>
                              <Loading style={{ margin: 0 }} />
                            </div>
                          </Dialog>
                        </div>
                      );
                    }}
                  </EditHardwareComponent>
                )}
              </UpdateSensorComponent>
            )}
          </EditCustomerHardwareComponent>
        )}
      </IsMobileConsumer>
    );
  }
}

export default EditHardware;
