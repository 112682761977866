import * as React from 'react';
import { FontClasses, colors } from 'services/theme';

interface TwoColumnProps {
  title: string;
  subtitle: string;
  content?: JSX.Element;
  leftContent?: JSX.Element;
}

class TwoColumn extends React.Component<TwoColumnProps> {
  render() {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          width: '100%',
        }}
      >
        <div style={{ width: 300, flex: '0 0 auto', paddingRight: 24 }}>
          <div className={FontClasses.subtitle}>{this.props.title}</div>
          <div style={{ color: colors.textLight }}>{this.props.subtitle}</div>
          {this.props.leftContent}
        </div>
        <div
          style={{
            flex: 1,
            paddingTop: 24,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {this.props.content}
        </div>
      </div>
    );
  }
}

export default TwoColumn;
