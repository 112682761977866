import * as React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { FontClasses, colors } from 'services/theme';
import TwoColumn from './TwoColumn';

interface AntennaLengthProps {
  value: number | undefined;
  onChange: (ev: React.ChangeEvent<{ name?: string; value: unknown }>) => void;
}

class AntennaLength extends React.Component<AntennaLengthProps> {
  render() {
    return (
      <TwoColumn
        title="Antenna"
        subtitle="Select an antenna height that is tall enough to reach above the crop canopy throughout the entire growing season."
        content={
          <React.Fragment>
            <div
              style={{ color: colors.textLight }}
              className={FontClasses.caption}
            >
              Antenna Height
            </div>
            <Select
              value={String(this.props.value)}
              onChange={this.props.onChange}
              style={{ width: 200, marginBottom: 12 }}
            >
              <MenuItem value={4}>4 foot</MenuItem>
              <MenuItem value={8}>8 foot</MenuItem>
              <MenuItem value={12}>12 foot</MenuItem>
            </Select>
          </React.Fragment>
        }
      />
    );
  }
}

export default AntennaLength;
