import React, { Suspense, lazy } from 'react';
import Loading from 'components/Loading/Loading';

const AdminStatusGateway = lazy(() =>
  import(/* webpackChunkName: "AdminStatusGateway" */ './AdminStatusGateway')
);

const LoadableAdminStatusGateway: React.FC = props => (
  <Suspense fallback={<Loading />}>
    <AdminStatusGateway {...props} />
  </Suspense>
);

export default LoadableAdminStatusGateway;
