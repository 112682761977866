/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import moment from 'moment';
import remove from 'lodash/remove';

import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import ViewOrderIcon from '@material-ui/icons/Description';
import Checkbox from '@material-ui/core/Checkbox';

import { hardwareTypeToPrettyName } from 'services/hardware';

import ErrorBoundary from 'components/Errors/ErrorBoundary';
import OrderPopup from 'components/OrderPopup';
import EnhancedTableHead from '../EnhancedTableHead';

const allColumns = [
  { id: 'selected', centered: false, sortDisabled: true, label: 'Selected' },
  { id: 'selectedRemove', centered: true, label: 'Remove' },
  { id: 'type', centered: false, label: 'Type' },
  { id: 'sensorSummary', centered: false, label: 'ID' },
  { id: 'id', centered: false, label: 'ID' },
  { id: 'name', centered: false, label: 'Name' },
  { id: 'depths', centered: false, label: 'Depths (in)' },
  { id: 'antenna', centered: false, label: 'Antenna (ft)' },
  { id: 'texture', centered: false, label: 'Soil Texture' },
  { id: 'crop', centered: false, label: 'Crop Type' },
  { id: 'orderDate', centered: false, label: 'Order Date' },
  { id: 'viewOrder', centered: true, label: 'View Order' },
];

type ColumnName =
  | 'selected'
  | 'id'
  | 'name'
  | 'antenna'
  | 'orderDate'
  | 'viewOrder'
  | 'type'
  | 'depths'
  | 'texture'
  | 'crop';

type OrderDirection = 'asc' | 'desc';

interface HardwareTableData {
  id: string;
  name?: string | null;
  soil_type?: { name: string; id: string } | null;
  crop_type?: { name: string; id: string } | null;
  model: {
    type: string | null;
    moistureDepths?: number[] | null;
    antennaLength: number | null;
  } | null;
  order?: {
    id: number;
    placedTimestamp: number;
  } | null;
  isOwner?: boolean;
}

interface HardwareTableProps {
  data: HardwareTableData[];
  columns: { [key in ColumnName]?: boolean };
  customColumns?: {
    header: {
      id: string;
      label: string;
      centered: boolean;
    };
    cell: (row: HardwareTableData, i: number) => JSX.Element;
  }[];
  selected?: string[];
  onSelectedChange?: (selected: string[]) => void;
}

interface HardwareTableState {
  orderBy: ColumnName;
  orderDir: OrderDirection;
  selectedOrder: number | null;
  orderPopup: boolean;
}

class HardwareTable extends React.Component<
  HardwareTableProps,
  HardwareTableState
> {
  handleRequestSort: (
    _event: React.MouseEvent<HTMLElement>,
    property: string
  ) => void;
  handleRowSelected: (id: string) => void;
  isSelected: (id: string) => boolean;
  handleOrderClick: (id: number | null) => void;
  getUpdatedArr: (id: string) => string[];

  constructor(props: HardwareTableProps) {
    super(props);
    this.state = {
      orderBy: 'id',
      orderDir: 'desc',
      selectedOrder: null,
      orderPopup: false,
    };

    this.handleOrderClick = (id) => {
      this.setState({
        selectedOrder: id,
        orderPopup: true,
      });
    };

    // sorts columns depending on header selected
    this.handleRequestSort = (_event, property) => {
      const orderBy = property as ColumnName;
      let orderDir: OrderDirection = 'desc';

      if (this.state.orderBy === property && this.state.orderDir === 'desc') {
        orderDir = 'asc';
      }

      this.setState({ orderDir, orderBy });
    };

    this.getUpdatedArr = (id) => {
      const arr = [...(this.props.selected || [])];
      const removed = remove(arr, (x) => x === id);
      if (removed.length > 0) return arr;
      arr.push(id);
      return arr;
    };

    this.handleRowSelected = (id) => {
      const { onSelectedChange } = this.props;
      if (onSelectedChange) onSelectedChange(this.getUpdatedArr(id));
    };

    this.isSelected = (id) => (this.props.selected || []).indexOf(id) !== -1;
  }

  render() {
    const { orderDir, orderBy } = this.state;
    const { columns, customColumns = [], data } = this.props;

    const rowToSortField = (col: ColumnName, row: any) => {
      switch (col) {
        case 'id':
          return row.id;
        case 'name':
          return row.name;
        case 'type':
          return row.model?.type
            ? hardwareTypeToPrettyName(row.model.type)
            : null;
        case 'antenna':
          return row.model?.antennaLength;
        case 'texture':
          return row.soil_type?.name;
        case 'crop':
          return row.crop_type?.name;
        case 'orderDate':
          return row.order && row.isOwner
            ? moment(row.order.placedTimestamp).format('YYYY-MM-DD')
            : null;
        case 'depths':
          return row.model?.moistureDepths
            ? row.model.moistureDepths.join(', ')
            : '-';
      }
    };

    const compareField = (fieldA: any, fieldB: any) => {
      if (fieldA === fieldB) return 0;
      if (fieldA === null || fieldA === undefined || fieldA === '') return 1;
      if (fieldB === null || fieldB === undefined || fieldB === '') return -1;
      if (!isNaN(fieldA) && !isNaN(fieldB))
        return parseInt(fieldA) < parseInt(fieldB) ? -1 : 1;
      return fieldA < fieldB ? -1 : 1;
    };

    const sortedData = data.sort(
      (a: any, b: any) =>
        compareField(rowToSortField(orderBy, a), rowToSortField(orderBy, b)) *
        (orderDir === 'desc' ? 1 : -1)
    );

    const columnsToUse = Object.keys(columns).filter(
      // eslint-disable-next-line
      (x) => (columns as any)[x] === true
    );
    const isCol = (column: string) => columnsToUse.indexOf(column) !== -1;
    const columnHeaderData = allColumns.filter(({ id }) => {
      return isCol(id);
    });

    customColumns.forEach((ccol) => columnHeaderData.push(ccol.header));

    const makeCell = (
      column: string,
      value: () => JSX.Element | string | number | null | undefined
    ) => {
      if (!isCol(column)) return null;
      return (
        <TableCell
          key={column}
          style={{
            padding: 8,
            textAlign: column === 'viewOrder' ? 'center' : undefined,
          }}
        >
          {value()}
        </TableCell>
      );
    };

    return (
      <ErrorBoundary>
        <Table>
          <EnhancedTableHead
            columnData={columnHeaderData}
            order={orderDir}
            orderBy={orderBy}
            onRequestSort={this.handleRequestSort}
          />
          <TableBody>
            {sortedData.map((row) => {
              const {
                id,
                name,
                model,
                order,
                soil_type,
                crop_type,
                isOwner,
              } = row;
              return (
                <TableRow hover key={id} id={`row_${id}`}>
                  {isCol('selected') && (
                    <TableCell
                      onClick={() => this.handleRowSelected(id)}
                      style={{ textAlign: 'left', padding: 0 }}
                      padding="checkbox"
                    >
                      <Checkbox color="primary" checked={this.isSelected(id)} />
                    </TableCell>
                  )}
                  {isCol('selectedRemove') && (
                    <TableCell
                      onClick={() => this.handleRowSelected(id)}
                      style={{ textAlign: 'center' }}
                      padding="checkbox"
                    >
                      <Checkbox
                        color="secondary"
                        checked={this.isSelected(id)}
                      />
                    </TableCell>
                  )}
                  {makeCell('type', () =>
                    model && model.type
                      ? hardwareTypeToPrettyName(model.type)
                      : null
                  )}
                  {makeCell('sensorSummary', () => (
                    <div>
                      <div>
                        <b>{id}</b>
                      </div>
                      <div>
                        <i>
                          Sensor Depths (in):{' '}
                          {(model && model.moistureDepths
                            ? model.moistureDepths
                            : []
                          ).join(', ')}
                        </i>
                      </div>
                      <div>
                        <i>
                          Antenna (ft): {model ? model.antennaLength : null}
                        </i>
                      </div>
                    </div>
                  ))}
                  {makeCell('id', () => id)}
                  {makeCell('name', () => (
                    <>
                      <div>{name}</div>
                      {!isOwner && <div>(shared)</div>}
                    </>
                  ))}
                  {makeCell('depths', () =>
                    model && model.moistureDepths
                      ? model.moistureDepths.join(', ')
                      : '-'
                  )}
                  {makeCell('antenna', () =>
                    model ? model.antennaLength : null
                  )}
                  {makeCell('texture', () =>
                    soil_type ? soil_type.name : null
                  )}
                  {makeCell('crop', () => (crop_type ? crop_type.name : null))}
                  {makeCell('orderDate', () =>
                    order && isOwner
                      ? moment(order.placedTimestamp).format('YYYY-MM-DD')
                      : null
                  )}
                  {makeCell('viewOrder', () =>
                    isOwner ? (
                      <Button
                        size="small"
                        onClick={() =>
                          this.handleOrderClick(order ? order.id : null)
                        }
                      >
                        <ViewOrderIcon />
                      </Button>
                    ) : null
                  )}
                  {customColumns.map(({ cell }, i) => cell(row, i))}
                </TableRow>
              );
            })}
          </TableBody>
          <OrderPopup
            orderId={this.state.selectedOrder}
            closeFunction={() =>
              this.setState({
                orderPopup: false,
              })
            }
            open={this.state.orderPopup}
          />
        </Table>
      </ErrorBoundary>
    );
  }
}

export default HardwareTable;
