import * as React from 'react';
import { LineItem } from './OrderTable';
import IconButton from '@material-ui/core/IconButton';
import { FontClasses, colors } from 'services/theme';
import TextField from '@material-ui/core/TextField';
import DeleteIcon from '@material-ui/icons/Delete';
import { getModelTitles } from 'services/model';
import { formatCurrency } from 'services/currency';

interface OrderTableRowProps {
  item: LineItem;
  pricePerUnit: number;
  remove: () => void;
  onAmountChange: (amount: number | null) => void;
  showPrice: boolean;
}

const OrderTableRow: React.FC<OrderTableRowProps> = ({
  item: { amount, model },
  pricePerUnit,
  remove,
  onAmountChange,
  showPrice,
}) => {
  const amountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (value === '') return onAmountChange(null);
    let num = Number(value);
    if (num < 0) num = 0;
    else onAmountChange(num);
  };

  const { title, subtitle } = getModelTitles(model);

  const amountVal = amount || '';
  const amountNum = amount || 0;

  const totalPrice = pricePerUnit * amountNum;

  const totalPriceStr = formatCurrency(totalPrice);

  const pricePerUnitStr = formatCurrency(pricePerUnit);

  return (
    <div
      style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
    >
      <TextField
        value={amountVal}
        onChange={amountChange}
        style={{ width: 74, marginRight: 24 }}
        inputProps={{ style: { textAlign: 'right' } }}
        type="number"
        variant="outlined"
        margin="dense"
      />
      <div>
        <div>{title}</div>
        <div
          className={FontClasses.caption}
          style={{ color: colors.textLight }}
        >
          {subtitle}
        </div>
      </div>
      {showPrice && (
        <div style={{ marginLeft: 'auto', textAlign: 'right' }}>
          <div>{totalPriceStr}</div>
          <div
            className={FontClasses.caption}
            style={{ color: colors.textLight }}
          >
            {amountNum} x {pricePerUnitStr}
          </div>
        </div>
      )}
      <div style={{ marginLeft: 8 }}>
        <IconButton onClick={remove}>
          <DeleteIcon />
        </IconButton>
      </div>
    </div>
  );
};

export default OrderTableRow;
