import React, { useState, useEffect } from 'react';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

import { SensorDataPlan, PermissionLevelInput } from 'types/graphql';

import { useIsMobile } from 'providers/Responsive';

import OrderIntro from './components/OrderIntro/OrderIntro';
import OrderRep from './components/OrderRep/OrderRep';
import OrderTable, { LineItem } from './components/OrderTable/OrderTable';
import OrderThanks from './components/OrderThanks/OrderThanks';
import OrderConfirm from './components/OrderConfirm/OrderConfirm';

// If you change the shape of the state update this
export const STATE_STORAGE_KEY = 'ORDER_STATE_2';

interface RepInfo {
  // Set by OrderRep
  repId: string;
  addressId: string;
  customerId: string;
  prepaid: boolean;
  prepaidMonths: number;
  prepaidStart: number | null;
  sharingEmails: string[];
  managedPrice: number | null;
  managedPrepaid: boolean;
  managedMonths: number | null;
}

interface OrderState extends RepInfo {
  // Set by OrderForm
  items: LineItem[];

  // Stepper
  activeStep: number;

  isManaged: boolean;
}

const Order: React.FC = () => {
  const isMobile = useIsMobile();
  const [state, setState] = useState<OrderState>(() => {
    const stateJSON = sessionStorage.getItem(STATE_STORAGE_KEY);
    if (stateJSON) {
      try {
        const oldState = JSON.parse(stateJSON);
        return oldState;
      } catch (err) {
        console.error(err);
      }
    }

    return {
      // Set by OrderForm
      items: [],

      // Set by OrderRep
      repId: '',
      addressId: '',
      customerId: '',
      prepaid: false,
      prepaidStart: null,
      prepaidMonths: 0,
      sharingEmails: [],

      // Stepper
      activeStep: 0,

      isManaged: false,
      managedPrice: null,
      managedPrepaid: false,
      managedMonths: null,
    };
  });
  const [completed, setCompleted] = useState(false);

  useEffect(() => {
    // Save the updated form state
    sessionStorage.setItem(STATE_STORAGE_KEY, JSON.stringify(state));
  }, [state]);

  const doComplete = () => {
    setCompleted(true);
    sessionStorage.removeItem(STATE_STORAGE_KEY);
  };

  const getStepContent = (step: number) => {
    const handleBack = () => {
      const { activeStep } = state;
      setState((s) => ({ ...s, activeStep: activeStep - 1 }));
    };
    const handleNext = () => {
      const { activeStep } = state;
      setState((s) => ({ ...s, activeStep: activeStep + 1 }));
    };

    const saveRep = (repInfo: RepInfo) => {
      setState((s) => ({
        ...s,
        ...repInfo,
      }));
    };

    const getManagedInfo = () => {
      if (typeof state.managedPrice !== 'number')
        throw Error('Managed service price is required');
      if (typeof state.managedMonths !== 'number')
        throw Error('Managed service months is required');
      return state.isManaged
        ? {
            months: state.managedMonths,
            prepaid: state.managedPrepaid,
            price: state.managedPrice,
          }
        : null;
    };

    const steps = [
      () => (
        <OrderIntro
          nextStep={handleNext}
          setManagedService={(isManaged) =>
            setState((s) => ({ ...s, isManaged }))
          }
        />
      ),
      () => (
        <OrderTable
          previousStep={handleBack}
          nextStep={handleNext}
          initialItems={state.items}
          initialManagedPrice={state.managedPrice}
          saveItems={(items) => setState((s) => ({ ...s, items }))}
          isManaged={state.isManaged}
        />
      ),
      () => (
        <OrderRep
          nextStep={handleNext}
          previousStep={handleBack}
          initialRepId={state.repId}
          initialCustomerId={state.customerId}
          initialAddressId={state.addressId}
          save={saveRep}
          isManaged={state.isManaged}
          initialManagedPrice={state.managedPrice}
          initialManagedPrepaid={state.managedPrepaid}
          initialManagedMonths={state.managedMonths}
        />
      ),
      () => (
        <OrderConfirm
          items={state.items}
          repId={state.repId}
          addressId={state.addressId}
          customerId={state.customerId}
          prepaid={
            state.prepaid
              ? {
                  months: state.prepaidMonths,
                  plan: SensorDataPlan.active,
                  start: state.prepaidStart,
                }
              : null
          }
          previousStep={handleBack}
          completed={doComplete}
          sharingEmails={state.sharingEmails.filter((e) => e !== '')}
          sharingLevel={PermissionLevelInput.admin}
          managedInfo={state.isManaged ? getManagedInfo() : null}
        />
      ),
    ];

    return steps[step];
  };

  const { activeStep } = state;

  const stepTitles = [
    'Select Type of Order',
    'Select Hardware',
    'Select Rep & Shipping',
    'Finalize Order',
  ];

  const divStyle = isMobile ? {} : { padding: 16 };

  if (completed) {
    return (
      <div style={divStyle}>
        <OrderThanks />
      </div>
    );
  }

  return (
    <div style={divStyle}>
      <Stepper activeStep={activeStep}>
        {stepTitles.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div style={{ marginTop: 24 }} />
      <div style={{ maxWidth: 800, margin: 'auto' }}>
        {getStepContent(activeStep)()}
      </div>
    </div>
  );
};

export default Order;
