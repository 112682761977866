import * as React from 'react';
import TwoColumn from './TwoColumn';
import Spacer from './Spacer';
import VolumePricing from './VolumePricing';

interface ReplacementWeatherStationProps {
  finish: (amount: number) => void;
  prices:
    | {
        quantity: number;
        price: number;
      }[]
    | null;
  hidePrices: boolean;
}

class ReplacementWeatherStation extends React.Component<
  ReplacementWeatherStationProps
> {
  render() {
    return (
      <React.Fragment>
        <TwoColumn
          title="Replacement Davis Vantage Pro2™ GroWeather Cabled weather station"
          subtitle="Includes anemometer, rain collector, & temperature, humidity & solar irradiance sensors."
        />
        <Spacer />
        <VolumePricing
          finish={this.props.finish}
          disabled={false}
          prices={this.props.prices}
          hidePrices={this.props.hidePrices}
        />
      </React.Fragment>
    );
  }
}

export default ReplacementWeatherStation;
