import * as React from 'react';

import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import BasicModal from '../../../../components/BasicModal';

import BatteryAlert from '@material-ui/icons/BatteryAlert';
import SignalCellularNull from '@material-ui/icons/SignalCellularNull';
import AccessTime from '@material-ui/icons/AccessTime';
import ADCZERO from '@material-ui/icons/Power';
import { FontClasses } from 'services/theme';

class StatusTableModal extends React.Component {
  state = {
    open: false,
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };
  render() {
    const content = (
      <React.Fragment>
        <div>
          <div className={FontClasses.subtitle} id="simple-modal-description">
            <div style={{ padding: 2 }}>
              <div>
                These icons give more information on the performance of your
                customers hardware.
              </div>
              <br />
              <div>
                <BatteryAlert /> The battery level is low and may be causing
                connectivity issues. Consider replacing the batteries on sensor
                stations or manually charging the battery on base stations.
              </div>
              <br />
              <div>
                <SignalCellularNull /> The signal strength is weak and is
                causing connectivity issues. For sensor stations, there is a
                weak radio signal with the base station. For base stations,
                there is a weak cellular signal. Consider moving the devices to
                a location with better signal strength.
              </div>
              <br />
              <div>
                <AccessTime /> The device has not reported within the last 12
                hours and is {"'"}offline{"'"}. This could be the result from a
                variety of issues and often requires manual inspection of the
                device.
              </div>
              <br />
              <div>
                <ADCZERO /> One or more of the sensors on the sensor station are
                disconnected. The sensor needs to be re-attached to the terminal
                blocks on the sensor station.
              </div>
              <br />
            </div>
            <div
              style={{
                paddingBottom: '5px',
              }}
            />
          </div>
        </div>
      </React.Fragment>
    );
    return (
      <React.Fragment>
        <IconButton onClick={this.handleOpen}>
          <InfoIcon />
        </IconButton>
        <BasicModal
          open={this.state.open}
          onClose={this.handleClose}
          addCloseButton
          title="Hardware Warnings: What do they mean?"
          content={content}
        />
      </React.Fragment>
    );
  }
}

export default StatusTableModal;
