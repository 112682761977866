import { HardwareTypeEnum, MoistureMethod } from 'types/graphql';
import gql from 'graphql-tag';
import last from 'lodash/last';

export interface Model {
  type: HardwareTypeEnum;
  moistureDepths?: number[] | null;
  temperatureDepths?: number[] | null;
  ecDepths?: number[] | null;
  antennaLength?: number | null;
  moistureMethod?: MoistureMethod | null;
  hasRainGauge?: boolean | null;
  hasWeatherStation?: boolean | null;
  hasPressureSensor?: boolean | null;
  probeless?: boolean | null;
  custom?: boolean | null;
  length?: number | null;
  hasSpigot?: boolean | null;
  softwareVersion?: string | null;
}

export const computeModelId = (model: Model) => {
  const {
    type,
    moistureMethod,
    moistureDepths,
    ecDepths,
    hasWeatherStation,
    hasPressureSensor,
    probeless,
    length,
    hasSpigot,
  } = model;
  let id = '';
  if (type === 'replacementAntenna') return 'r-a';
  if (type === 'replacementWeatherStation') return 'r-ws';
  if (type === 'replacementSensorEnclosure') return 'r-se';
  if (type === 'replacementGatewayEnclosure') return 'r-ge';
  if (type === 'replacementRainGauge') return 'r-rg';
  if (type === 'replacementSensorProbe') id += 'r-sp';
  if (type === 'auger') id += 'auger';
  if (type === 'watermarkSensor') id += 'watermarkSensor';
  if (type === 'sensor') id += 'ss';
  if (type === 'replacementSensorBoard') id = 'r-cb-ss';
  if (type === 'replacementGatewayBoard') id = 'r-cb-gw';
  if (type === 'replacementMercuryModule') id = 'r-cb-mm';

  if (probeless) return id;
  if (type === 'gateway') id += 'cbs';
  if (moistureMethod && type !== 'auger' && type !== 'watermarkSensor') {
    id += '-';
    if (moistureMethod === 'tension') id += 'w';
    if (moistureMethod === 'capacitance' && !ecDepths) id += 'e';
    if (moistureMethod === 'capacitance' && ecDepths) id += 't';
  }
  if (moistureDepths) id += `-${moistureDepths.length}`;
  if (hasWeatherStation) id += '-ws';
  if (hasPressureSensor) id += '-ps';
  if (length) id += `-${length}`;
  if (hasSpigot) id += '-spigot';
  return id;
};

// Not used in the pricing table, but this helps
// with having an id for array keys etc
export const computeUniqueModelId = (model: Model) => {
  let id = computeModelId(model);
  if (model.hasRainGauge) id += '-rg';
  if (model.custom || model.probeless) {
    if (model.moistureDepths) id += `-m${model.moistureDepths}`;
    if (model.temperatureDepths) id += `-t${model.temperatureDepths}`;
    if (model.ecDepths) id += `-v${model.ecDepths}`;
  }
  if (model.antennaLength) id += `-a${model.antennaLength}`;
  if (model.moistureDepths) id += `m${last(model.moistureDepths)}`;
  return id;
};

export const getModelTitles = (model: Model) => {
  let title = '';
  if (model.type === HardwareTypeEnum.sensor) {
    if (model.moistureMethod === MoistureMethod.capacitance) {
      if (model.ecDepths !== null) {
        title += 'Sentek Tri-Scan';
      } else {
        title += 'Sentek';
      }
    } else {
      title += 'Watermark';
    }
    title += ' Sensor Station';
  }
  if (model.type === HardwareTypeEnum.gateway) title = 'Central Base Station';
  if (model.type === HardwareTypeEnum.replacementAntenna)
    title = 'Replacement Antenna';
  if (model.type === HardwareTypeEnum.replacementGatewayEnclosure)
    title = 'Replacement Base Station Enclosure';
  if (model.type === HardwareTypeEnum.replacementSensorEnclosure)
    title = 'Replacement Sensor Station Enclosure';
  if (model.type === HardwareTypeEnum.replacementRainGauge)
    title = 'Replacement Rain Gauge';
  if (model.type === HardwareTypeEnum.replacementSensorProbe)
    title = 'Replacement Sensor Station Probe';
  if (model.type === HardwareTypeEnum.replacementWeatherStation)
    title = 'Replacement Weather Station';
  if (model.type === HardwareTypeEnum.auger) {
    if (model.moistureMethod === MoistureMethod.tension) {
      title = 'Watermark Installation Auger';
    } else {
      title = 'Sentek Installation Auger';
    }
  }
  if (model.type === HardwareTypeEnum.watermarkSensor)
    title = 'Watermark Sensor';

  if (model.type === HardwareTypeEnum.replacementSensorBoard) {
    title = 'Replacement Sensor Board';
  }
  if (model.type === HardwareTypeEnum.replacementGatewayBoard) {
    title = 'Replacement Base Station Board';
  }
  if (model.type === HardwareTypeEnum.replacementMercuryModule) {
    title = 'Replacement Mercury Module';
  }
  const subtitleParts = [];
  if (model.antennaLength) {
    subtitleParts.push(`${model.antennaLength}' Antenna`);
  }
  if (model.moistureDepths) {
    subtitleParts.push(`${model.moistureDepths.join(', ')}" Depths`);
  }
  if (model.hasRainGauge) {
    subtitleParts.push('Includes Rain Gauge');
  }
  if (model.hasWeatherStation) {
    subtitleParts.push('Includes Weather Station');
  }
  if (model.hasPressureSensor) {
    subtitleParts.push('Includes Pressure Sensor');
  }
  if (model.probeless) {
    subtitleParts.push('No Probe');
  }
  if (model.type === HardwareTypeEnum.auger) {
    const unit = model.moistureMethod === MoistureMethod.tension ? 'in' : 'cm';
    subtitleParts.push(`${model.length} ${unit}`);
  }
  if (model.type === HardwareTypeEnum.watermarkSensor) {
    subtitleParts.push(`${model.length} ft`);
    if (model.hasSpigot) subtitleParts.push('Includes spigot');
  }

  if (model.type === HardwareTypeEnum.replacementSensorBoard) {
    if (model.moistureMethod === MoistureMethod.capacitance) {
      subtitleParts.push('Sentek');
    } else {
      subtitleParts.push('Watermark');
    }
  }

  if (model.type === HardwareTypeEnum.replacementGatewayBoard) {
    if (!model.hasWeatherStation && !model.hasPressureSensor) {
      subtitleParts.push('Regular, no add-ons');
    }
  }

  const subtitle = subtitleParts.join(' | ');

  return { title, subtitle };
};

export const makeGetPricing = (
  pricing: {
    id: string;
    hardware: {
      modelId: string;
      prices: {
        quantity: number;
        price: number;
      }[];
    }[];
  },
  rgCount: number
) => {
  return (model: Model, qtyOrNull: number | null) => {
    let qty = qtyOrNull === null ? 1 : qtyOrNull;
    if (qty < 1) qty = 1;
    const modelId = computeModelId(model);
    const sheet = pricing.hardware.find((hw) => hw.modelId === modelId);
    if (!sheet) throw Error(`No sheet for modelId ${modelId}`);
    let qtyPrice = 0;
    sheet.prices.forEach(({ price, quantity }) => {
      if (qty >= quantity) qtyPrice = price;
    });
    if (!qtyPrice) throw Error('Could not find price for the quantity');
    if (model.hasRainGauge) {
      const rgSheet = pricing.hardware.find((hw) => hw.modelId === 'rg');
      if (!rgSheet) throw Error('Could not find RG sheet');
      let rgPrice = 0;
      rgSheet.prices.forEach(({ price, quantity }) => {
        if (rgCount >= quantity) rgPrice = price;
      });
      if (model.type === HardwareTypeEnum.replacementSensorBoard) {
        rgPrice = 0;
        qtyPrice += rgPrice;
      } else {
        qtyPrice += rgPrice;
      }
    }
    const { custom, moistureDepths } = model;
    if (custom && moistureDepths) {
      const customSheet = pricing.hardware.find(
        (hw) => hw.modelId === `custom-${moistureDepths.length}`
      );
      if (!customSheet) throw Error('Could not find custom sheet');
      const customPrice = customSheet.prices[0].price;
      qtyPrice += customPrice;
    }
    return qtyPrice;
  };
};

export const MODEL_FIELDS = gql`
  fragment ModelFields on Model {
    antennaLength
    moistureDepths
    temperatureDepths
    ecDepths
    hasWeatherStation
    hasPressureSensor
    hasRainGauge
    moistureMethod
    type
    probeless
    custom
  }
`;
