import * as React from 'react';
import { Link } from '@reach/router';

import Paper from '@material-ui/core/Paper';
import ButtonBase from '@material-ui/core/ButtonBase';

import './HeaderButton.css';
import { FontClasses } from 'services/theme';

interface HeadersProps {
  title: string;
  to: string;
  icon: string;
}

const Headers = (props: HeadersProps) => {
  const { title, to, icon } = props;
  const boxPadding = 8;
  const boxHeight = 162 + boxPadding * 2;
  const boxWidth = boxHeight * 1.61803399;
  const boxStyle = {
    width: boxWidth,
    height: boxHeight,
    margin: 24,
  };

  // React router link does not support external links so we need this
  const isExternal = (url: string) => url.slice(0, 4) === 'http';
  const BothLink = (p: {
    to: string;
    style: React.CSSProperties;
    children: JSX.Element;
  }) =>
    isExternal(p.to) ? (
      <a href={p.to} {...p} target="_blank" rel="noopener noreferrer" />
    ) : (
      <Link {...p} />
    );
  return (
    <Paper style={boxStyle} elevation={2} className="headerButton">
      <BothLink to={to} style={{ textDecoration: 'none' }}>
        <ButtonBase
          disableRipple
          style={{
            padding: boxPadding,
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'space-around',
          }}
        >
          <div>
            <img src={icon} style={{ height: 96 }} />
          </div>
          <div>
            <div className={FontClasses.subtitle}>{title}</div>
          </div>
        </ButtonBase>
      </BothLink>
    </Paper>
  );
};

export default Headers;
