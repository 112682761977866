import React from 'react';

import HeaderButton from 'components/HeaderButton/HeaderButton';

const BrochureIcon = require('./assets/brochure.svg');
const PriceIcon = require('./assets/price.svg');
const ExternalIcon = require('./assets/external.svg');
const FolderIcon = require('./assets/folder.svg');

const Help: React.FC = () => {
  const helpBoxPadding = 8;
  const helpBoxHeight = 162 + helpBoxPadding * 2;
  const helpBoxWidth = helpBoxHeight * 1.61803399;
  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        margin: 'auto',
        justifyContent: 'center',
        maxWidth: helpBoxWidth * 4,
      }}
    >
      <HeaderButton
        title="Marketing Material"
        icon={FolderIcon}
        to="/help/marketing"
      />
      <HeaderButton
        title="Customer Support"
        icon={ExternalIcon}
        to="https://dealersupport.mytrellis.com"
      />
      <HeaderButton
        title="Price List"
        icon={PriceIcon}
        to="https://firebasestorage.googleapis.com/v0/b/project-2290036988929696476.appspot.com/o/DistributorPortal%2F%5BDealer%5D%202020%20Pricing%20Guide.pdf?alt=media&token=7706e2c3-5db6-4759-b899-99008da276ab"
      />
      <HeaderButton
        title="Managed Service Overview"
        icon={BrochureIcon}
        to="https://firebasestorage.googleapis.com/v0/b/project-2290036988929696476.appspot.com/o/DistributorPortal%2F2020%20Trellis%20Managed%20Service%20Overview%20.pdf?alt=media&token=f92a88d1-198e-407e-959b-3f0dc3a8be5d"
      />
    </div>
  );
};

export default Help;
