import React, { useState, useEffect } from 'react';
import gql from 'graphql-tag';

import { UserNameComponent } from 'types/graphql';

import { useIsMobile } from 'providers/Responsive';

import Appbar from './components/Appbar/Appbar';
import Sidebar from './components/Sidebar/Sidebar';
import chat from '../../services/chat';
import ErrorBoundary from 'components/Errors/ErrorBoundary';

gql`
  query UserName {
    currentDistributor {
      id
      isRep
      isDistributor
      isAdmin
      name {
        first
        last
      }
    }
  }
`;

const sideBarWidth = 94;
const appBarHeight = 64;

const Overlay: React.FC = (props) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const toggleDrawer = () => setDrawerOpen(!drawerOpen);
  const isMobile = useIsMobile();
  const drawerType = isMobile ? 'temporary' : 'permanent';
  useEffect(() => chat(drawerOpen, isMobile));

  return (
    <UserNameComponent>
      {({ data, loading, error }) => {
        const name = {
          first: '',
          last: '',
        };
        let isAdmin = false;
        let isDistributor = false;
        let isRep = false;
        if (error) {
          console.error('error getting user name: ', error);
        } else if (!loading && data) {
          const { currentDistributor } = data;
          if (currentDistributor) {
            if (currentDistributor.name) {
              if (currentDistributor.name.first)
                name.first = currentDistributor.name.first;
              if (currentDistributor.name.last)
                name.last = currentDistributor.name.last;
            }
            isAdmin = !!currentDistributor.isAdmin;
            isDistributor = !!currentDistributor.isDistributor;
            isRep = !!currentDistributor.isRep;
          }
        }

        return (
          <>
            <Appbar
              isMobile={isMobile}
              toggleDrawer={toggleDrawer}
              appBarHeight={appBarHeight}
              sideBarWidth={isMobile ? 0 : sideBarWidth}
              name={name}
            />
            <Sidebar
              isOpen={drawerOpen}
              toggleDrawer={toggleDrawer}
              drawerType={drawerType}
              sideBarWidth={sideBarWidth}
              isRep={isRep}
              isDistributor={isDistributor}
              isAdmin={isAdmin}
            />
            <div
              id="content"
              style={{
                paddingLeft: isMobile ? 0 : sideBarWidth,
                paddingTop: appBarHeight,
              }}
            >
              <ErrorBoundary>{props.children}</ErrorBoundary>
            </div>
          </>
        );
      }}
    </UserNameComponent>
  );
};
export default Overlay;
