import * as React from 'react';
import { RouteComponentProps } from '@reach/router';

import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import { bgImageStyle } from '../styles';
import TandC from './steps/TandC';
import ChangePassword from './steps/ChangePassword';
import TandCPreface from './steps/TandCPreface';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import { DesktopOnly, MobileOnly } from 'providers/Responsive';
import StepButton from '@material-ui/core/StepButton';
import StepContent from '@material-ui/core/StepContent';
import MobileStepper from '@material-ui/core/MobileStepper';

const TrellisBGImage = require('../TrellisBackgroundQ17.JPG');
const logo = require('../trellis_logo_full_color_dots_only.png');

type RegisterProps = RouteComponentProps<{}>;

interface RegisterState {
  step: number;
}

class Register extends React.Component<RegisterProps, RegisterState> {
  state = { step: 0 };
  steps: { title: string; content: () => JSX.Element }[];
  constructor(props: RegisterProps) {
    super(props);

    // Get token
    const { location } = this.props;
    if (!location) throw Error();
    const { search } = location;
    let queryData = { uid: '', token: '', email: '' };
    queryData = JSON.parse(atob(search.slice(6)));

    this.steps = [
      {
        title: 'Terms and Conditions Preface',
        content: () => (
          <TandCPreface
            next={() => this.setState({ step: this.state.step + 1 })}
          />
        ),
      },
      {
        title: 'Terms and Conditions',
        content: () => (
          <TandC
            uid={queryData.uid}
            token={queryData.token}
            next={() => this.setState({ step: this.state.step + 1 })}
          />
        ),
      },
      {
        title: 'Set Password',
        content: () => (
          <ChangePassword
            userId={queryData.uid}
            token={queryData.token}
            email={queryData.email}
          />
        ),
      },
    ];
  }

  render() {
    const { step } = this.state;

    return (
      <React.Fragment>
        <DesktopOnly>
          <div
            style={{
              flexDirection: 'column',
              minHeight: '100vh',
              minWidth: '100%',
              display: 'flex',
              justifyContent: 'space-around',
            }}
          >
            <img src={TrellisBGImage} style={bgImageStyle} />
            <Paper
              elevation={4}
              style={{
                maxWidth: '100vw',
                width: 800,
                margin: 'auto',
                marginTop: 24,
                marginBottom: 24,
              }}
            >
              <img
                src={logo}
                style={{
                  margin: 'auto',
                  display: 'block',
                  marginTop: 24,
                  maxWidth: 128,
                }}
                alt="logo"
              />
              <Stepper nonLinear activeStep={step} orientation="vertical">
                {this.steps.map(({ title, content }, i) => {
                  return (
                    <Step key={title} disabled={i > step}>
                      <StepButton completed={i < step} disabled>
                        {title}
                      </StepButton>
                      <StepContent>{content()}</StepContent>
                    </Step>
                  );
                })}
              </Stepper>
            </Paper>
          </div>
        </DesktopOnly>

        <MobileOnly>
          <Paper
            style={{ width: '100%', position: 'fixed', top: 0, zIndex: 1 }}
          >
            <h1 style={{ paddingLeft: 20 }}>{this.steps[step].title}</h1>
          </Paper>
          <div
            style={{
              position: 'absolute',
              top: 100,
              left: 0,
              padding: '0px 20px',
              marginBottom: 60,
            }}
          >
            {this.steps[step].content()}
          </div>
          <MobileStepper
            steps={this.steps.length}
            activeStep={step}
            nextButton={
              <Button
                size="small"
                onClick={() => this.setState({ step: step + 1 })}
                disabled={step === 1}
              >
                Next
              </Button>
            }
            backButton={
              <Button
                size="small"
                onClick={() => this.setState({ step: step - 1 })}
                disabled={step === 0}
              >
                Back
              </Button>
            }
          />
        </MobileOnly>
      </React.Fragment>
    );
  }
}

export default Register;
