import React, { Suspense, lazy } from 'react';
import Loading from 'components/Loading/Loading';

const AdminCustomerList = lazy(() =>
  import(/* webpackChunkName: "AdminCustomerList" */ './AdminCustomerList')
);

const LoadableAdminCustomerList: React.FC = props => (
  <Suspense fallback={<Loading />}>
    <AdminCustomerList {...props} />
  </Suspense>
);

export default LoadableAdminCustomerList;
