import * as React from 'react';
import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Marker,
} from 'react-google-maps';

import { stateToColor } from 'services/SensorTension';
import { getRGMapIconURL } from 'services/iconPicker/IconPicker';

import { DeviceWithLocation, MAX_ZOOM, gatewayIconUrl } from './Device';
import { ConnectionStatus } from 'types/graphql';

const markerBase =
  'https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|';

const getMarkerIcon = (device: DeviceWithLocation) => {
  if (device.hasRainGauge) {
    return {
      url: getRGMapIconURL(device.state),
      scaledSize: new google.maps.Size(25, 35),
    };
  } else if (
    device.type === 'sensor' &&
    device.connectionStatus === ConnectionStatus.offline
  ) {
    return { url: `${markerBase}FF4026` };
  } else if (device.type === 'sensor') {
    return { url: `${markerBase}${stateToColor(device.state).slice(1)}` };
  } else if (device.type === 'gateway') {
    return { url: gatewayIconUrl, scaledSize: new google.maps.Size(32, 32) };
  } else {
    return undefined;
  }
};

interface DevicesMapViewProps {
  devices: DeviceWithLocation[];
  onMarkerClicked: (id: string) => void;
  hovered?: string;
}

interface DevicesMapViewState {
  initialBoundsSet: boolean;
  maxZoom: number;
}

class DevicesMapView extends React.Component<
  DevicesMapViewProps,
  DevicesMapViewState
> {
  state = {
    initialBoundsSet: false,
    maxZoom: MAX_ZOOM,
  };

  map: GoogleMap | null = null;

  render() {
    const { devices, onMarkerClicked, hovered } = this.props;
    const bounds = new google.maps.LatLngBounds();
    devices.forEach(({ location }) => bounds.extend(location));
    return (
      <GoogleMap
        ref={map => {
          if (map && !this.state.initialBoundsSet) {
            this.map = map;
            map.fitBounds(bounds);
            this.setState({ initialBoundsSet: true });
          }
        }}
        mapTypeId={google.maps.MapTypeId.HYBRID}
        options={{
          streetViewControl: false,
          disableDefaultUI: true,
          maxZoom: this.state.maxZoom,
          zoomControl: true,
          draggable: true,
          gestureHandling: 'greedy',
        }}
        onBoundsChanged={() => {
          if (this.state.maxZoom !== 0) {
            this.setState({ maxZoom: 0 });
          }
        }}
      >
        {devices.map(device => {
          const { id, name, location, type } = device;
          const icon = getMarkerIcon(device);
          let displayName: string | undefined;
          if (device.hasRainGauge === true) {
            displayName = name
              ? `${name} - Rain Gauge`
              : `Sensor ${id} - Rain Gauge`;
          } else if (type === 'sensor') {
            displayName = name ? name : `Sensor ${id}`;
          }
          if (type === 'gateway') displayName = `Base Station ${id}`;

          return (
            <Marker
              key={device.id}
              position={location}
              title={displayName}
              icon={icon}
              onClick={() => onMarkerClicked(id)}
              defaultAnimation={google.maps.Animation.DROP}
              zIndex={device.type === 'sensor' ? 1 : 0}
              animation={
                hovered === device.id ? google.maps.Animation.BOUNCE : undefined
              }
              opacity={device.opacity}
            />
          );
        })}
      </GoogleMap>
    );
  }
}

export default withScriptjs(withGoogleMap(DevicesMapView));
