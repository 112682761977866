import * as React from 'react';
import moment from 'moment';

/* eslint-disable react/no-unescaped-entities */

interface TandCContentProps {
  style?: React.CSSProperties;
  name: string;
  loc: string;
}

const TandCContent = React.forwardRef<HTMLDivElement, TandCContentProps>(
  (props, ref) => {
    const now = moment();
    const day = now.format('Mo');
    const month = now.format('MMMM');
    const name = props.name || '_______';
    const loc = props.loc || '________';
    const priceListUrl =
      'https://docs.zoho.com/file/bcoqzf24e3d7f18ac40a6a00abe48e38886f4';
    return (
      <div style={props.style} ref={ref}>
        <p>
          <strong>NON-EXCLUSIVE DEALER AGREEMENT</strong>
        </p>
        <p>
          THIS AGREEMENT is made this {day} day of {month}, 2019, by and between
          Trellis, Inc., with its principal place of business located at 147
          Technology Parkway, Suite 100, Peachtree Corners, GA, 30092 (the
          "Company") and {name}, with its principal place of business located at{' '}
          {loc} (the "Dealer").
        </p>
        <p>
          NOW, THEREFORE, in consideration of the promises hereinafter made by
          the parties hereto, it is agreed as follows:
        </p>

        <strong>ARTICLE I</strong>
        <p>APPOINTMENT OF DEALERSHIP</p>
        <ol>
          <li>
            Distribution Right. The Company hereby appoints and grants the
            Dealer the non-exclusive right to sell the equipment of the Company
            ("Equipment") listed in the current "Price List" (Exhibit "A"
            attached) in the Territory (as defined in Exhibit &ldquo;B&rdquo;)
            during the term of this Agreement.
          </li>
          <li>
            Sales Limited to Territory. Dealer shall not solicit orders for the
            Equipment from any prospective purchaser with its principal place of
            business located outside the Territory, as defined in Exhibit
            &ldquo;B&rdquo; without Company&rsquo;s permission. If Dealer
            receives any order for the Equipment from a prospective purchaser
            whose principal place of business is located outside the Territory,
            Dealer shall promptly refer that order to the Company and Dealer
            shall not accept any such orders without Company&rsquo;s permission.
            Dealer may not deliver or tender (or cause to be delivered or
            tendered) any Equipment outside of the Territory without
            Company&rsquo;s permission.
          </li>
          <li>
            Modification of Equipment and Territories. The Company reserves the
            right, upon consultation with Dealer, to expand or reduce the
            Equipment which is covered by this Agreement. The Company also
            reserves the right, upon consultation with Dealer, to expand or
            reduce the Territory, as defined in this Agreement.
          </li>
          <li>
            Competing Products. Nothing herein shall restrict Dealer from having
            the right to: (i) obtain or retain the rights to use, sell, resell,
            offer for sale and/or distribute any other goods, products or
            equipment, including but not limited to goods, products or equipment
            that may compete with the Equipment; and/or (ii) enter into
            agreements with any other entity related to the sale or distribution
            of other goods, products or equipment, including but not limited to
            goods, products or equipment that may compete with the Equipment.
            Dealer is not required to purchase any minimum amount or quantity of
            Equipment under this Agreement.
          </li>
          <li>
            Prices. All prices stated are FOB the Company's offices at 147
            Technology Parkway, Suite 100, Peachtree Corners, GA 30092. Prices
            do not include transportation and insurance costs which shall be
            borne by Dealer. Prices do not include federal, state or local taxes
            applicable to the products sold under this Agreement. An amount
            equal to the appropriate taxes will be added to the invoice by the
            Company where the Company has the legal obligation to collect such
            taxes. Dealer shall pay such amount to the Company unless Dealer
            provides Company with a valid tax exemption certificate authorized
            by the appropriate taxing authority.
          </li>
          <li>
            Customer Prices. Dealer shall be free to establish its own pricing
            for Equipment sold pursuant to this Agreement. Dealer shall notify
            the Company of its pricing, as in effect from time to time.
          </li>
          <li>
            Terms. 100% of payment is due upon placing an order. The Company
            will begin preparing the units once payment is received. The units
            will be available for delivery or shipped no later than four weeks
            after the order has been placed, unless otherwise agreed to by both
            parties.
          </li>
          <li>
            Title to Equipment. The Company hereby reserves a purchase money
            security interest in each unit of Equipment sold or to be sold under
            this Agreement and in the proceeds thereof, if Dealer shall have
            sold or leased a unit(s) to another party prior to Dealer paying
            Company the purchase price for such Unit as set forth herein, in the
            amount of such unit's purchase price. These interests will be
            satisfied by payment in full. A copy of this Agreement may be filed
            with the appropriate authorities at any time after the signature by
            the Company as a financing statement in order to perfect the
            Company's security interest. On the request of the Company, Dealer
            shall execute financing statement(s) and other instruments the
            Company shall desire to perfect a security interest in the Equipment
            for its purchase price. Title to the Equipment shall pass to Dealer
            upon receipt by the Company of payment in full for all amounts due
            for such units of Equipment.
          </li>
        </ol>

        <strong>ARTICLE II</strong>
        <p>MARKETING AND SUPPORT</p>
        <ol>
          <li>
            Sales. Dealer shall use commercially reasonable efforts to promote
            the sale and distribution of the Equipment and to provide adequate
            support, which efforts shall include the following:
          </li>
          <ol type="a">
            <li>
              Provide a trained sales and technical staff to promote the sale
              and support of the Equipment;
            </li>
            <li>
              Undertake promotional campaigns and canvas prospective users to
              stimulate the sales of Equipment;
            </li>
            <li>
              Provide Company with Point of Sale report detailing the end
              customer&rsquo;s name, address, and phone number. Reporting forms
              will be specified by the Company and provided to the Dealer.
            </li>
            <li>
              Provide Company with forecasts every month of its probability
              requirements for the next three (3) months for Equipment and
              accessories, such forecasts to be in such manner and on forms to
              be specified by Company and agreed to by Dealer.
            </li>
          </ol>
          <li>
            Advertising. Company shall, upon request, assist the Dealer on all
            advertising, sales promotion, and public relations campaigns to be
            conducted, including providing Dealer with documentation of previous
            promotional campaigns conducted in connection with the Equipment,
            and shall provide necessary technical information and assistance.
          </li>
          <li>
            Training. Company shall furnish training of Dealer's sales and
            technical representatives at various times and locations as shall be
            designated for this purpose by the Company. Training shall be
            limited to a reasonable number of persons who shall be sufficiently
            qualified to participate. The Company is not responsible for the
            salaries or any travel, lodging or subsistence expenses incurred by
            the Dealer&rsquo;s representatives.
          </li>
        </ol>

        <strong>ARTICLE III</strong>
        <p>DELIVERY</p>
        <ol>
          <li>
            Purchase Orders. Dealer shall order Equipment on the Company&rsquo;s
            website (
            <a href={'http://mytrellis.com/'}>{'http://mytrellis.com'}</a>) or
            by email. Each order shall specify the number of units, the type of
            units (as identified by Company model number designations indicated
            in the Price List) including all optional features, and the desired
            method of delivery. Company shall indicate its acceptance by
            returning an electronic invoice of the purchase. Company agrees to
            ship units to Dealer as close as possible to the delivery schedule
            set forth in each order as accepted by Company, unless Company
            otherwise indicates in writing. Company shall not be required to
            honor any release which: (a) specifies a shipping date earlier than
            Company's then current delivery schedule for the date such release
            is received by Company and/or (b) specifies a quantity to be
            delivered in any one month within the current delivery schedule
            which is greater than one hundred percent (100%) of the total
            quantity shipped in the preceding sixty (60) day period.
          </li>
          <li>
            Equipment Acceptance. The criterion for acceptance of Company
            Equipment by Dealer shall be the successful operation of the
            Equipment using Company's standard test procedures and diagnostic
            test programs applicable to the Equipment involved.
          </li>
          <li>
            Shipment. Dealer can choose to pick-up Equipment from
            Company&rsquo;s office or to ship Equipment to a designated address.
            All shipments of Equipment shall be made FOB Company's office and
            liability for loss or damage in transit, or thereafter, shall pass
            to Dealer upon Company's delivery of Equipment to a common carrier
            for shipment. Shipping dates are approximate and are based, to a
            great extent, on prompt receipt by Company of all necessary ordering
            and shipment information from Dealer. Company or Dealer shall not be
            in default by reason of any failure in its performance under this
            Agreement if such failure results from, whether directly or
            indirectly, fire, explosion, strike, freight embargo, Act of God or
            of the public enemy, war, civil disturbance, unusually severe
            weather, default of any other manufacturer or a supplier or
            subcontractor, quarantine, restriction, epidemic, or catastrophe,
            lack of timely instructions or essential information from Dealer, or
            otherwise arisen out of causes beyond the control of the Company.
            Nor shall the Company at any time be liable for any incidental,
            special or consequential damages.
          </li>
          <li>
            Cancellation. Dealer may, at any time prior to the scheduled date of
            shipment, cancel any or all Equipment or order upon giving timely
            written notice and upon payment of the following inventory credits
            for each unit cancelled. The nonrefundable inventory credits listed
            below may be applied to future purchases of Equipment made by the
            Dealer. The inventory credits are as follows:
          </li>
        </ol>
        <table>
          <tbody>
            <tr>
              <td>
                <p>
                  <strong># of units in order</strong>
                </p>
              </td>
              <td>
                <p>
                  <strong>
                    Inventory Credits for Units Expressed as a Percentage of
                    Purchase Price:
                  </strong>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>50+</p>
              </td>
              <td>
                <p>50%</p>
              </td>
            </tr>
          </tbody>
        </table>

        <strong>ARTICLE IV</strong>
        <p>PROPRIETARY RIGHTS</p>
        <ol>
          <li>
            Use of Company Name. Company expressly prohibits any direct or
            indirect use, reference to, or other employment of its name,
            trademarks, or trade name exclusively licensed to Company, except as
            specified in this Agreement or as expressly authorized by Company in
            writing. Company hereby authorizes and requires Dealer's use of the
            Company's insignia or lettering which will be on the Equipment at
            the time of the delivery. The Company shall provide to the Dealer
            authorized company insignia or lettering for use on any letterhead,
            invoices, promotional material or otherwise.
          </li>
          <p>
            If authorized company insignia is used on any letterhead, invoices,
            promotion material or otherwise by Dealer, Dealer will, on
            termination of this Agreement, or upon request of Company,
            discontinue the use of such authorized company insignia on any
            letterhead, invoices, promotion material or otherwise and thereafter
            will not use, either directly or indirectly in connection with its
            business, such insignia or any other names, titles of expressions so
            nearly resembling the same as would likely lead to confusion or
            uncertainty, or to deceive the public.
          </p>
          <li>
            Drawings and Data. The Company normally supplies all necessary data
            for the proper installation, test, operation and maintenance of its
            Equipment. Portions of this data are proprietary in nature and will
            be so marked. The Dealer agrees to abide by the terms of such
            markings and to be liable for all loss or damage incurred by the
            Company as a result of the improper or unauthorized use of such
            data. The Company retains for itself all proprietary rights in and
            to all designs, engineering details, and other data pertaining to
            any Equipment specified in the contract and to all discoveries
            inventions, patent rights, etc., arising out of work done in
            connection with the contract and to any and all Equipment developed
            as a result thereof, including the sole right to manufacture any and
            all such products. The Dealer shall not contact the Company's
            suppliers, or any other person, for the purpose of manufacture.
          </li>
        </ol>

        <strong>ARTICLE V</strong>
        <p>WARRANTY</p>
        <ol>
          <li>
            Equipment Warranty. Company represents and warrants that Dealer
            shall acquire Equipment purchased hereunder free and clear of all
            liens and encumbrances except for Company's purchase money security
            interest defined in Article I-4, above. Company further represents
            and warrants all Equipment to be free from defects in material or
            workmanship under normal use and service for a period of six (6)
            months from the date of delivery. All repair covered by this
            warranty must be done at Company's factory, or other such warranty
            repair facilities of Company as designated by Company unless Company
            specifically directs that this service be performed at another
            location. Any defect corrected within ninety (90) days and found to
            be within this scope of the warranty will be repaired by Company and
            all charges for labor and material, will be borne by Company. If it
            is determined that either no fault exists in Company, or the damage
            to be repaired was caused by negligence of Dealer, its agents, or
            employees or customers, Dealer agrees to pay all charges associated
            with each such repair. THIS CONSTITUTES THE SOLE WARRANTY MADE BY
            COMPANY EITHER EXPRESSED OR IMPLIED. THERE ARE NO OTHER WARRANTIES
            EXPRESSED OR IMPLIED WHICH EXTEND BEYOND THE FACE HEREOF, HEREIN,
            INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR
            A PARTICULAR PURPOSE. IN NO EVENT SHALL COMPANY BE LIABLE FOR ANY
            INCIDENTAL OR CONSEQUENTIAL DAMAGES AND DEALER'S REMEDIES SHALL BE
            LIMITED TO REPAIR OR REPLACEMENT OF NONCONFORMING UNITS OR PARTS.
          </li>
          <li>
            Misuse of Equipment. Any tampering, misuse or negligence in handling
            or use of Equipment renders the warranty void. Further, the warranty
            is void if, at any time, Dealer attempts to make any internal
            changes to any of the components of the Equipment; if at any time
            the power supplied to any part of the Equipment exceeds the rated
            tolerance; if any external device attached by Dealer creates
            conditions exceeding the tolerance of the Equipment; or if any time
            the serial number plate is removed or defaced. OPERATION OF THE
            EQUIPMENT THAT RENDERS THIS WARRANTY VOID WILL BE DEFINED TO INCLUDE
            ALL OF THE POSSIBILITIES DESCRIBED IN THIS PARAGRAPH, TOGETHER WITH
            ANY PRACTICE WHICH RESULTS IN CONDITIONS EXCEEDING THE DESIGN
            TOLERANCE OF THE EQUIPMENT.
          </li>
        </ol>

        <strong>ARTICLE VI</strong>
        <p>SPARES</p>
        <ol>
          <li>
            Availability. Spares, as used herein, shall be defined as Company's
            standard subassemblies and parts used to fabricate and/or repair the
            Equipment manufactured by the Company. Company shall make spares for
            purchase by Dealer for a period of not less than one (1) year after
            shipment of the last unit to Dealer hereunder. Such spares will be
            available to Dealer at prices, terms and conditions in effect at the
            time such spares are purchased. No provision in this Paragraph, or
            in any other part of this Agreement, shall relieve Dealer of
            Dealer's responsibility to stock spares. Dealer is expected to
            maintain an adequate inventory of spares to support the Equipment
            purchased hereunder.
          </li>
        </ol>

        <strong>ARTICLE VII</strong>
        <p>DURATION OF AGREEMENT</p>
        <ol>
          <li>
            Term. The term of this Agreement shall be for one year from the date
            hereof and shall automatically renew each year for another one-year
            term, unless either party provides notice to the other of its intent
            to not to renew this agreement not less than thirty (30) days before
            the end of the then current term. Expiration or termination shall
            not relieve either party of obligations incurred prior thereto.
          </li>
          <li>Termination. This Agreement may be terminated early only:</li>
          <ol type="a">
            <li>
              By either party for substantial breach of any material provision
              of this Agreement by the other, provided due notice has been given
              to the other of the alleged breach and such other party has not
              cured the breach within thirty (30) days thereof; or
            </li>
            <li>
              By either party if: the other party ceases to function as a going
              concern or makes an assignment for the benefit of creditors; if a
              petition in bankruptcy is filed by or against the other party,
              resulting in an adjudication of bankruptcy; or, if the other party
              fails to pay its debts as they become due and provided due notice
              has been given to it and it has not cured such breach within
              thirty (30) days thereof;
            </li>
            <li>
              Upon termination of this Agreement all further rights and
              obligations of the parties shall cease, except that Dealer shall
              not be relieved of (i) its obligation to pay any monies due, or to
              become due, as of or after the date of termination, and (ii) any
              other obligation set forth in this Agreement which is to take
              effect after the date of termination. Dealer shall have the right
              to continue to purchase spare parts in accordance with Article VI.
            </li>
          </ol>
        </ol>

        <strong>ARTICLE VIII</strong>
        <p>NOTICES</p>
        <ol>
          <li>
            Notice or Communication. Any notice or communication required or
            permitted hereunder (other than Administrative Notice) shall be sent
            by email to{' '}
            <a href="mailto:info@mytrellis.com">info@mytrellis.com</a>. If
            necessary, written notices and communication to the Company may
            additionally be sent to:
          </li>
          <p>Trellis, Inc.</p>
          <p>147 Technology Parkway</p>
          <p>Suite 100</p>
          <p>Peachtree Corners, GA 30092</p>
          <p>
            Notices and communications to Dealer shall be sent by email to the
            address indicated on the Signature Page or by mail to address shown
            on first page of this Agreement. Any notices or communications to
            either party hereunder shall be deemed to have been given when the
            email has been sent, addressed to the then current email address of
            such party.
          </p>
          <li>
            Date of Effectiveness. Any such notice or communication so emailed
            shall be deemed delivered and effective twenty-four (24) hours after
            sending. Mailed correspondence shall be deemed delivered and
            effective seventy-two (72) hours after mailing within the United
            States and fourteen (14) days after mailing internationally.
          </li>
        </ol>

        <strong>ARTICLE IX</strong>
        <p>GENERAL PROVISIONS</p>
        <ol>
          <li>
            Relationship of Parties. The relationship between the parties
            established by this Agreement shall be solely that of vendor and
            vendee and all rights and powers not expressly granted to the Dealer
            are expressly reserved to the Company. The Dealer shall have no
            right, power or authority in any way to bind the Company to the
            fulfillment of any condition not herein contained, or to any
            contract or obligation, expressed or implied.
          </li>
          <li>
            Independence of Parties. Nothing contained in this Agreement shall
            be construed to make the Dealer the agent for the Company for any
            purpose, and neither party hereto shall have any right whatsoever to
            incur any liabilities or obligations on behalf or binding upon the
            other party. The Dealer specifically agrees that it shall have no
            power or authority to represent the Company in any manner; that it
            will solicit orders for products as an independent contractor in
            accordance with the terms of this Agreement; and that it will not at
            any time represent the Company in any manner; and that it will not
            at any time represent orally or in writing to any person or
            corporation or other business entity that it has any right, power or
            authority not expressly granted by this Agreement.
          </li>
          <li>
            Indemnity. The Dealer agrees to hold the Company free and harmless
            from any and all claims, damages, and expenses of every kind or
            nature whatsoever (a) arising from acts of the Dealer; (b) as a
            direct or indirect consequence of termination of this Agreement in
            accordance with its terms; or (c) arising from acts of third parties
            in relation to products sold to the Dealer under this Agreement,
            including, but not limited to execution of liens and security
            interests by third parties with respect to any such products.
          </li>
          <li>
            Assignment. Dealer shall not transfer or assign this Agreement or
            any part thereof without the advance written consent of Company.
          </li>
          <li>
            Entire Agreement. The entire Agreement between the Company and the
            Dealer covering the Equipment is set forth herein and any amendment
            or modification shall be in writing and shall be executed by duly
            authorized representatives in the same manner as this Agreement,
            provided however, that the parties agree that any confidential
            information exchanged between them pursuant to this Agreement shall
            be governed by the separate Non-Disclosure Agreement executed by the
            parties on or about December 21, 2017, the terms of which are hereby
            incorporated by reference. The provisions of this Agreement are
            severable, and if any one or more such provisions are determined to
            be illegal or otherwise unenforceable, in whole or in part, under
            the laws of any jurisdiction, the remaining provisions or portions
            hereof shall, nevertheless, be binding on and enforceable by and
            between the parties hereto. Any provisions, terms or conditions of
            Dealer's Purchase Orders which are, in any way contradicting of this
            Agreement, except those additional provisions specifying quantity
            and shipping instructions, shall not be binding upon Company and
            shall have no applicability to the sale of goods by Company to
            Dealer. This Agreement may be executed in counterparts, each of
            which shall be considered an original. Signatures may be delivered
            electronically or by facsimile, and Company and Dealer agree to
            accept and be bound by electronic and facsimile copies of original
            signatures to this Agreement.
          </li>
          <li>
            Applicable Law. This Agreement shall be governed by the laws of the
            State of Georgia and is accepted by Company at its Corporate Office
            in Peachtree Corners, GA. All payments hereunder shall be made
            electronically via bank transfer or credit card transaction.
            Company's rights granted hereby are cumulative and in addition to
            any rights it may have at law or equity.
          </li>
          <li>
            Separate Provisions. If any provision of this Agreement shall be
            held to be invalid, illegal or unenforceable, the validity, legality
            and enforceability of the remaining provisions shall in no way be
            affected or impaired thereby.
          </li>
        </ol>

        <strong>EXHIBIT A: 2019 DEALER PRICE LIST</strong>
        <p>
          Shipping and insurance not included in prices. Contact Trellis, Inc.
          for other order quantities not listed.
        </p>
        <p>
          Price list can be viewed at{' '}
          <a href={priceListUrl} target="_blank" rel="noopener noreferrer">
            {priceListUrl}
          </a>
        </p>

        <strong>EXHIBIT B: DEALER TERRITORY</strong>
        <p>
          The Company is appointing the Dealer hereunder with respect to the
          sale of Equipment to any purchasers whose principal place of business
          is located in the following described territory (the “Territory”): the
          United States
        </p>
      </div>
    );
  }
);

export default TandCContent;
