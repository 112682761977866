import React, { Suspense, lazy } from 'react';
import Loading from 'components/Loading/Loading';

const AdminCreateDistributor = lazy(() =>
  import(/* webpackChunkName: "AdminCreateDistributor" */ './AdminCreateDistributor')
);

const LoadableAdminCreateDistributor: React.FC = props => (
  <Suspense fallback={<Loading />}>
    <AdminCreateDistributor {...props} />
  </Suspense>
);

export default LoadableAdminCreateDistributor;
